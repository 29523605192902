import React from "react";
import { Spinner } from "Utils/SvgIcons";

const LoadingSpinner = ({ loading = true, label, ...other }) => {
  if (!loading) {
    return <div />;
  }

  return (
    <div className="spinner">
      <Spinner />
      <p>{label}</p>
    </div>
  );
};

export default LoadingSpinner;
