import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import * as actions from "Actions";
import { ROOT_URL } from "Api";
import { displayPricingType } from "Constants/displayConstants";
import { TapGoods, Info } from "Utils/SvgIcons";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import { combineDateAndTime, toNumber } from "HelperFunctions/general";
import {
  showSelectedPrice,
  hasSelectedFlatPrice,
  getSelectedFlatPrice,
} from "HelperFunctions/rental_items";
import { setFilterParamsBusiness } from "HelperFunctions/urls";
import {
  getFirstAvailablePricingType,
  getFirstAvailablePricingObject
} from "HelperFunctions/rentals";
import Imgix from "react-imgix";
import { sendSearchId } from "../../actions/ProductActions";
import CurrencyLabel from "Utils/CurrencyLabel";

class AvailableItemCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: "",
      submitted: false,
      open: false
    };
  }

  handleQuantityChange = event => {
    const { value } = event.target;
    const newValue = toNumber(value.replace(/[^0-9]/g, ""));
    this.setState({
      quantity: newValue,
      submitted: false
    });
  };

  handleAddToQuote = () => {
    const { quantity } = this.state;
    if (quantity) {
      if (this.props.product.productType === "items") {
        this.handleAddItemToQuote();
      } else if (this.props.product.productType === "add_ons") {
        this.handleAddPurchaseToQuote();
      } else {
        this.handleAddBundleToQuote();
      }
    }
  };

  handleAddPurchaseToQuote = () => {
    const {
      event: { eventStart, eventStartTime, eventEnd, eventEndTime, addOns },
      product,
      setCartProps,
      searchId
    } = this.props;

    const locationName = product.businessInfo.name;
    const { quantity } = this.state;
    let newItems = { ...addOns };
    if (!newItems.hasOwnProperty(locationName)) {
      newItems[locationName] = [];
    }
    const oldItemIndex = newItems[locationName].findIndex(
      item => item.addOnId === product.id
    );
    let itemQuantity =
      oldItemIndex !== -1
        ? newItems[locationName][oldItemIndex].quantity + Number(quantity)
        : Number(quantity);
    const selected = showSelectedPrice(
      product,
      itemQuantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      "add_ons"
    );
    const newItem = {
      name: product.name,
      type: "RentalAddOnStandard",
      quantity: itemQuantity,
      addOnId: product.id,
      addOn: product,
      pricing: product.pricing,
      description: product.description,
      holidayHourlyPrice: product.holidayHourlyPrice,
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period
    };

    if (oldItemIndex !== -1) {
      newItems[locationName][oldItemIndex] = newItem;
    } else {
      newItems[locationName].push(newItem);
    }

    sendSearchId(searchId, "AddOn", product.id);
    setCartProps({
      addOns: newItems
    });

    this.setState({
      submitted: true
    });
  };

  handleAddItemToQuote = () => {
    const {
      event: { eventStart, eventStartTime, eventEnd, eventEndTime, items },
      product,
      setCartProps,
      searchId
    } = this.props;

    const { locationName } = product;
    const { quantity } = this.state;
    let newItems = { ...items };
    if (!newItems.hasOwnProperty(locationName)) {
      newItems[locationName] = [];
    }
    const oldItemIndex = newItems[locationName].findIndex(
      item => item.productId === product.id
    );
    let itemQuantity =
      oldItemIndex !== -1
        ? newItems[locationName][oldItemIndex].quantity + Number(quantity)
        : Number(quantity);
    const selected = showSelectedPrice(
      product,
      itemQuantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      "items"
    );
    let newRentalItemCustomTaxRelationship;
    if (product.customTaxes && product.customTaxes.length) {
      newRentalItemCustomTaxRelationship = {
        customTaxId: product.customTaxes[0].id,
        _destroy: "0",
        name: product.customTaxes[0].name,
        amount: product.customTaxes[0].amount,
        amountType: product.customTaxes[0].amountType
      }
    }
    const newItem = {
      name: product.name,
      type: "RentalItemStandard",
      quantity: itemQuantity,
      productId: product.id,
      product: product,
      defaultPricing: product.defaultPricing,
      hourlyPrice: product.hourlyPrice,
      halfDayPrice: product.halfDayPrice,
      dailyPrice: product.dailyPrice,
      weeklyPrice: product.weeklyPrice,
      monthlyPrice: product.monthlyPrice,
      // flatPrice: product.flatPrice,
      flatPrices: product.flatPrices.map(
        flatPrice => ({
          id: "",
          name: flatPrice.name,
          amount: flatPrice.amount,
          pricingLabelId: flatPrice.pricing_label_id
        })
      ),
      description: product.description,
      holidayHourlyPrice: product.holidayHourlyPrice,
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period,
      rentalItemCustomTaxRelationship: newRentalItemCustomTaxRelationship,
    };

    if (oldItemIndex !== -1) {
      newItems[locationName][oldItemIndex] = newItem;
    } else {
      newItems[locationName].push(newItem);
    }

    sendSearchId(searchId, "Product", product.id);

    setCartProps({
      items: newItems
    });

    this.setState({
      submitted: true
    });
  };

  handleAddBundleToQuote = () => {
    const {
      event: {
        rentalBundles
      },
      product: bundle,
      addBundleInCart,
      updateBundleInCart,
      setErrors,
      searchId
    } = this.props;

    const { locationName } = bundle;
    const { quantity } = this.state;

    if (quantity > 0) {
      let newRentalBundles = { ...rentalBundles };
      if (!newRentalBundles.hasOwnProperty(locationName)) {
        newRentalBundles[locationName] = [];
      }
      const rentalBundleIndex = newRentalBundles[locationName].findIndex(
        item => item.bundleId === bundle.id
      );
      if (rentalBundleIndex !== -1) {
        let bundleQuantity = newRentalBundles[locationName][rentalBundleIndex].quantity + Number(quantity);
        updateBundleInCart('quantity', bundleQuantity, rentalBundleIndex, locationName)
      } else {
        addBundleInCart(bundle, Number(quantity), locationName);
      }
      sendSearchId(searchId, "Bundle", bundle.id);
      this.setState({
        submitted: true
      });
    } else {
      setErrors("Amount must be greater than zero.");
    }
  };

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  handleBusinessRedirect = locationId => {
    const { location, history } = this.props;
    setFilterParamsBusiness(
      {
        locationId
      },
      location,
      history
    );
  };

  renderPricing = product => {
    if (hasSelectedFlatPrice(product, product.productType)) {
      const selectedFlatPrice = getSelectedFlatPrice(product);
      if (selectedFlatPrice) {
        return (
          <label>
            <CurrencyLabel value={selectedFlatPrice.amount} />
            / Flat: {selectedFlatPrice.name}
          </label>
        );
      }
    } else {
      const firstAvailablePricingType = getFirstAvailablePricingType(product, product.productType);

      if (firstAvailablePricingType) {
        const pricingObject = getFirstAvailablePricingObject(product);
        return (
          <label>
            <CurrencyLabel value={pricingObject.amount} />
            / {pricingObject.label}
          </label>
        );
      }
    }

    return <label>Price Available Upon Request</label>;
  };

  render() {
    const { product } = this.props;
    const { quantity, submitted } = this.state;

    return (
      <div className="item">
        <Link to={`/shop/${product.productType}/${product.token}`}>
          <figure>
            {product.pictures[0] ? (
              <Imgix
                src={product.pictures[0].imgixUrl}
                alt={product.name}
                width={510}
                height={288}
              />
            ) : (
              <TapGoods />
            )}
          </figure>
        </Link>
        {product.businessInfo.shopShowItemPricing &&
          this.renderPricing(product)}

        <a
          className="caption highlight"
          onClick={() => this.handleBusinessRedirect(product.businessInfo.locationId)}
        >
          {product.locationName}
        </a>
        <strong>{product.name}</strong>
        <div>
          <StandardTextField
            type="text"
            meta={{}}
            placeholder="Qty"
            input={{
              value: quantity,
              onChange: this.handleQuantityChange
            }}
          />
          {quantity > product.numberAvailable && (
            <div
              className="info"
              data-tip
              data-for="info"
              data-event="click"
              data-event-off="mouseleave"
            >
              <Info />
            </div>
          )}
          {quantity > product.numberAvailable && (
            <ReactTooltip
              id="info"
              class="tooltip bottom"
              place="bottom"
              type="light"
              effect="solid"
            >
              <h3>Item not available for autobook</h3>
              <p>
                You're still available to add this to a quote, just note this
                item needs an approval from the rental company before you can
                rent it for your event.
              </p>
            </ReactTooltip>
          )}

          {submitted ? (
            <Link className="btn" to="/cart">
              View Cart
            </Link>
          ) : (
            <button className="btn" onClick={this.handleAddToQuote}>
              Add To Quote
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { searchId } = state.products;
  const { event } = state.cart;
  return { event, searchId };
};

export default withRouter(connect(mapStateToProps, actions)(AvailableItemCard));
