import React from "react";
import { FormattedNumber } from "react-intl";
import { calculateBillingFields } from "HelperFunctions/Billing";
import CurrencyLabel from "Utils/CurrencyLabel";

const RentalBilling = props => {
  const { rental, billing, locale } = props;

  const { total } = calculateBillingFields(rental);
  let creditCardFees = [];
  let standardFees = [];
  rental.feeRentalRelationships.forEach((fee) => {
    if (fee.feeType === "credit_card_fee") {
      creditCardFees.push(fee);
    } else {
      standardFees.push(fee);
    }
  });
  const hasPriceAvailableOnRequestOnItem = rental.rentalItems.some(
    (ri) => +ri.selectedPrice === 0
  );
  const hasPriceAvailableOnRequestOnAddOn = rental.rentalAddOns.some(
    (ri) => +ri.selectedPrice === 0
  );
  const hasPriceAvailableOnRequestOnBundle = rental.rentalBundles.some(
    (ri) => +ri.selectedPrice === 0
  );
  const hasPriceAvailableOnRequest =
    hasPriceAvailableOnRequestOnItem ||
    hasPriceAvailableOnRequestOnAddOn ||
    hasPriceAvailableOnRequestOnBundle;
  let taxBreakDown =
    (rental && rental.location && rental.location.taxBreakdown) || (rental && rental.cachedLocation && rental.cachedLocation.taxBreakdown);
  return (
    <section className="billing">
      <h4>Billing</h4>
      <div className="billingDetails">
        {rental.rentalItems.length > 0 && (
          <div>
            <label>Item(s) Total</label>
            {hasPriceAvailableOnRequestOnItem ? (
              <span>Price Available Upon Request</span>
            ) : (
              <CurrencyLabel
                value={billing.rental_item_total}
                locale={locale}
              />
            )}
          </div>
        )}

        {rental.rentalAddOns.length > 0 && (
          <div>
            <label>Add On(s) Total</label>
            {hasPriceAvailableOnRequestOnAddOn ? (
              <span>Price Available Upon Request</span>
            ) : (
              <CurrencyLabel
                value={billing.rental_add_on_total}
                locale={locale}
              />
            )}
          </div>
        )}

        {rental.rentalBundles.length > 0 && (
          <div>
            <label>Bundle(s) Total</label>
            {hasPriceAvailableOnRequestOnBundle ? (
              <span>Price Available Upon Request</span>
            ) : (
              <CurrencyLabel
                value={billing.rental_bundle_total}
                locale={locale}
              />
            )}
          </div>
        )}

        {billing.delivery_cost > 0 && (
          <div>
            <label>Delivery</label>
            <CurrencyLabel
              value={billing.delivery_cost}
              locale={locale}
            />
          </div>
        )}
        {rental.damageWaiverFeeTotal > 0 && (
          <div>
            <label>Damage Waiver</label>
            {hasPriceAvailableOnRequest ? (
              <span>Price Available Upon Request</span>
            ) : (
              <CurrencyLabel
                value={rental.damageWaiverFeeTotal}
                locale={locale}
              />
            )}
          </div>
        )}
        {standardFees.map((fee, i) => {
          return (
            <div key={i}>
              <label>
                {fee.name}
                {fee.valueType === "percentage" && (
                  <span>
                    (
                    <FormattedNumber
                      value={fee.amount}
                      style="percent"
                      minimumFractionDigits={2}
                      minimumSignificantDigits={3}
                    />
                    )
                  </span>
                )}
              </label>
              {fee.valueType === "percentage" ? (
                <CurrencyLabel
                  value={fee.amount * total}
                  locale={locale}
                />
              ) : (
                <CurrencyLabel
                  value={fee.amount}
                  locale={locale}
                />
              )}
            </div>
          );
        })}

        <div className="subtotal">
          <label>Subtotal</label>
          {hasPriceAvailableOnRequest ? (
            <span>Price Available Upon Request</span>
          ) : (
            <CurrencyLabel
              value={rental.subTotal - rental.creditCardFeeTotal}
              locale={locale}
            />
          )}
        </div>
        {taxBreakDown ? (
          rental.salesTaxes &&
          rental.salesTaxes.length > 0 &&
          rental.salesTaxes.map((tax) => {
            if(tax.taxPercent > 0){
              return (
                <div>
                  <label>{tax.name}</label>
                  {hasPriceAvailableOnRequest ? (
                    <span>Price On Request</span>
                  ) : (
                    <FormattedNumber
                      value={
                        tax.taxPercent *
                        (rental.subTotal - rental.creditCardFeeTotal)
                      }
                      style="currency"
                      currency="USD"
                    />
                  )}
                </div>
              );
            } else {
              return null;
            }
          })
        ) : (
          <div>
            <label>Tax</label>
            <span>
              {rental.taxExempt && (
                <label className="caption">(Tax Exempt)</label>
              )}
              {hasPriceAvailableOnRequest ? (
                <span>Price Available Upon Request</span>
              ) : (
                <CurrencyLabel
                value={billing.tax_total}
                locale={locale}
              />
              )}
            </span>
          </div>
        )}

		<div>
          <label>Additional Taxes</label>
          <span>
            {hasPriceAvailableOnRequest ? (
              <span>Price Available Upon Request</span>
            ) : (
              <CurrencyLabel
                value={billing.total_custom_tax}
                locale={locale}
              />
            )}
          </span>
        </div>
        
        {creditCardFees.map((fee, i) => {
          return (
            <div key={i}>
              <label>
                {fee.name}
                {fee.valueType === "percentage" && (
                  <span>
                    (
                    <FormattedNumber
                      value={fee.amount}
                      style="percent"
                      minimumFractionDigits={2}
                      minimumSignificantDigits={3}
                    />
                    )
                  </span>
                )}
              </label>
              {fee.valueType === "percentage" ? (
                <CurrencyLabel
                  value={fee.amount * total}
                  locale={locale}
                />
              ) : (
                <CurrencyLabel
                  value={fee.amount}
                  locale={locale}
                />
              )}
            </div>
          );
        })}
        {rental.tapgoodsFeeTotal > 0 && (
          <div>
            <label>
              TapGoods Processing Fee{" "}
              <span>
                (
                <FormattedNumber
                  value={rental.marketplaceCustomerPercent}
                  style="percent"
                  minimumFractionDigits={2}
                  minimumSignificantDigits={3}
                />
                )
              </span>
            </label>
            <span>
              <CurrencyLabel
                value={rental.tapgoodsFeeTotal}
                locale={locale}
              />
            </span>
          </div>
        )}

        <div className="total">
          <label>Rental Total</label>
          {hasPriceAvailableOnRequest ? (
            <span>Price Available Upon Request</span>
          ) : (
            <CurrencyLabel
              value={billing.overall_total}
              locale={locale}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default RentalBilling;
