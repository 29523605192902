import React from "react";
import { Link } from "react-router-dom";
import SignInForm from "Components/home/SignInForm";
import * as actions from "Actions";
import { connect } from "react-redux";

class SignIn extends React.Component {
  render() {
    const { errors, loginUser } = this.props;
    return (
      <section>
        <SignInForm errors={errors} onSubmit={loginUser} />
        <Link to="/forgotPassword">Forgot Password</Link>
        <Link to="/signUp">Sign Up for FREE!</Link>
      </section>
    );
  }
}
const mapStateToProps = state => {
  const { errors, loading } = state.auth;

  return { errors, loading };
};

export default connect(mapStateToProps, actions)(SignIn);
