import React from "react";
import { connect } from "react-redux";
import LoadingSpinner from "Utils/LoadingSpinner";
import SearchFilterKazzam from "Utils/SearchFilterKazzam";
import Favicon from "react-favicon";

class HomeKazzam extends React.Component {
  render() {
    const { loading, authenticated } = this.props;
    if (loading) {
      return <LoadingSpinner />;
    }

    return (
      <div className="kazzamHome">
        <Favicon url="https://www.kazzam.com/favicon.png?e3c5a8454db2ead65ff677f62add3d61" />
        <header>
          <div className="headerLine">
            <h1>Great Holiday Parties are Easy with Kazzam</h1>
          </div>
          <SearchFilterKazzam />
        </header>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { loading, authenticated } = state.auth;
  return { loading, authenticated };
};

export default connect(mapStateToProps)(HomeKazzam);
