import React from "react";
import * as actions from "Actions";
import { connect } from "react-redux";
import EventsTable from "./EventsTable";
import { withRouter, Link } from "react-router-dom";
import classnames from "classnames";

class Events extends React.Component {
  componentDidMount() {
    const { type, fetchEventList } = this.props;
    fetchEventList({ type });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.type !== nextProps.type) {
      this.props.fetchEventList({
        type: nextProps.type
      });
    }
  }
  render() {
    const { type } = this.props;

    return (
      <div className="events">
        <nav className="sideNav">
          <Link
            to="/events/pending"
            className={classnames({ active: type === "pending" })}
          >
            Pending Events
          </Link>
          <Link
            to="/events/upcoming"
            className={classnames({ active: type === "upcoming" })}
          >
            Upcoming Events
          </Link>
          <Link
            to="/events/past"
            className={classnames({ active: type === "past" })}
          >
            Past Events
          </Link>
          <Link
            to="/events/cancelled"
            className={classnames({ active: type === "cancelled" })}
          >
            Cancelled Events
          </Link>
          <Link
            to="/events/expired"
            className={classnames({ active: type === "expired" })}
          >
            Expired Events
          </Link>
        </nav>
        <EventsTable type={type} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    type: ownProps.type || "upcoming"
  };
};

export default withRouter(connect(mapStateToProps, actions)(Events));
