import {
  FETCH_CONVERSATION_LIST_SUCCESS,
  ADD_UNREAD_MESSAGE
} from "Constants/redux";
import createReducer from "../lib/CreateReducer";

const INITIAL_STATE = {
  conversations: [],
  unreadMessages: 0
};

export default createReducer(INITIAL_STATE, {
  [FETCH_CONVERSATION_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      conversations: action.payload.conversations,
      unreadMessages: action.payload.unreadMessages
    };
  },
  [ADD_UNREAD_MESSAGE]: (state, action) => {
    const unreadMessages = (state.unreadMessages += 1);

    return {
      ...state,
      unreadMessages: unreadMessages
    };
  }
});
