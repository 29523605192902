import React from "react";
import Cleave from "cleave.js/dist/cleave-react";
/*eslint-disable */
import CleavePhone from "cleave.js/dist/addons/cleave-phone.us";
/*eslint-disable */
import classNames from "classnames";

class PhoneInput extends React.Component {
  handleChange = event => {
    const { onChange } = this.props;
    const target = event.target;
    const value = target.rawValue;
    const name = target.name;
    const FakeEvent = {
      target: {
        value: value,
        name: name
      }
    };
    onChange(FakeEvent);
  };

  render() {
    /*eslint-disable */
    const {
      input,
      placeholder,
      className,
      type,
      meta: { touched, error }
    } = this.props;
    /*eslint-disable */
    const value = input.value || "";
    return (
      <Cleave
        {...input}
        value={value}
        className={classNames({
          [className]: className,
          error: touched && error,
          "css-phone": true
        })}
        placeholder={placeholder}
        options={{ phone: true, phoneRegionCode: "US" }}
      />
    );
  }
}

export default PhoneInput;
