import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import RentalSection from "./RentalSection";

class RentalItemList extends React.Component {
  render() {
    const { rental } = this.props;
    const {
      businessName,
      token,
      approvalStatus,
      rentalSetting,
      rentalSections
    } = rental;

    const {
      showDiscountsOnPricing,
      showItemImages,
      showItemNotes,
      showUnitPricingToCustomers,
      showSubtotalsInSections
    } = rentalSetting;
    const tileView = showItemImages;
    return (
      <section className="items">
        <h3>
          Items from:
          <span>{businessName}</span>
          <label>#{token}</label>
        </h3>
        {rentalSections.map(
          (section) =>
            section.sectionMemberships.length > 0 && (
              <RentalSection
                key={section.id}
                rental={rental}
                section={section}
                approvalStatus={approvalStatus}
                tileView={tileView}
                showDiscountsOnPricing={showDiscountsOnPricing}
                showItemImages={showItemImages}
                showItemNotes={showItemNotes}
                showUnitPricingToCustomers={showUnitPricingToCustomers}
                showSubtotalsInSections={showSubtotalsInSections}
              />
            )
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { tileView } = state.products;

  return { tileView };
};

export default connect(mapStateToProps, actions)(RentalItemList);
