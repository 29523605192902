import { history as browserHistory } from "Components/Routes";
import axios from "axios";

export const ROOT_URL = process.env.REACT_APP_API_DOMAIN + "/api";

export const isValidToken = () => {
  const currentToken = JSON.parse(localStorage.getItem("authTokenHeaders"));
  if (currentToken) {
    const expDate = new Date(currentToken.expiry * 1000);
    const now = new Date();
    if (expDate > now) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const clearAuthToken = () => {
  localStorage.removeItem("authTokenHeaders");
};
export const clearAdminAuthToken = () => {
  localStorage.removeItem("adminAuthTokenHeaders");
};
export const getAuthToken = () => {
  if (isValidToken()) {
    const currentToken = JSON.parse(localStorage.getItem("authTokenHeaders"));
    return currentToken;
  } else {
    browserHistory.push("/marketplace");
  }
};

export const authToken = () => {
  const currentToken = JSON.parse(localStorage.getItem("authTokenHeaders"));
  return currentToken;
};
export const adminAuthToken = () => {
  const currentToken = JSON.parse(
    localStorage.getItem("adminAuthTokenHeaders")
  );
  return currentToken;
};

export const saveAuthToken = headers => {
  if (headers["access-token"]) {
    const { expiry, uid, client } = headers;
    const authTokenObj = {
      uid,
      expiry,
      client: client,
      "token-type": "Bearer",
      "access-token": headers["access-token"],
      "Content-Type": "application/json"
    };
    localStorage.setItem("authTokenHeaders", JSON.stringify(authTokenObj));
  }
};

export const isValidAdminToken = () => {
  const currentToken = JSON.parse(
    localStorage.getItem("adminAuthTokenHeaders")
  );
  if (currentToken) {
    const expDate = new Date(currentToken.expiry * 1000);
    const now = new Date();
    if (expDate > now) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const getAdminAuthToken = () => {
  if (isValidToken) {
    const currentToken = JSON.parse(
      localStorage.getItem("adminAuthTokenHeaders")
    );
    return currentToken;
  } else {
    browserHistory.push("/login");
  }
};

export const saveAdminAuthToken = headers => {
  if (headers["access-token"]) {
    const { expiry, uid, client } = headers;
    const authTokenObj = {
      uid,
      expiry,
      client: client,
      "token-type": "Bearer",
      "access-token": headers["access-token"],
      "Content-Type": "application/json"
    };
    localStorage.setItem("adminAuthTokenHeaders", JSON.stringify(authTokenObj));
  }
};

export const saveError = (error, state = {}) => {
  let errorObj = {
    error: error.message,
    time: Date.now(),
    route: window.location.pathname,
    user_agent: window.navigator.userAgent,
    screen_width: window.innerWidth
  };

  if (error.response) {
    errorObj = Object.assign(errorObj, {
      ...errorObj,
      data_error: error.response.data.error,
      exception: error.response.data.exception,
      status: error.response.status,
      request_headers: error.config.headers,
      end_point: error.config.url
    });
  }
  if (error.response && error.response.status === 422) {
    // Do nothing this is a validation error
    return;
  } else {
    axios
      .post(process.env.REACT_APP_API_DOMAIN + "/errors", {
        error: errorObj
      })
      .then(response => {
        console.log(response);
      });
  }
};
