import React from "react";
import Logo from "../../images/logos/logo_white.png";
import ProLogo from "../../images/logos/logo_white_pro.png";

const Landing = () => (
  <div className="landingWrapper">
    <div className="landing">
      <div className="renters">
        <img src={Logo} alt="TapGoods" />
        <div className="callout">For Renters</div>
        <h1>
          Event
          <br />
          Rental
          <br />
          Marketplace
        </h1>
        <p>
          Rent anything from
          <br />
          rental companies near you.
        </p>
        <a className="btn" href="/marketplace">
          Shop TapGoods Marketplace
        </a>
      </div>
      <div className="businesses">
        <img src={ProLogo} alt="TapGoods PRO" />
        <div className="callout">For Rental Businesses</div>
        <h1>
          Powerful
          <br />
          Management
          <br />
          Software
        </h1>
        <p>
          Explore our comprehensive, powerful,
          <br />
          user-friendly, cloud-based system.
        </p>
        <a className="btn" href="https://www.tapgoods.com/pro">
          Learn More About TapGoods PRO
        </a>
        <a className="btn outline" href="https://business.tapgoods.com/login">
          Sign In
        </a>
      </div>
    </div>
  </div>
);

export default Landing;
