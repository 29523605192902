import React from "react";
import { connect } from "react-redux";
import LoadingSpinner from "Utils/LoadingSpinner";
import SearchFilter from "Utils/SearchFilter";

class Home extends React.Component {
  render() {
    const { loading, authenticated } = this.props;
    if (loading) {
      return <LoadingSpinner />;
    }

    return (
      <div className="home">
        <header>
          <SearchFilter />
          <div className="headerLine">
            <h1>Event Rental Marketplace</h1>
            <p>
              Rent anything from
              <br />
              rental companies near you.
            </p>
          </div>
        </header>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { loading, authenticated } = state.auth;
  return { loading, authenticated };
};

export default connect(mapStateToProps)(Home);
