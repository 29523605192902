import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import classnames from "classnames";
import isSelected from "HelperFunctions/ActiveStyling";
import TimeAgo from "react-timeago";

const formatter = (value, unit, suffix, date) => {
  var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(date);
  var secondDate = new Date();
  if (date) {
    var diffDays = Math.round(Math.abs((date - secondDate.getTime()) / oneDay));

    if (diffDays > 7) {
      const date_formatter = Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric"
      });
      return date_formatter.format(firstDate);
    } else if (diffDays > 1) {
      const date_formatter = Intl.DateTimeFormat("en-US", {
        weekday: "short"
      });
      return date_formatter.format(firstDate);
    } else {
      const date_formatter = Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric"
      });
      return date_formatter.format(firstDate);
    }
  }
};

class ConversationCard extends React.Component {
  renderRentalName = () => {
    const { conversation, forRental, rental, location } = this.props;

    let rentalName = "";
    if (forRental) {
      // If this is for rental conversation, show the current rental name.
      rentalName = rental.name;
    } else {
      // If this is for location conversation,
      if (conversation.conversationType === "b2b") {
        // If conversation is b2b,
        if (conversation.supplierLocationInfo.id === location.id) {
          rentalName = conversation.supplierRentalInfo.name;
        } else {
          rentalName = conversation.tenantRentalInfo.name;
        }
      } else {
        // If conversation is b2c,
        rentalName = conversation.supplierRentalInfo
          ? conversation.supplierRentalInfo.name
          : "";
      }
    }

    return <label className="caption">{rentalName}</label>;
  };

  renderContactName = () => {
    const { conversation } = this.props;
    return <label>{conversation.contactNames}</label>;
  };

  render() {
    const { conversation, forRental, rental, location } = this.props;
    let first =
      conversation.messages.length > 0
        ? conversation.messages[conversation.messages.length - 1]
        : null;
    const link = forRental
      ? `/events/rentals/${rental.id}/conversations/${conversation.id}`
      : `/conversations/${conversation.id}`;
    return (
      <li
        className={classnames({
          selected: isSelected(location, link, true),
          unread: conversation.unreadMessages > 0
        })}
      >
        <NavLink to={link}>
          <label className="highlight">{conversation.rentalName}:</label>
          <label className="caption">{conversation.locationName}</label>
          {first && <TimeAgo date={first.createdAt} formatter={formatter} />}
        </NavLink>
      </li>
    );
  }
}

export default withRouter(ConversationCard);
