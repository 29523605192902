import React from "react";
import { Chairs } from "Utils/SvgIcons";
import axios from "axios";
import { connect } from "react-redux";

class BusinessNoItemsPage extends React.Component {
  state = {
    email: "",
    zipCode: null,
    pressed: false
  };

  componentWillMount() {
    this.setState({ zipCode: this.props.zipCode });
  }

  handleChange = e => {
    this.setState({ email: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();

    const email = this.state.email;
    const zipCode = this.state.zipCode;
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/api/marketplace/emails/no_items_email`,
        { email: email, zip_code: zipCode }
      )
      .then(res => {
        console.log(res);
        console.log(res.data);
      });
    this.setState({ pressed: true });
  };

  render() {
    return (
      <section className="businessNoItems">
        <div className="icon">
          <Chairs />
        </div>
        <h2>We didn't find items in your area.</h2>
        <p>
          The good news... we're always adding items! Leave us your email
          <br />
          address, and we'll let you know when we've added a lot more in your
          area.
        </p>
        {!this.state.pressed && (
          <form onSubmit={this.handleSubmit}>
            <input
              type="text"
              placeholder="Your Email"
              onChange={this.handleChange}
            />
            <button type="submit">Submit</button>
          </form>
        )}
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { zipCode } = state.serviceArea;
  return {
    zipCode
  };
};

export default connect(mapStateToProps)(BusinessNoItemsPage);
