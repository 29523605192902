import React from "react";
import { Delete } from "Utils/SvgIcons";

class Doc extends React.Component {
  render() {
    const { doc, onDelete } = this.props; // From Redux
    return (
      <li key={doc.id}>
        <a href={doc.url} download>
          {doc.name}
        </a>
        <p>{doc.type}</p>
        <a className="btnLink delete" onClick={() => onDelete(doc.id)}>
          <Delete />
        </a>
      </li>
    );
  }
}

export default Doc;
