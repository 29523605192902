import React from "react";
import { DatePickerInput } from "rc-datepicker";

export default ({ onChange, value, name, ...other }) => {
  const handleChange = (jsDate, dateString) => {
    if (dateString !== "Invalid date") {
      const FakeEvent = {
        target: {
          value: jsDate,
          name
        }
      };
      onChange(FakeEvent);
    }
  };

  return (
    <DatePickerInput
      showOnInputClick={true}
      showInputButton={false}
      onChange={handleChange}
      value={value}
      autoComplete="off"
      minDate={new Date()}
      readOnly
      {...other}
    />
  );
};
