import React from "react";
import { Route, Switch } from "react-router-dom";
import ShopIndex from "./ShopIndex";
import SearchItems from "./SearchItems";
import AvailableItem from "./AvailableItem";

const Shop = props => {
  return (
    <Switch>
      <Route exact path="/shop" component={ShopIndex} />
      <Route path="/shop/search" component={SearchItems} />
      <Route path="/shop/items/:id" component={AvailableItem("items")} />
      <Route path="/shop/bundles/:id" component={AvailableItem("bundles")} />
      <Route path="/shop/add_ons/:id" component={AvailableItem("add_ons")} />
    </Switch>
  );
};

export default Shop;
