import React from "react";
import { Link, withRouter } from "react-router-dom";
import { setFilterParams, filterFromPropsAndState } from "HelperFunctions/urls";
import { connect } from "react-redux";
import { uniq } from "lodash";
import * as actions from "Actions";
import classnames from "classnames";
import CartItems from "./add_items/CartItems";
import CartBilling from "./CartBilling";
import EventSummary from "./EventSummary";
import { CheckOnCircle } from "Utils/SvgIcons";
import { history as browserHistory } from "Components/Routes";
import { combineDateAndTime } from "HelperFunctions/general";
import Validator from "HelperFunctions/validator";

class CartAddItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      signInOpen: false,
      signUpOpen: false
    };
  }

  toggleSignUp = () => {
    this.setState({
      signUpOpen: !this.state.signUpOpen,
      signInOpen: this.state.signUpOpen
    });
  };

  toggleSignIn = () => {
    this.setState({
      signInOpen: !this.state.signInOpen,
      signUpOpen: this.state.signInOpen
    });
  };

  closeSignIn = () => {
    this.setState({
      signInOpen: false
    });
  };

  closeSignUp = () => {
    this.setState({
      signUpOpen: false
    });
  };

  handleReview = step => {
    const { editReview } = this.props;
    editReview(() => {
      this.handleSelectStep(step);
    });
  };

  handleSelectStep = step => {
    const { location, history } = this.props;
    setFilterParams(
      {
        step: step
      },
      location,
      history
    );
  };

  handleSubmitQuote = () => {
    const component = this;
    if (component.props.authenticated) {
      component.props.editSubmit(event => {
        component.eventRedirect(event);
      });
    } else {
      component.toggleSignIn();
    }
  };

  renderLeftSection = step => {
    switch (step) {
      case 1:
        return <CartItems />;
      case 2:
        return <EventSummary />;
      case 3:
        return <EventSummary />;
      default:
        return <CartItems />;
    }
  };

  reviewStepOne() {
    const { event, setErrors } = this.props;
    let eventForValidation = {
      ...event,
      eventStartDateTime: combineDateAndTime(
        event.eventStart,
        event.eventStartTime
      ),
      eventEndDateTime: combineDateAndTime(event.eventEnd, event.eventEndTime)
    };
    const validations = {
      name: {
        required: {
          value: true,
          message: "Event name is required."
        },
        minLength: {
          value: 5,
          message: "Event name must be at least 5 characters."
        }
      },
      eventStart: {
        required: {
          value: true,
          message: "Event start date is required."
        }
      },
      eventStartTime: {
        required: {
          value: true,
          message: "Event start time is required."
        }
      },
      eventStartDateTime: {
        required: {
          value: true,
          message: "Event start date is required."
        },
        greaterThanOrEqualToDate: {
          value: new Date(),
          message: "Start date cannot be in the past."
        }
      },
      eventEnd: {
        required: {
          value: true,
          message: "Event end date is required."
        }
      },
      eventEndTime: {
        required: {
          value: true,
          message: "Event end time is required."
        }
      },
      eventEndDateTime: {
        required: {
          value: true,
          message: "Event end date is required."
        },
        greaterThanOrEqualToDate: {
          value: eventForValidation.eventStartDateTime,
          message: "End date must be after start date."
        }
      }
    };
    const errors = Validator(validations)(eventForValidation);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      this.handleSelectStep(2);
    }
  }

  handleSignIn = data => {
    const component = this;
    const { loginUser, newSubmit } = component.props;
    loginUser({
      ...data,
      onSuccess: () => {
        component.setState({
          signInOpen: false,
          signUpOpen: false
        });

        // redirect to step 4(final step) if submitted successfully
        newSubmit(event => {
          component.eventRedirect(event);
        });
      }
    });
  };

  handleSignUp = data => {
    const component = this;
    const { createUser, newSubmit } = component.props;
    createUser({
      ...data,
      onSuccess: () => {
        component.setState({
          signInOpen: false,
          signUpOpen: false
        });
        // redirect to step 4(final step) if submitted successfully
        newSubmit(event => {
          component.eventRedirect(event);
        });
      }
    });
  };

  eventRedirect = event => {
    this.props.setCartProps({ token: event.token, id: event.id });
    this.trackProgress();
  };

  trackProgress = () => {
    const { event, initCartProps } = this.props;
    browserHistory.push(`/events/${event.id}`);
    initCartProps();
  };

  renderNextButton = () => {
    const { step, event } = this.props;
    const businessKeys = uniq([
      ...Object.keys(event.items),
      ...Object.keys(event.rentalBundles),
      ...Object.keys(event.addOns)
    ]);

    switch (step) {
      case 1:
        if (businessKeys.length === 0) {
          return (
            <Link className="btn" to="/shop/search">
              Shop Now
            </Link>
          );
        } else {
          return (
            <a className="btn" onClick={() => this.handleReview(step + 1)}>
              Next Step: Review and Submit
            </a>
          );
        }
      case 2:
        return (
          <a className="btn" onClick={this.handleSubmitQuote}>
            Submit
          </a>
        );
      case 3:
        return (
          <a className="btn" onClick={this.trackProgress}>
            Track Progress
          </a>
        );
      default:
        return (
          <a className="btn" onClick={() => this.handleSelectStep(step + 1)}>
            Next Step: Review and Submit
          </a>
        );
    }
  };

  render() {
    const { step, stopAddingItemsToEvent } = this.props;
    return (
      <div className="cart">
        <header>
          <h1>
            {step === 1
              ? "Add to Event"
              : step === 2
              ? "Review & Submit"
              : "Submit"}
          </h1>
          <div className="actions">
            <Link className="btn" to="/shop/search">
              Keep Shopping
            </Link>
            <a className="btn outline" onClick={stopAddingItemsToEvent}>
              Cancel Editing
            </a>
          </div>
        </header>
        <div className="content">
          <section className="progress">
            <ul>
              <li
                className={classnames({
                  active: step === 1,
                  complete: step > 1
                })}
              >
                <a onClick={() => step > 1 && this.handleSelectStep(1)}>
                  {step > 1 ? <CheckOnCircle /> : <div>1</div>}
                  <label>Add to Event</label>
                </a>
              </li>
              <li
                className={classnames({
                  active: step === 2,
                  complete: step > 2
                })}
              >
                <a onClick={() => step > 2 && this.handleSelectStep(2)}>
                  {step > 2 ? <CheckOnCircle /> : <div>2</div>}
                  <label>Review & Submit</label>
                </a>
              </li>
            </ul>
            {this.renderNextButton()}
          </section>
          {this.renderLeftSection(step)}
          <CartBilling
            step={step}
            onSelectStep={this.handleSelectStep}
            onSubmitQuote={this.handleSubmitQuote}
            onReview={this.handleReview}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  const step = newFilter.step ? +newFilter.step : 1;
  const { authenticated, errors } = state.auth;
  const { event } = state.cart;
  return { event, step, authenticated, errors };
};

export default withRouter(connect(mapStateToProps, actions)(CartAddItems));
