import React from "react";
import { Route, Switch } from "react-router-dom";
import Event from "./Event";
import EventsIndex from "./EventsIndex";
import MobileEventsIndex from "./MobileEventsIndex";
import EditRental from "./rentals/EditRental";
import RentalContainer from "./rentals/RentalContainer";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import MobileEvents from "./MobileEvents";

const EventsSection = props => {
  return (
    <MediaBreakpoints
      desktop={
        <Switch>
          <Route exact path={props.match.path} component={EventsIndex} />
          <Route
            exact
            path={`${props.match.path}/pending`}
            render={props => {
              return <EventsIndex type="pending" />;
            }}
          />
          <Route
            exact
            path={`${props.match.path}/upcoming`}
            render={props => {
              return <EventsIndex type="upcoming" />;
            }}
          />
          <Route
            exact
            path={`${props.match.path}/past`}
            render={props => {
              return <EventsIndex type="past" />;
            }}
          />
          <Route
            exact
            path={`${props.match.path}/cancelled`}
            render={props => {
              return <EventsIndex type="cancelled" />;
            }}
          />
          <Route
            exact
            path={`${props.match.path}/expired`}
            render={props => {
              return <EventsIndex type="expired" />;
            }}
          />
          <Route exact path={`${props.match.path}/:id`} component={Event} />
          <Route
            exact
            path={`${props.match.path}/:id/rentals/:rentalId`}
            component={RentalContainer}
          />
          <Route
            exact
            path={`${props.match.path}/:id/rentals/:rentalId/edit`}
            component={EditRental}
          />
        </Switch>
      }
      mobile={
        <Switch>
          <Route exact path={props.match.path} component={MobileEventsIndex} />
          <Route
            exact
            path={`${props.match.path}/pending`}
            render={props => {
              return <MobileEvents type="pending" />;
            }}
          />
          <Route
            exact
            path={`${props.match.path}/upcoming`}
            render={props => {
              return <MobileEvents type="upcoming" />;
            }}
          />
          <Route
            exact
            path={`${props.match.path}/cancelled`}
            render={props => {
              return <MobileEvents type="cancelled" />;
            }}
          />
          <Route
            exact
            path={`${props.match.path}/past`}
            render={props => {
              return <MobileEvents type="past" />;
            }}
          />
          <Route exact path={`${props.match.path}/:id`} component={Event} />
          <Route
            exact
            path={`${props.match.path}/:id/rentals/:rentalId`}
            component={RentalContainer}
          />
          <Route
            exact
            path={`${props.match.path}/:id/rentals/:rentalId/edit`}
            component={EditRental}
          />
        </Switch>
      }
    />
  );
};

export default EventsSection;
