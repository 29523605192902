import React from "react";
import classnames from "classnames";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import RentalItem from "./RentalItem";
import RentalBundle from "./RentalBundle";
import RentalStaff from "./RentalStaff";
import {
  renderSubTotals,
  extractFeeFromSelectedPrice,
  extractFlatFeeFromSelectedPrice
} from "HelperFunctions/rentals";
import CurrencyLabel from "Utils/CurrencyLabel";
import { countryToLocale } from "Constants/currencyConstants";

export default class RentalSection extends React.Component {
  isFlatPriceInventory = (inventoryItem) => {
    return (
      inventoryItem &&
      inventoryItem.period &&
      inventoryItem.period.includes("flat_price")
    );
  };
  calculateSubTotal = () => {
    const { section, rental } = this.props;
    const { sectionMemberships } = section;
    const subTotal = sectionMemberships
      .filter(
        (sectionMembership) =>
          sectionMembership.sectionMembershipType !== "blank" &&
          sectionMembership._destroy !== "1"
      )
      .reduce((sum, sectionMembership) => {
        if (sectionMembership.parentConnectionType === "duplicate") {
          return (sum = sum + 0);
        } else if (sectionMembership.rentalInventory.productType === "staffs") {
          return (sum = sum + +sectionMembership.rentalInventory.cost);
        } else {
          let quantity =
            sectionMembership.quantity ||
            sectionMembership.rentalInventory.quantity;
          let { selectedPriceBeforeDiscount, selectedPrice } = renderSubTotals(
            sectionMembership.rentalInventory,
            quantity,
            rental
          );
          return (sum =
            sum +
            (this.isFlatPriceInventory(sectionMembership.rentalInventory)
              ? +extractFlatFeeFromSelectedPrice(
                  sectionMembership.rentalInventory,
                  selectedPriceBeforeDiscount,
                  selectedPrice,
                  quantity
                )
              : +extractFeeFromSelectedPrice(
                  sectionMembership.rentalInventory,
                  selectedPriceBeforeDiscount,
                  selectedPrice,
                  quantity
                )));
        }
      }, 0);
    return subTotal;
  };
  render() {
    const {
      approvalStatus,
      section,
      showDiscountsOnPricing,
      showItemImages,
      showItemNotes,
      showUnitPricingToCustomers,
      showSubtotalsInSections,
      tileView,
      rental: { businessAddress: { country: businessCountry } }
    } = this.props;
    const locale = countryToLocale[businessCountry];
    const { sectionMemberships } = section;

    return (
      <div>
        <h4>{section.name}</h4>
        <table
          className={classnames({
            grid: true,
            thumbnail: tileView && showItemImages
          })}
        >
          <MediaBreakpoints
            desktop={
              <thead>
                <tr>
                  <th colSpan={tileView && showItemImages ? "2" : "1"}>Item</th>
                  <th className="sm right">Quantity</th>
                  {showUnitPricingToCustomers && (
                    <th className="medSm right">Cost</th>
                  )}
                  <th className="medSm right">Fee</th>
                  <th className="medSm right">SubTotal</th>
                </tr>
              </thead>
            }
            mobile={
              <thead>
                <tr>
                  <th
                    className="title"
                    colSpan={showUnitPricingToCustomers ? "3" : "2"}
                  >
                    {section.name}
                  </th>
                </tr>
                <tr>
                  <th className="sm">Quantity</th>
                  {showUnitPricingToCustomers && (
                    <th className="right">Cost</th>
                  )}
                  <th className="medSm right">Fee</th>
                  <th className="medSm right">SubTotal</th>
                </tr>
              </thead>
            }
          />
          {sectionMemberships.map((sectionMembership, index) => {
            const item = sectionMembership.rentalInventory;
            const inventoryType = item.productType;
            const { parentConnectionType } = sectionMembership;

            if (inventoryType === "items" || inventoryType === "addons") {
              return (
                <RentalItem
                  key={index}
                  approvalStatus={approvalStatus}
                  showDiscountsOnPricing={showDiscountsOnPricing}
                  showItemImages={showItemImages}
                  showItemNotes={showItemNotes}
                  showUnitPricingToCustomers={showUnitPricingToCustomers}
                  parentConnectionType={parentConnectionType}
                  rentalItem={item}
                  tileView={tileView}
                  sectionMembership={sectionMembership}
                  locale={locale}
                />
              );
            } else if (inventoryType === "bundles") {
              return (
                <RentalBundle
                  approvalStatus={approvalStatus}
                  showDiscountsOnPricing={showDiscountsOnPricing}
                  showItemImages={showItemImages}
                  showItemNotes={showItemNotes}
                  showUnitPricingToCustomers={showUnitPricingToCustomers}
                  rentalBundle={item}
                  key={index}
                  tileView={tileView}
                  locale={locale}
                />
              );
            } else if (inventoryType === "staffs") {
              return (
                <RentalStaff
                  key={index}
                  showItemImages={showItemImages}
                  showItemNotes={showItemNotes}
                  showUnitPricingToCustomers={showUnitPricingToCustomers}
                  item={item}
                  tileView={tileView}
                  locale={locale}
                />
              );
            } else {
              return null;
            }
          })}
        </table>
        {showSubtotalsInSections && (
          <div className="subtotal">
            <strong>Subtotals</strong>
            <CurrencyLabel
              value={this.calculateSubTotal()}
              locale={locale}
            />
          </div>
        )}
      </div>
    );
  }
}
