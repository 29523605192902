import React from "react";
import AriaModal from "react-aria-modal";
import { Close } from "Utils/SvgIcons";
import classnames from "classnames";

const Modal = props => {
  const { className, actions, open, toggle, title, large, children } = props;

  return (
    <AriaModal
      titleText={title}
      onExit={toggle}
      focusDialog={true}
      escapeExits={false}
      aria-describedby="describer"
      mounted={open}
      focusTrapPaused={true}
      includeDefaultStyles={false}
      dialogClass={classnames({
        modal: true,
        large: large,
        [className]: className
      })}
      underlayClass="overlay"
    >
      <header>
        {title && <h4>{title}</h4>}
        <a onClick={toggle}>
          <Close />
        </a>
      </header>
      <main>{children}</main>
      <footer className="actions">{actions}</footer>
    </AriaModal>
  );
};

Modal.defaultProps = {
  modal: false,
  title: ""
};

export default Modal;
