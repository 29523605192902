import React from "react";
import SignInForm from "Components/home/SignInForm";
import ForgotPasswordForm from "Components/home/ForgotPasswordForm";
import * as actions from "Actions";
import { connect } from "react-redux";
import { parseQuery } from "HelperFunctions/QueryString";

class ReclaimAccount extends React.Component {
  render() {
    const { email } = parseQuery(this.props.location.search);
    const { errors, loginUser, sendPasswordReset } = this.props;
    return (
      <div className="reclaim">
        <header>
          <h1>Good news!</h1>
        </header>
        <section>
          <p>
            Looks like you've rented with a business powered by TapGoods using
            that email.
          </p>
          <SignInForm
            errors={errors}
            onSubmit={loginUser}
            initialValues={{ email: email }}
          />
          <p>Or</p>
          <ForgotPasswordForm
            onSubmit={sendPasswordReset}
            initialValues={{ email: email }}
          />
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { errors, loading } = state.auth;

  return { errors, loading };
};

export default connect(mapStateToProps, actions)(ReclaimAccount);
