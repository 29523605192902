import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import {
  Accents,
  Bars,
  Chairs,
  Linens,
  Seating,
  Tabletop,
  Tents
} from "Utils/SvgIcons";
import Tile01 from "../../../images/kazzam/tile-santa.jpg";
import Tile02 from "../../../images/kazzam/tile-inflatable.jpg";
import Tile03 from "../../../images/kazzam/tile-facepainting.jpg";
import Bubble01 from "../../../images/kazzam/bubble_01.jpg";
import Bubble02 from "../../../images/kazzam/bubble_02.jpg";
import Bubble03 from "../../../images/kazzam/bubble_03.jpg";
import Bubble04 from "../../../images/kazzam/bubble_04.jpg";
import Bubble05 from "../../../images/kazzam/bubble_05.jpg";
import Bubble06 from "../../../images/kazzam/bubble_06.jpg";
import Bubble07 from "../../../images/kazzam/bubble_07.jpg";
import Bubble08 from "../../../images/kazzam/bubble_08.jpg";
import Bubble09 from "../../../images/kazzam/bubble_09.jpg";
import Bubble10 from "../../../images/kazzam/bubble_10.jpg";
import Bubble11 from "../../../images/kazzam/bubble_11.jpg";
import Bubble12 from "../../../images/kazzam/bubble_12.jpg";
import Bubble13 from "../../../images/kazzam/bubble_13.jpg";
import Bubble14 from "../../../images/kazzam/bubble_14.jpg";
import Bubble15 from "../../../images/kazzam/bubble_15.jpg";
import Bubble16 from "../../../images/kazzam/bubble_16.jpg";

class KazzamCategories extends React.Component {
  pageCategoriesLarge = () => {
    const { pathname } = this.props.location;
    switch (pathname) {
      case "/kazzam":
        return [
          {
            id: "#",
            component: <img src={Tile01} alt="Santa Claus" />,
            label: "Santa Claus",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Tile02} alt="Bounce Houses" />,
            label: "Bounce Houses",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Tile03} alt="Face Painters" />,
            label: "Face Painters",
            type: "kazzam"
          }
        ];
      default:
        return [
          { id: "4", component: <Tabletop />, label: "Tabletop", type: "" },
          {
            id: "8",
            component: <Seating />,
            label: "Specialty Seating",
            type: ""
          },
          { id: "10", component: <Bars />, label: "bars", type: "" }
        ];
    }
  };

  pageCategoriesSmall = () => {
    const { pathname } = this.props.location;
    switch (pathname) {
      case "/kazzam":
        return [
          {
            id: "#",
            component: <img src={Bubble01} alt="Entertainment" />,
            label: "Entertainment",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble02} alt="Party Packages" />,
            label: "Party Packages",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble03} alt="Bounce Houses" />,
            label: "Bounce Houses",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble04} alt="Face Painters" />,
            label: "Face Painters",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble05} alt="Ballon Artists" />,
            label: "Ballon Artists",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble06} alt="Ballon Decor" />,
            label: "Ballon Decor",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble07} alt="Buffet" />,
            label: "Buffet",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble08} alt="Food Truck" />,
            label: "Food Truck",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble09} alt="Desserts" />,
            label: "Desserts",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble10} alt="Caricature Artist" />,
            label: "Caricature Artist",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble11} alt="Water Slide Packages" />,
            label: "Water Slide Packages",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble12} alt="Activities" />,
            label: "Activities",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble13} alt="Concessions" />,
            label: "Concessions",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble14} alt="Photo Booths" />,
            label: "Photo Booths",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble15} alt="Event Rentals" />,
            label: "Event Rentals",
            type: "kazzam"
          },
          {
            id: "#",
            component: <img src={Bubble16} alt="Party Characters" />,
            label: "Party Characters",
            type: "kazzam"
          }
        ];
      default:
        return [
          { id: "21", component: <Tents />, label: "Tents", type: "" },
          { id: "3", component: <Linens />, label: "Linens", type: "" },
          { id: "2", component: <Chairs />, label: "Chairs", type: "" },
          { id: "6", component: <Accents />, label: "Accents", type: "" }
        ];
    }
  };
  render() {
    return (
      <div className="kazzamCategories">
        <div className="large">
          <h3>Most Popular Products</h3>
          <ul>
            {this.pageCategoriesLarge().map(category => {
              return (
                <li>
                  <Link
                    to={`/shop/search?selectedCategories=${category.id}&type=${category.type}`}
                  >
                    {category.component}
                    <label>{category.label}</label>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="small">
          <h3>Browse All Products</h3>
          <ul>
            {this.pageCategoriesSmall().map(category => {
              return (
                <li>
                  <Link
                    to={`/shop/search?selectedCategories=${category.id}&type=${category.type}`}
                  >
                    {category.component}
                    <label>{category.label}</label>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { allCategories, featuredCategories } = state.categoryList;
  const { zipCode } = state.serviceArea;
  const { customPages } = state;
  return { featuredCategories, allCategories, zipCode, customPages };
};
export default connect(mapStateToProps, actions)(KazzamCategories);
