import axios from "axios";
import {
  FETCH_INVENTORY_COLORS_REQUEST,
  FETCH_INVENTORY_COLORS_SUCCESS,
  FETCH_INVENTORY_COLORS_FAILURE
} from "Constants/redux";
import { ROOT_URL } from "Api";

export function fetchInventoryColors(query = "", locationId = "") {
  return (dispatch, getState) => {
    const { zipCode } = getState().serviceArea;
    dispatch({ type: FETCH_INVENTORY_COLORS_REQUEST });
    axios
      .get(
        `${ROOT_URL}/marketplace/inventories/colors?location_id=${locationId}&query=${query}&zip_code=${zipCode}`
      )
      .then(response => {
        return dispatch({
          response,
          type: FETCH_INVENTORY_COLORS_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        dispatch({
          error,
          type: FETCH_INVENTORY_COLORS_FAILURE
        });
      });
  };
}
