import {
  RELOAD_INVENTORY_REQUEST,
  RELOAD_INVENTORY_SUCCESS
} from "Constants/redux";
import { ROOT_URL } from "Api";
import axios from "axios";
import Rx from "rxjs/Rx";

const reloadInventoryEpic = action$ =>
  action$
    .ofType(RELOAD_INVENTORY_REQUEST)
    .debounceTime(500)
    .switchMap(action => {
      return Rx.Observable.fromPromise(
        axios.get(`${ROOT_URL}/marketplace/inventories/paginated`, {
          params: action.options
        })
      ).map(response => {
        return {
          response,
          inventoryType: action.inventoryType,
          type: RELOAD_INVENTORY_SUCCESS
        };
      });
    });

export default reloadInventoryEpic;
