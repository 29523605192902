import React from "react";
import CurrencyLabel from "Utils/CurrencyLabel";

class EmptyRentalBilling extends React.Component {
  render() {
    return (
      <div>
        <div className="billingDetails">
          <div>
            <label>Items Subtotal</label>
            <CurrencyLabel value={0} />
          </div>
          <div>
            <label>Tax</label>
            <span>TBD</span>
          </div>
          <div>
            <label>Damage Waiver Fee</label>
            <CurrencyLabel value={0} />
          </div>
          <div>
            <label>Estimated Delivery</label>
            <span>TBD</span>
          </div>
          <div className="subtotal">
            <label>Subtotal</label>

            <CurrencyLabel value={0} />
          </div>
        </div>
      </div>
    );
  }
}

export default EmptyRentalBilling;
