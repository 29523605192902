import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import { uniq } from "lodash";
import * as actions from "Actions";
import DatePicker from "Utils/DatePicker";
import TimePicker from "Utils/TimePicker";
import { CartOutline, ListView, ImageView } from "Utils/SvgIcons";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import { setFilterParams } from "HelperFunctions/urls";
import { combineDateAndTime, toNumber } from "HelperFunctions/general";
import { showSelectedPrice } from "HelperFunctions/rental_items";
import { getItemsWithNewPricesForEvent } from "HelperFunctions/events";
import CartItem from "./CartItem";
import PhoneInputField from "Utils/redux_form_inputs/PhoneInputField";
import CurrencyLabel from "Utils/CurrencyLabel";

class CartItems extends Component {
  handleSelectStep = step => {
    const { location, history } = this.props;
    setFilterParams({ step }, location, history);
  };

  handleContactPhoneChange = event => {
    const { setCartProps } = this.props;
    const { name, value } = event.target;
    setCartProps({ [name]: value });
  };

  handleChange = event => {
    const { setCartProps } = this.props;
    const { name, value } = event.target;
    const newEvent = {
      [name]: toNumber(value)
    };
    setCartProps(newEvent);
  };

  handleDateChange = e => {
    const {
      event,
      inventoryFilter,
      setCartProps,
      reloadInventory
    } = this.props;
    const { name, value } = e.target;

    let newEvent = Object.assign(
      {
        ...event,
        [name]: value
      },
      name === "eventStart" &&
        (!event.eventEnd || event.eventEnd === "") && { eventEnd: value },
      name === "eventEnd" &&
        (!event.eventStart || event.eventStart === "") && { eventStart: value }
    );

    setCartProps(newEvent);

    // Reload inventory with new event dates
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = newEvent;
    const eventStartDateTime = eventStart
      ? combineDateAndTime(eventStart, eventStartTime)
      : null;
    const eventEndDateTime = eventEnd
      ? combineDateAndTime(eventEnd, eventEndTime)
      : null;
    reloadInventory({
      ...inventoryFilter,
      eventStart: eventStartDateTime,
      eventEnd: eventEndDateTime
    });
  };

  componentDidUpdate(prevProps) {
    const { event } = this.props;
    const { event: prevEvent } = prevProps;
    if ((event.startDate !== prevEvent.startDate ||
      event.eventStartTime !== prevEvent.eventStartTime ||
      event.eventEnd !== prevEvent.eventEnd ||
      event.eventEndTime !== prevEvent.eventEndTime
    )) {
      const { setCartProps } = this.props;
      const { items, rentalBundles } = getItemsWithNewPricesForEvent(event);
      event.items = items;
      event.rentalBundles = rentalBundles;
      setCartProps(event);
    }
  }

  handleAddOnChange = (e, businessKey, itemIndex) => {
    const { event, setCartProps } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { name, value } = e.target;

    let newItems = event.addOns[businessKey].slice();

    newItems[itemIndex] = {
      ...newItems[itemIndex],
      [name]: toNumber(value)
    };

    const selected = showSelectedPrice(
      newItems[itemIndex].addOn,
      newItems[itemIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      "add_ons"
    );
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period
    };

    setCartProps({
      addOns: {
        ...event.addOns,
        [businessKey]: newItems
      }
    });
  };

  handleItemChange = (e, businessKey, itemIndex) => {
    const { event, setCartProps } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { name, value } = e.target;

    let newItems = event.items[businessKey].slice();

    newItems[itemIndex] = {
      ...newItems[itemIndex],
      [name]: toNumber(value)
    };

    const selected = showSelectedPrice(
      newItems[itemIndex].product,
      newItems[itemIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      "items"
    );
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period
    };

    setCartProps({
      items: {
        ...event.items,
        [businessKey]: newItems
      }
    });
  };

  handleAddOnRemove = (businessKey, itemIndex) => {
    const { event, setCartProps } = this.props;
    let newItems = { ...event.addOns };
    if (newItems[businessKey].length === 1) {
      delete newItems[businessKey];
    } else {
      newItems[businessKey].splice(itemIndex, 1);
    }
    setCartProps({
      addOns: newItems
    });
  };

  handleItemRemove = (businessKey, itemIndex) => {
    const { event, setCartProps } = this.props;
    let newItems = { ...event.items };
    if (newItems[businessKey].length === 1) {
      delete newItems[businessKey];
    } else {
      newItems[businessKey].splice(itemIndex, 1);
    }
    setCartProps({
      items: newItems
    });
  };

  handleRentalBundleChange = (e, businessKey, rentalBundleIndex) => {
    const { updateBundleInCart } = this.props;
    const { name, value } = e.target;
    updateBundleInCart(name, value, rentalBundleIndex, businessKey);
  };

  handleRentalBundleRemove = (businessKey, rentalBundleIndex) => {
    const { event, setCartProps } = this.props;
    let newRentalBundles = { ...event.rentalBundles };
    if (newRentalBundles[businessKey].length === 1) {
      delete newRentalBundles[businessKey];
    } else {
      newRentalBundles[businessKey].splice(rentalBundleIndex, 1);
    }
    setCartProps({
      rentalBundles: newRentalBundles
    });
  };

  render() {
    const { event, tileView, showTiles, hideTiles, errors } = this.props;
    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      items,
      rentalBundles,
      addOns,
      customerContactPhone
    } = event;

    const businessKeys = uniq([
      ...Object.keys(items),
      ...Object.keys(rentalBundles),
      ...Object.keys(addOns)
    ]);

    return (
      <section
        className={classnames({
          itemForm: true,
          noItems: businessKeys.length === 0
        })}
      >
        {businessKeys.length === 0 ? (
          <div className="prompt">
            <CartOutline />
            <p>You haven’t added anything to your cart.</p>
            <Link className="btn" to="/shop/search">
              Shop Now
            </Link>
          </div>
        ) : (
          <div>
            <div className="details">
              <h2>Event Name & Dates</h2>
              <div className="form">
                <div className="fields">
                  <label>Event Name *</label>
                  <StandardTextField
                    type="text"
                    meta={{ touched: true, error: errors["name"] }}
                    input={{
                      name: "name",
                      value: event.name,
                      onChange: this.handleChange
                    }}
                  />
                </div>
                <div className="fields dateRange">
                  <div>
                    <label>Event Start *</label>
                    <DatePicker
                      name="eventStart"
                      value={eventStart}
                      onChange={this.handleDateChange}
                      className={classnames({
                        error:
                          errors["eventStartDateTime"] || errors["eventStart"]
                      })}
                    />
                    <TimePicker
                      name="eventStartTime"
                      value={eventStartTime}
                      onChange={this.handleDateChange}
                      className={classnames({
                        error:
                          errors["eventStartDateTime"] ||
                          errors["eventStartTime"]
                      })}
                    />
                  </div>
                  <div>
                    <label>Event End *</label>
                    <DatePicker
                      name="eventEnd"
                      value={eventEnd}
                      onChange={this.handleDateChange}
                      startDate={eventStart ? eventStart : new Date()}
                      className={classnames({
                        error: errors["eventEndDateTime"] || errors["eventEnd"]
                      })}
                    />
                    <TimePicker
                      name="eventEndTime"
                      value={eventEndTime}
                      onChange={this.handleDateChange}
                      className={classnames({
                        error: errors["eventEndDateTime"] || errors["eventEnd"]
                      })}
                    />
                  </div>
                </div>
                <div className="fields">
                  <label>Contact Phone Number *</label>
                  <PhoneInputField
                    meta={{}}
                    input={{
                      name: "customerContactPhone",
                      value: customerContactPhone,
                      onChange: this.handleContactPhoneChange
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="items">
              <h2>Items</h2>
              <div className="tileToggle">
                <a
                  onClick={hideTiles}
                  className={classnames({
                    active: !tileView
                  })}
                >
                  <ListView />
                </a>
                <a
                  onClick={showTiles}
                  className={classnames({
                    active: tileView
                  })}
                >
                  <ImageView />
                </a>
              </div>
              {businessKeys.length > 0 &&
                businessKeys.map((key, index) => {
                  const businessRentalItems = items[key] || [];
                  const businessRentalBundles = rentalBundles[key] || [];
                  const businessRentalAddOns = addOns[key] || [];
                  const itemTotal = businessRentalItems.reduce(
                    (sum, item) => (sum += toNumber(item.selectedPrice)),
                    0
                  );
                  const rentalBundleTotal = businessRentalBundles.reduce(
                    (sum, item) => (sum += toNumber(item.selectedPrice)),
                    0
                  );
                  const rentalAddOnTotal = businessRentalAddOns.reduce(
                    (sum, item) => (sum += toNumber(item.selectedPrice)),
                    0
                  );
                  const total =
                    itemTotal + rentalBundleTotal + rentalAddOnTotal;

                  const business = businessRentalItems[0]
                    ? businessRentalItems[0].product.businessInfo
                    : businessRentalBundles[0]
                    ? businessRentalBundles[0].bundle.businessInfo
                    : businessRentalAddOns[0].addOn.businessInfo;

                  return (
                    <div key={index} className="companyItems">
                      <strong>
                        Items from: <span>{key}</span>
                      </strong>
                      <table
                        className={classnames({
                          grid: true,
                          thumbnail: tileView
                        })}
                      >
                        <tbody>
                          {businessRentalItems.map((item, itemIndex) => {
                            if (item._destroy === "1") {
                              return null;
                            } else {
                              return (
                                <CartItem
                                  key={itemIndex}
                                  period={item.period}
                                  businessKey={key}
                                  item={item}
                                  itemIndex={itemIndex}
                                  tileView={tileView}
                                  onItemChange={this.handleItemChange}
                                  onItemRemove={this.handleItemRemove}
                                />
                              );
                            }
                          })}
                          {businessRentalBundles.map((item, itemIndex) => {
                            if (item._destroy === "1") {
                              return null;
                            } else {
                              return (
                                <CartItem
                                  key={itemIndex}
                                  businessKey={key}
                                  item={item}
                                  itemIndex={itemIndex}
                                  tileView={tileView}
                                  onItemChange={this.handleRentalBundleChange}
                                  onItemRemove={this.handleRentalBundleRemove}
                                />
                              );
                            }
                          })}
                          {businessRentalAddOns.map((item, itemIndex) => {
                            if (item._destroy === "1") {
                              return null;
                            } else {
                              return (
                                <CartItem
                                  key={itemIndex}
                                  businessKey={key}
                                  item={item}
                                  itemIndex={itemIndex}
                                  tileView={tileView}
                                  onItemChange={this.handleAddOnChange}
                                  onItemRemove={this.handleAddOnRemove}
                                />
                              );
                            }
                          })}
                        </tbody>
                      </table>
                      <div className="billingDetails">
                        <div>
                          <label>Estimated Delivery</label>
                          <span>TBD</span>
                        </div>
                        <div className="subtotal">
                          <label>Item Total</label>
                          {!business.shopShowItemPricing ||
                          (businessKeys > 0 && total === 0) ? (
                            <span>Price Available Upon Request</span>
                          ) : (
                            <CurrencyLabel value={total} />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { event } = state.cart;
  const { errors } = state.dashboard;
  const { inventoryFilter, tileView } = state.products;
  return { event, inventoryFilter, tileView, errors };
};

export default connect(mapStateToProps, actions)(CartItems);
