import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import SignedInNav from "./SignedInNav";
import SignedOutNav from "./SignedOutNav";
import MobileSignedInNav from "./MobileSignedInNav";
import MobileSignedOutNav from "./MobileSignedOutNav";
import SignedInNavKazzam from "./kazzam/SignedInNavKazzam";
import SignedOutNavKazzam from "./kazzam/SignedOutNavKazzam";
import MobileSignedInNavKazzam from "./kazzam/MobileSignedInNavKazzam";
import MobileSignedOutNavKazzam from "./kazzam/MobileSignedOutNavKazzam";
import SignedInNavAra from "./ARA/SignedInNavAra";
import SignedOutNavAra from "./ARA/SignedOutNavAra";
import MobileSignedInNavAra from "./ARA/MobileSignedInNavAra";
import MobileSignedOutNavAra from "./ARA/MobileSignedOutNavAra";
import { restoreZipCodeIfNeeded, restoreCountryIfNeeded } from "Actions";

class MainMenu extends React.Component {
  componentDidMount() {
    restoreCountryIfNeeded();
  }
  render() {
    const { authenticated, location } = this.props;
    if (location.pathname === "/makeAPayment") {
      return null;
    } else if (location.pathname === "/kazzam") {
      return (
        <MediaBreakpoints
          desktop={
            <header>
              {authenticated ? <SignedInNavKazzam /> : <SignedOutNavKazzam />}
            </header>
          }
          mobile={
            <header>
              {authenticated ? (
                <MobileSignedInNavKazzam />
              ) : (
                <MobileSignedOutNavKazzam />
              )}
            </header>
          }
        />
      );
    } else if (location.pathname === "/ara") {
      return (
        <MediaBreakpoints
          desktop={
            <header className="araHeader">
              {authenticated ? <SignedInNavAra /> : <SignedOutNavAra />}
            </header>
          }
          mobile={
            <header className="araHeader">
              {authenticated ? (
                <MobileSignedInNavAra />
              ) : (
                <MobileSignedOutNavAra />
              )}
            </header>
          }
        />
      );
    } else {
      return (
        <MediaBreakpoints
          desktop={
            <header>
              {authenticated ? <SignedInNav /> : <SignedOutNav />}
            </header>
          }
          mobile={
            <header>
              {authenticated ? <MobileSignedInNav /> : <MobileSignedOutNav />}
            </header>
          }
        />
      );
    }
  }
}

MainMenu.propTypes = {
  authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { authenticated } = state.auth;
  return { authenticated };
};

export default connect(mapStateToProps, {
  restoreZipCodeIfNeeded,
  restoreCountryIfNeeded
})(MainMenu);
