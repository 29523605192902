import axios from "axios";

import { saveAuthToken, getAuthToken, saveError } from "Api";

import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";

import { fetchProfile } from "./CustomerActions";

// Save the customer profile to the backend, using the values
// saved from redux-form HOC into values

export const deleteDocument = id => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Deleting document..."));
    return axios
      .delete(
        process.env.REACT_APP_API_DOMAIN + "/api/portal/documents/" + id,
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        saveAuthToken(response.headers);
        dispatch(closeLoadingSpinner());
        dispatch(fetchProfile());
      })
      .catch(error => {
        saveError(error);
        dispatch(closeLoadingSpinner());
      });
  };
};
