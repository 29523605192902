import React from "react";
import { stringify, parseQuery } from "HelperFunctions/QueryString";
import { hasSelectedFlatPrice, getSelectedFlatPrice } from "HelperFunctions/rental_items";
import DraggableToolTip from "Utils/DraggableToolTip";
import { DollarsCircle } from "Utils/SvgIcons";
import moment from "moment";
import { displayPricingType } from "Constants/displayConstants";
import CurrencyFormatter from "./CurrencyFormatter";

export const renderItemSpecificFee = (item) => {
  return (
    item.rentalItemFeeRelationships &&
    item.rentalItemFeeRelationships.length > 0 && (
      <div>
        <DraggableToolTip
          tooltipContent={<p>This item has an associated fee.</p>}
          tooltipProps={{
            id: "itemFee-tip",
            class: "tooltip",
            effect: "solid"
          }}
          linkProps={{
            "data-tip": true,
            "data-for": "itemFee-tip",
            "data-event": "mouseenter",
            "data-event-off": "mouseleave"
          }}
        >
          <DollarsCircle />
        </DraggableToolTip>
      </div>
    )
  );
};

export const isItem = (item) =>
  item.type === "RentalItemTemporary" || item.type === "RentalItemStandard";
export const renderSubTotals = (item, quantity, rental) => {
  // The following function return time multiple, hours in case of hourly price , weeks in case of weekly price and same for months
  // Don't get confused by the name 'hours'
  const hours = getHoursFromSchedule(item, rental);
  let selectedPrice = "";
  let selectedPriceBeforeDiscount = "";
  if (isItem(item)) {
    const periodPrice = +item.periodPrice
      .split(" ")[0]
      .replace("$", "")
      .replace(/,/g, "");
    const periodPriceAfterDiscount = +item.periodPriceAfterDiscount
      .split(" ")[0]
      .replace("$", "")
      .replace(/,/g, "");
    if (periodPrice !== periodPriceAfterDiscount) {
      selectedPrice = (periodPriceAfterDiscount * quantity * hours).toString();
      selectedPriceBeforeDiscount = (periodPrice * quantity * hours).toString();
    } else {
      selectedPrice = (periodPrice * quantity * hours).toString();
      selectedPriceBeforeDiscount = selectedPrice;
    }
  } else {
    selectedPrice = (quantity / item.quantity) * item.selectedPrice;
    selectedPriceBeforeDiscount =
      (quantity / item.quantity) * item.selectedPrice;
  }

  return { selectedPrice, selectedPriceBeforeDiscount };
};

export const extractFeeFromSelectedPrice = (
  item,
  basePrice,
  priceWithFees,
  quantity
) => {
  if (item.rentalItemFeeRelationships) {
    item.rentalItemFeeRelationships.forEach((rifr) => {
      if (rifr.valueType == "amount") {
        priceWithFees = priceWithFees - +rifr.amount * quantity;
      } else if (rifr.valueType == "percentage") {
        priceWithFees = priceWithFees - basePrice * +rifr.amount;
      }
    });
  }
  return parseFloat(priceWithFees).toFixed(2);
};

export const extractFeeFromPrice = (
  item,
  basePrice,
  priceWithFees,
  quantity
) => {
  if (item.rentalItemFeeRelationships) {
    item.rentalItemFeeRelationships.forEach((rifr) => {
      if (rifr.valueType == "amount") {
        // Following Line checks if qunantity is split so it extract fee only for the splitted quantity
        const feeAmount = (+rifr.amount / item.quantity) * quantity;
        priceWithFees =
          priceWithFees -
          feeAmount * (item.period !== "edited_flat_price" ? quantity : 1);
      } else if (rifr.valueType == "percentage") {
        priceWithFees = priceWithFees - basePrice * +rifr.amount;
      }
    });
  }
  return parseFloat(priceWithFees).toFixed(2);
};
export const extractFlatFeeFromSelectedPrice = (
  item,
  basePrice,
  priceWithFees,
  quantity
) => {
  if (item.rentalItemFeeRelationships) {
    item.rentalItemFeeRelationships.forEach((rifr) => {
      if (rifr.valueType == "amount") {
        priceWithFees = priceWithFees - +rifr.amount;
      } else if (rifr.valueType == "percentage") {
        priceWithFees = priceWithFees - basePrice * +rifr.amount;
      }
    });
  }

  return parseFloat(priceWithFees).toFixed(2);
};

export const customersString = (rental) => {
  const customers = rental.customers
    ? rental.customers
    : rental.customerRentalRelationships.map((cr) => cr.client);
  const customerNames = customers.map((customer, index) => {
    return customer.firstName || customer.name;
  });
  return customerNames.join(", ");
};

export const renderItemQty = (item, quantity= 0) => {
  const subRentalQty = item.subRental ? item.subRental.quantity : 0;
  if (subRentalQty > 0) {
    return `(${subRentalQty}) ${item.quantity}`;
  } else {
    return quantity || item.quantity;
  }
};

export const isQuoteExpired = (rental) => {
  if (rental.expireDate) {
    const expireDate = new Date(rental.expireDate);
    const today = new Date();
    return expireDate < today;
  } else {
    return false;
  }
};

export const setRentalFilterParams = (filter, location, history = null) => {
  const query = parseQuery(location.search);
  const newFilter = {
    ...query,
    ...filter
  };
  const stringified = stringify(newFilter);
  history.push({
    pathname: location.pathname,
    search: `?${stringified}`
  });
};

export const rentalsFilterFromPropsAndState = (
  state,
  ownProps,
  includeReduxFilter = true
) => {
  const { filter } = state.rentalsList;
  const { location } = ownProps;
  const query = parseQuery(location.search);
  const initialFilter = {
    startDate: null,
    endDate: null,
    source: "",
    salesperson: "",
    numberPer: 15
  };
  const {
    startDate,
    endDate,
    page,
    numberPer,
    search,
    ...otherFromQuery
  } = query;
  const newFilter = Object.assign(initialFilter, includeReduxFilter && filter, {
    ...otherFromQuery,
    query: search,
    showAll: false,
    numberPer: numberPer ? numberPer : 10,
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
    page: page ? Number(page) : 1
  });

  return newFilter;
};

export const humanizeTimeLeftToExpire = (milliseconds) => {
  if (milliseconds > 0) {
    let hours = Math.floor(milliseconds / (1000 * 3600));
    let minutes = Math.floor((milliseconds % (1000 * 3600)) / (1000 * 60));
    let string = "";
    if (hours > 0) {
      string += hours + "hrs";
    }
    if (minutes > 0) {
      string += " " + minutes + "min";
    }
    return string;
  } else {
    return "";
  }
};

export const getFirstAvailablePricingType = (product, productType) => {
  const pricingTypes = [
    "dailyPrice",
    "hourlyPrice",
    "halfDayPrice",
    "weeklyPrice",
    "monthlyPrice",
    "pricing",
    // "flatPrice"
  ];
  let availablePrice = pricingTypes.find((pt) => {
    return product[pt] && +product[pt] > 0;
  });

  if (!availablePrice && productType === "bundles" && !product.priceLocked && product.flatPrices && product.flatPrices.find(fp => +fp.amount > 0)) {
    availablePrice = "flatPrices";
  }
  return availablePrice;
};

export const getFirstAvailablePricingObject = (product) => {
  if (hasSelectedFlatPrice(product, product.productType)) {
    const selectedFlatPrice = getSelectedFlatPrice(product);
    if (selectedFlatPrice) {
      return {
        amount: selectedFlatPrice.amount,
        label: `Flat: ${selectedFlatPrice.name}`
      }
    }
  } else {
    const firstAvailablePricingType = getFirstAvailablePricingType(product, product.productType);
    if (firstAvailablePricingType !== "flatPrices") {
      return {
        amount: product[firstAvailablePricingType],
        label: displayPricingType[firstAvailablePricingType]
      }
    } else {
      return {
        amount: product.flatPrices.reduce((sum, fp) => { return sum + Number(fp.amount) }, 0),
        label: 'Each'
      }
    }
  }
}

export const getDamageWaiverFeeForRental = (rental) => {
  if(rental.manualDamageWaiver) {
    return parseFloat(rental.damageWaiverFeeTotal);
  }
  const { location } = rental;
  const defaultDamageFixed = location.damageWaiverFixedFee
    ? parseFloat(location.damageWaiverFixedFee)
    : 0;
  const rentalItems = Object.values(rental.items);
  const rentalBundles = Object.values(rental.rentalBundles);

  const rentalItemTotal = rentalItems
    .filter((item_container) => item_container._destroy !== "1")
    .reduce((total, item_container) => {
      if (
        item_container.type === "RentalItemTemporary" ||
        !item_container.product.damageWaiverExempt
      ) {
        return total + parseFloat(item_container.selectedPrice);
      } else {
        return total;
      }
    }, 0);
  const rentalBundleTotal = rentalBundles
    .filter((item_container) => item_container._destroy !== "1")
    .reduce((total, item_container) => {
      return total + Number(item_container.selectedPrice);
    }, 0);
  let defaultDamage = location.damageWaiverPercent
    ? parseFloat(
        ((rentalItemTotal + rentalBundleTotal) * location.damageWaiverPercent) /
          100
      )
    : 0;
  defaultDamage += defaultDamageFixed;

  return defaultDamage;  
}

export const getHoursFromSchedule = (item, rental) => {
  let hours = 1;
  if (
    rental &&
    rental.schedule &&
    [
      "none_selected",
      "flat_price",
      "flat_unit_price",
      "edited_flat_price",
      "edited_flat_unit_price"
    ].indexOf(item.period) < 0
  ) {
    let startTime = moment(rental.schedule.eventStartDateTime);
    let endTime = moment(rental.schedule.eventEndDateTime);
    hours = moment.duration(endTime.diff(startTime)).asHours();
    hours = convertHoursTo(item.period, hours);
  }
  return hours;
};
export const convertHoursTo = (unit, hours) => {
  if (hours > 0) {
    let newTime;
    switch (unit) {
      case "daily_price":
        newTime = Math.round(hours / 24);
        break;
      case "half_day_price":
        newTime = Math.round(hours / 12);
        break;
      case "weekly_price":
        newTime = Math.round(hours / 24 / 7);
        break;
      case "monthly_price":
        newTime = Math.round(hours / 24 / 30);
        break;
      default:
        newTime = Math.round(hours);
        break;
    }
    newTime = newTime <= 0 ? 1 : newTime;
    return newTime;
  } else {
    return 1;
  }
};

export const getItemPeriodPrice = (item, productType, locale) => {
  const formatter = CurrencyFormatter({ locale });
  let displayUnit = '';
  if (hasSelectedFlatPrice(item, productType)) {
    const selectedFlatPrice = getSelectedFlatPrice(item);
    displayUnit = `Flat: ${selectedFlatPrice.name}`;
  } else if (item.pricing && +item.pricing > 0) {
    displayUnit = "Each"
  } else {
    const units = {
      hourly_price: "Hour",
      half_day_price: "Half-Day",
      daily_price: "Day",
      weekly_price: "Week",
      monthly_price: "Month",
      flat_unit_price: "Each",
    }
    displayUnit = units[item.period];
  }

  return <span className="normalText">{formatter.format(item.selectedPrice)} / {displayUnit}</span>;
};
