import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import StandardSelectField from "Utils/redux_form_inputs/StandardSelectField";
import StandardYearSelectField from "Utils/redux_form_inputs/StandardYearSelectField";
import StandardMonthSelectField from "Utils/redux_form_inputs/StandardMonthSelectField";
import StandardRegionDropdown from "Utils/redux_form_inputs/StandardRegionDropdown";
import Checkbox from "Utils/Checkbox";
import Validator from "HelperFunctions/validator";
import { Link } from "react-router-dom";

// Redux-form validations written in json

const validations = {
  country: {
    required: {
      value: true,
      message: "Country/Region is required"
    }
  },
  street_address1: {
    required: {
      value: true,
      message: "Street Address is required"
    }
  },
  city: {
    required: {
      value: true,
      message: "City is required"
    }
  },
  locale: {
    required: {
      value: true,
      message: "State is required"
    }
  },
  postal_code: {
    required: {
      value: true,
      message: "ZIP is required"
    }
  },
  name: {
    required: {
      value: true,
      message: "Name On Card is required"
    }
  },
  number: {
    required: {
      value: true,
      message: "Card Number is required"
    }
  },
  exp_month: {
    required: {
      value: true,
      message: "Month is required"
    }
  },
  exp_year: {
    required: {
      value: true,
      message: "Year is required"
    }
  },
  cvc: {
    required: {
      value: true,
      message: "CVV is required"
    }
  }
};

// This HOC has access to our own redux, plus it pulls in redux-form HOC,
// which does the validations for us.

class CreditCardForm extends React.Component {
  render() {
    const {
      formValues,
      handleSubmit,
      copyAddress,
      type,
      withPaymentType,
      hideCopy
    } = this.props; // From Redux

    return (
      <form onSubmit={handleSubmit} className="form">
        <div className="fields">
          <label>Billing Information</label>
          {!hideCopy && (
            <Checkbox
              onCheck={copyAddress}
              label="Copy Billing Address On File"
            />
          )}
          <Field
            name="country"
            label="Country/Region"
            component={StandardSelectField}
          >
            <option value="">Select country</option>
            <option value="USA">United States</option>
            <option value="CAN">Canada</option>
          </Field>
          <Field
            name="street_address1"
            placeholder="Street Address"
            className="lg"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="street_address2"
            placeholder="Suite #"
            className="sm"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="city"
            placeholder="City"
            className="med"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="locale"
            defaultOptionLabel={
              formValues && formValues.country === "USA" ? "State" : "Province"
            }
            className="sm"
            disableWhenEmpty={true}
            countryValueType="short"
            labelType="short"
            valueType="short"
            country={
              formValues && formValues.country ? formValues.country : "USA"
            }
            component={StandardRegionDropdown}
          />
          <Field
            name="postal_code"
            placeholder="ZIP"
            className="sm"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="phone_number"
            placeholder="Phone Number"
            component={StandardTextField}
            type="text"
          />
        </div>
        <div className="fields">
          <label>Payment Method</label>
          {withPaymentType && (
            <Field name="payment_method_type" component={StandardSelectField}>
              <option value="Visa">Visa</option>
              <option value="MasterCard">MasterCard</option>
              <option value="AMEX">AMEX</option>
              <option value="Discover">Discover</option>
            </Field>
          )}
          <Field
            name="nickname"
            placeholder="Card Nickname (Optional)"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="name"
            placeholder="Name on Card"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="number"
            placeholder="Card Number"
            className="lg"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="cvc"
            placeholder="CVV"
            className="sm"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="exp_month"
            className="med"
            component={StandardMonthSelectField}
          />
          <Field
            name="exp_year"
            className="med"
            component={StandardYearSelectField}
          />
        </div>
        {type === "checkout" ? (
          <div className="actions">
            <button type="submit" className="btnIcon full">
              Submit Payment
            </button>
          </div>
        ) : (
          <div className="actions">
            <button type="submit" className="btn">
              Add Card
            </button>
            <Link to="/profile/paymentMethods" className="btn cancel">
              Cancel
            </Link>
          </div>
        )}
      </form>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  let newProps = {
    initialValues: {
      payment_method_type: "Visa"
    }
  };
  if (ownProps.initialBillingAddress) {
    newProps.initialValues = {
      ...newProps.initialValues,
      ...ownProps.initialBillingAddress
    };
  }
  const { CreditCard } = state.form;
  newProps.formValues = CreditCard ? CreditCard.values : {};

  return newProps;
};

export default connect(mapStateToProps)(
  reduxForm(
    {
      form: "CreditCard",
      validate: Validator(validations),
      enableReinitialize: true
    },
    mapStateToProps
  )(CreditCardForm)
);
