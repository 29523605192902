import React from "react";
import injectTapEventPlugin from "react-tap-event-plugin";
import Routes from "./Routes";
// import getMuiTheme from "@material-ui/core/styles/getMuiTheme";
// import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import configureStore from "../configureStore";
import Airbrake from "./Airbrake";

injectTapEventPlugin();

export const store = configureStore();

// const muiTheme = getMuiTheme({
//   fontFamily: "Heebo, sans-serif",
//   palette: {
//     primary1Color: "#278ADA", // blue
//     primary2Color: "#278ADA", // blue
//     primary3Color: "#466B88", // cobalt
//     accent1Color: "#8FA9BE", // darkgrey
//     accent2Color: "#C1CDD7", // meddarkgrey
//     accent3Color: "#E0E5E9", // medlightgrey
//     textColor: "#0E2C44", // darkblue
//     alternateTextColor: "#FFFFFF", // white
//     canvasColor: "#FFFFFF", // white
//     borderColor: "transparent", // transparent
//     disabledColor: "#C1CDD7", // meddarkgrey
//     pickerHeaderColor: "#278ADA", // blue
//     clockCircleColor: "#E0E5E9", // medlightgrey
//     shadowColor: "transparent" // transparent
//   },
//   overlay: {
//     backgroundColor: "rgba(7,24,39,.85)" // navyblue @ 85%
//   },
//   ripple: {
//     color: "transparent"
//   }
// });

const Root = () => (
  <Airbrake>
    <Provider store={store}>
      <IntlProvider locale="en">
        <Routes store={store} />
      </IntlProvider>
    </Provider>
  </Airbrake>
);

export default Root;
