import {
  SET_BEFORE_ATTRIBUTES,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILURE
} from "Constants/redux";

const INITIAL_STATE = {
  event: {
    name: "",
    token: "",
    eventStartDateTime: "",
    eventEndDateTime: "",
    eventTotal: "",
    balanceDue: "",
    unreadMessagesCount: 0,
    timeLeftToExpire: 166647,
    rentals: []
  },
  beforeAttributes: null,
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_EVENT_REQUEST:
      return { ...state, loading: true };
    case FETCH_EVENT_FAILURE:
      return { ...state, loading: true };
    case FETCH_EVENT_SUCCESS:
      const event = action.payload;
      const {} = event;
      const newObject = Object.assign(state.event, {}, {});

      return { ...state, event, loading: false };
    case SET_BEFORE_ATTRIBUTES:
      return { ...state, beforeAttributes: action.payload };
    default:
      return state;
  }
};
