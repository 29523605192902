import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import { history as browserHistory } from "Components/Routes";
import { reset } from "redux-form";
import { Documents } from "Utils/SvgIcons";
import NewDocumentForm from "Components/documents/NewDocumentForm";

class NewCustomerDocument extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onSubmit(values) {
    const {
      customer,
      uploadCustomerDocuments,
      reset,
      fetchProfile
    } = this.props;
    uploadCustomerDocuments({
      id: customer.customerLocationRelationships[0].id,
      values: values,
      onSuccess: response => {
        fetchProfile();
        browserHistory.push(`/profile/documents`);
        reset("newDocument");
      }
    });
  }

  render() {
    return (
      <section className="documents new">
        <h4>Add Document</h4>
        <Documents />
        <NewDocumentForm onSubmit={this.onSubmit} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { customer, errors, loading } = state.customer;
  return { customer, errors, loading };
};

export default connect(mapStateToProps, { ...actions, reset })(
  NewCustomerDocument
);
