import React from "react";
import { FormattedDate, FormattedTime } from "react-intl";
import { getTrackingUrl } from "HelperFunctions/getTrackingUrl";
import { carrierTypes } from "Constants/displayConstants";

const RentalDelivery = ({
  delivery,
  checkout,
  checkoutWindowEnd,
  checkout_time,
  checkinWindowStart,
  checkin_time,
  hideDeliveryInfo,
  schedule,
  rental
}) => {
  const {
    startWindowFinish,
    startDate,
    startTime,
    endWindowBeginning,
    endTime
  } = schedule;
  if (rental.approvalStatus === "lead") {
    return (
      <section className="delivery">
        <h4>Delivery</h4>
        <div className="details">
          <div>
            <label>
              {delivery.delivery_type === "ship"
                ? "Delivery"
                : "Drop Off"}
            </label>
          </div>
          <div>
            <label>
              {delivery.delivery_type === "ship" ? "Return" : "Pick Up"}
            </label>
          </div>
          <div>
            <label>Address</label>
          </div>
        </div>
      </section>
    );
  }
  const timeZone = schedule.timeZone;
  const pickup = {
    pickup_same_as_delivery: rental.pickupSameAsDelivery,
    pickup_address_location_name: rental.pickupAddressLocationName,
    pickup_address_street_address_1: rental.pickupAddressStreetAddress1,
    pickup_address_street_address_2: rental.pickupAddressStreetAddress2,
    pickup_address_city: rental.pickupAddressCity,
    pickup_address_locale: rental.pickupAddressLocale,
    pickup_address_postal_code: rental.pickupAddressPostalCode,
    pickup_address_country: rental.pickupAddressCountry
  };
  return (
    <section className="delivery">
      <h4>
        {delivery.delivery_type === "customer_pick_up"
          ? "Pick Up/Drop Off Info"
          : "Delivery Info"}
      </h4>
      {delivery.delivery_type &&(
        <div className="details">
          <div>
            <label>Type:</label>
            <p>{delivery.delivery_type === "customer_pick_up"
              ? "Pick Up"
              : delivery.delivery_type === "ship"
                ? "Ship"
                : "Delivery"}</p>
          </div>
        </div>
      )}
      {hideDeliveryInfo ? (
        <div className="details">TBD</div>
      ) : (
        <div className="details">
          <div>
            <label>
              {delivery.delivery_type === "customer_pick_up"
                ? "Pick Up"
                : delivery.delivery_type === "ship"
                ? "Delivery"
                : "Drop Off"}
            </label>
            {delivery.delivery_type === "ship" && !!delivery.carrier_type && (
              <p>{carrierTypes[delivery.carrier_type]}</p>
            )}
            {delivery.delivery_type === "ship" &&
              delivery.delivery_tracking_number &&
              delivery.carrier_type && (
                <a
                  className="trackingLink"
                  href={getTrackingUrl(
                    delivery.delivery_tracking_number,
                    delivery.carrier_type
                  )}
                  target="_blank"
                >
                  {delivery.delivery_tracking_number}
                </a>
              )}
            {delivery.delivery_type === "ship" &&
              delivery.delivery_tracking_number &&
              !delivery.carrier_type && (
                <p>{delivery.delivery_tracking_number}</p>
              )}
            {delivery.delivery_type === "ship" && <h6>Estimated</h6>}
            <br />
            {delivery.delivery_type === "ship" && (
              <div>
                <label>Delivery Window:</label>
                <FormattedDate
                  weekday="short"
                  month="short"
                  day="numeric"
                  year="numeric"
                  value={startWindowFinish}
                />
                <br />
                <FormattedTime
                  value={startWindowFinish}
                  timeZone={timeZone}
                  timeZoneName="short"
                />
              </div>
            )}
            {delivery.delivery_type !== "ship" && (
              <div>
                <label>Delivery Window:</label>
                <FormattedDate
                  month="2-digit"
                  day="2-digit"
                  year="2-digit"
                  value={startDate}
                />
                <br />
                <FormattedTime
                  value={startTime}
                  timeZone={timeZone}
                  timeZoneName="short"
                />
                {" - "}
                <FormattedTime
                  value={startWindowFinish}
                  timeZone={timeZone}
                  timeZoneName="short"
                />
              </div>
            )}
          </div>
          <br />
          <div>
            {["default_delivery", "custom_delivery"].indexOf(
              delivery.delivery_type
            ) >= 0 &&
              pickup.pickup_same_as_delivery == false && (
                <div>
                  <h4>Pick Up Address</h4>
                  <div>
                    {pickup.pickup_address_location_name && (
                      <strong>{pickup.pickup_address_location_name}</strong>
                    )}
                    <div>{pickup.pickup_address_street_address_1}</div>
                    {pickup.pickup_address_street_address_2 && (
                      <div># {pickup.pickup_address_street_address_2}</div>
                    )}
                    <div>
                      {pickup.pickup_address_city &&
                        `${pickup.pickup_address_city}, `}
                      {pickup.pickup_address_locale}{" "}
                      {pickup.pickup_address_postal_code &&
                        `${pickup.pickup_address_postal_code}, `}
                      {pickup.pickup_address_country}
                    </div>
                  </div>
                </div>
              )}
            <br />
            <label>
              {delivery.delivery_type === "customer_pick_up"
                ? "Drop Off"
                : delivery.delivery_type === "ship"
                ? "Return"
                : "Pick Up"}
            </label>
            {delivery.delivery_type === "ship" && !!delivery.carrier_type && (
              <p>{carrierTypes[delivery.carrier_type]}</p>
            )}
            {delivery.delivery_type === "ship" &&
              delivery.return_tracking_number &&
              delivery.carrier_type && (
                <a
                  className="trackingLink"
                  href={getTrackingUrl(
                    delivery.return_tracking_number,
                    delivery.carrier_type
                  )}
                  target="_blank"
                >
                  {delivery.return_tracking_number}
                </a>
              )}
            {delivery.delivery_type === "ship" &&
              delivery.return_tracking_number &&
              !delivery.carrier_type && (
                <p>{delivery.return_tracking_number}</p>
              )}
            {delivery.delivery_type === "ship" && <h6>Estimated</h6>}
            <br/>
            {delivery.delivery_type === "ship" && (
              <div>
                <label>Return Window:</label>
                <FormattedDate
                  weekday="short"
                  month="short"
                  day="numeric"
                  year="numeric"
                  value={endWindowBeginning}
                />
                <br />
                <FormattedTime
                  value={endWindowBeginning}
                  timeZone={timeZone}
                  timeZoneName="short"
                />
              </div>
            )}
            {delivery.delivery_type !== "ship" && (
              <div>
                <label>Return Window:</label>
                <FormattedDate
                  month="2-digit"
                  day="2-digit"
                  year="2-digit"
                  value={endWindowBeginning}
                />
                <br />
                <FormattedTime
                  value={endWindowBeginning}
                  timeZone={timeZone}
                  timeZoneName="short"
                />
                {" - "}
                <FormattedTime
                  value={endWindowBeginning}
                  timeZone={timeZone}
                  timeZoneName="short"
                />
              </div>
            )}
          </div>
          {delivery.delivery_type !== "customer_pick_up" && (
            <div>
              <h4>Venue</h4>
              {delivery.delivery_address_location_name && (
                <div>{delivery.delivery_address_location_name}</div>
              )}
              <div>
                {delivery.delivery_address_street_address_1},{" "}
                {delivery.delivery_address_street_address_2 &&
                delivery.delivery_address_street_address_2}
              </div>

              <div>
                {delivery.delivery_address_city},{" "}
                {delivery.delivery_address_locale}{" "}
                {delivery.delivery_address_postal_code},{" "}
                {delivery.delivery_address_country}
              </div>
            </div>
          )}
          <br />
        </div>
      )}
    </section>
  );
};

export default RentalDelivery;
