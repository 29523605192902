import React, { Component } from "react";
import PropTypes from "prop-types";
import EventListener from "react-event-listener";
import keycode from "keycode";
import classnames from "classnames";
import { CheckOn, CheckOff } from "Utils/SvgIcons";

class Checkbox extends Component {
  static propTypes = {
    /**
     * Checkbox is checked if true.
     */
    checked: PropTypes.bool,
    /**
     * Disabled if true.
     */
    disabled: PropTypes.bool,
    /**
     * Callback function that is fired when the checkbox is checked.
     *
     * @param {object} event `change` event targeting the underlying checkbox `input`.
     * @param {boolean} isInputChecked The `checked` value of the underlying checkbox `input`.
     */
    onCheck: PropTypes.func
  };

  static defaultProps = {
    disabled: false,
    checkedIcon: <CheckOn />,
    uncheckedIcon: <CheckOff />
  };

  state = {
    switched: false,
    isKeyboardFocused: false
  };

  componentWillMount() {
    const { checked } = this.props;

    if (checked) {
      this.setState({
        switched: true
      });
    }
    this.componentWillReceiveProps(this.props);
  }

  componentDidMount() {
    const inputNode = this.refs.checkbox;
    if (!this.props.switched || inputNode.checked !== this.props.switched) {
      this.handleStateChange(inputNode.checked);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.checked !== nextProps.checked) {
      this.setState({
        switched: nextProps.checked
      });

      this.handleStateChange(nextProps.checked);
    }
  }

  handleStateChange = newSwitched => {
    this.setState({
      switched: newSwitched
    });
  };

  getValue() {
    return this.refs.checkbox.value;
  }

  handleChange = event => {
    this.tabPressed = false;
    this.setState({
      isKeyboardFocused: false
    });

    const isInputChecked = this.refs.checkbox.checked;

    if (!this.props.hasOwnProperty("checked")) {
      this.handleStateChange(isInputChecked);
    }

    if (this.props.onCheck) {
      this.props.onCheck(event, isInputChecked);
    }
  };

  // Checkbox inputs only use SPACE to change their state. Using ENTER will
  // update the ui but not the input.
  handleKeyDown = event => {
    const code = keycode(event);

    if (code === "tab") {
      this.tabPressed = true;
    }
    if (this.state.isKeyboardFocused && code === "space") {
      this.handleChange(event);
    }
  };

  handleKeyUp = event => {
    if (this.state.isKeyboardFocused && keycode(event) === "space") {
      this.handleChange(event);
    }
  };

  handleBlur = event => {
    this.setState({
      isKeyboardFocused: false
    });
  };

  handleFocus = event => {
    // setTimeout is needed because the focus event fires first
    // Wait so that we can capture if this was a keyboard focus
    // or touch focus
    setTimeout(() => {
      if (this.tabPressed) {
        this.setState({
          isKeyboardFocused: true
        });
      }
    }, 150);
  };

  render() {
    const {
      name,
      value,
      checked, // eslint-disable-line no-unused-vars
      label,
      disabled,
      className,
      onCheck, // eslint-disable-line no-unused-vars
      checkedIcon,
      uncheckedIcon
    } = this.props;

    return (
      <div
        className={classnames({
          check: true,
          [className]: className,
          on: this.state.switched,
          off: !this.state.switched,
          disabled: disabled
        })}
      >
        <EventListener
          target="window"
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
        />
        <input
          ref="checkbox"
          type="checkbox"
          name={name}
          value={value}
          checked={this.state.switched}
          disabled={disabled}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
        />
        {this.state.switched ? checkedIcon : uncheckedIcon}
        {label && <label>{label}</label>}
      </div>
    );
  }
}

export default Checkbox;
