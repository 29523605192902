import React, { Component } from "react";
import { connect } from "react-redux";
import { standardService } from "Api/service";
import service from "Api/service";
import { Link } from "react-router-dom";
import * as actions from "Actions";
import { arraysEqual, debounce } from "HelperFunctions/general";
import { setFilterParams, filterFromPropsAndState } from "HelperFunctions/urls";
import PaginationBoxView from "Utils/PaginationBoxView";
import CustomPageCard from "./CustomPageCard";
import update from "react/lib/update";
import Modal from "Utils/Modal";
import SignInForm from "Components/home/SignInForm";
import SignUpForm from "Components/home/SignUpForm";

class CustomPages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: props.filter,
      pagination: {},
      customPages: [],
      favoriteAction: "",
      favoritingIndex: -1,
      signInOpen: false,
      signUpOpen: false
    };

    this.handleQueryChange = debounce(this.handleQueryChange, 500);
  }

  componentDidMount() {
    const { filter } = this.props;

    this.fetchCustomPages(filter);
  }

  fetchCustomPages = filter => {
    const { zipCode } = this.props;
    const { query, page } = filter;
    const component = this;
    standardService
      .get(
        process.env.REACT_APP_API_DOMAIN +
          "/api/marketplace/marketplace_custom_pages/paginated",
        {
          query: query,
          page: page,
          number_per: 12,
          zip_code: zipCode
        }
      )
      .then(response => {
        component.setState({
          customPages: response.data.marketplaceCustomPages,
          pagination: response.data.meta.pagination
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleFavorite = index => {
    const component = this;
    if (component.props.authenticated) {
      component.favoriteCustomPage(index);
    } else {
      component.setState({
        favoriteAction: "favorite",
        favoritingIndex: index
      });
      component.toggleSignIn();
    }
  };

  handleUnfavorite = index => {
    const component = this;
    if (component.props.authenticated) {
      component.unfavoriteCustomPage(index);
    } else {
      component.setState({
        favoriteAction: "unfavorite",
        favoritingIndex: index
      });
      component.toggleSignIn();
    }
  };

  favoriteCustomPage = index => {
    const customPage = this.state.customPages[index];
    const component = this;
    component.props.openLoadingSpinner("Favoriting...");
    service
      .post(
        process.env.REACT_APP_API_DOMAIN +
          "/api/marketplace/marketplace_custom_pages/" +
          customPage.id +
          "/favorite"
      )
      .then(response => {
        const newState = update(this.state, {
          customPages: {
            [index]: { $set: response.data.marketplaceCustomPage }
          }
        });
        component.props.receiveCustomPage(response.data.marketplaceCustomPage);
        component.props.closeLoadingSpinner();
        component.setState(newState);
      })
      .catch(error => {
        console.log(error);
        component.props.closeLoadingSpinner();
      });
  };

  unfavoriteCustomPage = index => {
    const customPage = this.state.customPages[index];
    const component = this;
    component.props.openLoadingSpinner("Unfavoriting...");
    service
      .post(
        process.env.REACT_APP_API_DOMAIN +
          "/api/marketplace/marketplace_custom_pages/" +
          customPage.id +
          "/unfavorite"
      )
      .then(response => {
        const newState = update(this.state, {
          customPages: {
            [index]: { $set: response.data.marketplaceCustomPage }
          }
        });
        component.props.receiveCustomPage(response.data.marketplaceCustomPage);
        component.props.closeLoadingSpinner();
        component.setState(newState);
      })
      .catch(error => {
        console.log(error);
        component.props.closeLoadingSpinner();
      });
  };

  componentWillReceiveProps(nextProps) {
    const oldFilter = this.props.filter;
    let newFilter = nextProps.filter;
    let changed = false;

    for (var property in newFilter) {
      if (newFilter.hasOwnProperty(property)) {
        if (newFilter[property] instanceof Array) {
          if (arraysEqual(newFilter[property], oldFilter[property]) === false) {
            changed = true;
            break;
          }
        } else {
          const newTest =
            newFilter[property] instanceof Date
              ? newFilter[property].getTime()
              : newFilter[property];
          const oldTest =
            oldFilter[property] instanceof Date
              ? oldFilter[property].getTime()
              : oldFilter[property];
          if (newTest !== oldTest) {
            changed = true;
            break;
          }
        }
      }
    }

    if (changed) {
      const filterToSubmit = Object.assign(newFilter);
      this.fetchCustomPages(filterToSubmit);
    }
  }

  handleSearch = event => {
    const { value } = event.target;
    this.setState({
      filter: {
        ...this.state.filter,
        query: value
      }
    });
    this.handleQueryChange(value);
  };

  handleQueryChange(value) {
    const { location, history } = this.props;
    setFilterParams(
      {
        query: value
      },
      location,
      history
    );
  }

  handlePageChange = data => {
    const { location, history } = this.props;
    setFilterParams(
      {
        page: data.selected + 1
      },
      location,
      history
    );
  };

  handleSignIn = data => {
    const component = this;
    const { loginUser } = component.props;
    loginUser({
      ...data,
      onSuccess: () => {
        const { favoriteAction, favoritingIndex } = component.state;
        if (favoriteAction === "favorite") {
          component.favoriteCustomPage(favoritingIndex);
        } else if (favoriteAction === "unfavorite") {
          component.unfavoriteCustomPage(favoritingIndex);
        }
        component.setState({
          signInOpen: false,
          signUpOpen: false,
          favoriteAction: "",
          favoritingIndex: -1
        });
      }
    });
  };

  handleSignUp = data => {
    const component = this;
    const { createUser } = component.props;
    createUser({
      ...data,
      onSuccess: () => {
        const { favoriteAction, favoritingIndex } = component.state;
        if (favoriteAction === "favorite") {
          component.favoriteCustomPage(favoritingIndex);
        } else if (favoriteAction === "unfavorite") {
          component.unfavoriteCustomPage(favoritingIndex);
        }
        component.setState({
          signInOpen: false,
          signUpOpen: false,
          favoriteAction: "",
          favoritingIndex: -1
        });
      }
    });
  };

  toggleSignUp = () => {
    this.setState({
      signUpOpen: !this.state.signUpOpen,
      signInOpen: this.state.signUpOpen
    });
  };

  toggleSignIn = () => {
    this.setState({
      signInOpen: !this.state.signInOpen,
      signUpOpen: this.state.signInOpen
    });
  };

  closeSignIn = () => {
    this.setState({
      signInOpen: false
    });
  };

  closeSignUp = () => {
    this.setState({
      signUpOpen: false
    });
  };

  render() {
    const {
      filter,
      customPages,
      pagination,
      signInOpen,
      signUpOpen,
      errors
    } = this.state;

    return (
      <div className="discoverTheLook">
        <header>
          <h1>Get the Look</h1>
          <div className="searchFilter">
            <input
              type="search"
              placeholder="Search Ideas"
              onChange={this.handleSearch}
              value={filter.query}
            />
          </div>
        </header>
        <section>
          <div className="looks">
            {customPages.map((cp, index) => (
              <CustomPageCard
                index={index}
                key={cp.id}
                customPage={cp}
                onFavorite={this.handleFavorite}
                onUnfavorite={this.handleUnfavorite}
              />
            ))}
          </div>
          <PaginationBoxView
            className="pagerWrapper"
            containerClassName="pager"
            forcePage={filter.page ? +filter.page - 1 : 0}
            pageCount={pagination.totalPages}
            onPageChange={this.handlePageChange}
          />
        </section>
        <Modal
          className="discoverSignInModal"
          title="Sign In"
          open={signInOpen}
          toggle={this.closeSignIn}
        >
          <h3>Looks like you're not signed in</h3>
          <p>Sign it to save your Get The Looks.</p>
          <SignInForm errors={errors} onSubmit={this.handleSignIn} />
          <p>
            <a onClick={this.toggleSignUp}>Sign Up for FREE</a>
            {" or "}
            <a onClick={this.closeSignIn}>Continue to Site</a>
          </p>
        </Modal>
        <Modal
          className="discoverSignInModal"
          title="Sign Up"
          open={signUpOpen}
          toggle={this.closeSignUp}
        >
          <SignUpForm errors={errors} onSubmit={this.handleSignUp} />
          <p>
            <a onClick={this.toggleSignIn}>or Sign In</a>
          </p>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  const { zipCode } = state.serviceArea;
  const { authenticated, errors } = state.auth;
  return {
    filter: newFilter,
    zipCode,
    errors,
    authenticated
  };
};

export default connect(mapStateToProps, actions)(CustomPages);
