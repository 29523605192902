import React from "react";
import { FormattedNumber } from "react-intl";
// import service from "Api/service";
import axios from "axios";
import { getAuthToken } from "Api";
import { connect } from "react-redux";
import { setErrors, openLoadingSpinner, closeLoadingSpinner } from "Actions";
import classnames from "classnames";
import Modal from "Utils/Modal";
import CurrencyInput from "Utils/CurrencyInput";
import validations from "HelperFunctions/validations";
import { toNumber } from "HelperFunctions/general";
import Radio from "Utils/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CreditCardForm from "Components/profile/creditCards/CreditCardForm";
import PropayBankForm from "Components/profile/bankAccounts/PropayBankForm";
import WorldpayBankForm from "Components/profile/bankAccounts/WorldpayBankForm";
import StandardSelectField from "Utils/redux_form_inputs/StandardSelectField";
import { MakeAPayment } from "Utils/SvgIcons";
import PlaidLink from "Utils/login/PlaidLink";
import converter from "json-style-converter/es5";
import ScrollArea from "Utils/react-scrollbar";
import CurrencyLabel from "Utils/CurrencyLabel";

class ConnectPaymentBox extends React.Component {
  state = {
    paymentSelection: "credit_card",
    open: false,
    successOpen: false,
    successRefundOpen: false,
    errors: {},
    loading: false,
    billingStatus: "",
    transaction: {
      amount: "",
      payment_type: "credit_card",
      payment_date: new Date(),
      card_token: "",
      bank_token: "",
      check_number: ""
    },
    new_card: null,
    new_bank: null,
    initialBillingAddress: null,
    amountType: "",
    customAmount: ""
  };

  componentWillMount() {
    const { rental } = this.props;
    let amountType;
    if (!rental.hasDeposit) {
      amountType = "amountDue";
    } else {
      amountType = "custom";
    }
    this.setState({
      amountType: amountType
    });
  }

  componentWillReceiveProps(nextProps) {
    const { rental } = nextProps;
    if (rental.hasDeposit !== this.props.rental.hasDeposit) {
      let amountType;
      if (!rental.hasDeposit) {
        amountType = "amountDue";
      } else {
        amountType = "custom";
      }
      this.setState({
        amountType: amountType
      });
    }
  }

  validate = () => {
    const errors = {};
    const { paymentSelection, transaction } = this.state;
    const { rental } = this.props;
    if (
      validations.lessThanOrEqual(transaction.amount, rental.amountRemaining)
    ) {
      errors.amount = "Thank you! Payment complete.";
    }
    if (
      paymentSelection === "credit_card" &&
      validations.required(transaction.card_token, true)
    ) {
      errors.body = "Please select a credit card.";
    }
    if (
      paymentSelection === "bank" &&
      validations.required(transaction.bank_token, true)
    ) {
      errors.body = "Please select a bank account.";
    }
    return errors;
  };

  buildRailsObject = () => {
    const { location, customer, rental } = this.props;
    const {
      transaction,
      amountType,
      new_card,
      new_bank,
      paymentSelection
    } = this.state;
    const newTransaction = {
      ...transaction,
      customer_id: customer.id,
      amount: this.getPaymentAmount(amountType),
      payment_source: "mp",
      payment_type:
        paymentSelection === "credit_card"
          ? transaction.card_token === "new_credit_card"
            ? "new_credit_card"
            : "credit_card"
          : transaction.bank_token === "new_bank"
          ? "new_bank"
          : "bank"
    };
    return {
      location_id: location.id,
      transaction: newTransaction,
      billing_note: {
        author_name: customer.firstName + " " + customer.lastName,
        message: "From Marketplace",
        note_type: "Payment"
      },
      new_card,
      new_bank
    };
  };

  validateNewCard = values => {
    const errors = {};
    const { rental } = this.props;
    const { payment_method_type, exp_month, exp_year } = values;
    if (
      rental.paymentProcessor === "propay" &&
      validations.required(payment_method_type, true)
    ) {
      errors.payment_method_type =
        "Card Type (Visa, mastercard, etc) not specified";
    }

    let today = new Date();
    let expireDate = new Date();
    expireDate.setFullYear(exp_year, exp_month, 1);
    if (expireDate < today) {
      errors.expire_month = "Expired card";
    }
    return errors;
  };

  handleSubmitNewCreditCard = values => {
    const errors = this.validateNewCard(values);
    if (Object.keys(errors).length > 0) {
      this.props.setErrors(errors);
    } else {
      this.setState(
        {
          new_card: values
        },
        this.handleSubmit
      );
    }
  };

  handleSubmitNewPropayBank = values => {
    this.setState(
      {
        new_bank: values
      },
      this.handleSubmit
    );
  };

  handleSubmitNewBank = (token, metadata) => {
    const new_bank = {
      token: token,
      account_id: metadata.account_id
    };
    this.setState(
      {
        new_bank: new_bank
      },
      this.handleSubmit
    );
  };

  handleSubmit = () => {
    const errors = this.validate();
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors: errors
      });
      const { setErrors } = this.props;
      setErrors(errors);
    } else {
      const component = this;
      const {
        rental,
        openLoadingSpinner,
        closeLoadingSpinner,
        setErrors,
        onPaymentSuccess
      } = this.props;
      openLoadingSpinner(
        "Processing payment... Please do not refresh or navigate away from this page."
      );
      axios
        .post(
          process.env.REACT_APP_API_DOMAIN +
            "/api/portal/rentals/" +
            rental.id +
            "/transactions",
          this.buildRailsObject(),
          { headers: getAuthToken() }
        )
        .then(response => {
          closeLoadingSpinner();
          onPaymentSuccess();
          component.setState({
            successOpen: true,
            open: false,
            transaction: {
              amount: "",
              payment_type: "new_credit_card",
              card_token: "",
              bank_token: "",
              check_number: ""
            },
            errors: {}
          });
        })
        .catch(error => {
          const errors = error.response.data;
          setErrors(errors);
          closeLoadingSpinner();
          component.setState({
            errors: errors
          });
        });
    }
  };

  handleSuccessClose = () => {
    this.setState({
      successOpen: false
    });
  };

  handleTransactionChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    let newTransaction = this.state.transaction;

    newTransaction[name] = toNumber(value);

    this.setState({
      transaction: newTransaction
    });
  };

  handlePaymentTypeChange = event => {
    const value = event.target.value;
    let newTransaction = {
      ...this.state.transaction,
      payment_type: value,
      bank_token: "",
      card_token: ""
    };
    this.setState({
      paymentSelection: value,
      transaction: newTransaction
    });
  };

  handleAmountTypeChange = event => {
    const newAmountType = event.target.value;
    this.setState({
      amountType: newAmountType
    });
  };

  getPaymentAmount = amountType => {
    const { rental } = this.props;
    const amountDue = rental.hasDeposit
      ? 0
      : Number(rental.minimumDeposit) - Number(rental.paymentBalance);
    switch (amountType) {
      case "amountDue":
        return amountDue;
      case "amountRemaining":
        return rental.amountRemaining;
      case "custom":
        return this.state.customAmount;
      default:
        return 0;
    }
  };

  renderCreditCardFee = () => {
    const {
      location: { creditCardPercent }
    } = this.props;
    const { paymentSelection, transaction } = this.state;
    const percent = creditCardPercent / 100.0;
    if (paymentSelection === "credit_card") {
      if (transaction.amount) {
        const newAmount = Number(transaction.amount) * percent;
        return (
          <p>
            You will be charged{" "}
            <CurrencyLabel value={newAmount} />{" "}
            for credit card fees.
          </p>
        );
      } else {
        return (
          <p>
            You will be charged{" "}
            <FormattedNumber
              value={percent || 0}
              style="percent"
              minimumFractionDigits={2}
              minimumSignificantDigits={3}
            />{" "}
            for credit card fees.
          </p>
        );
      }
    } else {
      return null;
    }
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: toNumber(value)
    });
  };

  copyAddress = (event, isChecked) => {
    if (isChecked) {
      const { customer } = this.props;
      const customerAddress = {
        streetAddress1: customer.streetAddress1,
        streetAddress2: customer.streetAddress2,
        city: customer.city,
        postalCode: customer.postalCode,
        locale: customer.locale,
        country: customer.country
      };
      this.setState({
        initialBillingAddress: converter.camelToSnakeCase(customerAddress)
      });
    }
  };

  render() {
    const {
      errors,
      paymentSelection,
      initialBillingAddress,
      customAmount,
      amountType,
      transaction
    } = this.state;
    const { location, customer, rental } = this.props;
    return (
      <section>
        <div className="summary">
          <MakeAPayment />
          <h4>{rental.locationName}</h4>
          <div className="billingDetails">
            {!rental.hasDeposit && rental.minimumDeposit !== "" && (
              <div>
                <label>Minimum Deposit</label>
                <CurrencyLabel
                  value={
                    Number(rental.minimumDeposit) -
                    Number(rental.paymentBalance)
                  }
                />
              </div>
            )}
            <div>
              <label>Amount Remaining</label>
              <CurrencyLabel value={rental.amountRemaining} />
            </div>
          </div>
        </div>
        <ScrollArea speed={0.8} horizontal={false}>
          <div className="details">
            <div className="fields amount">
              <label>Payment Amount</label>
              <RadioGroup
                name="amountType"
                className="radioList"
                value={amountType}
                onChange={this.handleAmountTypeChange}
              >
                <FormControlLabel
                  label="Amount Due"
                  value="amountRemaining"
                  control={<Radio />}
                />
                <FormControlLabel
                  label="Minimum Deposit Due"
                  value="amountDue"
                  control={<Radio />}
                />
                <FormControlLabel
                  label="Custom Amount"
                  value="custom"
                  control={<Radio />}
                />
              </RadioGroup>
              <strong>
                <CurrencyLabel value={this.getPaymentAmount("amountRemaining")} />
              </strong>
              <strong>
                <CurrencyLabel value={this.getPaymentAmount("amountDue")} />
              </strong>
              <CurrencyInput
                name="customAmount"
                type="text"
                placeholder="$0.00"
                className={classnames({
                  med: true,
                  error: errors.amount
                })}
                value={customAmount}
                onChange={this.handleChange}
              />
            </div>
            <div className="fields">
              <label>Method of Payment</label>
              <RadioGroup
                name="paymentType"
                className="radioList"
                onChange={this.handlePaymentTypeChange}
                value={paymentSelection}
              >
                <FormControlLabel
                  value="credit_card"
                  control={<Radio />}
                  label="Credit Card"
                />
                {(rental.paymentProcessor !== "propay" ||
                  (rental.paymentProcessor === "propay" &&
                    rental.locationPropayCountry === "USA")) && (
                  <FormControlLabel
                    value="bank"
                    control={<Radio />}
                    label="Bank Account"
                  />
                )}
              </RadioGroup>
            </div>
            <div className="fields">
              <label>Pay with</label>
              {paymentSelection === "credit_card" && (
                <StandardSelectField
                  meta={{}}
                  input={{
                    name: "card_token",
                    value: transaction.card_token,
                    onChange: this.handleTransactionChange
                  }}
                >
                  <option value="">Select a Credit Card</option>
                  <option value="new_credit_card">New Credit Card</option>
                  {customer.creditCards
                    .filter(
                      cc => cc.paymentProcessor === rental.paymentProcessor
                    )
                    .map(credit_card => (
                      <option key={credit_card.id} value={credit_card.token}>
                        {credit_card.name}
                      </option>
                    ))}
                </StandardSelectField>
              )}
              {transaction.card_token === "new_credit_card" && (
                <CreditCardForm
                  onSubmit={this.handleSubmitNewCreditCard}
                  copyAddress={this.copyAddress}
                  initialBillingAddress={initialBillingAddress}
                  type="checkout"
                  hideCopy={customer.streetAddress1 === null}
                  withPaymentType={["propay", "worldpay"].includes(
                    rental.paymentProcessor
                  )}
                />
              )}
              {paymentSelection === "bank" && (
                <StandardSelectField
                  meta={{}}
                  input={{
                    name: "bank_token",
                    value: transaction.bank_token,
                    onChange: this.handleTransactionChange
                  }}
                >
                  <option value="">Select a Bank Account</option>
                  <option value="new_bank">New Bank Account</option>
                  {customer.bankAccounts
                    .filter(
                      ba => ba.paymentProcessor === rental.paymentProcessor
                    )
                    .map(bankAccount => (
                      <option key={bankAccount.id} value={bankAccount.token}>
                        {`${bankAccount.bankInfo.bankName} ${bankAccount.bankInfo.last4}`}
                      </option>
                    ))}
                </StandardSelectField>
              )}
            </div>
            {paymentSelection === "bank" &&
              transaction.bank_token === "new_bank" &&
              rental.paymentProcessor === "stripe" && (
                <PlaidLink
                  publicKey="0809d4ccb43aa8a75b132e17f3da03"
                  product="auth"
                  env={process.env.REACT_APP_PLAID_ENV}
                  clientName={rental.locationName}
                  onSuccess={this.handleSubmitNewBank}
                  selectAccount={true}
                  className="btn full"
                  buttonText="Pay with Bank Account"
                >
                  Pay with Bank Account
                </PlaidLink>
              )}
            {paymentSelection === "bank" &&
              transaction.bank_token === "new_bank" &&
              rental.paymentProcessor === "propay" && (
                <PropayBankForm
                  onSubmit={this.handleSubmitNewPropayBank}
                  type="checkout"
                />
              )}
            {paymentSelection === "bank" &&
              transaction.bank_token === "new_bank" &&
              rental.paymentProcessor === "worldpay" && (
                <WorldpayBankForm
                  onSubmit={this.handleSubmitNewPropayBank}
                  type="checkout"
                />
              )}
            {((paymentSelection === "credit_card" &&
              transaction.card_token !== "new_credit_card" &&
              transaction.card_token !== "") ||
              (paymentSelection === "bank" &&
                transaction.bank_token !== "new_bank" &&
                transaction.bank_token !== "")) && (
              <div className="actions">
                <input
                  type="submit"
                  value="Submit Payment"
                  onClick={this.handleSubmit}
                  className="btn full"
                />
              </div>
            )}
            {this.renderCreditCardFee()}
          </div>
        </ScrollArea>

        <Modal
          open={this.state.successOpen}
          toggle={this.handleSuccessClose}
          title="Payment Confirmation"
          actions={[
            <a className="btn full" onClick={this.handleSuccessClose}>
              Ok
            </a>
          ]}
        >
          <p>Submitted payment was received</p>
        </Modal>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const location = ownProps.location
    ? ownProps.location
    : {
        id: ownProps.rental.locationId,
        paymentProcessor: ownProps.rental.paymentProcessor,
        creditCardPercent: ownProps.rental.locationCreditCardPercent
      };
  const customer = ownProps.customer
    ? ownProps.customer
    : state.customer.customer;

  return { location, customer };
};

const mapDispatchToProps = {
  setErrors,
  openLoadingSpinner,
  closeLoadingSpinner
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectPaymentBox);
