import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import * as actions from "Actions";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import StandardSelectField from "Utils/redux_form_inputs/StandardSelectField";
import {
  arraysEqual,
  combineDateAndTime,
  debounce,
  formatPhoneNumber
} from "HelperFunctions/general";
import { setFilterParams, filterFromPropsAndState } from "HelperFunctions/urls";
import CategoryFilterDesktop from "./CategoryFilterDesktop";
import CategoryFilterMobile from "./CategoryFilterMobile";
import AvailableItemList from "./AvailableItemList";
import BusinessNoItemsPage from "./BusinessNoItemsPage";
import Imgix from "react-imgix";
import Suggestions from "Utils/Suggestions";
import classnames from "classnames";
import { parseQuery } from "HelperFunctions/QueryString";

class SearchItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: null,
      filter: props.filter,
      results: [],
      focused: false
    };
    this.performSearch = debounce(this.performSearch, 500);
    this.handleQueryChange = debounce(this.handleQueryChange, 500);
    this.handleQuantityChange = debounce(this.handleQuantityChange, 500);
    this.handleSelectType = this.handleSelectType.bind(this);
  }

  componentDidMount() {
    const {
      filter,
      reloadInventory,
      fetchInventoryCategories,
      fetchInventoryColors
    } = this.props;
    if (!this.props.type) {
      const { location } = this.props;
      const { type } = parseQuery(location.search);
      this.setState({
        filter: {
          type: type
        }
      });
    } else {
      this.setState({
        filter: {
          type: this.props.type
        }
      });
    }
    reloadInventory(filter);
    fetchInventoryCategories("items", filter.query, filter.locationId);
    fetchInventoryColors(filter.query, filter.locationId);
  }

  componentWillReceiveProps(nextProps) {
    const oldFilter = this.props.filter;
    let newFilter = nextProps.filter;
    let changed = false;

    for (var property in newFilter) {
      if (newFilter.hasOwnProperty(property)) {
        if (newFilter[property] instanceof Array) {
          if (arraysEqual(newFilter[property], oldFilter[property]) === false) {
            changed = true;
            break;
          }
        } else {
          const newTest =
            newFilter[property] instanceof Date
              ? newFilter[property].getTime()
              : newFilter[property];
          const oldTest =
            oldFilter[property] instanceof Date
              ? oldFilter[property].getTime()
              : oldFilter[property];
          if (newTest !== oldTest) {
            changed = true;
            break;
          }
        }
      }
    }

    if (changed) {
      const {
        reloadInventory,
        fetchInventoryCategories,
        fetchInventoryColors
      } = this.props;
      const filterToSubmit = Object.assign(newFilter);

      reloadInventory(filterToSubmit);
      fetchInventoryCategories("items", newFilter.query, newFilter.locationId);
      fetchInventoryColors(newFilter.query, newFilter.locationId);
    }

    if (newFilter.locationId) {
      this.fetchLocation(newFilter.locationId);
    }
  }

  getResults = () => {
    const { query, type } = this.state.filter;

    axios
      .get(
        process.env.REACT_APP_API_DOMAIN +
          `/api/marketplace/inventories/search_filter?query=${query}&type=${type}`
      )
      .then(({ data }) => {
        this.setState({
          results: data.products
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleSelectType = event => {
    const selected = event.target.value;
    this.setState({
      filter: {
        type: selected
      }
    });
  };

  fetchLocation = id => {
    axios
      .get(
        process.env.REACT_APP_API_DOMAIN + `/api/marketplace/locations/${id}`
      )
      .then(response => {
        this.setState({ location: response.data.location });
      });
  };

  handleSubCategorySelected = (isChecked, category, subCategory) => {
    const {
      filter: { selectedCategories, selectedSubCategories }
    } = this.props;
    let newSubCategories = [...selectedSubCategories];
    let newCategories = [...selectedCategories];

    if (isChecked) {
      newSubCategories.push(subCategory.id);
    } else {
      newSubCategories = newSubCategories.filter(ns => subCategory.id !== +ns);
    }

    if (
      newSubCategories.length > 0 &&
      newCategories.indexOf(category.id) === -1
    ) {
      newCategories.push(category.id);
    } else if (newSubCategories.length === 0) {
      newCategories = [];
    }

    const { location, history } = this.props;
    setFilterParams(
      {
        selectedCategories: newCategories.join(","),
        selectedSubCategories: newSubCategories.join(",")
      },
      location,
      history
    );
  };

  handleCategorySelected = (isChecked, category) => {
    const {
      filter: { selectedCategories, selectedSubCategories }
    } = this.props;
    let newSelected = [...selectedCategories];
    let newSelectedSubCategories = [...selectedSubCategories];

    if (isChecked) {
      newSelected.push(category.id);
      category.subCategories.forEach(subCategory => {
        if (newSelectedSubCategories.indexOf(String(subCategory.id)) === -1) {
          newSelectedSubCategories.push(String(subCategory.id));
        }
      });
    } else {
      newSelected = newSelected.filter(ns => category.id !== +ns);
      category.subCategories.forEach(subCategory => {
        const index = newSelectedSubCategories.indexOf(String(subCategory.id));
        if (index > -1) {
          newSelectedSubCategories.splice(index, 1);
        }
      });
    }

    const { location, history } = this.props;
    setFilterParams(
      {
        selectedCategories: newSelected.join(","),
        selectedSubCategories: newSelectedSubCategories.join(",")
      },
      location,
      history
    );
  };

  handleColorSelection = (isChecked, id) => {
    const {
      filter: { selectedColors }
    } = this.props;
    const index = selectedColors.indexOf(id.toString());
    let newSelection = selectedColors.slice();
    if (isChecked) {
      if (index === -1) {
        newSelection.push(id);
      }
    } else {
      if (index > -1) {
        newSelection.splice(index, 1);
      }
    }
    const { location, history } = this.props;
    setFilterParams(
      {
        selectedColors: newSelection.join(",")
      },
      location,
      history
    );
  };
  clearColorSelection = () => {
    const { location, history } = this.props;
    setFilterParams(
      {
        selectedColors: null
      },
      location,
      history
    );
  };

  handleClick = event => {
    this.setState({
      filter: {
        ...this.state.filter,
        query: event.target.innerText
      },
      results: []
    });
    const selected = event.target.innerText;
    const type = this.state.filter.type || "All";
    this.handleQueryChange(selected, type);
  };

  handleQuantitySelection = newValues => {
    const [minQuantity, maxQuantity] = newValues;
    this.setState({
      filter: {
        ...this.state.filter,
        minQuantity,
        maxQuantity
      }
    });
    this.handleQuantityChange(newValues);
  };

  handleQuantityChange(newValues) {
    const [minQuantity, maxQuantity] = newValues;
    const { location, history } = this.props;
    setFilterParams(
      {
        minQuantity,
        maxQuantity,
        page: null
      },
      location,
      history
    );
  }
  performSearch = value => {
    this.getResults();
  };
  handleSearch = event => {
    const { value } = event.target;
    this.setState(
      {
        filter: {
          ...this.state.filter,
          query: value
        }
      },
      this.performSearch
    );
  };

  handleCategoryClick = id => {
    const selected = id;
    this.props.history.push(
      `/shop/search?query=&selectedCategories=${selected}`
    );
    this.setState({
      filter: {
        query: ""
      },
      results: []
    });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      const { query, type } = this.state.filter;
      this.handleQueryChange(query, type);
    }
  };

  handleQueryChange(value, type) {
    const { location, history } = this.props;
    setFilterParams(
      {
        query: value,
        type: type,
        page: null
      },
      location,
      history
    );
  }

  handleNumberPerChange = event => {
    const { location, history } = this.props;
    const { value } = event.target;
    setFilterParams(
      {
        numberPer: value,
        page: null
      },
      location,
      history
    );
  };

  focused = () => {
    this.setState({
      query: "",
      results: []
    });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.props.setCartProps({ [name]: value });
  };

  render() {
    const {
      loading,
      inventory,
      filter: {
        selectedCategories,
        selectedSubCategories,
        selectedColors,
        numberPer
      }
    } = this.props;
    const { location, filter, results, focused } = this.state;

    return (
      <div className="shop">
        <header>
          <h1>Shop</h1>
          <div
            className={classnames({
              searchFilter: true,
              open: filter.query !== "" && results.length > 0
            })}
          >
            <select
              name="inventory_type"
              onChange={this.handleSelectType}
              value={this.state.filter.type}
            >
              <option value="All">All</option>
              <option value="inflatables">Inflatables</option>
            </select>
            <input
              type="search"
              placeholder="Search for items to rent..."
              onChange={this.handleSearch}
              onKeyPress={this.handleKeyPress}
              value={filter.query}
              onBlur={this.focused}
            />
            {filter.query !== "" && (
              <Suggestions
                results={results}
                handleClick={this.handleClick}
                handleCategoryClick={this.handleCategoryClick}
              />
            )}
          </div>
          {inventory.length > 0 && (
            <StandardSelectField
              className="numberSelect"
              meta={{}}
              input={{
                name: "numberPer",
                value: numberPer,
                onChange: this.handleNumberPerChange
              }}
            >
              <option value="12">12</option>
              <option value="24">24</option>
              <option value="48">48</option>
            </StandardSelectField>
          )}
        </header>
        {location && (
          <section className="business">
            {location.picture && (
              <figure>
                <Imgix src={location.picture.imgixUrl} alt={location.name} />
              </figure>
            )}
            <div className="summary">
              <h3>{location.name}</h3>
              <p>{location.marketplaceSetting.aboutusText}</p>
            </div>
            <div className="details">
              {location.phoneNumber && (
                <p>{formatPhoneNumber(location.phoneNumber.office)}</p>
              )}
              <p>{location.email}</p>
              {location.physicalAddress && (
                <div>
                  {location.physicalAddress.streetAddress1}
                  {location.physicalAddress.streetAddress2 && (
                    <div>{location.physicalAddress.streetAddress2}</div>
                  )}
                  {location.physicalAddress.city}
                  {", "}
                  {location.physicalAddress.locale}{" "}
                  {location.physicalAddress.postalCode}
                  {", "}
                  {location.physicalAddress.country}
                </div>
              )}
            </div>
          </section>
        )}

        {!loading && inventory.length === 0 ? (
          <BusinessNoItemsPage />
        ) : (
          <section className="storefront">
            <MediaBreakpoints
              desktop={
                <CategoryFilterDesktop
                  handleCategorySelected={this.handleCategorySelected}
                  handleSubCategorySelected={this.handleSubCategorySelected}
                  onColorSelection={this.handleColorSelection}
                  onQuantitySelection={this.handleQuantitySelection}
                  selectedCategories={selectedCategories}
                  selectedSubCategories={selectedSubCategories}
                  selectedColors={selectedColors}
                  selectedMinQuantity={filter.minQuantity || 0}
                  selectedMaxQuantity={filter.maxQuantity || 500}
                  clearColorSelection={this.clearColorSelection}
                  type={this.props.filter.type}
                />
              }
              mobile={
                <CategoryFilterMobile
                  handleCategorySelected={this.handleCategorySelected}
                  handleSubCategorySelected={this.handleSubCategorySelected}
                  onColorSelection={this.handleColorSelection}
                  onQuantitySelection={this.handleQuantitySelection}
                  selectedCategories={selectedCategories}
                  selectedSubCategories={selectedSubCategories}
                  selectedColors={selectedColors}
                  selectedMinQuantity={filter.minQuantity || 0}
                  selectedMaxQuantity={filter.maxQuantity || 500}
                  clearColorSelection={this.clearColorSelection}
                  type={this.props.filter.type}
                  forConnect
                />
              }
            />
            <AvailableItemList />
          </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  const selectedCategories = newFilter.selectedCategories
    ? newFilter.selectedCategories.split(",")
    : [];
  const selectedSubCategories = newFilter.selectedSubCategories
    ? newFilter.selectedSubCategories.split(",")
    : [];
  const selectedColors = newFilter.selectedColors
    ? newFilter.selectedColors.split(",")
    : [];

  const { event } = state.cart;
  const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
  const eventStartDateTime = eventStart
    ? combineDateAndTime(eventStart, eventStartTime)
    : null;
  const eventEndDateTime = eventEnd
    ? combineDateAndTime(eventEnd, eventEndTime)
    : null;
  return {
    inventory: state.products.inventory,
    loading: state.products.loading,
    filter: {
      ...newFilter,
      selectedCategories,
      selectedSubCategories,
      selectedColors,
      eventStart: eventStartDateTime,
      eventEnd: eventEndDateTime
    },
    event
  };
};

export default connect(mapStateToProps, actions)(SearchItems);
