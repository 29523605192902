import React from "react";
import { Link } from "react-router-dom";
import ConversationCard from "./ConversationCard";

const MobileConversations = props => {
  const { conversations, newConversationLink, forRental } = props;
  return (
    <section className="messages">
      <Link className="btn" to={newConversationLink}>
        New Message
      </Link>
      <ul className="convItemList">
        {conversations.map((conversation, index) => {
          return (
            <ConversationCard
              conversation={conversation}
              key={index}
              forRental={forRental}
            />
          );
        })}
      </ul>
    </section>
  );
};

export default MobileConversations;
