import React from "react";
import { Link } from "react-router-dom";
import * as actions from "Actions";
import { connect } from "react-redux";
import { FormattedDate } from "react-intl";
import RentalDelivery from "./RentalDelivery";
import RentalBilling from "./RentalBilling";
import RentalItemList from "./RentalItemList";
import ConnectPaymentBox from "./ConnectPaymentBox";
import DetailedAmountPaid from "./DetailedAmountPaid";
import DetailedAmountRefunded from "./DetailedAmountRefunded";
import Modal from "Utils/Modal";
import { MessageBubble, Document } from "Utils/SvgIcons";
import NewDocumentForm from "Components/documents/NewDocumentForm";
import SignatureModal from "Utils/SignatureModal";
import CurrencyLabel from "Utils/CurrencyLabel";
import { countryToLocale } from "Constants/currencyConstants";
import ChangeRequestDetails from "./change_request/ChangeRequestDetails";
import moment from "moment";
import { capitalizeFirstLetter } from "HelperFunctions/general";

class Rental extends React.Component {
  handlePaymentSuccess = () => {
    const { togglePayment, refreshRental } = this.props;
    togglePayment();
    refreshRental();
  };
  handleAddDocument = values => {
    const newDocument = {
      document_attributes: {
        name: values.name,
        document_type: values.documentType,
        file: values.file[0],
        visible_to_customer: true
      },
      _destroy: "0"
    };
    this.props.addDocument(newDocument);
  };
  renderActionButton = rental => {
    if (rental.status === "cancelled") {
      return (
        <button className="full disabled" disabled>
          Quote Cancelled
        </button>
      );
    } else if (rental.cancelRequested) {
      return (
        <button className="full disabled" disabled>
          Cancellation Pending
        </button>
      );
    } else {
      switch (rental.approvalStatus) {
        case "draft":
          return (
            <button
              className="full"
              onClick={() => this.handleSubmitQuote(rental)}
            >
              Submit
            </button>
          );
        case "pending":
          return (
            <button className="full disabled" disabled>
              Quote Pending
            </button>
          );
        case "approved":
          if (
            rental.changeRequest !== null &&
            rental.changeRequest.status === "pending"
          ) {
            return (
              <button className="full disabled" disabled>
                Changes Requested
              </button>
            );
          } else {
            if (rental.autoBooked) {
              return null;
            } else {
              return (
                <button className="full disabled" disabled>
                  Quote Approved
                </button>
              );
            }
          }
        default:
          return null;
      }
    }
  };
  renderRentalStatus = rental => {
    if (rental.status === "cancelled") {
      return "Cancelled";
    } else if (rental.cancelRequested) {
      return "Cancellation Pending";
    } else if (
      rental.source === "marketplace" &&
      rental.timeLeftToExpire <= 0
    ) {
      return "Expired";
    } else {
      switch (rental.approvalStatus) {
        case "pending":
          return "Quote Pending";
        case "approved":
          if (
            rental.changeRequest &&
            rental.changeRequest.status === "pending"
          ) {
            return "Changes Requested";
          } else if (rental.status === "cancelled") {
            return "Rental Cancelled";
          } else {
            if (rental.signatureRequired && !rental.hasSignature) {
              return "Signature Required";
            } else {
              if (rental.autoBooked) {
                return "Quote";
              } else {
                return "Quote Approved";
              }
            }
          }
        case "declined":
          return "Quote Declined";
        default:
          return null;
      }
    }
  };
  render() {
    const {
      fields: {
        items,
        delivery_type,
        delivery_address_location_name,
        delivery_address_street_address_1,
        delivery_address_street_address_2,
        delivery_address_city,
        delivery_address_locale,
        delivery_address_postal_code,
        delivery_address_country,
        paymentOpen,
        documentModalOpen,
        signModalOpen
      },
      rental,
      business,
      customer,
      adjustRentalItems,
      deleteRentalItem,
      onSubmitSignature,
      togglePayment,
      toggleDocumentModal,
      toggleSignModal
    } = this.props;
    const delivery = {
      delivery_type,
      delivery_address_location_name,
      delivery_address_street_address_1,
      delivery_address_street_address_2,
      delivery_address_city,
      delivery_address_locale,
      delivery_address_postal_code,
      delivery_address_country
    };
    const billing = {
      rental_item_total: rental.rentalItemTotal,
      rental_add_on_total: rental.rentalAddOnTotal,
      rental_bundle_total: rental.rentalBundleTotal,
      delivery_cost: rental.deliveryCost,
      discount_total: rental.discountTotal,
      expenseTotal: rental.expenseTotal,
      fee_total: rental.feeTotal,
      sub_total: rental.subTotal,
      subTotalForTax: rental.subTotalForTax,
      tax_total: rental.taxTotal,
      total_custom_tax: rental.totalCustomTax,
      overall_total: rental.overallTotal,
      tapgoods_fee_total: rental.tapgoodsFeeTotal,
      tapgoodsTaxTotal: rental.tapgoodsTaxTotal,
      amount_paid: rental.totalPaid,
      amount_remaining: rental.amountRemaining,
      processingFeeRefunded: rental.processingFeeRefunded,
      tapgoodsFeeRefunded: rental.tapgoodsFeeRefunded,
      tapgoodsTaxRefunded: rental.tapgoodsTaxRefunded
    };
    let venue = null;
    if (rental.venueRentalRelationship) {
      venue = rental.venueRentalRelationship.venue;
    }
    const changeRequests = rental.changeRequests;
    const hasNotes = (rental.notes && rental.notes.length > 0) || rental.additionalDeliveryInfo != null || rental.additionalOrderInfo != null || changeRequests.length > 0;

    let locale = "en-US";
    if (rental && rental.businessAddress) {
      locale = countryToLocale[rental.businessAddress.country];
    }

    return (
      <div>
        <RentalItemList
          rental={rental}
          products={items}
          adjustRentalItems={adjustRentalItems}
          deleteRentalItem={deleteRentalItem}
        />

        <section className="summary">
          <h4>
            Status:
            <span>{this.renderRentalStatus(rental)}</span>
          </h4>
          <Link to={`/conversations`}>
            <MessageBubble />
          </Link>
          <div className="billingDetails">
            <div>
              <label>Quote Amount</label>
              <CurrencyLabel
                value={rental.overallTotal}
                locale={locale}
              />
            </div>
            <div>
              <label>Amount Due</label>
              <CurrencyLabel
                value={rental.amountRemaining}
                locale={locale}
              />
            </div>
            <div className="date">
              <label>Delivery</label>
              <span>
                <FormattedDate
                  value={rental.startDate}
                  month="numeric"
                  day="numeric"
                />
                {" - "}
                <FormattedDate
                  value={rental.endDate}
                  month="numeric"
                  year="numeric"
                  day="numeric"
                />
              </span>
            </div>
          </div>

          <div className="actions">{this.renderActionButton(rental)}</div>
          {rental.paymentTerm && (
            <div className="term">{rental.paymentTerm.replace(/-/g, " ")}</div>
          )}

          {rental.isRecurringPayment && rental.recurringPaymentData &&
            <div className="recurringPaymentDetails">
              <div>
                <label>Recurring Payment Amount</label>
                <CurrencyLabel value={rental.recurringPaymentData.paymentAmount} />
              </div>
              <div>
                <label>Start Date</label>
                <span>
                  {moment(rental.recurringPaymentData.startDate).format('MM/DD/YYYY')}
                </span>
              </div>
              {rental.recurringTransactionInfo && Object.keys(rental.recurringTransactionInfo).reverse().map( (itemKey, index) => (
                <div key={index}>
                  <label>{itemKey === "endDate" ? "End Date" : capitalizeFirstLetter(itemKey)}</label>
                  <span>
                    {rental.recurringTransactionInfo[itemKey]}
                  </span>
                </div>
              ))}
              <div>
                <label>Next Payment Due On</label>
                <span>{rental.recurringPaymentData.nextDueDateOn}</span>
              </div>
            </div>
          }
        </section>

        <RentalDelivery
          rental={rental}
          delivery={delivery}
          schedule={rental.schedule}
        />

        {venue && (
          <section className="venue">
            <h4>Venue</h4>
            <div>{venue.name}</div>
            <div>{venue.streetAddress1}</div>
            {venue.streetAddress2 && <div># {venue.streetAddress2}</div>}
            <div>
              {venue.city}, {venue.locale} {venue.postalCode}, {venue.country}
            </div>
          </section>
        )}

        <section className="documents">
          <h4>Documents</h4>
          {rental.documents.map(document => (
            <a key={document.id} href={document.url} target="_blank">
              <Document />
              {document.name}
            </a>
          ))}
          <div className="actions">
            <button className="full" onClick={toggleDocumentModal}>
              Upload a Document
            </button>
          </div>
        </section>

        <section className="notes">
          {hasNotes && <h4>Notes</h4>}
          {rental.notes
            .filter(n => n.visibleToCustomer)
            .map(note => (
              <div key={note.id}>
                <FormattedDate
                  value={note.createdAt}
                  month="numeric"
                  year="numeric"
                  day="numeric"
                  hour="numeric"
                  minute="numeric"
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: note.message
                  }}
                />
              </div>
            ))}      
            {rental.additionalOrderInfo && (
              <div>
                <strong>Additional Order Info:</strong>
                <p>{rental.additionalOrderInfo}</p>
              </div>
            )}
            {rental.additionalDeliveryInfo && (
              <div>
                <strong>Additional Delivery Info:</strong>
                <p>{rental.additionalDeliveryInfo}</p>
              </div>
            )}
            <div>
              <ChangeRequestDetails changeRequests={changeRequests} />
            </div>
        </section>

        <RentalBilling
          rentalId={rental.id}
          rental={rental}
          billing={billing}
          secondary={true}
          locale={locale}
        />

        <section className="payments">
          <h4>Payments</h4>
          <div className="billingDetails">
            {rental.payments &&
              rental.payments.length > 0 &&
              rental.payments.map(payment => (
                <DetailedAmountPaid
                  key={payment.id}
                  payment={payment}
                  ownerView={false}
                  recurringTransactionInfo={rental.recurringTransactionInfo}
                />
              ))}

            {rental.refunds &&
              rental.refunds.map(refund => (
                <DetailedAmountRefunded key={refund.id} refund={refund} />
              ))}
            <div className="subtotal">
              <label>Amount Remaining</label>
              <CurrencyLabel
                value={billing.amount_remaining}
                locale={locale}
              />
            </div>
          </div>
          {rental.isAllowedToMakePayment && (
            <div className="actions">
              {rental.signatureRequired && !rental.hasSignature ? (
                <button className="btn full" onClick={toggleSignModal}>
                  Signature Required Before Payment
                </button>
              ) : (
                <button className="full" onClick={togglePayment}>
                  Make a Payment
                </button>
              )}
            </div>
          )}
          <Modal
            title="Make a Payment"
            className="large paymentModal"
            toggle={togglePayment}
            open={paymentOpen}
          >
            <ConnectPaymentBox
              onPaymentSuccess={this.handlePaymentSuccess}
              rentalId={rental.id}
              rental={rental}
              business={business}
              customer={customer}
            />
          </Modal>
          <Modal
            title="Upload Document"
            toggle={toggleDocumentModal}
            open={documentModalOpen}
          >
            <NewDocumentForm onSubmit={this.handleAddDocument} />
          </Modal>
          <SignatureModal
            open={signModalOpen}
            toggle={toggleSignModal}
            rental={rental}
            onSubmitSignature={onSubmitSignature}
          />
        </section>
      </div>
    );
  }
}

export default connect(null, actions)(Rental);
