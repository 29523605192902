import { combineReducers } from "redux";
import ServiceAreaReducer from "./ServiceAreaReducer";
import CategoryListReducer from "./CategoryListReducer";
import ColorListReducer from "./ColorListReducer";
import ProductReducer from "./ProductReducer";
import ConversationListReducer from "./ConversationListReducer";
import ConversationReducer from "./ConversationReducer";
import MessageableReducer from "./MessageableReducer";
import RentalListReducer from "./RentalListReducer";
import EventListReducer from "./EventListReducer";
import LoadingSpinnerReducer from "./LoadingSpinnerReducer";
import DashboardReducer from "./dashboard";
import CustomerReducer from "./CustomerReducer";
import CartReducer from "./CartReducer";
import RentalReducer from "./RentalReducer";
import EventReducer from "./EventReducer";

import AuthReducer from "./AuthReducer";
import CustomPagesReducer from "./CustomPagesReducer";
import SnackbarReducer from "./SnackbarReducer";
import { reducer as formReducer } from "redux-form";

const mainReducer = combineReducers({
  serviceArea: ServiceAreaReducer,
  categoryList: CategoryListReducer,
  customPages: CustomPagesReducer,
  colorList: ColorListReducer,
  products: ProductReducer,
  conversationList: ConversationListReducer,
  conversation: ConversationReducer,
  messageable: MessageableReducer,
  rentalsList: RentalListReducer,
  dashboard: DashboardReducer,
  eventList: EventListReducer,
  loadingSpinner: LoadingSpinnerReducer,
  customer: CustomerReducer,
  cart: CartReducer,
  rental: RentalReducer,
  event: EventReducer,
  auth: AuthReducer,
  form: formReducer
});

export default mainReducer;
