import React from "react";
import { Helmet } from "react-helmet";
import ValidationPopUp from "Utils/ValidationPopUp";
import SuccessPopUp from "Utils/SuccessPopUp";
import VersionPopUp from "Utils/VersionPopUp";
import GlobalLoadingSpinner from "Utils/GlobalLoadingSpinner";
import GlobalProgressBar from "Utils/GlobalProgressBar";

class App extends React.Component {
  render() {
    return (
      <aside>
        <Helmet>
          <title>TapGoods</title>
          <meta
            name="description"
            content="Renting Made Easy. Rent anything from rental companies near you."
          />
          <meta
            name="keywords"
            content="Inventory Management Software, Rental Software, Point of rental software, Rental, Renting Items, Rentals Dallas Texas, Event Rentals, Party Rentals, Inventory Management"
          />
        </Helmet>
        <ValidationPopUp />
        <GlobalLoadingSpinner />
        <GlobalProgressBar />
        <SuccessPopUp />
        <VersionPopUp />
      </aside>
    );
  }
}

export default App;
