import React from "react";
import { connect } from "react-redux";
import { validateUser } from "Actions";
import LoadingSpinner from "Utils/LoadingSpinner";

// WithAuth HOC
const WithAuth = WrappedComponent => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      const { validateUser, authenticated } = this.props;
      if (!authenticated) {
        validateUser();
      }
    }

    render() {
      /*eslint-disable */
      const {
        user,
        loading,
        authenticated,
        validateUser,
        ...other
      } = this.props;
      /*eslint-disable */
      if (loading) {
        return <LoadingSpinner />;
      } else if (authenticated) {
        return <WrappedComponent {...other} />;
      } else {
        return null;
      }
    }
  }
  const mapStateToProps = state => {
    const { user, loading, authenticated } = state.auth;

    return { user, authenticated, loading };
  };
  return connect(mapStateToProps, { validateUser })(WithAuthentication);
};

export default WithAuth;
