import React from "react";
import SignInWidget from "Components/home/widgets/SignIn";

class SignIn extends React.Component {
  render() {
    return (
      <div className="signIn">
        <header>
          <h1>Sign In</h1>
        </header>
        <SignInWidget />
      </div>
    );
  }
}

export default SignIn;
