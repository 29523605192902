import React from "react";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import Validator from "HelperFunctions/validator";

const validations = {
  email: {
    required: {
      value: true,
      message: "Email is required"
    }
  },
  password: {
    required: {
      value: true,
      message: "Password is required"
    }
  }
};

const SignInForm = props => {
  const { handleSubmit, pristine, submitting } = props;
  return (
    <form onSubmit={handleSubmit} className="form">
      <div className="fields">
        <Field
          name="email"
          component={StandardTextField}
          type="text"
          placeholder="Email"
        />
        <Field
          name="password"
          component={StandardTextField}
          type="password"
          placeholder="Password"
        />
      </div>
      <div className="actions">
        <button
          className="full"
          type="submit"
          disabled={pristine || submitting}
        >
          Sign In
        </button>
      </div>
    </form>
  );
};
export default reduxForm({
  form: "SignIn", // a unique identifier for this form
  validate: Validator(validations)
})(SignInForm);
