import React from "react";
import DatePicker from "Utils/DatePicker";
import TimePicker from "Utils/TimePicker";
import { connect } from "react-redux";
import * as actions from "Actions";
import { combineDateAndTime } from "HelperFunctions/general";

class DateSelector extends React.Component {
  handleChange = e => {
    const {
      event,
      inventoryFilter,
      setCartProps,
      reloadInventory
    } = this.props;
    const { name, value } = e.target;
    let newEvent = {
      ...event,
      [name]: value
    };
    setCartProps(newEvent);

    // Reload inventory with new event dates
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = newEvent;
    const eventStartDateTime = eventStart
      ? combineDateAndTime(eventStart, eventStartTime)
      : null;
    const eventEndDateTime = eventEnd
      ? combineDateAndTime(eventEnd, eventEndTime)
      : null;
    reloadInventory({
      ...inventoryFilter,
      eventStart: eventStartDateTime,
      eventEnd: eventEndDateTime
    });
  };

  render() {
    const { event } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;

    return (
      <div className="dateSelector">
        <label>Event Start</label>
        <DatePicker
          name="eventStart"
          value={eventStart}
          onChange={this.handleChange}
        />
        <TimePicker
          name="eventStartTime"
          value={eventStartTime}
          onChange={this.handleChange}
        />
        <label>Event End</label>
        <DatePicker
          name="eventEnd"
          value={eventEnd}
          onChange={this.handleChange}
          startDate={eventStart ? eventStart : new Date()}
        />
        <TimePicker
          name="eventEndTime"
          value={eventEndTime}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { event } = state.cart;
  const { inventoryFilter } = state.products;
  return {
    event,
    inventoryFilter
  };
};

export default connect(mapStateToProps, actions)(DateSelector);
