import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import rootReducer from "./reducers";
import { createEpicMiddleware } from "redux-observable";
import rootEpic from "./epics/index";
import { history } from "Components/Routes";

const routingMiddleware = routerMiddleware(history);

const epicMiddleware = createEpicMiddleware(rootEpic);

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(
      applyMiddleware(thunkMiddleware, routingMiddleware, epicMiddleware)
    )
  );
}
