import {
  SET_RENTAL_PROPS,
  SET_BEFORE_ATTRIBUTES,
  FETCH_RENTAL_REQUEST,
  FETCH_RENTAL_SUCCESS,
  FETCH_RENTAL_FAILURE,
  UPDATE_BUNDLE_IN_RENTAL
} from "Constants/redux";
import axios from "axios";
import { getAuthToken } from "Api";
import converter from "json-style-converter/es5";
import { combineDateAndTime, toNumber } from "HelperFunctions/general";
import {
  getRentalBundleWithPrice,
  getItemsWithNewPricesForRental
} from "HelperFunctions/rental_items";
import { getDamageWaiverFeeForRental } from "HelperFunctions/rentals";
import { setErrors } from "./SnackbarActions";
import { ROOT_URL } from "Api";
import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";


export const updateRentalBundle = (name, value, rentalBundleIndex) => {
  return (dispatch, getState) => {
    const { rental } = getState().rental;
    const { eventStart, eventStartTime, eventEnd, eventEndTime, rentalBundles } = rental;

    let newRentalBundles = rentalBundles.slice();
    let rentalBundle = newRentalBundles[rentalBundleIndex];

    let newRentalBundle = {
      ...rentalBundle,
      [name]: toNumber(value)
    };

    dispatch({
      type: UPDATE_BUNDLE_IN_RENTAL,
      payload: {
        newRentalBundle
      }
    });

    /** Update quantities of underlying bundle inventories **/
    const newBundleQuantity = Number(newRentalBundle.quantity);
    newRentalBundle.rentalItems = newRentalBundle.rentalItems.map(
      rentalItem => {
        if (!rentalItem.setManually) {
          const container = newRentalBundle.bundle.productBundleRelationships.find(
            pbr => pbr.productId === rentalItem.productId
          );
          rentalItem.quantity = newBundleQuantity * container.quantity;
        }
        return rentalItem;
      }
    );

    newRentalBundle.rentalAccessories = newRentalBundle.rentalAccessories.map(
      rentalAccessory => {
        if (!rentalAccessory.setManually) {
          const container = newRentalBundle.bundle.accessoryBundleRelationships.find(
            pbr => pbr.accessoryId === rentalAccessory.accessoryId
          );
          rentalAccessory.quantity = newBundleQuantity * container.quantity;
        }
        return rentalAccessory;
      }
    );

    newRentalBundle.rentalAddOns = newRentalBundle.rentalAddOns.map(
      rentalAddOn => {
        if (!rentalAddOn.setManually) {
          const container = newRentalBundle.bundle.addOnBundleRelationships.find(
            pbr => pbr.addOnId === rentalAddOn.addOnId
          );
          rentalAddOn.quantity = newBundleQuantity * container.quantity;
        }
        return rentalAddOn;
      }
    );
    /** Update quantities of underlying bundle inventories **/

    const rentalBundleWithPrice = getRentalBundleWithPrice(
      newRentalBundle,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime)
    );
    newRentalBundles[rentalBundleIndex] = rentalBundleWithPrice;

    const newRental = {
      ...rental,
      rentalBundles: newRentalBundles
    };

    const newDamageWaiverFee = getDamageWaiverFeeForRental(newRental);
    dispatch({
      type: SET_RENTAL_PROPS,
      payload: {
        rentalBundles: newRentalBundles,
        damageWaiverFeeTotal: newDamageWaiverFee
      }
    });
  }
}

export const fetchRental = id => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_RENTAL_REQUEST
    });
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${id}`, {
        headers: getAuthToken()
      })
      .then(response => {
        const { rental } = response.data;

        rental.items = rental.rentalItems;
        const newDamageWaiverFee = getDamageWaiverFeeForRental(rental);

        let newRental = {
          ...rental,
          rentalItems: rental.rentalItems,
          rentalBundles: rental.rentalBundles,
          damageWaiverFeeTotal: newDamageWaiverFee
        };
        dispatch({
          type: FETCH_RENTAL_SUCCESS,
          payload: newRental
        });
        dispatch({
          type: SET_BEFORE_ATTRIBUTES,
          payload: build_rails_object(getState())
        });
        getState();
      })
      .catch(error => {
        console.log(error);
        const errors = error.response.data;
        dispatch({
          type: FETCH_RENTAL_FAILURE
        });
        dispatch(setErrors(errors));
      });
  };
};

const build_rails_object = state => {
  const { rental } = state.rental;
  const {
    items,
    rentalBundles,
    addOns,
    eventStart,
    eventStartTime,
    eventEnd,
    eventEndTime,
    deliveryAddressStreetAddress1,
    deliveryAddressStreetAddress2,
    deliveryType,
    deliveryCost,
    ...other
  } = rental;
  const itemsRailsObject = items.reduce((holder, item, currentIndex) => {
    item.flatPricesAttributes = item.flatPrices;
    item.rentalItemCustomTaxRelationshipAttributes = item.rentalItemCustomTaxRelationship;
    const formattedItem = converter.camelToSnakeCase(item);
    holder[currentIndex] = formattedItem;
    return holder;
  }, {});
  const addOnsRailsObject = addOns.reduce((holder, item, currentIndex) => {
    const formattedItem = converter.camelToSnakeCase(item);
    holder[currentIndex] = formattedItem;
    return holder;
  }, {});
  const rentalBundlesRailsObject = rentalBundles.reduce(
    (holder, rentalBundle, currentIndex) => {
      rentalBundle.flatPricesAttributes = rentalBundle.flatPrices;
      for(let rentalItem of rentalBundle.rentalItems) {
        if (rentalItem.hasOwnProperty("flatPrices")) {
          rentalItem["flatPricesAttributes"] = rentalItem.flatPrices;
        }
      }
      let formattedItem = converter.camelToSnakeCase(rentalBundle);
      formattedItem["rental_item_standards_attributes"] =
        formattedItem.rental_items;
      formattedItem["rental_accessories_attributes"] =
        formattedItem.rental_accessories;
      formattedItem["rental_add_ons_attributes"] = formattedItem.rental_add_ons;
      holder[currentIndex] = formattedItem;
      return holder;
    },
    {}
  );

  const eventStartDateTime = combineDateAndTime(eventStart, eventStartTime);
  const eventEndDateTime = combineDateAndTime(eventEnd, eventEndTime);
  const scheduleRailsObject = {
    id: rental.schedule ? rental.schedule.id : "",
    event_start_date_time: eventStartDateTime,
    event_end_date_time: eventEndDateTime,
    start_date_time: eventStartDateTime,
    end_date_time: eventEndDateTime,
    start_window_finish: eventStartDateTime,
    end_window_beginning: eventEndDateTime,
    off_shelf_at: eventStartDateTime,
    on_shelf_at: eventEndDateTime,
    same_as_event_date: true
  };
  const otherRailsObject = converter.camelToSnakeCase(other);
  return Object.assign(
    {
      ...otherRailsObject,
      rental_item_standards_attributes: itemsRailsObject,
      rental_bundles_attributes: rentalBundlesRailsObject,
      rental_add_ons_attributes: addOnsRailsObject,
      schedule_attributes: scheduleRailsObject,
      delivery_address_street_address_1: deliveryAddressStreetAddress1,
      delivery_address_street_address_2: deliveryAddressStreetAddress2,
      delivery_type: deliveryType
    },
    deliveryType !== "default_delivery" && {
      delivery_cost: deliveryCost
    }
  );
};

export const editRentalSubmit = onSuccess => {
  return (dispatch, getState) => {
    const { rental } = getState().rental;
    const { business } = getState().businesses;
    const data = build_rails_object(getState());

    dispatch(openLoadingSpinner("Updating rental..."));
    axios
      .patch(
        process.env.REACT_APP_API_DOMAIN + "/api/portal/rentals/" + rental.id,
        {
          business_id: business.id,
          rental: data
        },
        { headers: getAuthToken() }
      )
      .then(response => {
        dispatch(closeLoadingSpinner());
        onSuccess();
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
      });
  };
};

export const requestChanges = onSuccess => {
  return (dispatch, getState) => {
    const { rental, beforeAttributes } = getState().rental;
    const afterAttributes = build_rails_object(getState());
    const data = {
      before_attributes: JSON.stringify(beforeAttributes),
      after_attributes: JSON.stringify(afterAttributes)
    };

    dispatch(openLoadingSpinner("Requesting changes..."));
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/api/marketplace/rentals/${rental.id}/request_changes`,
        data,
        { headers: getAuthToken() }
      )
      .then(response => {
        dispatch(closeLoadingSpinner());
        onSuccess();
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
      });
  };
};

export const recalcDamageWaiverFee = ({ items, onSuccess }) => {
  return (dispatch, getState) => {
    const business = items[0].product.businessInfo;
    const defaultDamageFixed = business.damageWaiverFixedFee
      ? parseFloat(business.damageWaiverFixedFee)
      : 0;
    const rentalItemTotal = items
      .filter(item_container => item_container._destroy !== "1")
      .reduce(
        (total, item_container) =>
          total + parseFloat(item_container.selectedPrice),
        0
      );
    let defaultDamage = business.damageWaiverPercent
      ? parseFloat((rentalItemTotal * business.damageWaiverPercent) / 100)
      : 0;
    defaultDamage += defaultDamageFixed;
    onSuccess(defaultDamage);
  };
};
export const calculateDefaultDeliveryPrice = ({ event, onSuccess }) => {
  return (dispatch, getState) => {
    const {
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry,
      items,
      rentalBundles,
      addOns
    } = event;

    let business = null;
    if (items.length > 0) {
      business = items[0].product.businessInfo;
    } else if (rentalBundles.length > 0) {
      business = rentalBundles[0].bundle.businessInfo
    } else if (addOns.length > 0) {
      business = addOns[0].addOn.businessInfo
    }

    const location_id = business.deliverySetting.locationId
    if (event.deliveryType === "customer_pick_up") {
      onSuccess(0);
      return;
    }

    if (
      deliveryAddressCity &&
      deliveryAddressLocale &&
      deliveryAddressStreetAddress1 &&
      deliveryAddressPostalCode &&
      deliveryAddressCountry &&
      (items.length > 0 || rentalBundles.length > 0)
    ) {
      let sub_total = 0;
      for (let item of items) {
        sub_total += Number(item.selectedPrice);
      }
      for (let rentalBundle of rentalBundles) {
        sub_total += Number(rentalBundle.selectedPrice);
      }
      axios
        .post(
          process.env.REACT_APP_API_DOMAIN +
            "/api/portal/delivery_setting/calculate_delivery_cost",
          {
            location_id: location_id,
            business_id: business.id,
            calculation: {
              sub_total: sub_total,
              street_address_1: deliveryAddressStreetAddress1,
              street_address_2: deliveryAddressStreetAddress2,
              city: deliveryAddressCity,
              locale: deliveryAddressLocale,
              postal_code: deliveryAddressPostalCode,
              country: deliveryAddressCountry
            }
          }
        )
        .then(response => {
          onSuccess(response.data);
        })
        .catch(error => {
          console.log(error);
          dispatch(setErrors(error.response.data));
        });
    } else {
      const errors = Object.assign(
        {},
        !deliveryAddressStreetAddress1 && {
          checkin: "Please specify a delivery street address."
        },
        !deliveryAddressCity && { city: "Please specify a delivery city." },
        !deliveryAddressLocale && {
          locale: "Please specify a delivery state."
        },
        !deliveryAddressPostalCode && {
          postal_code: "Please specify a delivery zip code."
        },
        !deliveryAddressCountry && {
          country: "Please specify a delivery country."
        },
        items.length === 0 &&
          rentalBundles.length === 0 &&
          addOns.length === 0 && { items: "Must select items to rent." }
      );
      dispatch(setErrors(errors));
    }
  };
};
export const setRentalProps = newProps => {
  return {
    type: SET_RENTAL_PROPS,
    payload: newProps
  };
};
