import React from "react";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import StandardSelectField from "Utils/redux_form_inputs/StandardSelectField";
import Validator from "HelperFunctions/validator";
import { Link } from "react-router-dom";
import { setErrors } from "Actions";

// Redux-form validations written in json

const validations = {
  payment_method_type: {
    required: {
      value: true,
      message: "Bank Type is required"
    }
  },
  bank_number: {
    routingNumber: {
      value: true,
      message: "Routing Number is invalid"
    }
  },
  account_number: {
    bankAccountNumber: {
      value: true,
      message: "Account Number is invalid"
    }
  },
  account_name: {
    required: {
      value: true,
      message: "Account Name is required"
    }
  },
  description: {
    required: {
      value: true,
      message: "Description is required"
    }
  }
};

// This HOC has access to our own redux, plus it pulls in redux-form HOC,
// which does the validations for us.

class PropayBankForm extends React.Component {
  render() {
    const { handleSubmit, type } = this.props; // From Redux

    return (
      <form onSubmit={handleSubmit} className="form">
        <div className="fields">
          <Field name="payment_method_type" component={StandardSelectField}>
            <option>Select Payment Method Type</option>
            <option value="Checking">Checking</option>
            <option value="Savings">Saving</option>
          </Field>
          <Field
            name="bank_number"
            placeholder="Routing Number"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="account_number"
            placeholder="Account Number"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="account_name"
            placeholder="Account Name"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="description"
            placeholder="Description"
            component={StandardTextField}
            type="text"
          />
        </div>
        {type === "checkout" ? (
          <div className="actions">
            <button type="submit" className="btnIcon full">
              Submit Payment
            </button>
          </div>
        ) : (
          <div className="actions">
            <button type="submit" className="btn">
              Add Bank Account
            </button>
            <Link to="/profile/paymentMethods" className="btn cancel">
              Cancel
            </Link>
          </div>
        )}
      </form>
    );
  }
}

export default reduxForm({
  form: "CreditCard",
  validate: Validator(validations),
  onSubmitFail: (errors, dispatch, submitError, props) => {
    if (errors) {
      dispatch(setErrors(errors));
    }
  }
})(PropayBankForm);
