import React from "react";
import axios from "axios";
import { getAuthToken } from "Api";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";
import * as actions from "Actions";
import { FormattedDate, FormattedTime } from "react-intl";
import RentalItemList from "./rentals/RentalItemList";
import LoadingSpinner from "Utils/LoadingSpinner";
import { Print, MessageBubble } from "Utils/SvgIcons";
import { customerDeliveryTypes } from "Constants/displayConstants";
import { formatPhoneNumber } from "HelperFunctions/general";
import RentalActionBox from "./rentals/RentalActionBox";
import moment from "moment";
import CurrencyLabel from "Utils/CurrencyLabel";
import { countryToLocale } from "Constants/currencyConstants";

class Event extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {
        token: "TG-12345678",
        name: "Mark's 50th Birthday Etravaganza!!!",
        eventStart: "11/15/17",
        eventEnd: "11/18/17",
        rentals: []
      }
    };
  }

  componentDidMount() {
    this.fetchEvent(this.props.match.params.id);
  }

  fetchEvent = id => {
    this.props.fetchEvent(id);
  };

  renderActionButton = rental => {
    const {
      match: { params }
    } = this.props;

    if (rental.status === "cancelled") {
      return (
        <button className="btn full disabled" disabled>
          Rental Cancelled
        </button>
      );
    }

    if (rental.cancelRequested) {
      return (
        <button className="btn full disabled" disabled>
          Cancellation Pending
        </button>
      );
    }

    switch (rental.approvalStatus) {
      case "pending":
        return (
          <button className="btn full disabled" disabled>
            Quote Pending
          </button>
        );
      case "approved":
        if (rental.changeRequest && rental.changeRequest.status === "pending") {
          return (
            <button className="btn full disabled" disabled>
              Changes Requested
            </button>
          );
        }

        if (rental.signatureRequired && !rental.hasSignature) {
          return (
            <Link
              className="btn full"
              to={`/events/${params.id}/rentals/${rental.id}`}
            >
              Sign User Agreement
            </Link>
          );
        }

        if (rental.status === "cancelled") {
          return (
            <button className="btn full disabled" disabled>
              Rental Cancelled
            </button>
          );
        }

        return null;
      default:
        return null;
    }
  };

  renderRentalStatus = rental => {
    if (rental.status === "cancelled") {
      return "Cancelled";
    }

    if (rental.cancelRequested) {
      return "Cancellation Pending";
    }

    if (rental.source === "marketplace" && rental.timeLeftToExpire <= 0) {
      return "Expired";
    }

    switch (rental.approvalStatus) {
      case "pending":
        return "Quote Pending";

      case "approved":
        if (rental.changeRequest && rental.changeRequest.status === "pending") {
          return "Changes Requested";
        }

        if (rental.status === "cancelled") {
          return "Rental Cancelled";
        }

        if (rental.signatureRequired && !rental.hasSignature) {
          return "Signature Required";
        }

        return rental.autoBooked ? "Quote" : "Quote Approved";

      case "declined":
        return "Quote Declined";

      default:
        return null;
    }
  };

  renderActionItems = () => {
    const { event } = this.state;
    let paymentsDue = 0;
    let signaturesDue = 0;

    event.rentals.forEach(rental => {
      if (rental.approvalStatus === "approved") {
        if (rental.amountRemaining > 0) {
          paymentsDue += 1;
        }
        if (rental.signatureRequired && !rental.hasSignature) {
          signaturesDue += 1;
        }
      }
    });

    return (
      <div className="tasks">
        {paymentsDue > 0 && <div>{paymentsDue} Rental(s) Require Payment</div>}
        {signaturesDue > 0 && <div>{signaturesDue} signature(s) required</div>}
      </div>
    );
  };

  handleAddItems = () => {
    const { event } = this.props;
    this.props.startAddingItemsToEvent(event);
    this.props.history.push("/cart");
  };

  render() {
    const { customer, event, loading } = this.props;

    if (loading) {
      return <LoadingSpinner />;
    }

    const firstRental = event.rentals[0] || {};
    const venue = get(firstRental, "venueRentalRelationship.venue", null);
    const changesAllowed = event.rentals.every(rental => rental.changesAllowed);
    const timeZone = moment.tz.guess(event.eventStartDateTime);
    let locale = "en-US";
    if (firstRental && firstRental.businessAddress) {
      locale = countryToLocale[firstRental.businessAddress.country];
    }
    return (
      <div className="event">
        <header>
          <h1>Events</h1>
          <div className="actions">
            {changesAllowed && (
              <a onClick={this.handleAddItems} className="btn">
                Edit This Event
              </a>
            )}
            <a
              onClick={() => {
                window.print();
                return false;
              }}
              className="btn outline"
            >
              <Print />
              Print
            </a>
          </div>
        </header>
        <div className="eventSummary">
          <div className="details">
            <div className="title">
              <h2>{event.name}</h2>
              <h3>
                <FormattedDate
                  value={event.eventStartDateTime}
                  month="numeric"
                  day="numeric"
                />{" "}
                <FormattedTime
                  value={event.eventStartDateTime}
                  timeZone={timeZone}
                  timeZoneName="short"
                />
                {" - "}
                <FormattedDate
                  value={event.eventEndDateTime}
                  month="numeric"
                  year="numeric"
                  day="numeric"
                />{" "}
                <FormattedTime
                  value={event.eventEndDateTime}
                  timeZone={timeZone}
                  timeZoneName="short"
                />
              </h3>
            </div>
            <div className="token">#{event.token}</div>
            <div className="contact">
              <label>Contact:</label>
              <strong>
                {customer.firstName} {customer.lastName}
              </strong>
              <div>{customer.email}</div>
              {customer.cell && <div>{formatPhoneNumber(customer.cell)}</div>}
              {customer.streetAddress1 && (
                <div>
                  <div>{customer.streetAddress1}</div>
                  {customer.streetAddress2 && (
                    <div>{customer.streetAddress2}</div>
                  )}
                  <div>
                    {customer.city}
                    {", "}
                    {customer.locale} {customer.postalCode}
                    {", "}
                    {customer.country}
                  </div>
                </div>
              )}
            </div>
            {venue && (
              <div className="venue">
                <label>Venue:</label>
                <strong>{venue.name}</strong>
                <div>{venue.streetAddress1}</div>
                {venue.streetAddress2 && <div># {venue.streetAddress2}</div>}
                <div>
                  {venue.city}, {venue.locale} {venue.postalCode}
                </div>
              </div>
            )}
            <div className="delivery">
              <label>{customerDeliveryTypes[firstRental.deliveryType]}:</label>
              {firstRental.deliveryType !== "customer_pick_up" && (
                <div>
                  {firstRental.deliveryAddressLocationName && (
                    <strong>{firstRental.deliveryAddressLocationName}</strong>
                  )}
                  <div>{firstRental.deliveryAddressStreetAddress1}</div>
                  {firstRental.deliveryAddressStreetAddress2 && (
                    <div># {firstRental.deliveryAddressStreetAddress2}</div>
                  )}
                  <div>
                    {firstRental.deliveryAddressCity},{" "}
                    {firstRental.deliveryAddressLocale}{" "}
                    {firstRental.deliveryAddressPostalCode} ,{" "}
                    {firstRental.deliveryAddressCountry}
                  </div>
                </div>
              )}
              <br />
              {["default_delivery", "custom_delivery"].indexOf(firstRental.deliveryType) >= 0 && firstRental.pickupSameAsDelivery == false && (
                <div>
                  <label>Pick Up:</label>
                  <div>
                    {firstRental.pickupAddressLocationName && (
                      <strong>{firstRental.pickupAddressLocationName}</strong>
                    )}
                    <div>{firstRental.pickupAddressStreetAddress1}</div>
                    {firstRental.pickupAddressStreetAddress2 && (
                      <div># {firstRental.pickupAddressStreetAddress2}</div>
                    )}
                    <div>
                      {firstRental.pickupAddressCity && `${firstRental.pickupAddressCity}, `}
                      {firstRental.pickupAddressLocale}{" "}
                      {firstRental.pickupAddressPostalCode && `${firstRental.pickupAddressPostalCode}, `}
                      {firstRental.pickupAddressCountry}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.renderActionItems()}
        </div>
        {event.rentals.map(rental => (
          <div key={rental.id} className="subRental">
            <RentalItemList rental={rental} />
            <section className="summary">
              <h4>
                Status:
                <span>{this.renderRentalStatus(rental)}</span>
              </h4>
              <Link to={`/conversations?locationId=${rental.locationId}`}>
                <MessageBubble />
              </Link>
              <div className="billingDetails">
                <div>
                  <label>Quote Amount</label>
                  <CurrencyLabel
                    value={rental.overallTotal}
                    locale={locale}
                  />
                </div>
                <div>
                  <label>Amount Due</label>
                  <CurrencyLabel
                    value={rental.amountRemaining}
                    locale={locale}
                  />
                </div>
                <div className="date">
                  <label>Delivery</label>
                  <span>
                    <FormattedDate
                      value={rental.startDate}
                      month="numeric"
                      day="numeric"
                    />
                    {" - "}
                    <FormattedDate
                      value={rental.endDate}
                      month="numeric"
                      year="numeric"
                      day="numeric"
                    />
                  </span>
                </div>
              </div>
              <RentalActionBox
                rental={rental}
                event={event}
                refreshRental={this.fetchEvent}
              />
            </section>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customer } = state.customer;
  const { event, loading } = state.event;

  return { customer, event, loading };
};
export default connect(mapStateToProps, actions)(Event);
