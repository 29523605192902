import classnames from "classnames";
import React from "react";

const selectField = props => {
  const {
    input,
    className,
    meta: { touched, error }
  } = props;
  return (
    <select
      {...input}
      className={classnames({
        [className]: className,
        error: touched && error
      })}
    >
      <option value="select_month">Select Month</option>
      <option value={1}>January</option>
      <option value={2}>February</option>
      <option value={3}>March</option>
      <option value={4}>April</option>
      <option value={5}>May</option>
      <option value={6}>June</option>
      <option value={7}>July</option>
      <option value={8}>August</option>
      <option value={9}>September</option>
      <option value={10}>October</option>
      <option value={11}>November</option>
      <option value={12}>December</option>
    </select>
  );
};

export default selectField;
