import React from "react";
import Modal from "Utils/Modal";
import { getChangedElements } from "./ChangeRequestData";

class ChangeRequestModal extends React.Component {
  state = {
    modalOpen: false
  }

  toggleModal = () => {
    const { modalOpen } = this.state;
    this.setState({
      modalOpen: !modalOpen
    })
  }

  render(){
    const { modalOpen } = this.state;
    const { request } = this.props;
    if(!request){
      return null
    };

    const changedElements = getChangedElements(request, false);

    return(
      <div>
        <a onClick={this.toggleModal}>
          View Adjusted Change Request
        </a>
        <Modal
          open={modalOpen}
          className="very-large"
          toggle={this.toggleModal}
          title="Change Requests"
        >
          <div className='rentalChangeRequestsModal'>
            <section className="changeRequestModal">
              <h2 className="itemChanges">
                <span>Change Requests</span>
                <label>Old Request</label>
                <label>New Request</label>
                <label>Approved/Denied</label>
              </h2>
              {changedElements}
            </section>
          </div>
        </Modal>
      </div>
      
    )
  }
}

export default ChangeRequestModal;
