import classnames from "classnames";
import React from "react";

const selectField = props => {
  const {
    input,
    className,
    meta: { touched, error }
  } = props;
  return (
    <select
      {...input}
      className={classnames({
        [className]: className,
        error: touched && error
      })}
    >
      {props.children}
    </select>
  );
};

export default selectField;
