import React from "react";
import { TapGoods } from "Utils/SvgIcons";

const UnsupportedBrowser = () => {
  return (
    <div className="notSupported">
      <TapGoods />
      <h1>WWW</h1>
      <p>
        TapGoods isn't currently supported by this browser. <br />
        We've optimized our online software for Google Chrome.
      </p>
      <a
        href="https://www.google.com/chrome/b/"
        className="btn"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download Chrome
      </a>
    </div>
  );
};

export default UnsupportedBrowser;
