export const deliveryTypes = {
  customer_pick_up: "Customer Pick Up",
  custom_delivery: "Manual Delivery",
  default_delivery: "Default Delivery"
};
export const customerDeliveryTypes = {
  customer_pick_up: "Customer Pick Up",
  custom_delivery: "Delivery:",
  default_delivery: "Delivery:",
  ship: "Shipping:"
};

export const carrierTypes = {
  fedex: "FedEx",
  usps: "USPS"
};

export const npsColors = [
  "#D3347F",
  "#D83553",
  "#D63744",
  "#DB4740",
  "#E55E4A",
  "#FF886D",
  "#FB9161",
  "#FFA96B",
  "#D7B36F",
  "#9BBA70",
  "#54C06E"
];

export const exportFields = {
  rental: {
    source: {
      type: "string",
      prettyName: "Rental Source"
    },
    name: {
      type: "string",
      prettyName: "Rental Name"
    },
    token: { type: "string", prettyName: "Rental Token" },
    quote_date: { type: "date", prettyName: "Quote Date" },
    reservation_date: { type: "date", prettyName: "Reservation Date" },
    closed_date: { type: "date", prettyName: "Closed Date" },
    event_start_date_time: { type: "date", prettyName: "Event Start Date" },
    event_end_date_time: { type: "date", prettyName: "Event End Date" },
    start_date: { type: "date", prettyName: "Check Out Date" },
    end_date: { type: "date", prettyName: "Check In Date" },
    status: { type: "string", prettyName: "Rental Status" },
    has_shortage: { type: "string", prettyName: "Overbooked" },
    alerting: { type: "string", prettyName: "Alerting" },
    checked_out_alerting: { type: "string", prettyName: "Check Out Alerts" },
    checked_in_alerting: { type: "string", prettyName: "Check In Alerts" },
    rental_item_total: { type: "number", prettyName: "Item Total" },
    rental_add_on_total: { type: "number", prettyName: "Add-On Total" },
    inventory_total: { type: "number", prettyName: "Inventory Total" },
    fee_total_excluding_damage_waiver: {
      type: "number",
      prettyName: "Fees (excluding damage waiver fee)"
    },
    damage_waiver_fee_total: {
      type: "number",
      prettyName: "Damage Waiver Fee"
    },
    discount_total: { type: "number", prettyName: "Discounts" },
    expense_total: { type: "number", prettyName: "Expense Total" },
    sub_total: { type: "number", prettyName: "Sub Total" },
    tax_total: { type: "number", prettyName: "Tax" },
    credit_card_fee_total: { type: "number", prettyName: "Credit Card Fee" },
    amount_remaining: { type: "number", prettyName: "Remaining Balance" },

    total_delivery_cost: { type: "number", prettyName: "Delivery Fee" },
    delivery_type: { type: "string", prettyName: "Delivery Type" },
    customer_names: { type: "string", prettyName: "Customer" }
  },
  transaction: {
    rental_name: { type: "string", prettyName: "Rental Name" },
    payment_date: { type: "date", prettyName: "Transaction Date" },
    payment_info: { type: "string", prettyName: "Payment Info" },
    amount: { type: "number", prettyName: "Amount" },
    processing_fee: { type: "number", prettyName: "Processing Fee" },
    net_earnings: { type: "number", prettyName: "Net Earnings" },
    amount_refunded: { type: "number", prettyName: "Refunded Amount" },
    token: { type: "string", prettyName: "Transaction Token" },
    tapgoods_fee: { type: "number", prettyName: "Tapgoods Fee" },
    payment_type: { type: "string", prettyName: "Payment Type" },
    status: { type: "string", prettyName: "Payment Status" },
    customer_names: { type: "string", prettyName: "Customer Name" }
  }
};

export const exportFilterDates = {
  quote_date: "Quote Date",
  reservation_date: "Reservation Date",
  closed_date: "Closed Date",
  event_start_date_time: "Event Start Date",
  event_end_date_time: "Event End Date",
  start_date: "Check Out Date",
  end_date: "Check In Date"
};

export const convertScoreToRotation = score => {
  const degrees = Math.round(((score + 100) / 200) * 180);
  return degrees;
};
export const convertNpsScoreToColor = score => {
  const postiveScore = Math.round((score + 100) / 20);
  return npsColors[postiveScore];
};

export const urlPrefixForBusinessTier = {
  all: "standard",
  services: "service",
  demo: "demo"
};

export const displayBusinessTier = {
  all: "TapGoods Rentals",
  services: "TapGoods Services"
};

export const displayRentalStatus = {
  quote: "Quote",
  reservation: "Reservation",
  in_use: "Checked-Out",
  checked_in: "Checked-In",
  closed: "Closed",
  cancelled: "Cancelled",
  draft: "Draft",
  template: "Template"
};

export const displayRentalSource = {
  standard: "Standard",
  wordpress: "Display",
  connect: "Connect"
};

export const displayPickedStatus = {
  alert: "Alerts",
  full: "Fully Picked",
  partial: "Partially Picked",
  none: "Nothing Picked"
};

export const displayCheckedInStatus = {
  alert: "Alerts",
  full: "Fully Checked-In",
  partial: "Partially Checked-In",
  none: "Nothing Checked-In"
};

export const displayTransactionStatus = {
  payment: "Payment",
  partial_refund: "Partial Refund",
  refund: "Refund"
};

export const displayPaymentType = {
  credit_card: "Credit Card",
  cash: "Cash",
  check: "Check",
  bank: "Bank"
};

export const displayDocumentType = {
  rental_agreement: "Rental Agreement",
  insurance: "Insurance",
  legal_documents: "Legal Documents",
  other: "Other",
  waiver_forms: "Waiver Forms",
  customer_documents: "Customer Documents",
  contracts: "Contracts",
  customer_tax_exempt_forms: "Customer Tax Exempt Forms",
  company_tax_exempt_forms: "Company Tax Exempt Forms",
  customer_rental_agreements: "Customer Rental Agreements",
  other_customer_documents: "Other Customer Documents"
};

export const displayRoles = {
  owner: "Owner",
  admin: "Admin",
  manager: "Manager",
  devops: "Warehouse",
  warehouse_manager: "Warehouse Manager",
  salesperson: "Team Member"
};

export const customerTitles = {
  contacts: "Contacts",
  customers: "Customers",
  venues: "Venues",
  companies: "Companies"
};
export const singleCustomerTitles = {
  contacts: "Contact",
  customers: "Customer",
  venues: "Venue",
  companies: "Company"
};
export const apiConnectionsForContact = {
  contacts: "contacts",
  customers: "customer_location_relationships",
  venues: "venues",
  companies: "companies"
};

export const displaySingularInventory = {
  items: "item",
  addons: "add-on",
  accessories: "accessory",
  bundles: "bundle",
  commodities: "item"
};
export const capitalSingularInventory = {
  items: "Item",
  addons: "Add-On",
  accessories: "Accessory",
  bundles: "Bundle",
  commodities: "Item"
};
export const capitalPluralInventory = {
  items: "Items",
  addons: "Add-Ons",
  addOns: "Add-Ons",
  accessories: "Accessories",
  bundles: "Bundles",
  commodities: "Items"
};
export const capitalHybridInventory = {
  items: "Item(s)",
  addons: "Add-On(s)",
  addOns: "Add-On(s)",
  accessories: "Accessory(s)",
  bundles: "Bundle(s)",
  commodities: "Item(s)"
};

export const displayPluralInventory = {
  items: "items",
  addons: "add-ons",
  accessories: "accessories",
  bundles: "bundles",
  commodities: "items"
};

export const apiConnections = {
  items: "products",
  addons: "add_ons",
  addOn: "add_ons",
  addOns: "add_ons",
  add_ons: "add_ons",
  product: "products",
  accessory: "accessories",
  accessories: "accessories",
  bundles: "bundles",
  bundle: "bundles",
  inventories: "inventories",
  inventory: "inventory",
  commodities: "products",
  commodity: "products",
  contacts: "customers",
  customers: "customers",
  venues: "venues",
  companies: "companies",
  maintenanceRecords: "maintenance_records"
};

export const apiToSingularRoot = {
  items: "product",
  addons: "add_on",
  add_ons: "addOn",
  addOn: "addOn",
  addOns: "add_on",
  accessory: "accessory",
  accessories: "accessory",
  bundles: "bundle",
  bundle: "bundle",
  inventories: "inventory",
  inventory: "inventory",
  commodities: "commodity"
};

export const apiToSingularConnections = {
  items: "product",
  addons: "addOn",
  addOn: "addOn",
  addOns: "add_on",
  accessory: "accessory",
  accessories: "accessory",
  bundles: "bundle",
  bundle: "bundle",
  inventories: "inventory",
  inventory: "inventory",
  commodities: "commodity"
};

export const apiToClassNames = {
  items: "Product",
  addons: "AddOn",
  addOn: "AddOn",
  addOns: "AddOn",
  accessory: "Accessory",
  accessories: "Accessory",
  bundles: "Bundle",
  bundle: "Bundle",
  commodities: "Commodity"
};

export const supplierTypes = {
  items: "product",
  addons: "add_on",
  accessories: "accessory"
};

export const suppliableTypes = {
  items: "Product",
  addons: "AddOn",
  accessories: "Accessory"
};

export const apiReceiving = {
  items: "products",
  addons: "addOns",
  addOns: "addOns",
  accessories: "accessories",
  bundles: "bundles",
  inventories: "inventories",
  maintenanceRecords: "maintenanceRecords",
  commodities: "products",
  item: "product",
  addon: "addOn",
  addOn: "addOn",
  accessory: "accessory",
  bundle: "bundle",
  maintenanceRecord: "maintenanceRecord",
  commodity: "product"
};

export const apiReceivingSingle = {
  items: "product",
  addons: "addOn",
  addOns: "addOn",
  accessories: "accessory",
  bundles: "bundle",
  inventories: "inventory",
  maintenanceRecords: "maintenanceRecord",
  commodities: "commodity"
};

export const apiRentalReceiving = {
  items: "rentalItems",
  addons: "rentalAddOns",
  addOns: "rentalAddOns",
  accessories: "rentalAccessories",
  item: "rentalItems",
  addon: "rentalAddOns",
  addOn: "rentalAddOns",
  accessory: "rentalAccessories"
};

export const typeToRental = {
  items: "rental_item",
  addons: "rental_add_on",
  addOns: "rental_add_on",
  accessories: "rental_accessory",
  bundles: "rental_bundle"
};
export const typeToRentalPlural = {
  items: "rental_items",
  addons: "rental_add_ons",
  addOns: "rental_add_ons",
  accessories: "rental_accessories",
  bundles: "rental_bundles"
};
export const getInventoryId = {
  items: "productId",
  addons: "addOnId",
  addOns: "addOnId",
  accessories: "accessoryId",
  bundles: "bundleId"
};

export const emailTypes = {
  RentalMailer: {
    email_summary: "Summary",
    quote_received: "Quote",
    quote_approved: "Quote Approved",
    quote_approved_connect: "Quote Approved - Connect",
    quote_denied: "Quote Denied",
    quote_denied_connect: "Quote Denied - Connect",
    quote_pending: "Quote Pending",
    email_invoice: "Invoice",
    email_reserved: "Reservation",
    payment_reminder: "Payment Reminder",
    email_closed: "Thank You",
    staff_reminder: "Staff Reminder",
    signature_confirmation: "Signature Confirmation",
    email_request: "Action Requested"
  },
  TransactionMailer: {
    payment_received: "Payment Confirmation",
    payment_received_reservation: "Payment Confirmation"
  },
  ScheduledMailer: {
    nps_email: "Net Promoter Score",
    reminder_email: "Event Reminder",
    expire_email: "Quote Expiration"
  }
};

export const emailTypesForRails = {
  quote: "quote_received",
  invoice: "email_invoice",
  summary: "email_summary"
};
export const periodToPricingType = {
  hourly_price: "hourlyPrice",
  half_day_price: "halfDayPrice",
  daily_price: "dailyPrice",
  weekly_price: "weeklyPrice",
  monthly_price: "monthlyPrice",
  flat_unit_price: "flatPrice",
  flat_price: "selectedPrice"
};
export const displayPeriodUnit = {
  hourly_price: "Hour",
  half_day_price: "Half-Day",
  daily_price: "Day",
  weekly_price: "Week",
  monthly_price: "Month",
  flat_unit_price: "Each",
  flat_price: "Each"
};
export const displayPricingType = {
  hourlyPrice: "Hour",
  halfDayPrice: "Half-Day",
  dailyPrice: "Day",
  weeklyPrice: "Week",
  monthlyPrice: "Month",
  flatPrice: "Each",
  pricing: "Each"
};
