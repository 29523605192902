// Customer Portal Specific DocumentTypeSelect

import React from "react";

const RentalDocumentTypes = {
  rental_agreement: "Rental Agreement",
  insurance: "Insurance",
  legal_documents: "Legal Documents",
  other: "Other Rental Documents",
  signed_agreement: "Signed Agreements",
  floor_plan: "Floor Plan"
};

const CustomerDocumentTypes = {
  waiver_forms: "Waiver Forms",
  customer_documents: "Customer Documents",
  contracts: "Contracts",
  customer_tax_exempt_forms: "Customer Tax Exempt Forms",
  customer_rental_agreements: "Customer Rental Agreements",
  other_customer_documents: "Other Customer Documents"
};

const renderOptions = documentTypes => {
  let options = [
    <option key="select_doc_type" value="select_doc_type">
      Select Document Type
    </option>
  ];
  for (let [key, value] of Object.entries(documentTypes)) {
    options.push(
      <option key={key} value={key}>
        {value}
      </option>
    );
  }

  return options;
};

const DocumentTypeSelect = props => {
  const { forCustomer } = props;
  const documentTypes =
    forCustomer === undefined
      ? {
          select_doc_type: "All Documents",
          ...RentalDocumentTypes,
          ...CustomerDocumentTypes
        }
      : forCustomer
      ? CustomerDocumentTypes
      : RentalDocumentTypes;

  return renderOptions(documentTypes);
};

export default DocumentTypeSelect;
