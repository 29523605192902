import { combineDateAndTime, toNumber } from "HelperFunctions/general";
import { uniq } from "lodash";

const getPriceUnit = hours => {
  if (hours < 12) {
    return "hourly";
  } else if (hours < 24) {
    return "halfDay";
  } else if (hours < 168) {
    return "daily";
  } else if (hours < 720) {
    return "weekly";
  } else {
    return "monthly";
  }
};

export const getProductType = (item) => {
  return item.product
    ? "items"
    : item.bundle
    ? "bundles"
    : "add_ons";  
}

const getPrice = (duration, rate, quantity) => {
  if (duration <= 0) {
    duration = 1;
  }
  return duration * rate * quantity;
};

export const hasSelectedFlatPrice = (product, productType) => {
  const selectedPricing = product.period || product.defaultPricing;
  return (
    ((productType === "bundles" && product.priceLocked) || productType === "items") &&
    selectedPricing && selectedPricing.includes("standard_flat_price")
  );
}

export const getSelectedFlatPrice = (product) => {
  const selectedPricing = product.period || product.defaultPricing
  return product.flatPrices.find(fp => selectedPricing.includes(fp.name));
}

export const showSelectedPrice = (
  product,
  quantity,
  startTime,
  endTime,
  productType
) => {
  const timeDiffms = new Date(endTime) - new Date(startTime);
  let timeDiffhours = timeDiffms / 1000 / 60 / 60;
  timeDiffhours = timeDiffhours > 0 ? timeDiffhours : 1;

  const prices = [
    "hourlyPrice",
    "halfDayPrice",
    "dailyPrice",
    "weeklyPrice",
    "monthlyPrice",
    "flatPrice"
  ];

  const usablePrices = prices.map((price, index) => {
    let newPrices = prices.slice();
    newPrices.splice(index, 1);

    if (product[price] && product[price] !== null) {
      return {
        price: product[price],
        unit: price
      };
    } else {
      const modifiedPrice = newPrices.find(testprice => {
        return product[testprice] != null;
      });
      if (modifiedPrice !== undefined) {
        return {
          price: product[modifiedPrice],
          unit: modifiedPrice
        };
      } else {
        const lastAvailablePrice = prices
          .slice()
          .reverse()
          .find(testprice => product[testprice] != null);
        return {
          price: product[lastAvailablePrice],
          unit: lastAvailablePrice
        };
      }
    }
  });

  let priceUnit;
  if (hasSelectedFlatPrice(product, productType)) {
    priceUnit = "standardFlatPrice";
  }
  else if (product.pricing && +product.pricing > 0) {
      priceUnit = 'pricing';
  } else if (
    !product.hourlyPrice &&
    !product.halfDayPrice &&
    !product.dailyPrice &&
    !product.weeklyPrice &&
    !product.monthlyPrice
  ) {
    if (productType === "bundles" && product.purchasePrice) {
      return {
        rate: product.purchasePrice,
        total: 0,
        period: "flat_unit_price"
      };
    } else {
      priceUnit = "flatPrice";
    }
  } else {
    priceUnit = getPriceUnit(timeDiffhours);
  }
  let duration;
  switch (priceUnit) {
    case "hourly":
      if (product.hourlyPrice) {
        duration = Math.round(timeDiffhours);
        return {
          rate: product.hourlyPrice,
          total: getPrice(duration, product.hourlyPrice, quantity),
          duration,
          period: "hourly_price"
        };
      } else {
        switch (usablePrices[0].unit) {
          case "hourlyPrice":
            duration = Math.round(timeDiffhours);
            return {
              rate: usablePrices[0].price,
              total: getPrice(duration, usablePrices[0].price, quantity),
              duration,
              period: "hourly_price"
            };
          case "halfDayPrice":
            duration = Math.round(timeDiffhours / 12);
            return {
              rate: usablePrices[0].price,
              total: getPrice(duration, usablePrices[0].price, quantity),
              duration,
              period: "half_day_price"
            };
          case "dailyPrice":
            duration = Math.round(timeDiffhours / 24);
            return {
              rate: usablePrices[0].price,
              total: getPrice(duration, usablePrices[0].price, quantity),
              duration,
              period: "daily_price"
            };
          case "weeklyPrice":
            duration = Math.round(timeDiffhours / 24 / 7);
            return {
              rate: usablePrices[0].price,
              total: getPrice(duration, usablePrices[0].price, quantity),
              duration,
              period: "weekly_price"
            };
          case "monthlyPrice":
            duration = Math.round(timeDiffhours / 24 / 30);
            return {
              rate: usablePrices[0].price,
              total: getPrice(duration, usablePrices[0].price, quantity),
              duration,
              period: "monthly_price"
            };
          default:
            return "N/A";
        }
      }
    case "halfDay":
      if (product.halfDayPrice) {
        duration = Math.round(timeDiffhours / 24);
        return {
          rate: product.halfDayPrice,
          total: getPrice(duration, product.halfDayPrice, quantity),
          duration,
          period: "half_day_price"
        };
      } else {
        switch (usablePrices[1].unit) {
          case "hourlyPrice":
            duration = Math.round(timeDiffhours);
            return {
              rate: usablePrices[1].price,
              total: getPrice(duration, usablePrices[1].price, quantity),
              duration,
              period: "hourly_price"
            };
          case "halfDayPrice":
            duration = Math.round(timeDiffhours / 12);
            return {
              rate: usablePrices[1].price,
              total: getPrice(duration, usablePrices[1].price, quantity),
              duration,
              period: "half_day_price"
            };
          case "dailyPrice":
            duration = Math.round(timeDiffhours / 24);
            return {
              rate: usablePrices[1].price,
              total: getPrice(duration, usablePrices[1].price, quantity),
              duration,
              period: "daily_price"
            };
          case "weeklyPrice":
            duration = Math.round(timeDiffhours / 24 / 7);
            return {
              rate: usablePrices[1].price,
              total: getPrice(duration, usablePrices[1].price, quantity),
              duration,
              period: "weekly_price"
            };
          case "monthlyPrice":
            duration = Math.round(timeDiffhours / 24 / 30);
            return {
              rate: usablePrices[1].price,
              total: getPrice(duration, usablePrices[1].price, quantity),
              duration,
              period: "monthly_price"
            };
          default:
            return "N/A";
        }
      }
    case "daily":
      if (product.dailyPrice) {
        duration = Math.round(timeDiffhours / 24);
        return {
          rate: product.dailyPrice,
          total: getPrice(duration, product.dailyPrice, quantity),
          duration,
          period: "daily_price"
        };
      } else {
        switch (usablePrices[2].unit) {
          case "hourlyPrice":
            duration = Math.round(timeDiffhours);
            return {
              rate: usablePrices[2].price,
              total: getPrice(duration, usablePrices[2].price, quantity),
              duration,
              period: "hourly_price"
            };
          case "halfDayPrice":
            duration = Math.round(timeDiffhours / 12);
            return {
              rate: usablePrices[2].price,
              total: getPrice(duration, usablePrices[2].price, quantity),
              duration,
              period: "half_day_price"
            };
          case "dailyPrice":
            duration = Math.round(timeDiffhours / 24);
            return {
              rate: usablePrices[2].price,
              total: getPrice(duration, usablePrices[2].price, quantity),
              duration,
              period: "daily_price"
            };
          case "weeklyPrice":
            duration = Math.round(timeDiffhours / 24 / 7);
            return {
              rate: usablePrices[2].price,
              total: getPrice(duration, usablePrices[2].price, quantity),
              duration,
              period: "weekly_price"
            };
          case "monthlyPrice":
            duration = Math.round(timeDiffhours / 24 / 30);
            return {
              rate: usablePrices[2].price,
              total: getPrice(duration, usablePrices[2].price, quantity),
              duration,
              period: "monthly_price"
            };
          default:
            return "N/A";
        }
      }
    case "weekly":
      if (product.weeklyPrice) {
        duration = Math.round(timeDiffhours / 24 / 7);
        return {
          rate: product.weeklyPrice,
          total: getPrice(duration, product.weeklyPrice, quantity),
          duration,
          period: "weekly_price"
        };
      } else {
        switch (usablePrices[3].unit) {
          case "hourlyPrice":
            duration = Math.round(timeDiffhours);
            return {
              rate: usablePrices[3].price,
              total: getPrice(duration, usablePrices[3].price, quantity),
              duration,
              period: "hourly_price"
            };
          case "halfDayPrice":
            duration = Math.round(timeDiffhours / 12);
            return {
              rate: usablePrices[3].price,
              total: getPrice(duration, usablePrices[3].price, quantity),
              duration,
              period: "half_day_price"
            };
          case "dailyPrice":
            duration = Math.round(timeDiffhours / 24);
            return {
              rate: usablePrices[3].price,
              total: getPrice(duration, usablePrices[3].price, quantity),
              duration,
              period: "daily_price"
            };
          case "weeklyPrice":
            duration = Math.round(timeDiffhours / 24 / 7);
            return {
              rate: usablePrices[3].price,
              total: getPrice(duration, usablePrices[3].price, quantity),
              duration,
              period: "weekly_price"
            };
          case "monthlyPrice":
            duration = Math.round(timeDiffhours / 24 / 30);
            return {
              rate: usablePrices[3].price,
              total: getPrice(duration, usablePrices[3].price, quantity),
              duration,
              period: "monthly_price"
            };
          default:
            return "N/A";
        }
      }
    case "monthly":
      if (product.monthlyPrice) {
        duration = Math.round(timeDiffhours / 24 / 30);
        return {
          rate: product.monthlyPrice,
          total: getPrice(duration, product.monthlyPrice, quantity),
          duration,
          period: "monthly_price"
        };
      } else {
        switch (usablePrices[4].unit) {
          case "hourlyPrice":
            duration = Math.round(timeDiffhours);
            return {
              rate: usablePrices[4].price,
              total: getPrice(duration, usablePrices[4].price, quantity),
              duration,
              period: "hourly_price"
            };
          case "halfDayPrice":
            duration = Math.round(timeDiffhours / 12);
            return {
              rate: usablePrices[4].price,
              total: getPrice(duration, usablePrices[4].price, quantity),
              duration,
              period: "half_day_price"
            };
          case "dailyPrice":
            duration = Math.round(timeDiffhours / 24);
            return {
              rate: usablePrices[4].price,
              total: getPrice(duration, usablePrices[4].price, quantity),
              duration,
              period: "daily_price"
            };
          case "weeklyPrice":
            duration = Math.round(timeDiffhours / 24 / 7);
            return {
              rate: usablePrices[4].price,
              total: getPrice(duration, usablePrices[4].price, quantity),
              duration,
              period: "weekly_price"
            };
          case "monthlyPrice":
            duration = Math.round(timeDiffhours / 24 / 30);
            return {
              rate: usablePrices[4].price,
              total: getPrice(duration, usablePrices[4].price, quantity),
              duration,
              period: "monthly_price"
            };
          default:
            return "N/A";
        }
      }
    case "standardFlatPrice":
      const selectedFlatPrice = getSelectedFlatPrice(product);
      if (selectedFlatPrice) {
        if (product.type === "RentalItemTemporary") {
          return {
            rate: selectedFlatPrice.amount,
            total: selectedFlatPrice.amount,
            duration: 1,
            period: `standard_flat_price[${selectedFlatPrice.name}]`,
          };
        } else {
          return {
            rate: selectedFlatPrice.amount,
            total: selectedFlatPrice.amount * quantity,
            duration: 1,
            period: `standard_flat_price[${selectedFlatPrice.name}]`,
          };
        }
      } else {
        return null;
      }
    case "flatPrice":
      if (product.type === "RentalItemTemporary") {
        return {
          rate: product.flatPrice,
          total: product.flatPrice,
          duration: 1,
          period: "flat_price"
        };
      } else {
        return {
          rate: product.flatPrice,
          total: product.flatPrice * quantity,
          duration: 1,
          period: "flat_price"
        };
      }
    case "flatUnitPrice":
      return {
        rate: product.flatUnitPrice,
        total: product.flatUnitPrice * quantity,
        duration: 1,
        period: "flat_unit_price"
      };
    case "pricing":
      return {
        rate: product.pricing,
        total: product.pricing * quantity,
        duration: 1,
        period: "flat_unit_price"
      };
    default:
      return null;
  }
};

export const showSelectedPriceBundle = (
  bundle,
  quantity,
  startTime,
  endTime
) => {
  // if bundle is locked & has selected a flat price, return that
  if (hasSelectedFlatPrice(bundle, "bundles")) {
    const selectedFlatPrice = getSelectedFlatPrice(bundle);
    return {
      rate: selectedFlatPrice.amount,
      duration: 1,
      period: bundle.defaultPricing,
      total: selectedFlatPrice.amount * quantity
    }
  }

  const selected = showSelectedPrice(
    bundle,
    quantity,
    startTime,
    endTime,
    "bundles"
  );
  const totalPrice = selected.total * (1 - Number(bundle.discountPercent));

  return {
    rate: selected.rate,
    duration: selected.duration,
    period: selected.period,
    total: totalPrice
  };
};

export const getItemsWithNewPricesForRental = ({
  items,
  rentalBundles,
  eventStart,
  eventStartTime,
  eventEnd,
  eventEndTime
}) => {
  const newItems = items.map(item => {
    const selected = showSelectedPrice(
      item.product,
      item.quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      "items"
    );
    item.selectedPrice = selected.total;
    item.selectedRate = selected.rate;
    item.duration = selected.duration;
    item.period = selected.period;
    return item;
  });
  const newRentalBundles = rentalBundles.map(item => {
    item = getRentalBundleWithPrice(
      item,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),      
    )
    return item;
  });

  return { items: newItems, rentalBundles: newRentalBundles };
};

export const getRentalBundleWithPrice = (
  rentalBundle,
  startTime,
  endTime
) => {
  let newRentalBundle = { ...rentalBundle };
  let period = newRentalBundle.period;
  if (!(period && period.includes("standard_flat_price"))) {
    period = null;
  }
  const selected = showSelectedPriceBundle(
    newRentalBundle,
    newRentalBundle.quantity,
    startTime,
    endTime
  );
  newRentalBundle.selectedPrice = selected.total;
  newRentalBundle.selectedRate = selected.rate;
  newRentalBundle.duration = selected.duration;
  newRentalBundle.period = selected.period;

  if (!newRentalBundle.priceLocked) {
    newRentalBundle = getUnlockedRentalBundle(
      newRentalBundle,
      startTime,
      endTime
    );
  }

  return newRentalBundle;
}

const getUnlockedRentalBundle = (
  rentalBundle,
  startTime,
  endTime
) => {
  const oldRentalBundle = rentalBundle;
  let newRentalBundle = { ...oldRentalBundle };
  if (newRentalBundle._destroy === "1") {
    // no need to recalculate if bundle is destroyed
    return newRentalBundle;
  }
  const newRentalItems = newRentalBundle.rentalItems.map(item => {
    if (
      (item.period === "edited_flat_unit_price" ||
        item.period === "flat_unit_price") &&
      item.setManually
    ) {
      return item;
    } else if (item.period === "edited_flat_price") {
      return item;
    }

    let period = item.period;
    period =
      item.periodIsManuallyChanged ||
      (period && period.includes("standard_flat_price"))
        ? item.period
        : null;

    const selected = showSelectedPrice(
      item,
      item.quantity,
      startTime,
      endTime,
      "items"
    );
    const selectedPrice =
      selected.total * (1 - newRentalBundle.discountPercent);
    item.selectedPrice = selectedPrice;
    item.selectedPriceBeforeDiscount = selectedPrice;
    item.selectedRate = selectedPrice / selected.duration;
    item.duration = selected.duration;
    item.period = selected.period;
    return item;
  });
  const newRentalAddOns = newRentalBundle.rentalAddOns.map(addOn => {
    if (!addOn.setManually) {
      addOn.selectedPrice =
        Number(addOn.pricing) *
        addOn.quantity *
        (1 - newRentalBundle.discountPercent);
      addOn.selectedRate =
        Number(addOn.pricing) *
        (1 - newRentalBundle.discountPercent);
    }
    const newRentalAddOn = Object.assign(addOn);
    addOn = newRentalAddOn;
    return addOn;
  });
  newRentalBundle.rentalItems = newRentalItems;
  newRentalBundle.rentalAddOns = newRentalAddOns;
  newRentalBundle.priceLocked = false;
  newRentalBundle.selectedPrice = calculateRentalBundlePrice(
    newRentalBundle
  );
  return newRentalBundle;  
}

const calculateRentalBundlePrice = rentalBundle => {
  let selectedPrice = 0;
  selectedPrice += rentalBundle.rentalItems
    .filter(item => item._destroy !== "1")
    .reduce((sum, ri) => {
      return sum + parseFloat(ri.selectedPrice || 0);
    }, 0);
  selectedPrice += rentalBundle.rentalAddOns
    .filter(item => item._destroy !== "1")
    .reduce((sum, ri) => {
      return sum + parseFloat(ri.selectedPrice || 0);
    }, 0);

  return selectedPrice;
};
