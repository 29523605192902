import React from "react";
import { connect } from "react-redux";
import Checkbox from "Utils/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { ArrowUp, ArrowDown } from "Utils/SvgIcons";
import * as actions from "Actions";
import classnames from "classnames";
import DateSelector from "./DateSelector";
import ColorPicker from "Utils/ColorPicker";
import Slider, { createSliderWithTooltip } from "rc-slider";

const Range = createSliderWithTooltip(Slider.Range);
const inflatableCats = [11, 31, 1, 2, 33];
class CategoryFilterDesktop extends React.Component {
  state = {
    open: {}
  };

  toggleOpen = category => {
    this.setState({
      open: {
        ...this.state.open,
        [category.id]: !(this.state.open[category.id] || false)
      }
    });
  };

  renderCategories = () => {
    const {
      categories,
      handleCategorySelected,
      handleSubCategorySelected,
      selectedCategories,
      selectedSubCategories
    } = this.props;
    let output = [];
    categories.forEach(category => {
      if (
        this.props.type === "inflatables" &&
        !inflatableCats.includes(category.id)
      ) {
        return;
      }
      output.push(
        <ListItem
          key={category.id}
          className={classnames({
            open: this.state.open[category.id]
          })}
        >
          <Checkbox
            onCheck={(event, isChecked) =>
              handleCategorySelected(isChecked, category)
            }
            checked={selectedCategories.includes(String(category.id))}
          />
          <ListItemText primary={category.name} disableTypography />
          <a onClick={() => this.toggleOpen(category)}>
            {this.state.open[category.id] ? <ArrowUp /> : <ArrowDown />}
          </a>
        </ListItem>
      );
      output.push(
        <Collapse
          key={`subCategories-${category.id}`}
          in={this.state.open[category.id] || false}
          transitionDuration="auto"
          unmountOnExit
        >
          <List className="checkList">
            {category.subCategories.map(subCategory => (
              <ListItem key={subCategory.id}>
                <Checkbox
                  onCheck={(event, isChecked) =>
                    handleSubCategorySelected(isChecked, category, subCategory)
                  }
                  checked={selectedSubCategories.includes(
                    String(subCategory.id)
                  )}
                />
                <ListItemText primary={subCategory.name} disableTypography />
              </ListItem>
            ))}
          </List>
        </Collapse>
      );
    });

    return output;
  };

  render() {
    const {
      onColorSelection,
      onQuantitySelection,
      selectedColors,
      selectedMinQuantity,
      selectedMaxQuantity,
      clearColorSelection
    } = this.props;
    const quantityValues = [+selectedMinQuantity, +selectedMaxQuantity];
    return (
      <aside className="filter">
        <DateSelector />
        <div className="quantityFilter">
          Quantity
          <Range
            allowCross={false}
            value={quantityValues}
            onChange={onQuantitySelection}
            max={500}
            tipProps={{ visible: true }}
          />
        </div>
        <div className="colorFilter">
          Color
          <ColorPicker
            onColorSelection={onColorSelection}
            selectedColorIds={selectedColors}
            clearColorSelection={clearColorSelection}
          />
        </div>
        <List className="checkList">{this.renderCategories()}</List>
      </aside>
    );
  }
}

const mapStateToProps = state => {
  const categories = state.categoryList[`itemsCategories`] || [];
  // const {categories} = state.products;
  return { categories };
};

export default connect(mapStateToProps, actions)(CategoryFilterDesktop);
