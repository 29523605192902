import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import axios from "axios";
import { filterFromPropsAndState } from "HelperFunctions/urls";
import Suggestions from "./Suggestions";
import classnames from "classnames";

class SearchFilterKazzam extends React.Component {
  state = {
    results: [],
    query: "",
    type: ""
  };

  componentDidMount() {
    if (this.props.location.pathname === "/inflatables") {
      this.setState({
        type: "inflatables"
      });
    }
  }

  getResults = () => {
    const { query, type } = this.state;
    axios
      .get(
        process.env.REACT_APP_API_DOMAIN +
          `/api/marketplace/inventories/search_filter?query=${query}&type=${type}`
      )
      .then(({ data }) => {
        this.setState({
          results: data.products
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      const { query, type } = this.state;
      this.props.history.push(`/shop/search?query=${query}&type=${type}`);
    }
  };

  handleClick = event => {
    const selected = event.target.innerText;
    const { type } = this.state;
    this.props.history.push(`/shop/search?query=${selected}&type=${type}`);
    this.setState({
      results: [],
      query: ""
    });
  };

  handleSelectType = event => {
    const selected = event.target.value;
    this.setState({
      type: selected
    });
  };

  handleCategoryClick = id => {
    const selected = id;
    this.props.history.push(
      `/shop/search?query=&selectedCategories=${selected}`
    );
    this.setState({
      results: [],
      query: ""
    });
  };

  handleChange = event => {
    this.setState(
      {
        query: event.target.value
      },
      () => {
        if (this.state.query.length > 0) {
          this.getResults();
        } else if (this.state.query.length < 1) {
          this.setState({
            results: []
          });
        }
      }
    );
  };

  notFocused = event => {
    this.setState({
      query: "",
      results: []
    });
  };

  render() {
    const { query, results } = this.state;
    return (
      <div
        className={classnames({
          searchFilter: true,
          open: results.length > 0
        })}
      >
        <input
          type="search"
          placeholder="Search for items to rent..."
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          value={query}
          onBlur={this.notFocused}
        />
        {query !== "" && (
          <Suggestions
            results={results}
            handleClick={this.handleClick}
            handleCategoryClick={this.handleCategoryClick}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  return { filter: newFilter };
};

export default withRouter(
  connect(mapStateToProps, actions)(SearchFilterKazzam)
);
