import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { uniq } from "lodash";
import * as actions from "Actions";
import { Burst } from "Utils/SvgIcons";
import RentalBilling from "./RentalBilling";
import EmptyRentalBilling from "./EmptyRentalBilling";

class CartBilling extends React.Component {
  render() {
    const { event, step } = this.props;

    const businessKeys = uniq([
      ...Object.keys(event.items),
      ...Object.keys(event.rentalBundles),
      ...Object.keys(event.addOns)
    ]);
    let canAutoBook = false;
    let shouldAllowAutoBook = false;
    if (businessKeys.length > 0) {
      for (var key of businessKeys) {
        const items = event.items[key];
        let indautobook = false;
        let indCanAutobook = false;
        if (items) {
          indautobook = event.canBeAutoBooked ? event.canBeAutoBooked[key] : items[0].product.businessInfo.shouldAllowAutoBook;
          indCanAutobook = items.every(
            item => item.product.numberAvailable > item.quantity
          );
        }
        const addOns = event.addOns[key];
        if (addOns) {
          indautobook = event.canBeAutoBooked ? event.canBeAutoBooked[key] : addOns[0].addOn.businessInfo.shouldAllowAutoBook;
          indCanAutobook = addOns.every(
            addOn => addOn.addOn.numberAvailable > addOn.quantity
          );
        }
        const bundles = event.rentalBundles[key];
        if (bundles) {
          indautobook = event.canBeAutoBooked ? event.canBeAutoBooked[key] : bundles[0].bundle.businessInfo.shouldAllowAutoBook;
        }
        if (indCanAutobook) {
          canAutoBook = true;
        }
        if (indautobook) {
          shouldAllowAutoBook = true;
        }
      }
    }

    return (
      <section className="billing">
        {step === 4 && (
          <div className="complete">
            <Burst />
            <p>We received your quote! (#{event.token})</p>
            <p>
              You should get a confirmation email with all the details, or you
              can manage it here.
            </p>
          </div>
        )}
        {step < 4 && (
          <div className="billingSummary">
            <h2>Quote Summary</h2>
            {businessKeys.length > 0 ? (
              businessKeys.map(key => (
                <RentalBilling
                  key={key}
                  event={event}
                  items={event.items[key] || []}
                  rentalBundles={event.rentalBundles[key] || []}
                  addOns={event.addOns[key] || []}
                  step={step}
                />
              ))
            ) : (
              <EmptyRentalBilling />
            )}
            <div className="notes">
              <h4>Notes</h4>
              <ul className="bullets">
                {step < 3 && (
                  <li>
                    Tax and Delivery are dependent on Delivery Info in Step 2.
                  </li>
                )}
                <li>Price is subject to change based on fees and taxes.</li>
                {shouldAllowAutoBook && !canAutoBook && (
                  <li>
                    One or more of the items in your rental require approval
                    before you can reserve them. Your quote will be sent for
                    approval when you submit.
                  </li>
                )}
                <li>
                  Event Dates are the dates you need the rentals. Delivery dates
                  will be set up after your quote is created.
                </li>
              </ul>
            </div>
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { event } = state.cart;
  return { event };
};

export default withRouter(connect(mapStateToProps, actions)(CartBilling));
