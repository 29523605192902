import React from "react";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import StandardSelectField from "Utils/redux_form_inputs/StandardSelectField";
import StandardUploadField from "Utils/redux_form_inputs/StandardUploadField";
import DocumentSelectOptions from "./DocumentSelectOptions";
import Validator from "HelperFunctions/validator";

const validations = {
  name: {
    required: {
      value: true,
      message: "Document name is required"
    }
  },
  documentType: {
    required: {
      value: true,
      message: "Document type is required"
    },
    oneOf: {
      value: [
        "rental_agreement",
        "insurance",
        "legal_documents",
        "other",
        "signed_agreement",
        "floor_plan"
      ]
    }
  },
  file: {
    required: {
      value: true,
      message: "Document file is required"
    }
  }
};

class NewDocumentForm extends React.Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit} className="form">
        <div className="fields">
          <Field
            name="file"
            placeholder="Upload your document"
            component={StandardUploadField}
          />
          <Field
            name="name"
            placeholder="Document Name"
            component={StandardTextField}
            type="text"
          />
          <Field name="documentType" component={StandardSelectField}>
            {DocumentSelectOptions({ forCustomer: true })}
          </Field>
        </div>
        <div className="actions">
          <button className="full" type="submit">
            Upload New Document
          </button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: "newDocument",
  validate: Validator(validations)
})(NewDocumentForm);
