import React from "react";
import {
  TickMark,
  Close,
  MessageBubble
} from "Utils/SvgIcons";
import Modal from "Utils/Modal";

class SubChangeRequestStatus extends React.Component {
  state = {
    notesModalOpen: false
  }

  toggleModal = () => {
    const { notesModalOpen } = this.state;
    this.setState({
      notesModalOpen: !notesModalOpen,
    })
  }

  render(){
    const { notesModalOpen } = this.state;
    const { status, notes } = this.props.subChangeRequest;
    const isApproved = status === 'approved';
    return(
      <div className="subChangeRequestStatus">
        {isApproved ? <TickMark className="approved"/> : <Close className="denied"/> }
        { notes !== '' ?
        <a onClick={this.toggleModal}><MessageBubble className="filledNotes" /></a>        
        : '' }
        <Modal
          open={notesModalOpen}
          toggle={this.toggleModal}
          title="Change Requests Note"
        >
          <div>{notes}</div>
        </Modal>
      </div>
    )
  }

}

export default SubChangeRequestStatus;