import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "Actions";
import Message from "./Message";
import NewMessage from "./NewMessageContainer";
import ActionCable from "actioncable";
import { getAuthToken } from "Api";
import ScrollArea from "Utils/react-scrollbar";

// Conversation Container
class Conversation extends React.Component {
  componentDidMount() {
    const { match, fetchConversation } = this.props;
    fetchConversation(match.params.conversationId);
    const { addMessage } = this.props;
    const authToken = getAuthToken();
    this.cable = ActionCable.createConsumer(
      `${process.env.REACT_APP_ACTION_CABLE}/cable?access-token=${authToken["access-token"]}&client=${authToken["client"]}&uid=${authToken["uid"]}&resource_type=customer`
    );
    this.channel = this.cable.subscriptions.create(
      {
        channel: "ConversationsChannel",
        id: this.props.match.params.conversationId
      },
      {
        connected: () => {},
        received: data => {
          addMessage(data);
        }
      }
    );
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.conversationId !==
      this.props.match.params.conversationId
    ) {
      const { addMessage, fetchConversation } = nextProps;
      fetchConversation(nextProps.match.params.conversationId);
      this.channel.unsubscribe();
      this.channel = this.cable.subscriptions.create(
        {
          channel: "ConversationsChannel",
          id: nextProps.match.params.conversationId
        },
        {
          connected: () => {},
          received: data => {
            addMessage(data);
          }
        }
      );
    }
  }
  componentWillUnmount() {
    // this.channel.unsubscribe();
  }

  render() {
    const { conversation } = this.props;
    return (
      <section className="conversation">
        {conversation.rentalId && (
          <Link
            className="rentalLink"
            to={`/events/rentals/${conversation.rentalId}`}
          >
            #{conversation.rentalToken}
          </Link>
        )}
        <NewMessage />
        <ScrollArea speed={0.8} horizontal={false}>
          {conversation.messages.map((message, index) => {
            return <Message message={message} key={index} />;
          })}
        </ScrollArea>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { conversation } = state.conversation;
  return { conversation };
};

export default connect(mapStateToProps, actions)(Conversation);
