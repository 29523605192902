import React from "react";
import Events from "Components/events/Events";

class EventsIndex extends React.Component {
  render() {
    return (
      <div className="eventLanding">
        <header>
          <h1>Events</h1>
        </header>
        <Events type={this.props.type} />
      </div>
    );
  }
}
export default EventsIndex;
