import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import { CreditCards } from "Utils/SvgIcons";
import CreditCardLineItem from "./creditCards/CreditCardLineItem";
import LoadingSpinner from "Utils/LoadingSpinner";

// Fetch profile info from Redux customer, then display it.

class ProfilePaymentMethods extends React.Component {
  componentDidMount() {
    const { fetchProfile } = this.props; // Redux
    fetchProfile();
  }

  render() {
    const { customer, loading } = this.props;
    if (loading) {
      return <LoadingSpinner />;
    }
    return (
      <section className="payments">
        <h4>Payment Methods</h4>
        <CreditCards />
        <div>
          <Link className="btn" to="/profile/paymentMethods/new">
            Add A New Card
          </Link>
          {customer.creditCards && (
            <ul className="itemList">
              {customer.creditCards.map(creditCard => (
                <CreditCardLineItem
                  creditCard={creditCard}
                  key={creditCard.id}
                />
              ))}
            </ul>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { customer, errors, loading } = state.customer;
  return { customer, errors, loading };
};

export default connect(mapStateToProps, actions)(ProfilePaymentMethods);
