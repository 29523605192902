import { uniq } from "lodash";
import { combineDateAndTime, toNumber } from "HelperFunctions/general";
import {
  showSelectedPrice,
  getRentalBundleWithPrice
} from "HelperFunctions/rental_items";

export const getItemsWithNewPricesForEvent = ({
  items,
  rentalBundles,
  eventStart,
  eventStartTime,
  eventEnd,
  eventEndTime
}) => {
  const businessKeys = uniq([
    ...Object.keys(items),
    ...Object.keys(rentalBundles),
  ]);
  let newItems = {};
  let newRentalBundles = {};
  businessKeys.map((key, index) => {
    const businessRentalItems = items[key] || [];
    const businessRentalBundles = rentalBundles[key] || [];

    const newBusinessRentalItems = businessRentalItems.map(item => {
      const selected = showSelectedPrice(
        item.product,
        item.quantity,
        combineDateAndTime(eventStart, eventStartTime),
        combineDateAndTime(eventEnd, eventEndTime),
        "items"
      );
      item.selectedPrice = selected.total;
      item.selectedRate = selected.rate;
      item.duration = selected.duration;
      item.period = selected.period;
      return item;
    });
    const newBusinessRentalBundles = businessRentalBundles.map(item => {
      item = getRentalBundleWithPrice(
        item,
        combineDateAndTime(eventStart, eventStartTime),
        combineDateAndTime(eventEnd, eventEndTime),      
      )
      return item;
    });

    if (newBusinessRentalItems.length > 0) {
      newItems[key] = newBusinessRentalItems;
    }
    if (newBusinessRentalBundles.length > 0) {
      newRentalBundles[key] = newBusinessRentalBundles;
    }
  });

  return { items: newItems, rentalBundles: newRentalBundles };
};

export const getDamageWaiverFeeForEvent = (event) => {
  const businessNames = Object.keys(event.rentalBundles)
    .concat(Object.keys(event.items))
    .filter(function(value, index, self) {
      return self.indexOf(value) === index;
    });
  let defaultDamageHolder = {};
  for (var name of businessNames) {
    const business = event.items[name]
      ? event.items[name][0].product.businessInfo
      : event.rentalBundles[name][0].bundle.businessInfo;
    const items = event.items[name] || [];
    const rentalBundles = event.rentalBundles[name] || [];
    const defaultDamageFixed = business.damageWaiverFixedFee
      ? parseFloat(business.damageWaiverFixedFee)
      : 0;
    const rentalItemTotal = items
      .filter((item_container) => item_container._destroy !== "1")
      .reduce((total, item_container) => {
        if (
          item_container.type === "RentalItemTemporary" ||
          !item_container.product.damageWaiverExempt
        ) {
          return total + parseFloat(item_container.selectedPrice);
        } else {
          return total;
        }
      }, 0);
    const rentalBundleTotal = rentalBundles
      .filter((item_container) => item_container._destroy !== "1")
      .reduce((total, item_container) => {
        return total + Number(item_container.selectedPrice);
      }, 0);
    let defaultDamage = business.damageWaiverPercent
      ? parseFloat(
          ((rentalItemTotal + rentalBundleTotal) *
            business.damageWaiverPercent) /
            100
        )
      : 0;
    defaultDamage += defaultDamageFixed;
    defaultDamageHolder[name] = defaultDamage;
  }

  return defaultDamageHolder;
}
