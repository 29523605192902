import React from "react";
import SubChangeRequestStatus from "./SubChangeRequestStatus";

function renderChange(rental, key) {
  return <div>{rental[key]}</div>;
}

export default function textChange(changeRequest, key, title) {
  const { subChangeRequests } = changeRequest;
  const subChangeRequest = subChangeRequests.filter( x => x.keyName === key )[0];
  
  if (!subChangeRequest) {
    return null;
  }

  const { beforeAttributes, afterAttributes } = subChangeRequest;

  return (
    <div className="itemChanges">
      <h4>{title}</h4>
      {renderChange(beforeAttributes, key)}
      {renderChange(afterAttributes, key)}
      {changeRequest.status !== 'pending' && 
        <div>
          <SubChangeRequestStatus subChangeRequest={subChangeRequest} />
        </div>}
    </div>
  );
}
