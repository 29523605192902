import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import Autocomplete from "react-autocomplete";
import validations from "HelperFunctions/validations";
import axios from "axios";
import { saveAuthToken, getAuthToken } from "Api";
import StandardTextareaField from "Utils/redux_form_inputs/StandardTextareaField";
import { Invitations } from "Utils/SvgIcons";
import { parseQuery } from "HelperFunctions/QueryString";

class NewConversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: {
        name: ""
      },
      rental: {
        name: ""
      },
      body: "",
      locations: [],
      rentalNames: []
    };
  }

  componentDidMount() {
    this.fetchLocations();
  }

  fetchLocations = () => {
    const component = this;
    const { locationId } = parseQuery(this.props.location.search);
    axios
      .get(
        process.env.REACT_APP_API_DOMAIN + `/api/marketplace/locations/names`,
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        saveAuthToken(response.headers);
        let location;
        if (locationId) {
          location = response.data.locations.find(b => b.id === +locationId);
        }
        if (location) {
          component.setState(
            {
              locations: response.data.locations,
              location: location
            },
            component.refreshRentalNames
          );
        } else {
          component.setState({ locations: response.data.locations });
        }
      });
  };

  fetchRentalNames = locationId => {
    const component = this;
    axios
      .get(process.env.REACT_APP_API_DOMAIN + `/api/portal/rentals/names`, {
        headers: getAuthToken(),
        params: { location_id: locationId }
      })
      .then(response => {
        saveAuthToken(response.headers);
        component.setState({ rentalNames: response.data.names });
      });
  };

  validate = () => {
    const errors = {};
    const { body } = this.state;
    if (validations.required(body, true)) {
      errors.body = "Message cannot be blank.";
    }
    return errors;
  };

  handleSubmit = () => {
    const { customer, setErrors, createConversation } = this.props;
    const { location, rental, body } = this.state;
    const errors = this.validate();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      createConversation("", body, customer.id, location.id, rental.id);
    }
  };

  refreshRentalNames = () => {
    const { location } = this.state;
    if (location.id) {
      this.fetchRentalNames(location.id);
    }
  };

  shouldItemRender(item, value) {
    return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  render() {
    const { location, rental, locations, rentalNames, body } = this.state;
    return (
      <section className="newConversation form">
        <Invitations />
        <div className="fields">
          <label>To</label>
          <Autocomplete
            value={location.name}
            inputProps={{
              id: "rental-autocomplete",
              placeholder: "Business Name...",
              type: "text"
            }}
            wrapperProps={{ className: "autocomplete" }}
            items={locations}
            getItemValue={item => item.name}
            onChange={(event, value) =>
              this.setState({ location: { name: value } })
            }
            onSelect={(value, item) =>
              this.setState({ location: item }, this.refreshRentalNames)
            }
            renderMenu={children => <ul className="results">{children}</ul>}
            shouldItemRender={this.shouldItemRender}
            renderItem={(item, isHighlighted) => (
              <li
                className={`${isHighlighted ? "highlight" : ""}`}
                key={item.id}
              >
                {item.name}
              </li>
            )}
          />
          <label>Rental</label>
          <Autocomplete
            value={rental.name}
            inputProps={{
              id: "rental-autocomplete",
              placeholder: "Rental Name...",
              type: "text"
            }}
            wrapperProps={{ className: "autocomplete" }}
            items={rentalNames}
            getItemValue={item => item.name}
            onChange={(event, value) =>
              this.setState({ rental: { name: value } })
            }
            onSelect={(value, item) => this.setState({ rental: item })}
            renderMenu={children => <ul className="results">{children}</ul>}
            shouldItemRender={this.shouldItemRender}
            renderItem={(item, isHighlighted) => (
              <li
                className={`${isHighlighted ? "highlight" : ""}`}
                key={item.id}
              >
                {item.name}
              </li>
            )}
          />
          <label>Message</label>
          <StandardTextareaField
            meta={{}}
            placeholder="Write new message..."
            input={{
              name: "body",
              value: body,
              onChange: this.handleChange
            }}
          />
        </div>
        <div className="actions">
          <button
            type="submit"
            className="btn secondary full"
            onClick={this.handleSubmit}
          >
            Submit
          </button>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state.auth;
  return { customer: user };
};

export default withRouter(connect(mapStateToProps, actions)(NewConversation));
