import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import EventFormFields from "../events/edit/EventFormFields";

class EventForm extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange = (event) => {
    const { setCartProps } = this.props;
    const { name, value } = event.target;
    const newEvent = Object.assign(
      { [name]: value },
      name === "deliveryAddressCountry" && { deliveryAddressLocale: "" },
      name === "pickupAddressCountry" && { pickupAddressLocale: "" }
    );
    setCartProps(newEvent);
  };
  handlePickupSameCheck = (event) => {
    const { setCartProps } = this.props;
    const { name, checked } = event.target;
    let newEvent = Object.assign(
      { [name]: checked },
      name === "deliveryAddressCountry" && { deliveryAddressLocale: "" },
      name === "pickupAddressCountry" && { pickupAddressLocale: "" }
    );
    if (checked) {
      newEvent = {
        ...newEvent,
        pickupAddressLocationName: "",
        pickupAddressStreetAddress1: "",
        pickupAddressStreetAddress2: "",
        pickupAddressCity: "",
        pickupAddressLocale: "",
        pickupAddressPostalCode: "",
        pickupAddressCountry: ""
      };
    }
    setCartProps(newEvent);
  };

  render() {
    const { event, errors } = this.props;
    return (
      <section className="eventForm">
        <h2>Delivery</h2>
        <EventFormFields
          event={event}
          errors={errors}
          handleChange={this.handleChange}
          handlePickupSameCheck = {this.handlePickupSameCheck}
        />
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { event } = state.cart;
  const { errors } = state.dashboard;
  return { event, errors };
};

export default connect(mapStateToProps, actions)(EventForm);
