export const getTrackingUrl = (trackingNumber, carriarType) => {
  switch (carriarType) {
    case "fedex":
      return `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingNumber}`;
    case "usps":
      return `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${trackingNumber}`;
    default:
      return;
  }
};
