import React from "react";
import { Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import * as actions from "Actions";
import { ConnectedRouter } from "react-router-redux";

import MainMenu from "Components/main_menu/MainMenu";
import HomeCarousel from "Components/home/HomeCarousel";
import HomeBanner from "Components/home/kazzam/HomeBanner";
import SignUp from "Components/home/SignUp";
import SignIn from "Components/home/SignIn";
import ReclaimAccount from "Components/home/ReclaimAccount";
import ConnectAccount from "Components/home/ConnectAccount";
import ForgotPassword from "Components/home/ForgotPassword";
import PasswordReset from "Components/home/PasswordReset";
import HomeCategories from "Components/home/Categories";
import KazzamCategories from "Components/home/kazzam/KazzamCategories";
import ShopCategories from "Components/shop/Categories";
import ProfileSection from "Components/profile/ProfileSection";
import Conversations from "Components/conversations/Conversations";
import ShopSection from "Components/shop/Shop";
import GetTheLook from "Components/get_the_look/GetTheLook";
import Favorites from "Components/get_the_look/Favorites";
import EventsSection from "Components/events/EventsSection";
import Cart from "Components/cart/CartMiddle";
import Home from "Components/home/Home";
import HomeKazzam from "Components/home/kazzam/HomeKazzam";
import TestimonialKazzam from "Components/home/kazzam/TestimonialKazzam";
import Landing from "Components/home/Landing";
import Footer from "Components/home/Footer";
import NotFound from "Utils/NotFound";

import ScrollToTop from "Utils/ScrollToTop";
import App from "./App";
import ReactGA from "react-ga";
import classnames from "classnames";
import WithAuth from "Components/../hocs/WithAuth";
import UnsupportedRoute from "Utils/UnsupportedRoute";
import { getTimeOfDayClass, isSupportedBroswer } from "HelperFunctions/general";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function logPageView() {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
}
export const history = createBrowserHistory();
const routesWithNav = [
  "/marketplace",
  "/inflatables",
  "/kazzam",
  "/login",
  "/signUp",
  "/reclaimAccount",
  "/forgotPassword",
  "/passwordReset",
  "/profile",
  "/shop",
  "/GettheLook",
  "/Favorites",
  "/conversations",
  "/cart",
  "/operations",
  "/services",
  "/calendars",
  "/contacts",
  "/inventory",
  "/events",
  "/notFound"
];
class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.signInStoredUserIfNeeded = this.signInStoredUserIfNeeded.bind(this);
  }

  componentWillMount() {
    const { restoreZipCodeIfNeeded, restoreCountryIfNeeded } = this.props;
    restoreZipCodeIfNeeded();
    restoreCountryIfNeeded();
    this.signInStoredUserIfNeeded();
  }

  signInStoredUserIfNeeded = () => {
    // Now you can access the store object here.
    const { store } = this.props;
    const state = store.getState();

    const { authenticated } = state.auth;

    if (!authenticated) {
      // Not authenticated, redirect to login.
      store.dispatch(actions.refreshStoredUser());
    }
  };

  render() {
    if (!isSupportedBroswer()) {
      return <UnsupportedRoute history={history} />;
    }

    return (
      <ConnectedRouter history={history} onUpdate={logPageView}>
        <ScrollToTop>
          <div
            className={classnames({
              container: true,
              [getTimeOfDayClass()]: true
            })}
          >
            <Route path="/" component={App} />
            {routesWithNav.map((path, index) => (
              <Route key={index} path={path} component={MainMenu} />
            ))}
            <Route exact path="/marketplace" component={HomeCarousel} />
            <Route exact path="/inflatables" component={HomeCarousel} />
            <Route exact path="/kazzam" component={HomeBanner} />

            <main>
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/marketplace" component={Home} />
                <Route exact path="/inflatables" component={Home} />
                <Route exact path="/kazzam" component={HomeKazzam} />
                <Route path="/login" component={SignIn} />
                <Route path="/signUp" component={SignUp} />
                <Route path="/reclaimAccount" component={ReclaimAccount} />
                <Route path="/connectAccount" component={ConnectAccount} />
                <Route path="/forgotPassword" component={ForgotPassword} />
                <Route path="/passwordReset" component={PasswordReset} />
                <Route path="/profile" component={WithAuth(ProfileSection)} />
                <Route path="/shop" component={ShopSection} />
                <Route path="/GettheLook" component={GetTheLook} />
                <Route path="/favorites" component={WithAuth(Favorites)} />
                <Route
                  path="/conversations"
                  component={WithAuth(Conversations)}
                />
                <Route path="/cart" component={Cart} />
                <Route path="/events" component={WithAuth(EventsSection)} />
                <Route component={NotFound} />
              </Switch>
            </main>

            <Route exact path="/marketplace" component={HomeCategories} />
            <Route exact path="/inflatables" component={HomeCategories} />
            <Route exact path="/kazzam" component={KazzamCategories} />
            <Route exact path="/shop" component={ShopCategories} />

            <Route exact path="/kazzam" component={TestimonialKazzam} />

            <Footer />
          </div>
        </ScrollToTop>
      </ConnectedRouter>
    );
  }
}
const mapStateToProps = state => {
  const { inServiceArea } = state.serviceArea;
  return { inServiceArea };
};
export default connect(mapStateToProps, actions)(Routes);
