import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import ReactTooltip from "react-tooltip";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  EmailShareButton
} from "react-share";
import {
  Share,
  Heart,
  Facebook,
  Pinterest,
  Twitter,
  Email,
  LinkIcon,
  TapGoods
} from "Utils/SvgIcons";
import classnames from "classnames";

class CustomPageCard extends Component {
  copyLink = () => {
    const str =
      window.location.origin +
      `/GetTheLook/${this.props.customPage.navigationLink}`;
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.props.closeSuccessSnackBar();
    this.props.openSuccessSnackBar("Link Copied");
  };
  handleFavorite = () => {
    this.props.onFavorite(this.props.index);
  };
  handleUnfavorite = () => {
    this.props.onUnfavorite(this.props.index);
  };
  render() {
    const { customer, customPage } = this.props;
    // const shareUrl = window.location.origin + `/GetTheLook/${customPage.navigationLink}`;
    const shareUrl = "http://github.com";
    const title = customPage.navigationName;
    let favorited;
    if (customer) {
      favorited = customPage.favoritedCustomerIds.includes(customer.id);
    } else {
      favorited = true;
    }

    const styles = {
      fill: "#00f"
    };

    return (
      <div className="look">
        <figure>
          {customPage.primaryPhotoUrl ? (
            <img src={customPage.primaryPhotoUrl} alt="" />
          ) : (
            <TapGoods />
          )}
        </figure>
        <div className="details">
          <h3>{customPage.navigationName}</h3>
          <Link to={`/GetTheLook/${customPage.navigationLink}`} className="btn">
            Shop This Look
          </Link>
        </div>
        <a
          className="shareBtn"
          data-tip
          data-for="info"
          data-event="click"
          title="Share"
        >
          <Share />
        </a>
        <a
          className={classnames({
            favoriteBtn: true,
            active: favorited
          })}
          onClick={favorited ? this.handleUnfavorite : this.handleFavorite}
          title={favorited ? "This is one of your favorites" : "Favorite"}
        >
          <Heart />
        </a>
        <ReactTooltip
          id="info"
          class="tooltip left"
          place="left"
          type="light"
          effect="solid"
          globalEventOff="click"
        >
          <p>Share This Look</p>
          <FacebookShareButton url={shareUrl} quote={title}>
            <Facebook />
            Facebook
          </FacebookShareButton>
          <PinterestShareButton
            url={shareUrl}
            media={customPage.primaryPhotoUrl}
            windowWidth={1000}
            windowHeight={730}
          >
            <Pinterest />
            Pinterest
          </PinterestShareButton>
          <TwitterShareButton url={shareUrl} title={title}>
            <Twitter />
            Twitter
          </TwitterShareButton>
          <EmailShareButton url={shareUrl} subject={title} body="body">
            <Email />
            Email
          </EmailShareButton>
          <div onClick={this.copyLink}>
            <LinkIcon />
            Copy Link
          </div>
        </ReactTooltip>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { user } = state.auth;
  return { customer: user };
};

export default connect(mapStateToProps, actions)(CustomPageCard);
