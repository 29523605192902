import {
  SET_BEFORE_ATTRIBUTES,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILURE
} from "Constants/redux";
import axios from "axios";
import { setErrors } from "./SnackbarActions";
import { getAuthToken } from "Api";

export const fetchEvent = id => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_EVENT_REQUEST
    });

    axios
      .get(process.env.REACT_APP_API_DOMAIN + "/api/marketplace/events/" + id, {
        headers: getAuthToken()
      })
      .then(response => {
        dispatch({
          type: FETCH_EVENT_SUCCESS,
          payload: response.data.event
        });
        dispatch({
          type: SET_BEFORE_ATTRIBUTES,
          payload: response.data.event
        });
        getState();
      })
      .catch(error => {
        console.log(error);
        const errors = error.response.data;
        dispatch({
          type: FETCH_EVENT_FAILURE
        });
        dispatch(setErrors(errors));
      });
  };
};
