import React from "react";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";
import { setFilterParams, filterFromPropsAndState } from "HelperFunctions/urls";
import { getItemsWithNewPricesForRental } from "HelperFunctions/rental_items";
import { connect } from "react-redux";
import * as actions from "Actions";
import classnames from "classnames";
import EventItems from "Components/events/edit/EventItems";
import EventBilling from "Components/events/edit/EventBilling";
import EventForm from "Components/events/edit/EventForm";
import EventSummary from "Components/events/edit/EventSummary";
import LoadingSpinner from "Utils/LoadingSpinner";
import { CheckOnCircle } from "Utils/SvgIcons";
import { countryToLocale } from "Constants/currencyConstants";
import Modal from "Utils/Modal";

class EditEvent extends React.Component {

  state = {
    cancelChangesModalOpen: false
  }

  componentDidMount() {
    const {
      match: {
        params: { rentalId }
      }
    } = this.props;
    this.props.fetchRental(rentalId);
  }

  componentDidUpdate(prevProps) {
    const { event } = this.props;
    const { event: prevEvent } = prevProps;
    if (
      event.startDate !== prevEvent.startDate ||
      event.eventStartTime !== prevEvent.eventStartTime ||
      event.eventEnd !== prevEvent.eventEnd ||
      event.eventEndTime !== prevEvent.eventEndTime ||
      !isEqual(event.items, prevEvent.items) ||
      !isEqual(event.rentalBundles, prevEvent.rentalBundles)
    ) {
      const { setRentalProps } = this.props;
      const { items, rentalBundles } = getItemsWithNewPricesForRental(event);
      event.items = items;
      event.rentalBundles = rentalBundles;
      setRentalProps(event);
    }
  }

  handleSelectStep = step => {
    const { location, history } = this.props;
    setFilterParams(
      {
        step: step
      },
      location,
      history
    );
  };

  handleSubmitQuote = () => {
    const { event, editRentalSubmit, requestChanges } = this.props;
    if (event.approvalStatus === "pending") {
      editRentalSubmit(event => {
        this.eventRedirect();
      });
    } else {
      requestChanges(event => {
        this.eventRedirect();
      });
    }
  };

  handleReview = () => {
    const { event, calculateDefaultDeliveryPrice, setRentalProps } = this.props;
    calculateDefaultDeliveryPrice({
      event,
      onSuccess: deliveryCost => {
        setRentalProps({
          deliveryCost: deliveryCost
        });
        this.handleSelectStep(3);
      }
    });
  };

  renderLeftSection = step => {
    const {
      event,
      errors,
      match,
      setRentalProps,
      recalcDamageWaiverFee,
      calculateDefaultDeliveryPrice
    } = this.props;
    let locale = "en-US";
    if (event && event.businessAddress) {
      locale = countryToLocale[event.businessAddress.country];
    }    

    return(
      <div>
        <EventItems
          event={event}
          errors={errors}
          setEventProps={setRentalProps}
          recalcDamageWaiverFee={recalcDamageWaiverFee}
          locale={locale}
          match={match}
        />
        <EventForm
          event={event}
          setEventProps={setRentalProps}
          calculateDefaultDeliveryPrice={calculateDefaultDeliveryPrice}
          locale={locale}
        />
      </div>
    )
  };

  eventRedirect = () => {
    const { history, match } = this.props;
    history.push(`/events/${match.params.id}/rentals/${match.params.rentalId}`);
  };

  renderNextButton = () => {
    const {
      event,
      step,
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <div className="progressBtns">
        <a className="btn skip-step" onClick={this.toggleCancelChangesModal}>
          Cancel Changes
        </a>
        <a className="btn" onClick={this.handleSubmitQuote}>
          Request Edits
        </a>
      </div>
    )
  };

  cancelEditedChanges = () =>{
    const { event, history, setCartProps, match } = this.props
    setCartProps({
      items: [], addOns: [], rentalBundles: [], editingEvent: false
    });
    history.push(`/events/${match.params.id}/rentals/${event.token}`);
  }

  toggleCancelChangesModal = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    })
  }

  render() {
    const {
      loading,
      step,
      match: {
        params: { id }
      },
      event
    } = this.props;
    let locale = "en-US";
    if (event && event.businessAddress) {
      locale = countryToLocale[event.businessAddress.country];
    }    
    if (loading) {
      return <LoadingSpinner />;
    } else {
      return (
        <div className="cart editRental">
          <header>
            <h1>Edit Your Event</h1>
          </header>
          <div className="content">
            <section className="progress">
              <div className="editOrder">
                <span>
                  <b>Editing Order: {event.name}</b> #{event.token}
                </span>
              </div>
              
              {this.renderNextButton()}
            </section>
            {this.renderLeftSection(step)}
            <EventBilling
              eventId={id}
              step={step}
              onSelectStep={this.handleSelectStep}
              onSubmitQuote={this.handleSubmitQuote}
              onReview={this.handleReview}
              locale={locale}
            />
            <Modal
              className="cancelChangeRequest"
              title="Cancel Changes"
              open={this.state.cancelChangesModalOpen}
              toggle={this.toggleCancelChangesModal}
            >
              <div>
                <p>
                  Looks like you’ve made some changes to your order. Are you sure you want to cancel them?
                </p>
                <div className="actions">
                  <a className="btn seconday" onClick={this.cancelEditedChanges}>
                    Yes, Cancel My Changes
                  </a>
                  <a className="btn cancel" onClick={this.toggleCancelChangesModal}>
                    Go Back
                  </a>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  const step = newFilter.step ? +newFilter.step : 1;
  const { errors } = state.dashboard;
  const { rental, loading } = state.rental;
  return { event: rental, loading, step, errors };
};

export default connect(mapStateToProps, actions)(EditEvent);
