import React from "react";
import PropTypes from "prop-types";

const ZoomImage = ({
  src,
  fadeDuration,
  top,
  left,
  isZoomed,
  onLoad,
  onTouchStart
}) => {
  return (
    <img
      className={`innerZoomZoomedImage ${isZoomed ? "zoomedVisible" : ""}`}
      style={{
        top,
        left,
        transition: `linear ${fadeDuration}ms opacity, linear ${fadeDuration}ms visibility`
      }}
      src={src}
      onLoad={onLoad}
      onTouchStart={onTouchStart}
      alt={src}
    />
  );
};

ZoomImage.propTypes = {
  src: PropTypes.string,
  fadeDuration: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
  isZoomed: PropTypes.bool,
  onLoad: PropTypes.func,
  onTouchStart: PropTypes.func
};

export default ZoomImage;
