import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import { TapGoods, Delete, Info } from "Utils/SvgIcons";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import { getItemPeriodPrice } from "HelperFunctions/rentals";
import { getProductType } from "HelperFunctions/rental_items";
import Imgix from "react-imgix";
import CurrencyFormatter from "HelperFunctions/CurrencyFormatter";

class EventItem extends Component {
  handleItemChange = e => {
    this.props.onItemChange(e, this.props.itemIndex);
  };

  handleItemRemove = () => {
    this.props.onItemRemove(this.props.itemIndex);
  };

  render() {
    const { item, tileView, locale } = this.props;
    const product = item.product || item.bundle || item.addOn;
    const productType = getProductType(item);
    const formatter = CurrencyFormatter({ locale });

    return (
      <MediaBreakpoints
        desktop={
          <tr>
            {tileView && (
              <td className="image">
                <figure>
                  {product.pictures[0] ? (
                    <Imgix
                      src={product.pictures[0].imgixUrl}
                      alt={product.name}
                      width={510}
                      height={288}
                    />
                  ) : (
                    <div className="placeholder" />
                  )}
                </figure>
              </td>
            )}
            <td className="itemContent">
              <StandardTextField
                type="text"
                meta={{ error: {} }}
                input={{
                  name: "quantity",
                  value: item.quantity,
                  onChange: this.handleItemChange
                }}
              />
              <label>
                {item.name}
                {item.quantity > product.numberAvailable && (
                  <div
                    className="info"
                    data-tip
                    data-for="info"
                    data-event="click"
                    data-event-off="mouseleave"
                  >
                    <Info />
                  </div>
                )}
                {item.quantity > product.numberAvailable && (
                  <ReactTooltip
                    id="info"
                    class="tooltip bottom"
                    place="bottom"
                    type="light"
                    effect="solid"
                  >
                    <h3>Item not available for autobook</h3>
                    <p>
                      You're still available to add this to a quote, just note
                      this item needs an approval from the rental company before
                      you can rent it for your event.
                    </p>
                  </ReactTooltip>
                )}
              </label>
              {product.businessInfo.shopShowItemPricing && getItemPeriodPrice(item, productType, locale)}
              {product.businessInfo.shopShowItemPricing &&
                item.bundle &&
                !product.priceLocked &&
                product.flatPrices && product.flatPrices.map((flatPrice, index) =>
                  <span key={index}>{formatter.format(flatPrice.amount)} / Flat: {flatPrice.name}</span>
                )}
              {item.bundle &&
                !product.priceLocked &&
                product.purchasePrice && (
                <span>+ {formatter.format(product.purchasePrice)} / Each</span>
              )}
            </td>
            <td className="action">
              <a className="btnLink remove" onClick={this.handleItemRemove}>
                <Delete />
              </a>
            </td>
          </tr>
        }
        mobile={
          <tr>
            <td className="mobileItemContent">
              {tileView && (
                <figure>
                  {product.pictures[0] ? (
                    <Imgix
                      src={product.pictures[0].imgixUrl}
                      alt={product.name}
                      width={510}
                      height={288}
                    />
                  ) : (
                    <TapGoods />
                  )}
                </figure>
              )}
              <label>{item.name}</label>
              <a className="btnLink remove" onClick={this.handleItemRemove}>
                <Delete />
              </a>
              <StandardTextField
                type="text"
                meta={{ error: {} }}
                input={{
                  name: "quantity",
                  value: item.quantity,
                  pattern: "d*",
                  onChange: this.handleQuantityChange
                }}
              />
              {product.businessInfo.shopShowItemPricing && getItemPeriodPrice(item, productType, locale)}
            </td>
          </tr>
        }
      />
    );
  }
}

export default EventItem;
