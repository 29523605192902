import React from "react";
import SignUpWidget from "Components/home/widgets/SignUp";

class SignUp extends React.Component {
  handleSubmit = () => {
    console.log("call sign up action");
  };
  render() {
    return (
      <div className="signUp">
        <header>
          <h1>Sign Up</h1>
        </header>
        <SignUpWidget />
      </div>
    );
  }
}

export default SignUp;
