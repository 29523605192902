import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import classnames from "classnames";
import {
  Accents,
  Balloons,
  Banners,
  Bars,
  Chairs,
  Drinks,
  Lighting,
  Linens,
  MirroredBall,
  Photobooth,
  Seating,
  Tabletop,
  Tents,
  Burst,
  Castle,
  Table,
  Utensils
} from "Utils/SvgIcons";

class Categories extends React.Component {
  state = {
    featuredCustomPages: []
  };
  componentDidMount() {
    this.setState({
      featuredCustomPages: this.props.customPages.customFrontPages
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      featuredCustomPages: this.props.customPages.customFrontPages
    });
  }

  pageCategoriesLarge = () => {
    const { pathname } = this.props.location;
    switch (pathname) {
      case "/inflatables":
        return [
          {
            id: "31",
            component: <Castle />,
            label: "Inflatables",
            type: "inflatables"
          },
          {
            id: "33",
            component: <Utensils />,
            label: "Concessions",
            type: "inflatables"
          },
          {
            id: "11",
            component: <Photobooth />,
            label: "Activities",
            type: "inflatables"
          }
        ];
      default:
        return [
          { id: "4", component: <Tabletop />, label: "Tabletop", type: "" },
          {
            id: "8",
            component: <Seating />,
            label: "Specialty Seating",
            type: ""
          },
          { id: "10", component: <Bars />, label: "bars", type: "" }
        ];
    }
  };

  pageCategoriesSmall = () => {
    const { pathname } = this.props.location;
    switch (pathname) {
      case "/inflatables":
        return [
          {
            id: "11%2C17%2C22",
            component: <Burst />,
            label: "Entertainment",
            type: "inflatables"
          },
          {
            id: "3",
            component: <Table />,
            label: "Tables",
            type: "inflatables"
          },
          {
            id: "2",
            component: <Chairs />,
            label: "Chairs",
            type: "inflatables"
          },
          {
            id: "6",
            component: <Lighting />,
            label: "Lighting",
            type: "inflatables"
          }
        ];
      default:
        return [
          { id: "21", component: <Tents />, label: "Tents", type: "" },
          { id: "3", component: <Linens />, label: "Linens", type: "" },
          { id: "2", component: <Chairs />, label: "Chairs", type: "" },
          { id: "6", component: <Accents />, label: "Accents", type: "" }
        ];
    }
  };
  render() {
    const { featuredCustomPages } = this.state;

    return (
      <div
        className={classnames({
          categories: true,
          shopLanding: window.location.pathname === "/shop"
        })}
      >
        <ul className="large">
          {this.pageCategoriesLarge().map(category => {
            return (
              <li>
                <Link
                  to={`/shop/search?selectedCategories=${category.id}&type=${category.type}`}
                >
                  {category.component}
                  <label>{category.label}</label>
                </Link>
              </li>
            );
          })}
        </ul>
        <ul className="small">
          {this.pageCategoriesSmall().map(category => {
            return (
              <li>
                <Link
                  to={`/shop/search?selectedCategories=${category.id}&type=${category.type}`}
                >
                  {category.component}
                  <label>{category.label}</label>
                </Link>
              </li>
            );
          })}
        </ul>
        {featuredCustomPages.length >= 6 &&
          this.props.location.pathname === "/marketplace" && (
            <section className="discoverTheLook">
              <h2>Get the Look</h2>
              <p>
                Events have never been this easy to DIY! Browse some looks we’ve
                already put together for you.
              </p>
              <ul className="looks">
                {featuredCustomPages.map(cp => (
                  <li className="look" key={cp.id}>
                    <figure>
                      <img src={cp.primaryPhotoUrl} alt="" />
                    </figure>
                    <div className="details">
                      <Link
                        to={`/GettheLook/${cp.navigationLink}`}
                        className="btn"
                      >
                        Shop This Look
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
              <Link className="btn" to="/GetTheLook">
                See More Inspiration
              </Link>
            </section>
          )}
        <section className="fullListing">
          <h1>
            All Categories
            <Link to="/shop/search">Shop All Categories</Link>
          </h1>
          <ul>
            <li>
              <Balloons />
              <h2>Decorate Like a Pro</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=23">
                    Confetti & Balloons
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=6">Accents</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=19">
                    Accents - Holiday
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=25">
                    Accents - Themed
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Banners />
              <h2>Spruce It Up</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=30">Plants</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=18">
                    Floral & Containers
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=24">
                    Accents - Hanging
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Drinks />
              <h2>Eat, Drink, Be Happy</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=10">Bars</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=15">
                    Catering & Utility
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Tabletop />
              <h2>Set the Table</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=2">Chairs</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=1">Tables</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=3">Linens</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=4">Tabletop</Link>
                </li>
              </ul>
            </li>
            <li>
              <Tents />
              <h2>Step Outside</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=14">
                    Venues & Outdoors
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=26">
                    Accents - Ceremony
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=21">Tents</Link>
                </li>
              </ul>
            </li>
            <li>
              <Seating />
              <h2>Lounge Around</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=8">
                    Specialty Seating
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=29">
                    Children's Furniture
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Photobooth />
              <h2>Game On</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=17">Services</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=11">
                    Party Activities & Entertainment
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=28">
                    Printing & Laser
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <MirroredBall />
              <h2>Bust a Move</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=20">
                    Audio & Video
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=22">DJs</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=12">Flooring</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=9">Stages</Link>
                </li>
              </ul>
            </li>
            <li>
              <Lighting />
              <h2>Create Ambience</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=16">Lighting</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=13">
                    Pipe & Drape
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { allCategories, featuredCategories } = state.categoryList;
  const { zipCode } = state.serviceArea;
  const { customPages } = state;
  return { featuredCategories, allCategories, zipCode, customPages };
};
export default connect(mapStateToProps, actions)(Categories);
