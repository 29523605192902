import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { uniq } from "lodash";
import classnames from "classnames";
import * as actions from "Actions";
import CartItems from "./CartItems";
import CartBilling from "./CartBilling";
import EventForm from "./EventForm";
import EventSummary from "./EventSummary";
import SignInForm from "Components/home/SignInForm";
import SignUpForm from "Components/home/SignUpForm";
import { history as browserHistory } from "Components/Routes";
import Modal from "Utils/Modal";
import { Alert, CheckOnCircle, Burst } from "Utils/SvgIcons";
import { setFilterParams, filterFromPropsAndState } from "HelperFunctions/urls";
import { combineDateAndTime } from "HelperFunctions/general";
import Validator from "HelperFunctions/validator";

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      signInOpen: false,
      signUpOpen: false
    };
  }

  toggleSignUp = () => {
    this.setState({
      signUpOpen: !this.state.signUpOpen,
      signInOpen: this.state.signUpOpen
    });
  };

  toggleSignIn = () => {
    this.setState({
      signInOpen: !this.state.signInOpen,
      signUpOpen: this.state.signInOpen
    });
  };

  closeSignIn = () => {
    this.setState({
      signInOpen: false
    });
  };

  closeSignUp = () => {
    this.setState({
      signUpOpen: false
    });
  };

  handleReview = step => {
    const { newReview } = this.props;
    newReview(() => {
      this.handleSelectStep(step);
    });
  };

  handleSelectStep = step => {
    const { location, history } = this.props;
    setFilterParams(
      {
        step: step
      },
      location,
      history
    );
  };

  handleSubmitQuote = () => {
    const component = this;
    if (component.props.authenticated) {
      component.props.newSubmit(event => {
        component.eventRedirect(event);
      });
    } else {
      component.toggleSignIn();
    }
  };

  renderLeftSection = step => {
    switch (step) {
      case 1:
        return <CartItems />;
      case 2:
        return <EventForm />;
      case 3:
      case 4:
        return <EventSummary />;
      default:
        return <CartItems />;
    }
  };

  reviewStepOne() {
    const { event, setErrors } = this.props;
    let eventForValidation = {
      ...event,
      eventStartDateTime: combineDateAndTime(
        event.eventStart,
        event.eventStartTime
      ),
      eventEndDateTime: combineDateAndTime(event.eventEnd, event.eventEndTime)
    };
    const validations = {
      name: {
        required: {
          value: true,
          message: "Event name is required."
        },
        minLength: {
          value: 5,
          message: "Event name must be at least 5 characters."
        }
      },
      eventStart: {
        required: {
          value: true,
          message: "Event start date is required."
        }
      },
      eventStartTime: {
        required: {
          value: true,
          message: "Event start time is required."
        }
      },
      eventStartDateTime: {
        required: {
          value: true,
          message: "Event start date is required."
        },
        greaterThanOrEqualToDate: {
          value: new Date(),
          message: "Start date cannot be in the past."
        }
      },
      eventEnd: {
        required: {
          value: true,
          message: "Event end date is required."
        }
      },
      eventEndTime: {
        required: {
          value: true,
          message: "Event end time is required."
        }
      },
      eventEndDateTime: {
        required: {
          value: true,
          message: "Event end date is required."
        },
        greaterThanOrEqualToDate: {
          value: eventForValidation.eventStartDateTime,
          message: "End date must be after start date."
        }
      },
      customerContactPhone: {
        required: {
          value: true,
          message: "Contact phone number is required."
        }
      }
    };

    const errors = Validator(validations)(eventForValidation);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      this.handleSelectStep(2);
    }
  }

  reviewStepTwo = () => {
    const { event, setErrors } = this.props;
    let eventForValidation = {
      ...event,
      eventStartDateTime: combineDateAndTime(
        event.eventStart,
        event.eventStartTime
      ),
      eventEndDateTime: combineDateAndTime(event.eventEnd, event.eventEndTime)
    };
    const validations = {
      name: {
        required: {
          value: true,
          message: "Event name is required."
        },
        minLength: {
          value: 5,
          message: "Event name must be at least 5 characters."
        }
      },
      customerContactPhone: {
        required: {
          value: true,
          message: "Contact phone number is required."
        }
      },
      eventStart: {
        required: {
          value: true,
          message: "Event start date is required."
        }
      },
      eventStartTime: {
        required: {
          value: true,
          message: "Event start time is required."
        }
      },
      eventStartDateTime: {
        required: {
          value: true,
          message: "Event start date is required."
        },
        greaterThanOrEqualToDate: {
          value: new Date(),
          message: "Start date cannot be in the past."
        }
      },
      eventEnd: {
        required: {
          value: true,
          message: "Event end date is required."
        }
      },
      eventEndTime: {
        required: {
          value: true,
          message: "Event end time is required."
        }
      },
      eventEndDateTime: {
        required: {
          value: true,
          message: "Event end date is required."
        },
        greaterThanOrEqualToDate: {
          value: eventForValidation.eventStartDateTime,
          message: "End date must be after start date."
        }
      }
    };
    const errors = Validator(validations)(eventForValidation);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      this.handleReview(3);
    }
  };

  handleSignIn = data => {
    const component = this;
    const { loginUser } = component.props;
    loginUser({
      ...data,
      onSuccess: () => {
        component.setState({
          signInOpen: false,
          signUpOpen: false
        });
      }
    });
  };

  handleSignUp = data => {
    const component = this;
    const { createUser } = component.props;
    createUser({
      ...data,
      onSuccess: () => {
        component.setState({
          signInOpen: false,
          signUpOpen: false
        });
      }
    });
  };

  eventRedirect = event => {
    this.props.setCartProps({ token: event.token, id: event.id });
    this.trackProgress(event);
    this.props.setCartProps({ customerContactPhone: this.props.customer.cell });
  };

  trackProgress = event => {
    const { initCartProps } = this.props;
    browserHistory.push(`/events/${event.id}`);
    initCartProps();
  };

  getMinimumItemTotalAlertForRental = (event, items, rentalBundles, addOns) => {
    const rentalInventory = items.concat(rentalBundles);
    const rentalInventoryTotal = rentalInventory.reduce(
      (total, item_container) =>
        total + parseFloat(item_container.selectedPrice),
      0
    );
    const hasPriceAvailableOnRequest = rentalInventory.some(
      item_container => +item_container.selectedPrice === 0
    );
    const business = items[0]
      ? items[0].product.businessInfo
      : rentalBundles[0]
      ? rentalBundles[0].bundle.businessInfo
      : addOns[0].addOn.businessInfo;
    const minimumItemTotal =
      event.deliveryType === "customer_pick_up"
        ? business.minimumItemTotalForPickup
        : business.minimumItemTotalForDelivery;
    return (
      !hasPriceAvailableOnRequest && rentalInventoryTotal < minimumItemTotal
    );
  };

  renderNextButton = () => {
    const { step, event } = this.props;
    const businessKeys = uniq([
      ...Object.keys(event.items),
      ...Object.keys(event.rentalBundles),
      ...Object.keys(event.addOns)
    ]);

    const hasMinimumItemTotalAlert = businessKeys.some(key =>
      this.getMinimumItemTotalAlertForRental(
        event,
        event.items[key] || [],
        event.rentalBundles[key] || [],
        event.addOns[key] || []
      )
    );

    switch (step) {
      case 1:
        if (businessKeys.length === 0) {
          return (
            <Link className="btn" to="/shop/search">
              Shop Now
            </Link>
          );
        } else {
          return (
            <a className="btn" onClick={() => this.reviewStepOne()}>
              Next Step: Set Delivery Info
            </a>
          );
        }
      case 2:
        return (
          <a className="btn" onClick={this.reviewStepTwo}>
            Next Step: Review and Submit
          </a>
        );
      case 3:
        if (hasMinimumItemTotalAlert) {
          return (
            <button className="btn alert" disabled>
              <Alert />
              Submit
            </button>
          );
        } else {
          return (
            <a className="btn" onClick={this.handleSubmitQuote}>
              Submit
            </a>
          );
        }
      case 4:
        return (
          <a className="btn" onClick={this.trackProgress}>
            Track Progress
          </a>
        );
      default:
        return (
          <a className="btn" onClick={() => this.handleSelectStep(step + 1)}>
            Next Step: Set Delivery Info
          </a>
        );
    }
  };

  render() {
    const { step, errors } = this.props;
    const { signInOpen, signUpOpen } = this.state;

    return (
      <div className="cart">
        <header>
          <h1>
            {step === 1
              ? "Build Quote"
              : step === 2
              ? "Set Delivery Info"
              : "Submit"}
          </h1>
        </header>
        <div className="content">
          <section className="progress">
            <ul>
              <li
                className={classnames({
                  active: step === 1,
                  complete: step > 1
                })}
              >
                <a onClick={() => step > 1 && this.handleSelectStep(1)}>
                  {step > 1 ? <CheckOnCircle /> : <div>1</div>}
                  <label>Build Quote</label>
                </a>
              </li>
              <li
                className={classnames({
                  active: step === 2,
                  complete: step > 2
                })}
              >
                <a onClick={() => step > 2 && this.handleSelectStep(2)}>
                  {step > 2 ? <CheckOnCircle /> : <div>2</div>}
                  <label>Delivery Info</label>
                </a>
              </li>
              <li
                className={classnames({
                  active: step === 3,
                  complete: step > 3
                })}
              >
                <a onClick={() => step > 3 && this.handleSelectStep(3)}>
                  {step > 3 ? <CheckOnCircle /> : <div>3</div>}
                  <label>Review & Submit</label>
                </a>
              </li>
            </ul>
            {this.renderNextButton()}
          </section>
          {this.renderLeftSection(step)}
          <CartBilling
            step={step}
            onSelectStep={this.handleSelectStep}
            onSubmitQuote={this.handleSubmitQuote}
            onReview={this.handleReview}
          />
        </div>
        <Modal
          className="signInModal"
          title="Sign In"
          open={signInOpen}
          toggle={this.closeSignIn}
        >
          <div>
            <div className="icon">
              <Burst />
            </div>
            <div className="details">
              <p>
                Please sign in/up to submit,
                <br />
                track and update your events.
              </p>
              <SignInForm errors={errors} onSubmit={this.handleSignIn} />
              <Link to="/forgotPassword">Forgot Password</Link>
              <a onClick={this.toggleSignUp}>or Sign Up for FREE</a>
            </div>
          </div>
        </Modal>
        <Modal
          className="signInModal"
          title="Sign Up"
          open={signUpOpen}
          toggle={this.closeSignUp}
        >
          <div>
            <div className="icon">
              <Burst />
            </div>
            <div className="details">
              <SignUpForm errors={errors} onSubmit={this.handleSignUp} />
              <a onClick={this.toggleSignIn}>or Sign In</a>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  const step = newFilter.step ? +newFilter.step : 1;
  const { authenticated, errors } = state.auth;
  const { event } = state.cart;
  const { customer } = state.customer;
  return { customer, event, step, authenticated, errors };
};

export default withRouter(connect(mapStateToProps, actions)(Cart));
