import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
import ProfilePaymentMethods from "./ProfilePaymentMethods";
import ProfilePaymentHistory from "./ProfilePaymentHistory";
import ProfileDocuments from "./ProfileDocuments";
import NewCreditCard from "./NewCreditCard";
import NewCustomerDocument from "./NewCustomerDocument";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import ProfileNav from "./ProfileNav";

class ProfileSection extends React.Component {
  render() {
    return (
      <MediaBreakpoints
        desktop={
          <div className="profile">
            <header>
              <h1>Profile</h1>
            </header>
            <Route path="/profile" component={ProfileNav} />
            <Switch>
              <Redirect exact from="/profile" to="/profile/info" />
              <Route exact path="/profile/info" component={Profile} />
              <Route path="/profile/edit" component={EditProfile} />
              <Route
                exact
                path="/profile/paymentMethods"
                component={ProfilePaymentMethods}
              />
              <Route
                path="/profile/paymentMethods/new"
                component={NewCreditCard}
              />
              <Route
                exact
                path="/profile/documents"
                component={ProfileDocuments}
              />
              <Route
                exact
                path="/profile/documents/new"
                component={NewCustomerDocument}
              />
              <Route
                exact
                path="/profile/paymentHistory"
                component={ProfilePaymentHistory}
              />
            </Switch>
          </div>
        }
        mobile={
          <div className="profile">
            <header>
              <h1>Profile</h1>
            </header>
            <Switch>
              <Route exact path="/profile" component={ProfileNav} />
              <Route exact path="/profile/info" component={Profile} />
              <Route path="/profile/edit" component={EditProfile} />
              <Route
                exact
                path="/profile/paymentMethods"
                component={ProfilePaymentMethods}
              />
              <Route
                path="/profile/paymentMethods/new"
                component={NewCreditCard}
              />
              <Route
                exact
                path="/profile/documents"
                component={ProfileDocuments}
              />
              <Route
                exact
                path="/profile/documents/new"
                component={NewCustomerDocument}
              />
              <Route
                exact
                path="/profile/paymentHistory"
                component={ProfilePaymentHistory}
              />
            </Switch>
          </div>
        }
      />
    );
  }
}

export default ProfileSection;
