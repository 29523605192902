import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { ROOT_URL } from "Api";
import * as actions from "Actions/SnackbarActions";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import { Chairs } from "Utils/SvgIcons";
import Validator from "HelperFunctions/validator";

class NoItemsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {}
    };
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
    this.validate();
  };

  handleSubmit = event => {
    event.preventDefault();
    const { email } = this.state;
    const { zipCode, openSuccessSnackBar } = this.props;

    axios
      .post(`${ROOT_URL}/marketplace/emails/no_items_email`, {
        email: email,
        zip_code: zipCode
      })
      .then(response => {
        console.log(response);
        openSuccessSnackBar("Submitted Successfully");
        this.setState({ email: "" });
      });
  };

  validate = () => {
    const validations = {
      email: {
        required: {
          value: true,
          message: "Email is required."
        },
        email: {
          value: true,
          message: "Invalid email format."
        }
      }
    };
    const errors = Validator(validations)(this.state);
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors: errors
      });
      return;
    }

    this.setState({ errors: {} });
  };

  render() {
    const { email, errors } = this.state;

    return (
      <div className="noItems">
        <div className="icon">
          <Chairs />
        </div>
        <h2>We didn't find items in your area.</h2>
        <p>
          The good news... we're always adding items!
          <br />
          Leave us your email address, and we'll let you know we've added a lot
          more in your area.
        </p>
        <form onSubmit={this.handleSubmit}>
          <StandardTextField
            type="text"
            meta={{ error: errors.email, touched: true }}
            input={{
              name: "email",
              value: email,
              onChange: this.handleChange
            }}
          />
          <input className="btn" type="submit" value="Submit" />
        </form>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { zipCode } = state.serviceArea;
  return { zipCode };
};

export default connect(mapStateToProps, actions)(NoItemsPage);
