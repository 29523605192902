import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import { MenuIcon, Close, Cart } from "Utils/SvgIcons";
import { filterFromPropsAndState } from "HelperFunctions/urls";

class MobileSignedOutNav extends React.Component {
  state = {
    open: false,
    zipCode: {
      open: false,
      value: ""
    }
  };
  componentWillMount() {
    this.setState({
      zipCode: {
        ...this.state.zipCode,
        value: this.props.zipCode
      },
      featuredCustomPages: this.props.customPages.customPages
    });
  }
  componentDidMount() {
    this.props.fetchCustomPages(this.state.zipCode.value);
    this.setState({
      featuredCustomPages: this.props.customPages.customPages
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      zipCode: {
        ...this.state.zipCode,
        value: nextProps.zipCode
      },
      featuredCustomPages: this.props.customPages.customPages
    });
  }
  toggleMenu = event => {
    this.setState({
      open: !this.state.open
    });
  };
  toggleZipCode = event => {
    this.setState({
      zipCode: {
        ...this.state.zipCode,
        open: !this.state.zipCode.open,
        anchorEl: event.currentTarget
      }
    });
  };
  handleZipCodeChange = event => {
    this.setState({
      zipCode: {
        ...this.state.zipCode,
        value: event.target.value
      }
    });
  };
  handleZipCodeSubmit = event => {
    event.preventDefault();
    this.props.setZipCode(this.state.zipCode.value);
    this.props.reloadInventory(this.props.filter);
    this.props.fetchCustomPages(this.state.zipCode.value);
    this.toggleMenu();
  };

  render() {
    const { event } = this.props;
    const { featuredCustomPages, ZipCode } = this.state;
    const { open } = this.state;

    return (
      <nav className="mobileMenu">
        <a className="topLink" onClick={this.toggleMenu}>
          <MenuIcon />
        </a>
        <NavLink className="headerLogo" to="/marketplace" />
        <NavLink className="topLink cart" to="/cart">
          <Cart />
          <label>
            {Object.values(event.items).length > 0 &&
              Object.values(event.items)
                .flat()
                .reduce(sum => sum + 1, 0)}
          </label>
        </NavLink>

        {open && (
          <div className="mobileMenuOverlay">
            <a className="topLink" onClick={this.toggleMenu}>
              <Close />
            </a>
            <NavLink className="topLink cart" to="/cart">
              <Cart />
              <label>
                {Object.values(event.items).length > 0 &&
                  Object.values(event.items)
                    .flat()
                    .reduce(sum => sum + 1, 0)}
              </label>
            </NavLink>

            <nav className="noGreeting">
              <NavLink to="/login" onClick={this.toggleMenu}>
                Sign In
              </NavLink>
              <NavLink to="/signUp" onClick={this.toggleMenu}>
                Sign Up Free
              </NavLink>
            </nav>

            <div className="location">
              <p>
                Rent anything from
                <br />
                rental companies near you.
              </p>
              <form onSubmit={this.handleZipCodeSubmit}>
                <input
                  type="text"
                  placeholder="Zip"
                  value={this.state.zipCode.value}
                  onChange={this.handleZipCodeChange}
                />
                <input type="submit" value="Update" className="btn" />
              </form>
            </div>

            <div className="footerLinks">
              <a
                href="//www.tapgoods.com/pro/terms-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
              <a
                href="//www.tapgoods.com/pro/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        )}
      </nav>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { event } = state.cart;
  const { customPages } = state;
  const { zipCode } = state.serviceArea;
  const newFilter = filterFromPropsAndState(ownProps);
  const selectedCategories = newFilter.selectedCategories
    ? newFilter.selectedCategories.split(",")
    : [];
  const selectedSubCategories = newFilter.selectedSubCategories
    ? newFilter.selectedSubCategories.split(",")
    : [];

  return {
    filter: {
      ...newFilter,
      selectedCategories,
      selectedSubCategories
    },
    event,
    zipCode,
    customPages
  };
};

export default withRouter(
  connect(mapStateToProps, actions)(MobileSignedOutNav)
);
