export const SET_RENTAL_FILTER = "SET_RENTAL_FILTER";
export const FETCH_RENTAL_LIST_REQUEST = "FETCH_RENTAL_LIST_REQUEST";
export const FETCH_RENTAL_LIST_SUCCESS = "FETCH_RENTAL_LIST_SUCCESS";
export const FETCH_RENTAL_LIST_FAILURE = "FETCH_RENTAL_LIST_FAILURE";
export const ADD_INFINITE_RENTALS_REQUEST = "ADD_INFINITE_RENTALS_REQUEST";
export const ADD_INFINITE_RENTALS_SUCCESS = "ADD_INFINITE_RENTALS_SUCCESS";
export const ADD_INFINITE_RENTALS_FAILURE = "ADD_INFINITE_RENTALS_FAILURE";
export const PAGINATE_RENTAL_LIST_REQUEST = "PAGINATE_RENTAL_LIST_REQUEST";
export const PAGINATE_RENTAL_LIST_SUCCESS = "PAGINATE_RENTAL_LIST_SUCCESS";
export const PAGINATE_RENTAL_LIST_FAILURE = "PAGINATE_RENTAL_LIST_FAILURE";
export const REQUEST_RENTAL_NAMES = "REQUEST_RENTAL_NAMES";
export const RECEIVE_RENTAL_NAMES = "RECEIVE_RENTAL_NAMES";
