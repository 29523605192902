import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedDate, FormattedTime } from "react-intl";
import { uniq } from "lodash";
import * as actions from "Actions";
import {
  periodToPricingType,
  displayPeriodUnit,
  customerDeliveryTypes
} from "Constants/displayConstants";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import { TapGoods } from "Utils/SvgIcons";
import { getFirstAvailablePricingType } from "HelperFunctions/rentals";
import {
  getProductType,
  hasSelectedFlatPrice,
  getSelectedFlatPrice
} from "HelperFunctions/rental_items";
import { formatPhoneNumber } from "HelperFunctions/general";
import Imgix from "react-imgix";
import moment from "moment";
import CurrencyLabel from "Utils/CurrencyLabel";
import CurrencyFormatter from "HelperFunctions/CurrencyFormatter";

class EventSummary extends Component {
  isShopShowItemPricing = (item) => {
    const product = item.product || item.bundle || item.addOn;
    const { businessInfo } = product;
    return businessInfo.shopShowItemPricing;
  };

  renderPricing = (item) => {
    if (!this.isShopShowItemPricing(item)) {
      return <span>Price Available Upon Request</span>;
    }
    const product = item.product || item.bundle || item.addOn;
    const productType = getProductType(item);
    const { locale } = this.props;
    const formatter = CurrencyFormatter({ locale });

    if (hasSelectedFlatPrice(product, productType)) {
      const selectedFlatPrice = getSelectedFlatPrice(product)
      return `${formatter.format(selectedFlatPrice.amount)} / Flat - ${selectedFlatPrice.name}`;
    }

    const firstAvailablePricingType = getFirstAvailablePricingType(product, productType);
    if (firstAvailablePricingType) {
      const pricingType = periodToPricingType[item.period];
      const periodPrice = `${formatter.format(product[pricingType])} / ${
        displayPeriodUnit[item.period]
      }`;
      if (productType === "items" || !product.purchasePrice) {
        if (product.productType === "add_ons") {
          return `${formatter.format(product.pricing)} / Each`;
        }
        return periodPrice;
      } else {
        const flatPrices = product.flatPrices.reduce((sum, fp) => { return sum + Number(fp.amount) }, 0) + Number(product.purchasePrice);
        return `${periodPrice}, ${formatter.format(
          flatPrices
        )} / Each`;
      }
    }

    return "Price Available Upon Request";
  };

  render() {
    const { customer, event } = this.props;
    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      items,
      rentalBundles,
      addOns,
      customerContactPhone,
      deliveryType,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry,
      pickupAddressStreetAddress1,
      pickupAddressStreetAddress2,
      pickupAddressCity,
      pickupAddressLocale,
      pickupAddressPostalCode,
      pickupAddressCountry,
      pickupSameAsDelivery
    } = event;

    const businessKeys = uniq([
      ...Object.keys(rentalBundles),
      ...Object.keys(items),
      ...Object.keys(addOns)
    ]);
    const timeZone = moment.tz.guess(eventEnd);

    return (
      <section className="summary">
        <div className="eventDetails">
          <div className="title">
            <h2>{event.name}</h2>
            <h3>
              <FormattedDate value={eventStart} month="numeric" day="numeric" />{" "}
              <FormattedTime
                value={eventStartTime}
                timeZone={timeZone}
                timeZoneName="short"
              />
              {" - "}
              <FormattedDate
                value={eventEnd}
                month="numeric"
                year="numeric"
                day="numeric"
              />{" "}
              <FormattedTime
                value={eventEndTime}
                timeZone={timeZone}
                timeZoneName="short"
              />
            </h3>
          </div>
          {customer.id && (
            <div className="contact">
              <label>Contact:</label>
              <strong>
                {customer.firstName} {customer.lastName}
              </strong>
              <div>{customer.email}</div>
              {customerContactPhone && (
                <div>{formatPhoneNumber(customerContactPhone)}</div>
              )}
              {customer.streetAddress1 && (
                <div>
                  {customer.streetAddress1}{" "}
                  {customer.streetAddress2 && (
                    <div>{customer.streetAddress2}</div>
                  )}
                  <div>
                    {customer.city}
                    {", "}
                    {customer.locale} {customer.postalCode}
                    {", "}
                    {customer.country}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="delivery">
            <label>{customerDeliveryTypes[deliveryType]}</label>
            {["default_delivery", "ship"].includes(deliveryType) && (
              <div>
                <div>
                  {deliveryAddressStreetAddress1}{" "}
                  {deliveryAddressStreetAddress2}
                </div>
                <div>
                  {deliveryAddressCity}, {deliveryAddressLocale}{" "}
                  {deliveryAddressPostalCode}, {deliveryAddressCountry}
                </div>
              </div>
            )}
          </div>

          {!pickupSameAsDelivery &&
            ["default_delivery", "custom_delivery"].includes(deliveryType) && (
            <div className="delivery">
              <label>Pick Up:</label>
              <div>
                <div>
                  {pickupAddressStreetAddress1} {pickupAddressStreetAddress2}
                </div>
                <div>
                  {pickupAddressCity}, {pickupAddressLocale}{" "}
                  {pickupAddressPostalCode}, {pickupAddressCountry}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="items">
          <h2>Items</h2>
          {businessKeys.map((key, index) => (
            <div key={index} className="companyItems">
              <strong>
                Items from: <span>{key}</span>
              </strong>
              <table className="grid thumbnail">
                <tbody>
                  {(items[key] || []).map((item, itemIndex) => (
                    <MediaBreakpoints
                      key={itemIndex}
                      desktop={
                        <tr>
                          <td className="image">
                            <figure>
                              {item.product.pictures &&
                              item.product.pictures[0] ? (
                                <Imgix
                                  src={item.product.pictures[0].imgixUrl}
                                  alt={item.product.name}
                                  width={510}
                                  height={288}
                                />
                              ) : (
                                <TapGoods />
                              )}
                            </figure>
                          </td>
                          <td>
                            {item.quantity}{" "}
                            <span>@ {this.renderPricing(item)}</span>
                            <br />
                            {item.name}
                          </td>
                          <td className="med right textSpace">
                            {this.isShopShowItemPricing(item) &&
                            item.selectedPrice > 0 ? (
                              <CurrencyLabel value={item.selectedPrice} />
                            ) : (
                              <span>Price Available Upon Request</span>
                            )}
                          </td>
                        </tr>
                      }
                      mobile={
                        <tr>
                          <td className="image">
                            <figure>
                              {item.product.pictures &&
                              item.product.pictures[0] ? (
                                <Imgix
                                  src={item.product.pictures[0].imgixUrl}
                                  alt={item.product.name}
                                  width={510}
                                  height={288}
                                />
                              ) : (
                                <TapGoods />
                              )}
                            </figure>
                          </td>
                          <td>
                            <div>
                              {item.quantity}{" "}
                              <span>@ {this.renderPricing(item)}</span>
                            </div>
                            <div className="normalText">{item.name}</div>
                            {item.selectedPrice > 0 ? (
                              <CurrencyLabel value={item.selectedPrice} />
                            ) : (
                              <span>Price Available Upon Request</span>
                            )}
                          </td>
                        </tr>
                      }
                    />
                  ))}
                  {(rentalBundles[key] || []).map((item, itemIndex) => (
                    <MediaBreakpoints
                      key={itemIndex}
                      desktop={
                        <tr>
                          <td className="image">
                            <figure>
                              {item.bundle.pictures &&
                              item.bundle.pictures[0] ? (
                                <Imgix
                                  src={item.bundle.pictures[0].imgixUrl}
                                  alt={item.bundle.name}
                                  width={510}
                                  height={288}
                                />
                              ) : (
                                <TapGoods />
                              )}
                            </figure>
                          </td>
                          <td>
                            {item.quantity}{" "}
                            <span>@ {this.renderPricing(item)}</span>
                            <br />
                            {item.name}
                          </td>
                          <td className="med right textSpace">
                            {item.selectedPrice > 0 ? (
                              <CurrencyLabel value={item.selectedPrice} />
                            ) : (
                              <span>Price Available Upon Request</span>
                            )}
                          </td>
                        </tr>
                      }
                      mobile={
                        <tr>
                          <td className="image">
                            <figure>
                              {item.bundle.pictures &&
                              item.bundle.pictures[0] ? (
                                <Imgix
                                  src={item.bundle.pictures[0].imgixUrl}
                                  alt={item.bundle.name}
                                  width={510}
                                  height={288}
                                />
                              ) : (
                                <TapGoods />
                              )}
                            </figure>
                          </td>
                          <td>
                            <div>
                              {item.quantity}{" "}
                              <span>@ {this.renderPricing(item)}</span>
                            </div>
                            <div className="normalText">{item.name}</div>
                            {item.selectedPrice > 0 ? (
                              <CurrencyLabel value={item.selectedPrice} />
                            ) : (
                              <span>Price Available Upon Request</span>
                            )}
                          </td>
                        </tr>
                      }
                    />
                  ))}
                  {(addOns[key] || []).map((item, itemIndex) => (
                    <MediaBreakpoints
                      key={itemIndex}
                      desktop={
                        <tr>
                          <td className="image">
                            <figure>
                              {item.addOn.pictures && item.addOn.pictures[0] ? (
                                <Imgix
                                  src={item.addOn.pictures[0].imgixUrl}
                                  alt={item.addOn.name}
                                  width={510}
                                  height={288}
                                />
                              ) : (
                                <TapGoods />
                              )}
                            </figure>
                          </td>
                          <td>
                            {item.quantity}{" "}
                            <span>@ {this.renderPricing(item)}</span>
                            <br />
                            {item.name}
                          </td>
                          <td className="med right textSpace">
                            {item.selectedPrice > 0 ? (
                              <CurrencyLabel value={item.selectedPrice} />
                            ) : (
                              <span>Price Available Upon Request</span>
                            )}
                          </td>
                        </tr>
                      }
                      mobile={
                        <tr>
                          <td className="image">
                            <figure>
                              {item.addOn.pictures && item.addOn.pictures[0] ? (
                                <Imgix
                                  src={item.addOn.pictures[0].imgixUrl}
                                  alt={item.addOn.name}
                                  width={510}
                                  height={288}
                                />
                              ) : (
                                <TapGoods />
                              )}
                            </figure>
                          </td>
                          <td>
                            <div>
                              {item.quantity}{" "}
                              <span>@ {this.renderPricing(item)}</span>
                            </div>
                            <div className="normalText">{item.name}</div>
                            {item.selectedPrice > 0 ? (
                              <CurrencyLabel value={item.selectedPrice} />
                            ) : (
                              <span>Price Available Upon Request</span>
                            )}
                          </td>
                        </tr>
                      }
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { locale } = state.serviceArea;
  const { event } = state.cart;
  const { customer } = state.customer;
  return { customer, event, locale };
};

export default connect(mapStateToProps, actions)(EventSummary);
