import React from "react";
import { NavLink } from "react-router-dom";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import {
  ProfileInfo,
  CreditCards,
  Documents,
  Receipts,
  ArrowRight
} from "Utils/SvgIcons";

class ProfileNav extends React.Component {
  render() {
    return (
      <MediaBreakpoints
        desktop={
          <aside>
            <nav className="sideNav">
              <NavLink exact to="/profile/info">
                Info
              </NavLink>
              <NavLink to="/profile/paymentMethods">Payment Methods</NavLink>
              <NavLink to="/profile/documents">Documents</NavLink>
              <NavLink to="/profile/paymentHistory">Payment History</NavLink>
            </nav>
          </aside>
        }
        mobile={
          <aside>
            <nav className="sideNav">
              <NavLink exact to="/profile/info">
                <ProfileInfo />
                Info
                <ArrowRight />
              </NavLink>
              <NavLink to="/profile/paymentMethods">
                <CreditCards />
                Payment Methods
                <ArrowRight />
              </NavLink>
              <NavLink to="/profile/documents">
                <Documents />
                Documents
                <ArrowRight />
              </NavLink>
              <NavLink to="/profile/paymentHistory">
                <Receipts />
                Payment History
                <ArrowRight />
              </NavLink>
            </nav>
          </aside>
        }
      />
    );
  }
}

export default ProfileNav;
