import React from "react";
import { FormattedDate } from "react-intl";
import CurrencyLabel from "Utils/CurrencyLabel";

const DetailedAmountRefunded = props => {
  return (
    <div>
      <label>Refund</label>
      <CurrencyLabel value={props.refund.amount} />
      <div>
        <label>
          <FormattedDate
            value={props.refund.createdAt}
            year="numeric"
            month="numeric"
            day="numeric"
          />
        </label>
      </div>
    </div>
  );
};

export default DetailedAmountRefunded;
