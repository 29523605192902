import React from "react";

const Suggestions = props => {
  const options = props.results.map(result => (
    <li key={result.id}>
      {result.productType === "categories" ? (
        <a onMouseDown={() => props.handleCategoryClick(result.id)}>
          In -> {result.name}
        </a>
      ) : (
        <a onMouseDown={props.handleClick}>{result.name}</a>
      )}
    </li>
  ));

  return props.results && props.results.length > 0 ? <ul>{options}</ul> : null;
};

export default Suggestions;
