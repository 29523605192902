import React from "react";
import { connect } from "react-redux";
import Checkbox from "Utils/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { ArrowUp, ArrowDown, Close, Filter, Delete } from "Utils/SvgIcons";
import * as actions from "Actions";
import classnames from "classnames";
import DateSelector from "./DateSelector";
import Chip from "@material-ui/core/Chip";
import ScrollArea from "Utils/react-scrollbar";
import Modal from "Utils/Modal";
import ColorPicker from "Utils/ColorPicker";
import Slider, { createSliderWithTooltip } from "rc-slider";

const Range = createSliderWithTooltip(Slider.Range);

// This component is only called from the mobile size, via
class CategoryFilterMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOpen: false,
      open: {}
    };
    this.handleFilterClose = this.handleFilterClose.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.renderChips = this.renderChips.bind(this);
  }

  toggleOpen = category => {
    this.setState({
      open: {
        ...this.state.open,
        [category.id]: !(this.state.open[category.id] || false)
      }
    });
  };
  handleFilterClose() {
    this.setState({ filterOpen: false });
  }
  toggleFilter() {
    this.setState({ filterOpen: !this.state.filterOpen });
  }

  // Render a chip
  renderChips() {
    const { categories, selectedCategories, handleDeleteCategory } = this.props;

    const chips = categories.reduce((chipsHolder, category) => {
      let chipContainer = chipsHolder.slice();
      if (selectedCategories.includes(String(category.id))) {
        console.log(category);
        chipContainer.push(
          <Chip
            className="chip"
            label={category.name}
            onDelete={() => handleDeleteCategory(category)}
            deleteIcon={<Delete />}
          />
        );
      }
      return chipContainer;
    }, []);

    return chips;
  }

  renderCategories = () => {
    const {
      categories,
      handleCategorySelected,
      handleSubCategorySelected,
      selectedCategories,
      selectedSubCategories
    } = this.props;
    let output = [];
    categories.forEach(category => {
      output.push(
        <ListItem
          className={classnames({
            open: this.state.open[category.id]
          })}
        >
          <Checkbox
            onCheck={(event, isChecked) =>
              handleCategorySelected(isChecked, category)
            }
            checked={selectedCategories.includes(String(category.id))}
          />
          <ListItemText primary={category.name} disableTypography />
          <a onClick={() => this.toggleOpen(category)}>
            {this.state.open[category.id] ? <ArrowUp /> : <ArrowDown />}
          </a>
        </ListItem>
      );
      output.push(
        <Collapse
          in={this.state.open[category.id] || false}
          transitionDuration="auto"
          unmountOnExit
        >
          <List className="checkList">
            {category.subCategories.map(subCategory => (
              <ListItem key={subCategory.id}>
                <Checkbox
                  onCheck={(event, isChecked) =>
                    handleSubCategorySelected(isChecked, category, subCategory)
                  }
                  checked={selectedSubCategories.includes(
                    String(subCategory.id)
                  )}
                />
                <ListItemText primary={subCategory.name} disableTypography />
              </ListItem>
            ))}
          </List>
        </Collapse>
      );
    });

    return output;
  };

  render() {
    const {
      onColorSelection,
      selectedColors,
      clearColorSelection,
      onQuantitySelection,
      selectedMinQuantity,
      selectedMaxQuantity
    } = this.props;
    const quantityValues = [+selectedMinQuantity, +selectedMaxQuantity];

    return (
      <aside className="mobileFilter">
        <a onClick={this.toggleFilter}>
          <Filter />
        </a>
        {/* Show Categories as Chips. */}
        <div className="chipWrapper">{this.renderChips()}</div>

        <Modal
          className="filterModal"
          open={this.state.filterOpen}
          toggle={this.toggleFilter}
          title="Filter"
        >
          <a className="linkClose" onClick={this.handleFilterClose}>
            <Close />
          </a>
          <h3>Filter</h3>
          <DateSelector />
          <div className="quantityFilter">
            Quantity
            <Range
              allowCross={false}
              value={quantityValues}
              onChange={onQuantitySelection}
              max={500}
              tipProps={{ visible: true }}
            />
          </div>
          <div className="colorFilter">
            Color
            <ColorPicker
              onColorSelection={onColorSelection}
              selectedColorIds={selectedColors}
              clearColorSelection={clearColorSelection}
            />
          </div>
          <ScrollArea speed={0.8} horizontal={false}>
            <List className="checkList">{this.renderCategories()}</List>
          </ScrollArea>
        </Modal>
      </aside>
    );
  }
}

const mapStateToProps = state => {
  const categories = state.categoryList[`itemsCategories`] || [];
  return { categories };
};

export default connect(mapStateToProps, actions)(CategoryFilterMobile);
