import React from "react";
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import StandardSelectField from "Utils/redux_form_inputs/StandardSelectField";
import StandardRegionDropdown from "Utils/redux_form_inputs/StandardRegionDropdown";
import Validator from "HelperFunctions/validator";
import { Link } from "react-router-dom";
import { setErrors } from "Actions";

// Redux-form validations written in json

const validations = {
  country: {
    required: {
      value: true,
      message: "Country is required"
    }
  },
  street_address1: {
    required: {
      value: true,
      message: "Address is required"
    }
  },
  city: {
    required: {
      value: true,
      message: "City is required"
    }
  },
  locale: {
    required: {
      value: true,
      message: "State is required"
    }
  },
  postal_code: {
    required: {
      value: true,
      message: "Zip code is required"
    }
  },
  payment_method_type: {
    required: {
      value: true,
      message: "Bank Type is required"
    }
  },
  check_type: {
    required: {
      value: true,
      message: "Check Type is required"
    }
  },
  bank_number: {
    required: {
      value: true,
      message: "Routing Number is invalid"
    }
  },
  account_number: {
    required: {
      value: true,
      message: "Account Number is required"
    }
  },
  first_name: {
    required: {
      value: true,
      message: "First Name is required"
    }
  },
  last_name: {
    required: {
      value: true,
      message: "Last Name is required"
    }
  }
};

// This HOC has access to our own redux, plus it pulls in redux-form HOC,
// which does the validations for us.

class WorldpayBankForm extends React.Component {
  render() {
    const {
      handleSubmit,
      type,
      country,
      check_type
    } = this.props; // From Redux

    console.log(this.props)

    return (
      <form onSubmit={handleSubmit} className="form">
        <div className="fields">
          <Field name="country" component={StandardSelectField}>
            <option value="">Select country</option>
            <option value="USA">United States</option>
            <option value="CAN">Canada</option>
          </Field>
          <Field
            name="street_address1"
            placeholder="Street Address"
            component={StandardTextField}
            className="lg"
            type="text"
          />
          <Field
            name="street_address2"
            placeholder="Suite #"
            component={StandardTextField}
            className="sm"
            type="text"
          />
          <Field
            name="city"
            placeholder="City"
            component={StandardTextField}
            className="med"
            type="text"
          />
          <Field
            name="locale"
            defaultOptionLabel={
              country === "USA" ? "State" : "Province"
            }
            className="sm"
            disableWhenEmpty={true}
            countryValueType="short"
            labelType="short"
            valueType="short"
            country={
              country ? country : "USA"
            }
            component={StandardRegionDropdown}
          />
          <Field
            name="postal_code"
            placeholder="Postal Code"
            className="sm"
            component={StandardTextField}
            type="text"
          />
          <Field name="payment_method_type" component={StandardSelectField}>
            <option>Select Payment Method Type</option>
            <option value="Checking">Checking</option>
            <option value="Savings">Saving</option> 
          </Field>
          <Field name="check_type" component={StandardSelectField}>
            <option>Check Type</option>
            <option value="Personal">Personal</option>
            <option value="Business">Business</option>
          </Field>
          {check_type === "Business" &&
            <Field
              name="company_name"
              placeholder="Company Name"
              component={StandardTextField}
              type="text"
            />
          }
          <Field
            name="bank_number"
            placeholder="Routing Number"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="account_number"
            placeholder="Account Number"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="first_name"
            placeholder="First name of the bank account"
            component={StandardTextField}
            type="text"
          />
          <Field
            name="last_name"
            placeholder="Last name of the bank account"
            component={StandardTextField}
            type="text"
          />
        </div>
        {type === "checkout" ? (
          <div className="actions">
            <button type="submit" className="btnIcon full">
              Submit Payment
            </button>
          </div>
        ) : (
          <div className="actions">
            <button type="submit" className="btn">
              Add Bank Account
            </button>
            <Link to="/profile/paymentMethods" className="btn cancel">
              Cancel
            </Link>
          </div>
        )}
      </form>
    );
  }
}

// Decorate with redux-form
WorldpayBankForm = reduxForm({
  form: 'NewBankForm', // a unique identifier for this form
  validate: Validator(validations),
  onSubmitFail: (errors, dispatch, submitError, props) => {
    if (errors) {
      dispatch(setErrors(errors));
    }
  }
})(WorldpayBankForm)

// Decorate with connect to read form values
const selector = formValueSelector('NewBankForm') // <-- same as form name
WorldpayBankForm = connect(state => {
  const country = selector(state, 'country')
  const check_type = selector(state, 'check_type')
  return {
    country,
    check_type
  }
})(WorldpayBankForm)

export default WorldpayBankForm
