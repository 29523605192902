import React from "react";
import Checkbox from "Utils/Checkbox";
import { connect } from "react-redux";

class ColorPicker extends React.Component {
  render() {
    const {
      colors,
      selectedColorIds,
      onColorSelection,
      clearColorSelection
    } = this.props;

    return (
      <div className="colorPicker">
        <a onClick={(_event, isChecked) => clearColorSelection(isChecked)}>
          Show All
        </a>
        <ul>
          {colors.map(({ id, name }) => (
            <li key={id} className={name}>
              <Checkbox
                onCheck={(_event, isChecked) => onColorSelection(isChecked, id)}
                checked={selectedColorIds.includes(id.toString())}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { colors } = state.colorList;
  return { colors };
};

export default connect(mapStateToProps)(ColorPicker);
