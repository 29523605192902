import React from "react";
import Modal from "Utils/Modal";
import SignaturePad from "Utils/SignaturePad";
import ScrollArea from "Utils/react-scrollbar";

class SignatureModal extends React.Component {
  state = {
    signature: null,
    signerName: ""
  };

  saveSignature = signature => {
    this.setState({
      signature: signature
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = () => {
    const { onSubmitSignature } = this.props;
    const { signature, signerName } = this.state;
    onSubmitSignature({ signature, signerName });
  };

  render() {
    const { rental, open, toggle } = this.props;
    const { signature, signerName } = this.state;
    return (
      <Modal
        open={open}
        large={true}
        toggle={toggle}
        title="Rental Signature"
        actions={[
          <button key={1} className="btn" onClick={this.handleSubmit}>
            Accept Signature
          </button>,
          <button key={2} className="btn cancel" onClick={toggle}>
            Cancel
          </button>
        ]}
      >
        <ScrollArea speed={0.8} horizontal={false}>
          <section
            dangerouslySetInnerHTML={{
              __html: rental.rentalAgreement ? rental.rentalAgreement : ""
            }}
          />
        </ScrollArea>
        <SignaturePad
          saveSignature={this.saveSignature}
          signature={signature}
        />
        <div className="form">
          <div className="fields">
            <input
              type="text"
              name="signerName"
              value={signerName}
              placeholder="Type Full Name"
              onChange={this.handleChange}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default SignatureModal;
