import React from "react";
import RCTimePicker from "rc-time-picker";
import moment from "moment";

const now = moment()
  .hour(0)
  .minute(0);

const formatDate = date => {
  if (date) {
    const time = moment(date);
    return time;
  }
  return null;
};

class TimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(value) {
    if (value && value.isValid()) {
      const date = value.toDate();
      const FakeEvent = {
        target: {
          value: date,
          name: this.props.name
        }
      };
      this.props.onChange(FakeEvent);
    }
  }
  render() {
    const { className, value, disabled } = this.props;
    return (
      <RCTimePicker
        showSecond={false}
        disabled={disabled}
        defaultValue={now}
        className={className}
        value={formatDate(value)}
        onChange={this.handleChange}
        format={"h:mm a"}
        use12Hours
      />
    );
  }
}

export default TimePicker;
