import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import moment from "moment";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import { FormattedDate, FormattedTime } from "react-intl";
import { TapGoods } from "Utils/SvgIcons";
import {
  periodToPricingType,
  displayPeriodUnit,
  customerDeliveryTypes
} from "Constants/displayConstants";
import { formatPhoneNumber } from "HelperFunctions/general";
import {
  getProductType,
  hasSelectedFlatPrice,
  getSelectedFlatPrice
} from "HelperFunctions/rental_items";
import Imgix from "react-imgix";
import CurrencyLabel from "Utils/CurrencyLabel";
import CurrencyFormatter from "HelperFunctions/CurrencyFormatter";

class EventSummary extends React.Component {
  renderPricing = item => {
    const productType = getProductType(item);
    const { locale } = this.props;
    const formatter = CurrencyFormatter({ locale });

    if (hasSelectedFlatPrice(item, productType)) {
      const selectedFlatPrice = getSelectedFlatPrice(item)
      return `${formatter.format(selectedFlatPrice.amount)} / Flat - ${selectedFlatPrice.name}`;
    } else {
      const pricingType = periodToPricingType[item.period];
      const product = item.product ? item.product : item.bundle;
      return `${formatter.format(product[pricingType])} / ${
        displayPeriodUnit[item.period]
      }`;
    }
  };
  render() {
    const { customer, event, locale } = this.props;
    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      items,
      rentalBundles,
      deliveryType,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry
    } = event;
    const timeZone = moment.tz.guess(eventEnd);

    return (
      <section className="summary">
        <div className="eventDetails">
          <div className="title">
            <h2>{event.name}</h2>
            <h3>
              <FormattedDate value={eventStart} month="numeric" day="numeric" />{" "}
              <FormattedTime
                value={eventStartTime}
                timeZone={timeZone}
                timeZoneName="short"
              />
              {" - "}
              <FormattedDate
                value={eventEnd}
                month="numeric"
                year="numeric"
                day="numeric"
              />{" "}
              <FormattedTime
                value={eventEndTime}
                timeZone={timeZone}
                timeZoneName="short"
              />
            </h3>
          </div>
          {customer.id && (
            <div className="contact">
              <label>Contact:</label>
              <strong>
                {customer.firstName} {customer.lastName}
              </strong>
              <div>{customer.email}</div>
              {customer.cell && <div>{formatPhoneNumber(customer.cell)}</div>}
              {customer.streetAddress1 && (
                <div>
                  {customer.streetAddress1}{" "}
                  {customer.streetAddress2 && (
                    <div>{customer.streetAddress2}</div>
                  )}
                  <div>
                    {customer.city}
                    {", "}
                    {customer.locale} {customer.postalCode}
                    {", "}
                    {customer.country}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="delivery">
            <label>{customerDeliveryTypes[deliveryType]}</label>
            {deliveryType === "default_delivery" && (
              <div>
                <div>
                  {deliveryAddressStreetAddress1}{" "}
                  {deliveryAddressStreetAddress2}
                </div>
                <div>
                  {deliveryAddressCity}, {deliveryAddressLocale}{" "}
                  {deliveryAddressPostalCode}, {deliveryAddressCountry}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="items">
          <h2>Items</h2>
          <div className="companyItems">
            <strong>
              Summary: <span>{event.name}</span>
            </strong>
            <MediaBreakpoints
              desktop={
                <table className="grid thumbnail">
                  <tbody>
                    {items.map((item, itemIndex) => {
                      if (item._destroy === "1") {
                        return null;
                      } else {
                        return (
                          <tr key={itemIndex}>
                            <td className="image">
                              <figure>
                                {item.product &&
                                item.product.pictures &&
                                item.product.pictures[0] ? (
                                  <Imgix
                                    src={item.product.pictures[0].imgixUrl}
                                    alt={item.product.name}
                                    width={510}
                                    height={288}
                                  />
                                ) : (
                                  <TapGoods />
                                )}
                              </figure>
                            </td>
                            <td>
                              {item.quantity}{" "}
                              <span>@ {this.renderPricing(item)}</span>
                              <br />
                              {item.name}
                            </td>
                            <td className="med right">
                              <CurrencyLabel
                                value={item.selectedPrice}
                                locale={locale}
                              />
                            </td>
                          </tr>
                        );
                      }
                    })}
                    {rentalBundles.map((item, itemIndex) => {
                      if (item._destroy === "1") {
                        return null;
                      } else {
                        return (
                          <tr key={itemIndex}>
                            <td className="image">
                              <figure>
                                {item.bundle &&
                                item.bundle.pictures &&
                                item.bundle.pictures[0] ? (
                                  <Imgix
                                    src={item.bundle.pictures[0].imgixUrl}
                                    alt={item.bundle.name}
                                    width={510}
                                    height={288}
                                  />
                                ) : (
                                  <TapGoods />
                                )}
                              </figure>
                            </td>
                            <td>
                              {item.quantity}{" "}
                              <span>@ {this.renderPricing(item)}</span>
                              <br />
                              {item.name}
                            </td>
                            <td className="sm right">
                              <CurrencyLabel
                                value={item.selectedPrice}
                                locale={locale}
                              />
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              }
              mobile={
                <table className="grid thumbnail">
                  <tbody>
                    {items.map((item, itemIndex) => {
                      if (item._destroy === "1") {
                        return null;
                      } else {
                        return (
                          <tr key={itemIndex}>
                            <td className="image">
                              <figure>
                                {item.product &&
                                item.product.pictures &&
                                item.product.pictures[0] ? (
                                  <Imgix
                                    src={item.product.pictures[0].imgixUrl}
                                    alt={item.product.name}
                                    width={510}
                                    height={288}
                                  />
                                ) : (
                                  <TapGoods />
                                )}
                              </figure>
                            </td>
                            <td>
                              <div>
                                {item.quantity}{" "}
                                <span>@ {this.renderPricing(item)}</span>
                              </div>
                              <div>{item.name}</div>
                              <CurrencyLabel
                                value={item.selectedPrice}
                                locale={locale}
                              />
                            </td>
                          </tr>
                        );
                      }
                    })}
                    {rentalBundles.map((item, itemIndex) => {
                      if (item._destroy === "1") {
                        return null;
                      } else {
                        return (
                          <tr key={itemIndex}>
                            <td className="image">
                              <figure>
                                {item.bundle &&
                                item.bundle.pictures &&
                                item.bundle.pictures[0] ? (
                                  <Imgix
                                    src={item.bundle.pictures[0].imgixUrl}
                                    alt={item.bundle.name}
                                    width={510}
                                    height={288}
                                  />
                                ) : (
                                  <TapGoods />
                                )}
                              </figure>
                            </td>
                            <td>
                              <div>
                                {item.quantity}{" "}
                                <span>@ {this.renderPricing(item)}</span>
                              </div>
                              <div>{item.name}</div>
                              <CurrencyLabel
                                value={item.selectedPrice}
                                locale={locale}
                              />
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              }
            />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customer } = state.customer;
  return { customer };
};

export default connect(mapStateToProps, actions)(EventSummary);
