import React from "react";
import EventFormFields from "./EventFormFields";

class EventForm extends React.Component {
  handleChange = event => {
    const { setEventProps, calculateDefaultDeliveryPrice } = this.props;
    const { name, value } = event.target;
    const newEvent = Object.assign(
      { [name]: value },
      name === "deliveryAddressCountry" && { deliveryAddressLocale: "" },
      name === "pickupAddressCountry" && { pickupAddressLocale: "" }
    );
    if(this.props.event && newEvent){
      const newEventObj = { ...this.props.event, ...newEvent };
      const {
        deliveryAddressCountry, 
        deliveryAddressStreetAddress1, 
        deliveryAddressStreetAddress2, 
        deliveryAddressCity, 
        deliveryAddressPostalCode,
        deliveryAddressLocale
      } = newEventObj;
      deliveryAddressCountry && 
      deliveryAddressStreetAddress1 && 
      deliveryAddressStreetAddress2 && 
      deliveryAddressCity && 
      deliveryAddressPostalCode &&
      deliveryAddressLocale &&
      calculateDefaultDeliveryPrice({
        event: newEventObj,
        onSuccess: deliveryCost => {
          setEventProps({
            deliveryCost: deliveryCost
          });
        }
      });
    }
    setEventProps(newEvent);
  };

  handlePickupSameCheck = (event) => {
    const { setEventProps } = this.props;
    const { name, checked } = event.target;
    let newEvent = Object.assign(
      { [name]: checked },
      name === "deliveryAddressCountry" && { deliveryAddressLocale: "" },
      name === "pickupAddressCountry" && { pickupAddressLocale: "" }
    );
    if (checked) {
      newEvent = {
        ...newEvent,
        pickupAddressLocationName: "",
        pickupAddressStreetAddress1: "",
        pickupAddressStreetAddress2: "",
        pickupAddressCity: "",
        pickupAddressLocale: "",
        pickupAddressPostalCode: "",
        pickupAddressCountry: ""
      };
    }

    setEventProps(newEvent);
  };

  render() {
    const { event } = this.props;

    return (
      <section className="eventForm">
        <h2>Delivery</h2>
        <EventFormFields
          event={event}
          handleChange={this.handleChange}
          handlePickupSameCheck = {this.handlePickupSameCheck}
        />
      </section>
    );
  }
}
export default EventForm;
