import React from "react";
import { withRouter, Link, NavLink } from "react-router-dom";
import { ArrowUp, ArrowDown, Cart, Heart } from "Utils/SvgIcons";
import * as actions from "Actions";
import { connect } from "react-redux";
import MessagesTab from "../MessagesTab";
import { filterFromPropsAndState } from "HelperFunctions/urls";
import Imgix from "react-imgix";

/*eslint-disable */
Object.defineProperty(Array.prototype, "flat", {
  value: function(depth = 1) {
    return this.reduce(function(flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) && depth - 1
          ? toFlatten.flat(depth - 1)
          : toFlatten
      );
    }, []);
  }
});

class SignedInNavAra extends React.Component {
  state = {
    profile: {
      open: false
    },
    zipCode: {
      open: false,
      value: ""
    }
  };
  componentWillMount() {
    this.setState({
      zipCode: {
        ...this.state.zipCode,
        value: this.props.zipCode
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      zipCode: {
        ...this.state.zipCode,
        value: nextProps.zipCode
      }
    });
  }
  toggleProfileMenu = event => {
    this.setState({
      profile: {
        open: !this.state.profile.open,
        anchorEl: event.currentTarget
      }
    });
  };
  toggleZipCode = event => {
    this.setState({
      zipCode: {
        ...this.state.zipCode,
        open: !this.state.zipCode.open,
        anchorEl: event.currentTarget
      }
    });
  };
  handleZipCodeChange = event => {
    this.setState({
      zipCode: {
        ...this.state.zipCode,
        value: event.target.value
      }
    });
  };
  handleZipCodeSubmit = event => {
    event.preventDefault();
    this.props.setZipCode(this.state.zipCode.value);
    this.props.reloadInventory(this.props.filter);
    this.props.fetchCustomPages(this.state.zipCode.value);
  };

  render() {
    const { user, event, signOutUser } = this.props;
    const { featuredCustomPages, zipCode } = this.state;
    const favoriteCustomPages = featuredCustomPages.filter(fcp =>
      fcp.favoritedCustomerIds.includes(user.id)
    );
    const cartItemCount = Object.values(event.items)
      .flat()
      .reduce(sum => sum + 1, 0);
    const cartBundleCount = Object.values(event.rentalBundles)
      .flat()
      .reduce(sum => sum + 1, 0);
    const cartAddOnCount = Object.values(event.addOns)
      .flat()
      .reduce(sum => sum + 1, 0);
    const cartCount = cartItemCount + cartBundleCount + cartAddOnCount;
    return (
      <nav className="mainMenu">
        <Link className="araLogo" to="/ara" />
        <div className="links">
          <NavLink exact to="/ara">
            Home
          </NavLink>
          <NavLink to="/shop">Shop</NavLink>
          <NavLink to="#">Discover</NavLink>
          <NavLink to="/events">Events</NavLink>
          <div
            className="profileMenu"
            onMouseEnter={this.toggleProfileMenu}
            onMouseLeave={this.toggleProfileMenu}
            onClick={this.toggleProfileMenu}
          >
            <figure>
              {user.picture ? (
                <Imgix
                  src={user.picture.imgixUrl}
                  alt={user.firstName}
                  height={200}
                  width={200}
                />
              ) : (
                <label>
                  {user.firstName.charAt(0)}
                  {user.lastName.charAt(0)}
                </label>
              )}
            </figure>
            {this.state.profile.open ? <ArrowUp /> : <ArrowDown />}
            {this.state.profile.open && (
              <ul>
                <li>
                  <a
                    href="//www.tapgoods.com/pro/terms-conditions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="//www.tapgoods.com/pro/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <Link to="/profile">My Profile Settings</Link>
                </li>
                <li>
                  <a onClick={signOutUser}>Sign Out</a>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="callouts">
          <div
            className="location"
            onMouseEnter={this.toggleZipCode}
            onMouseLeave={this.toggleZipCode}
          >
            {zipCode.value === "" ? "No Zip" : zipCode.value}
            {this.state.zipCode.open ? <ArrowUp /> : <ArrowDown />}
            {this.state.zipCode.open && (
              <div>
                <form onSubmit={this.handleZipCodeSubmit}>
                  <input
                    type="text"
                    placeholder="Zip"
                    value={this.state.zipCode.value}
                    onChange={this.handleZipCodeChange}
                  />
                  <input type="submit" value="Update Zip" className="btn" />
                </form>
              </div>
            )}
          </div>
          <MessagesTab />
          <NavLink className="icon cart" to="/cart">
            <Cart />
            <label>{cartCount > 0 && cartCount}</label>
          </NavLink>
        </div>
      </nav>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { user } = state.auth;
  const { customPages } = state;
  const { event } = state.cart;
  const { zipCode } = state.serviceArea;
  const newFilter = filterFromPropsAndState(ownProps);
  const selectedCategories = newFilter.selectedCategories
    ? newFilter.selectedCategories.split(",")
    : [];
  const selectedSubCategories = newFilter.selectedSubCategories
    ? newFilter.selectedSubCategories.split(",")
    : [];

  return {
    filter: {
      ...newFilter,
      selectedCategories,
      selectedSubCategories
    },
    user,
    event,
    zipCode,
    customPages
  };
};

export default withRouter(connect(mapStateToProps, actions)(SignedInNavAra));
