import axios from "axios";
import { history as browserHistory } from "Components/Routes";

import { saveAuthToken, getAuthToken, saveError } from "Api";
import { reset } from "redux-form";
import { setErrors } from "./SnackbarActions";

import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";

import { fetchProfile } from "./CustomerActions";

// Save the customer profile to the backend, using the values
// saved from redux-form HOC into values

export const submitCreditCard = values => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Creating a new credit card..."));
    const creditCard = values;

    const data = { credit_card: creditCard };
    return axios
      .post(
        process.env.REACT_APP_API_DOMAIN + "/api/portal/credit_cards",
        data,
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        saveAuthToken(response.headers);
        dispatch(fetchProfile());
        dispatch(closeLoadingSpinner());
        dispatch(reset("CreditCard"));
        browserHistory.push("/profile/paymentMethods");
      })
      .catch(err => {
        console.log(err);
        dispatch(setErrors(err.response.data));
        dispatch(closeLoadingSpinner());
      });
  };
};

export const deleteCreditCard = id => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Deleting credit_card..."));
    return axios
      .delete(
        process.env.REACT_APP_API_DOMAIN + "/api/portal/credit_cards/" + id,
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        saveAuthToken(response.headers);
        dispatch(closeLoadingSpinner());
        dispatch(fetchProfile());
      })
      .catch(error => {
        saveError(error);
        dispatch(closeLoadingSpinner());
      });
  };
};
