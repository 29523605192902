import converter from "json-style-converter/es5";

export function getIndex(value, arr, prop) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i][prop] === value) {
      return i;
    }
  }
  return -1; //to handle the case where the value doesn't exist
}
export function nestedFieldStateFor(name, count, base_state) {
  let holder = [];
  for (var i = 0; i < count; i++) {
    holder.push({
      key: i,
      [name]: base_state
    });
  }
  return holder;
}

export function newStateForNested(prevArr, key, name, newModelState) {
  let array = prevArr.slice();
  let index = getIndex(key, array, "key");
  if (index === -1) {
    array.push({
      key: key,
      [name]: newModelState
    });
  } else {
    let oldModelState = array[index];
    oldModelState[name] = Object.assign(oldModelState[name], newModelState);
  }
  return array;
}

export function removeNestedObject(prevArr, key, name) {
  let destroyObject = {
    _destroy: "1"
  };
  let newState = newStateForNested(prevArr, key, name, destroyObject);
  return newState;
}

export function canRemoveObjectFromArray(array, name) {
  let removedObjectsCount = 0;
  for (let object of array) {
    if (object[name]._destroy === "1") {
      removedObjectsCount++;
    }
  }
  return removedObjectsCount < array.length - 1;
}

export function validObjectsCountOfArray(array, name) {
  let removedObjectsCount = 0;
  for (let object of array) {
    if (object[name]._destroy === "1") {
      removedObjectsCount++;
    }
  }
  return array.length - removedObjectsCount;
}

export function nestedStateForRails(containerArray, indName) {
  let attributes = {};
  for (let indObj of containerArray) {
    attributes[indObj.key] = indObj[indName];
  }
  return attributes;
}
export function nestedStateForRailsWithConversion(containerArray, indName) {
  let attributes = {};
  let newArr = converter.camelToSnakeCase(containerArray);
  let name = converter.camelToSnakeCase(indName);
  for (let indObj of newArr) {
    let newObj = indObj[name];
    attributes[indObj.key] = newObj;
  }
  return attributes;
}

export function prepopulateArray(nextKey, array, name) {
  let holder = [];
  for (let item of array) {
    holder.push({
      key: nextKey,
      [name]: item
    });
    nextKey += 1;
  }
  return [holder, nextKey];
}

export function prepopulateArrayWithoutIds(nextKey, array, name) {
  let holder = [];
  for (let item of array) {
    let tempObj = {
      key: nextKey,
      [name]: item
    };
    tempObj[name].id = "";
    holder.push(tempObj);
    nextKey += 1;
  }
  return [holder, nextKey];
}

export function objectToFormData(obj, form, namespace) {
  var fd = form || new FormData();
  var formKey;
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (
        typeof obj[property] === "object" &&
        !(
          obj[property] instanceof File ||
          obj[property] instanceof Blob ||
          obj[property] instanceof Date
        )
      ) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
}

export function updateObject(oldObject, newValues) {
  // Encapsulate the idea of passing a new object as the first parameter
  // to Object.assign to ensure we correctly copy data instead of mutating
  return Object.assign({}, oldObject, newValues);
}

export function updateItemInArray(array, itemKey, updateItemCallback) {
  const updatedItems = array.map(item => {
    if (item.key !== itemKey) {
      // Since we only want to update one item, preserve all others as they are now
      return item;
    }

    // Use the provided callback to create an updated item
    const updatedItem = updateItemCallback(item);
    return updatedItem;
  });

  return updatedItems;
}
