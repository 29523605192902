import axios from "axios";
import {
  FETCH_INVENTORY_CATEGORIES_REQUEST,
  FETCH_INVENTORY_CATEGORIES_SUCCESS,
  FETCH_INVENTORY_CATEGORIES_FAILURE,
  FETCH_FEATURED_CATEGORIES_SUCCESS,
  FETCH_ALL_CATEGORIES_SUCCESS
} from "Constants/redux";
import { ROOT_URL } from "Api";

export function fetchInventoryCategories(
  productType,
  query = "",
  locationId = ""
) {
  return (dispatch, getState) => {
    const { zipCode } = getState().serviceArea;
    dispatch({ type: FETCH_INVENTORY_CATEGORIES_REQUEST });
    axios
      .get(
        `${ROOT_URL}/marketplace/inventories/categories?location_id=${locationId}&query=${query}&zip_code=${zipCode}`
      )
      .then(response => {
        return dispatch({
          productType,
          response,
          type: FETCH_INVENTORY_CATEGORIES_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        dispatch({
          productType,
          error,
          type: FETCH_INVENTORY_CATEGORIES_FAILURE
        });
      });
  };
}

export function fetchFeaturedCategories() {
  return dispatch => {
    axios.get(`${ROOT_URL}/marketplace/featured_categories`).then(response => {
      return dispatch({
        response,
        type: FETCH_FEATURED_CATEGORIES_SUCCESS
      });
    });
  };
}

export function fetchAllCategories() {
  return dispatch => {
    axios.get(`${ROOT_URL}/categories`).then(response => {
      return dispatch({
        response,
        type: FETCH_ALL_CATEGORIES_SUCCESS
      });
    });
  };
}
