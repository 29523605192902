export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (var i = arr1.length; i--; ) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};

export const flattenArray = array => {
  return [].concat.apply([], array);
};

export const combineDateAndTime = (date, time) => {
  let combinedDate;
  if (date && time) {
    combinedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes()
    );
  } else if (date) {
    combinedDate = date;
  } else if (time) {
    combinedDate = time;
  }
  return combinedDate;
};

export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export function getTimeOfDayClass() {
  const hr = new Date().getHours();
  if (hr >= 4 && hr < 12) {
    return "morning";
  } else if (hr >= 12 && hr < 19) {
    return "afternoon";
  } else {
    return "evening";
  }
}

export function isSupportedBroswer() {
  return !isIE();
}
export function isChrome() {
  return (
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  );
}

function isIE() {
  const ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

  return is_ie;
}
export const toNumber = value => Number(value) || value;

export const restoreCart = () => {
  const cartEvent = JSON.parse(localStorage.getItem("cartEvent") || null);

  if (!cartEvent) {
    return null;
  }

  if (cartEvent && !cartEvent.version) {
    localStorage.removeItem("cartEvent");
    return null;
  }

  const { eventStart, eventStartTime, eventEnd, eventEndTime } = cartEvent;

  let start = new Date();
  let end = new Date();
  start.setHours(7, 0, 0, 0);
  end.setHours(16, 0, 0, 0);

  return {
    ...cartEvent,
    eventStart: eventStart ? new Date(eventStart) : start,
    eventStartTime: eventStartTime ? new Date(eventStartTime) : start,
    eventEnd: eventEnd ? new Date(eventEnd) : end,
    eventEndTime: eventEndTime ? new Date(eventEndTime) : end
  };
};

export const debounce = (fn, delay) => {
  var timer = null;
  return function() {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(context, args);
    }, delay);
  };
};
