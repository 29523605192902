import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import converter from "json-style-converter/es5";
import * as actions from "Actions";
import DatePicker from "Utils/DatePicker";
import TimePicker from "Utils/TimePicker";
import { ListView, ImageView } from "Utils/SvgIcons";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import { combineDateAndTime, toNumber } from "HelperFunctions/general";
import { showSelectedPrice } from "HelperFunctions/rental_items";
import EventItem from "./EventItem";
import CurrencyLabel from "Utils/CurrencyLabel";
import axios from "axios";
import { getAuthToken } from "Api";
import { history } from "Components/Routes";
import { getItemsWithNewPricesForRental } from "HelperFunctions/rental_items";

class EventItems extends Component {
  componentDidMount() {
    const { event } = this.props;
    if (event) {
      this.updatePrices(event);
    }
  }

  updatePrices = (event) => {
    const { setEventProps } = this.props;
    const { items, rentalBundles } = getItemsWithNewPricesForRental(event);
    event.items = items;
    event.rentalBundles = rentalBundles;
    setEventProps(event);
  }

  handleChange = e => {
    const { event, setEventProps } = this.props;
    const { name, value } = e.target;
    const newEvent = {
      [name]: value
    };
    setEventProps(newEvent);
    this.updatePrices({...event, ...newEvent});
    event.items && event.items.forEach((item, itemIndex) => {
      this.updateItemData({...event, ...newEvent}, item, itemIndex);
    });
  };

  updateItemData = (newEvent, item, itemIndex) => {
    const { setEventProps } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = newEvent;
    let newItems = newEvent.items.slice();
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      quantity: item.quantity
    };
    const selected = showSelectedPrice(
      newItems[itemIndex],
      newItems[itemIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      "items"
    );
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period
    };
    setEventProps({
      items: newItems
    });
  }

  handleItemChange = (e, itemIndex) => {
    const { event, setEventProps, recalcDamageWaiverFee } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { name, value } = e.target;
    let newItems = event.items.slice();
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      [name]: toNumber(value)
    };
    const selected = showSelectedPrice(
      newItems[itemIndex].product,
      newItems[itemIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      "items"
    );
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period
    };
    recalcDamageWaiverFee({
      items: newItems,
      onSuccess: defaultDamage => {
        setEventProps({
          items: newItems,
          damageWaiverFeeTotal: defaultDamage
        });
      }
    });
  };

  handleItemRemove = itemIndex => {
    const { event, setEventProps } = this.props;
    let newItems = event.items.slice();
    newItems[itemIndex] = {
      ...newItems[itemIndex],
      _destroy: "1"
    };
    setEventProps({ items: newItems });
  };

  handleRentalBundleChange = (e, rentalBundleIndex) => {
    const { updateRentalBundle } = this.props;
    const { name, value } = e.target;
    updateRentalBundle(name, value, rentalBundleIndex);
  };

  handleRentalBundleRemove = rentalBundleIndex => {
    const { event, setEventProps } = this.props;
    let newRentalBundles = event.rentalBundles.slice();
    newRentalBundles[rentalBundleIndex] = {
      ...newRentalBundles[rentalBundleIndex],
      _destroy: "1"
    };
    setEventProps({ rentalBundles: newRentalBundles });
  };


  handleAddItems = () => {
    const { startAddingItemsToEvent, match } = this.props;
    axios
      .get(process.env.REACT_APP_API_DOMAIN + "/api/marketplace/events/" + match.params.id, {
        headers: getAuthToken()
      })
      .then(response => {
        startAddingItemsToEvent(response.data.event);
        history.push('/shop');
      });
  };

  render() {
    const { event, errors, tileView, showTiles, hideTiles, locale } = this.props;
    const {
      deliveryCost,
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      items,
      rentalBundles
    } = event;
    const itemTotal = items.reduce(
      (sum, item) => (sum += toNumber(item.selectedPrice)),
      0
    );
    const bundleTotal = rentalBundles.reduce(
      (sum, item) => (sum += toNumber(item.selectedPrice)),
      0
    );
    const total = itemTotal + bundleTotal;

    return (
      <section className="itemForm">
        <div className="details">
          <h2>Event Name & Dates</h2>
          <div className="form">
            <div className="fields">
              <label>Event Name</label>
              <StandardTextField
                type="text"
                meta={{ touched: true, error: errors["rentals.name"] }}
                input={{
                  name: "name",
                  value: event.name,
                  onChange: this.handleChange
                }}
                placeholder="Event Name"
              />
            </div>
            <div className="fields dateRange">
              <div>
                <label>Event Start</label>
                <DatePicker
                  name="eventStart"
                  value={eventStart}
                  onChange={this.handleChange}
                />
                <TimePicker
                  name="eventStartTime"
                  value={eventStartTime}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <label>Event End</label>
                <DatePicker
                  name="eventEnd"
                  value={eventEnd}
                  onChange={this.handleChange}
                  startDate={eventStart ? eventStart : new Date()}
                />
                <TimePicker
                  name="eventEndTime"
                  value={eventEndTime}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="items">
          <div className="addItemBtn">
            <a onClick={this.handleAddItems} className="btn">
              Add More Items To Order
            </a>
          </div>
          <h2>Rental Items</h2>
          <div className="tileToggle">
            <a
              onClick={hideTiles}
              className={classnames({
                active: !tileView
              })}
            >
              <ListView />
            </a>
            <a
              onClick={showTiles}
              className={classnames({
                active: tileView
              })}
            >
              <ImageView />
            </a>
          </div>
          <div className="companyItems">
            <table
              className={classnames({
                grid: true,
                thumbnail: tileView
              })}
            >
              <tbody>
                {items.map((item, itemIndex) => {
                  if (item._destroy === "1") {
                    return null;
                  } else {
                    return (
                      <EventItem
                        key={itemIndex}
                        item={item}
                        itemIndex={itemIndex}
                        tileView={tileView}
                        onItemChange={this.handleItemChange}
                        onItemRemove={this.handleItemRemove}
                        locale={locale}
                      />
                    );
                  }
                })}
                {rentalBundles.map((item, itemIndex) => {
                  if (item._destroy === "1") {
                    return null;
                  } else {
                    return (
                      <EventItem
                        key={itemIndex}
                        item={item}
                        itemIndex={itemIndex}
                        tileView={tileView}
                        onItemChange={this.handleRentalBundleChange}
                        onItemRemove={this.handleRentalBundleRemove}
                        locale={locale}
                      />
                    );
                  }
                })}
              </tbody>
            </table>

            <div className="billingDetails">
              <div>
                <label>Estimated Delivery</label>
                <span>
                  <CurrencyLabel
                    value={deliveryCost}
                    locale={locale}
                  />
                </span>
              </div>
              <div className="subtotal">
                <label>Item Total</label>
                <CurrencyLabel
                  value={total}
                  locale={locale}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { tileView } = state.products;
  return { tileView };
};

export default connect(mapStateToProps, actions)(EventItems);
