import { stringify, parseQuery } from "HelperFunctions/QueryString";

export const setFilterParams = (filter, location, history = null) => {
  let query = parseQuery(location.search);
  let type = parseQuery(location.type);
  query.page = "";
  const newFilter = {
    ...query,
    ...type,
    ...filter
  };
  const stringified = stringify(newFilter);
  history.push({
    pathname: location.pathname,
    search: `?${stringified}`
  });
};

export const setFilterParamsBusiness = (filter, location, history = null) => {
  let query = parseQuery(location.search);
  query.page = "";
  const newFilter = {
    ...query,
    ...filter
  };
  const stringified = stringify(newFilter);
  history.push({
    pathname: location.pathname,
    search: `?${stringified}`
  });
};

export const filterFromPropsAndState = (
  props,
  reduxFilter = {},
  includeReduxFilter = false
) => {
  const { location } = props;
  const parsed = parseQuery(location.search);
  const { page } = parsed;
  const newFilter = Object.assign({}, includeReduxFilter && reduxFilter, {
    ...parsed,
    page: page ? Number(page) : 1
  });

  return newFilter;
};
