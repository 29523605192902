import React from "react";
import { Chairs } from "Utils/SvgIcons";

const LowItemCard = props => (
  <div key="moreAvailable" className="lowItem">
    <div className="icon">
      <Chairs />
    </div>
    <p>
      <strong>We're always adding items.</strong>
    </p>
    <p>Check back often to see what's new</p>
  </div>
);
export default LowItemCard;
