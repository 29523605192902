import React from "react";
import { Link } from "react-router-dom";
import ConversationCard from "./ConversationCard";
import ScrollArea from "Utils/react-scrollbar";

// IndexRentals is the parent of RentalsFilter.
// This page is called whenever a Filter is in the path.

const ConversationsDesktopSidePane = props => {
  const { conversations, newConversationLink, forRental } = props;

  return (
    <section className="messages">
      <Link className="btn secondary" to={newConversationLink}>
        New Message
      </Link>
      <ScrollArea className="dark" speed={0.8} horizontal={false}>
        <ul className="convItemList">
          {conversations.map((conversation, index) => {
            return (
              <ConversationCard
                conversation={conversation}
                key={index}
                forRental={forRental}
              />
            );
          })}
        </ul>
      </ScrollArea>
    </section>
  );
};

export default ConversationsDesktopSidePane;
