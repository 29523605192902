import {
  SET_CART_PROPS,
  INIT_CART_PROPS,
  START_ADDING_ITEMS_TO_EVENT,
  STOP_ADDING_ITEMS_TO_EVENT
} from "Constants/redux";
import { restoreCart } from "HelperFunctions/general";

let start = new Date();
let end = new Date();
start.setHours(start.getHours() + 1, 0, 0, 0);
end.setHours(end.getHours() + 1, 0, 0, 0);

const cartEvent = restoreCart();

const INITIAL_EVENT = {
  eventStart: null,
  eventStartTime: start,
  eventEnd: null,
  eventEndTime: end,
  name: "",
  items: {},
  addOns: {},
  rentalBundles: {},
  deliveryType: "customer_pick_up",
  deliveryCost: {},
  taxTotal: {},
  damageWaiverFeeTotal: {},
  customerContactPhone: "",
  deliveryAddressLocationName: "",
  deliveryAddressStreetAddress1: "",
  deliveryAddressStreetAddress2: "",
  deliveryAddressCity: "",
  deliveryAddressLocale: "",
  deliveryAddressPostalCode: "",
  deliveryAddressCountry: "",
  pickupSameAsDelivery: true,
  pickupAddressLocationName: "",
  pickupAddressStreetAddress1: "",
  pickupAddressStreetAddress2: "",
  pickupAddressCity: "",
  pickupAddressLocale: "",
  pickupAddressPostalCode: "",
  pickupAddressCountry: "",
  preferredDeliveryWindow: "",
  preferredPickupWindow: "",
  venue: {
    name: "",
    cell: "",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    locale: "",
    postalCode: "",
    country: ""
  },
  version: 1,
  editingEvent: false
};

const INITIAL_STATE = {
  event: cartEvent || INITIAL_EVENT,
  addingItems: false,
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  let newState;
  switch (action.type) {
    case SET_CART_PROPS:
      const newEvent = {
        ...state.event,
        ...action.payload
      };
      newState = { ...state, event: newEvent };
      break;
    case START_ADDING_ITEMS_TO_EVENT:
      const event = {
        ...state.event,
        ...action.payload
      };
      newState = { ...state, event, addingItems: true };
      break;
    case STOP_ADDING_ITEMS_TO_EVENT:
      newState = { ...state, addingItems: false };
      break;
    case INIT_CART_PROPS:
      newState = { event: INITIAL_EVENT, addingItems: false, loading: false };
      break;
    default:
      newState = state;
      break;
  }

  if (
    Object.keys(newState.event.items).length !== 0 ||
    Object.keys(newState.event.rentalBundles).length !== 0 ||
    Object.keys(newState.event.addOns).length !== 0
  ) {
    localStorage.setItem("cartEvent", JSON.stringify(newState.event));
  } else {
    localStorage.removeItem("cartEvent");
    newState = {
      event: {
        ...INITIAL_EVENT,
        customerContactPhone: newState.event.customerContactPhone
      },
      loading: false,
      addingItems: false
    };
  }

  return newState;
};
