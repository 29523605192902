import React from "react";
import axios from "axios";
import { getAuthToken } from "Api";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import ConnectPaymentBox from "./ConnectPaymentBox";
import SignatureModal from "Utils/SignatureModal";
import Modal from "Utils/Modal";
import { objectToFormData } from "HelperFunctions/formHelpers";

class Event extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentOpen: false,
      signModalOpen: false
    };
  }

  handlePaymentSuccess = () => {
    const { refreshRental, event } = this.props;
    this.togglePayment();
    refreshRental(event.id);
  };

  renderActionButton = rental => {
    if (rental.status === "cancelled") {
      return (
        <button className="disabled" disabled>
          Quote Cancelled
        </button>
      );
    }

    if (rental.cancelRequested) {
      return (
        <button className="disabled" disabled>
          Cancellation Pending
        </button>
      );
    }

    switch (rental.approvalStatus) {
      case "draft":
        return (
          <button onClick={() => this.handleSubmitQuote(rental)}>
            Submit
          </button>
        );
      case "pending":
        return (
          <button className="disabled" disabled>
            Quote Pending
          </button>
        );
      case "approved":
        if (
          rental.changeRequest !== null &&
          rental.changeRequest.status === "pending"
        ) {
          return (
            <button className="disabled" disabled>
              Changes Requested
            </button>
          );
        }
        return null;
      default:
        return null;
    }
  };

  renderRentalStatus = rental => {
    if (rental.status === "cancelled") {
      return "Cancelled";
    }

    if (rental.cancelRequested) {
      return "Cancellation Pending";
    }

    if (rental.timeLeftToExpire <= 0) {
      return "Expired";
    }

    switch (rental.approvalStatus) {
      case "pending":
        return "Quote Pending";
      case "approved":
        if (rental.changeRequest && rental.changeRequest.status === "pending") {
          return "Changes Requested";
        }

        if (rental.status === "cancelled") {
          return "Rental Cancelled";
        }

        if (rental.signatureRequired && !rental.hasSignature) {
          return "Signature Required";
        }

        return rental.autoBooked ? "Quote" : "Quote Approved";
      case "declined":
        return "Quote Declined";
      default:
        return null;
    }
  };

  renderActionItems() {
    const { event } = this.state;
    let paymentsDue = 0;
    let signaturesDue = 0;

    event.rentals.forEach(rental => {
      if (rental.approvalStatus === "approved") {
        if (rental.amountRemaining > 0) {
          paymentsDue += 1;
        }
        if (rental.signatureRequired && !rental.hasSignature) {
          signaturesDue += 1;
        }
      }
    });

    return (
      <div className="tasks">
        {paymentsDue > 0 && <div>{paymentsDue} payment(s) remaining</div>}
        {signaturesDue > 0 && <div>{signaturesDue} signature(s) required</div>}
      </div>
    );
  }

  toggleSignModal = () => {
    this.setState({
      signModalOpen: !this.state.signModalOpen
    });
  };

  togglePayment = () => {
    this.setState({
      paymentOpen: !this.state.paymentOpen
    });
  };

  handleSubmitSignature = ({ signature, signerName }) => {
    const {
      openLoadingSpinner,
      closeLoadingSpinner,
      event,
      rental,
      refreshRental
    } = this.props;
    openLoadingSpinner("Submitting signature...");

    const data = {
      signature: signature,
      signer_name: signerName
    };
    const component = this;
    const params = objectToFormData({ rental: data });
    axios
      .patch(
        `${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${rental.id}/submit_signature`,
        params
      )
      .then(response => {
        let rental = response.data.rental;
        component.toggleSignModal();
        closeLoadingSpinner();
        refreshRental(event.id);
      })
      .catch(error => {
        console.log(error);
        const errors = error.response.data;
        this.props.setErrors(errors);
        closeLoadingSpinner();
        component.toggleSignModal();
      });

    this.updateRental(data, response => {
      closeLoadingSpinner();
      component.toggleSignModal();
    });
  };

  extendExpiration = () => {
    const {
      openLoadingSpinner,
      refreshRental,
      rental,
      event,
      closeLoadingSpinner
    } = this.props;
    openLoadingSpinner("Extending expiration...");
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN +
          "/api/marketplace/rentals/" +
          rental.id +
          "/extend_expiration",
        {},
        { headers: getAuthToken() }
      )
      .then(response => {
        closeLoadingSpinner();
        refreshRental(event.id);
      })
      .catch(error => {
        const errors = error.response.data;
        this.props.setErrors(errors);
      });
  };

  updateRental = (data, onSuccess) => {
    const {
      openLoadingSpinner,
      refreshRental,
      rental,
      event,
      closeLoadingSpinner
    } = this.props;
    openLoadingSpinner("Updating rental...");
    const params = objectToFormData({ rental: data });

    axios
      .patch(
        process.env.REACT_APP_API_DOMAIN + "/api/portal/rentals/" + rental.id,
        params
      )
      .then(response => {
        closeLoadingSpinner();
        refreshRental(event.id);
      })
      .catch(error => {
        const errors = error.response.data;
        this.props.setErrors(errors);
      });
  };

  render() {
    const { rental, event, customer } = this.props;
    const { paymentOpen, signModalOpen } = this.state;

    return (
      <div className="actions">
        {this.renderActionButton(rental)}
        {rental.isAllowedToMakePayment &&
          (rental.signatureRequired && !rental.hasSignature ? (
            <button onClick={this.toggleSignModal}>
              Signature Required Before Payment
            </button>
          ) : (
            <button onClick={this.togglePayment}>Make a Payment</button>
          ))}
        {rental.source === "marketplace" && rental.timeLeftToExpire <= 0 && (
          <button onClick={this.extendExpiration}>
            Extend Expiration by 24 Hours
          </button>
        )}
        <Link
          className="btn outline"
          to={`/events/${event.id}/rentals/${rental.token}`}
        >
          See More Details
        </Link>
        <SignatureModal
          open={signModalOpen}
          toggle={this.toggleSignModal}
          rental={rental}
          onSubmitSignature={this.handleSubmitSignature}
        />
        <Modal
          title="Make a Payment"
          className="large paymentModal"
          toggle={this.togglePayment}
          open={paymentOpen}
        >
          <ConnectPaymentBox
            onPaymentSuccess={this.handlePaymentSuccess}
            rentalId={rental.id}
            rental={rental}
            customer={customer}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { customer } = state.customer;
  return { customer };
};

export default connect(mapStateToProps, actions)(Event);
