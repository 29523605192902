import queryString from "query-string";

const strictUriEncode = str =>
  encodeURIComponent(str).replace(
    /[!'()*]/g,
    x =>
      `%${x
        .charCodeAt(0)
        .toString(16)
        .toUpperCase()}`
  );
const encode = (value, options) => {
  if (options.encode) {
    return strictUriEncode(value);
  }

  return value;
};

const encoderForArrayFormat = options => {
  return (key, value) => {
    return value === null
      ? encode(key, options)
      : [encode(key, options), "=", encode(value, options)].join("");
  };
};

export const stringify = (obj, options) => {
  const defaults = {
    encode: true,
    strict: true,
    arrayFormat: "none"
  };

  options = Object.assign(defaults, options);

  if (options.sort === false) {
    options.sort = () => {};
  }

  const formatter = encoderForArrayFormat(options);

  return obj
    ? Object.keys(obj)
        .sort(options.sort)
        .map(key => {
          const value = obj[key];

          if (value === undefined) {
            return "";
          }

          if (value === null) {
            return encode(key, options);
          }

          if (Array.isArray(value)) {
            const result = [];

            for (const value2 of value.slice()) {
              if (value2 === undefined) {
                continue;
              }

              result.push(formatter(key, value2, result.length));
            }

            return result.join("&");
          }

          return encode(key, options) + "=" + encode(value, options);
        })
        .filter(x => x.length > 0)
        .join("&")
    : "";
};

export const parseQuery = query => {
  return queryString.parse(query);
};
