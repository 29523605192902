import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import LoadingSpinner from "Utils/LoadingSpinner";
import { ProfileInfo, Edit } from "Utils/SvgIcons";
import PasswordResetForm from "Components/home/PasswordResetForm";
import { getAuthToken } from "Api";
import Modal from "Utils/Modal";
import { formatPhoneNumber } from "HelperFunctions/general";
import Imgix from "react-imgix";
import axios from "axios";
// Fetch profile info from Redux customer, then display it.

class Profile extends React.Component {
  state = {
    resetPasswordOpen: false,
    mergeAccountsOpen: false
  };

  componentDidMount() {
    const { fetchProfile } = this.props; // Redux
    fetchProfile();
  }

  toggleResetPassword = () => {
    this.setState({
      resetPasswordOpen: !this.state.resetPasswordOpen
    });
  };

  toggleMergeAccounts = () => {
    this.setState({
      mergeAccountsOpen: !this.state.mergeAccountsOpen
    });
  };

  handleReset = data => {
    const authCredentials = getAuthToken();
    const component = this;
    this.props.resetPassword({
      ...data,
      query: {
        uid: authCredentials["uid"],
        client_id: authCredentials["client"],
        token: authCredentials["access-token"]
      },
      onComplete: () => {
        component.setState({
          resetPasswordOpen: false
        });
        component.props.openSuccessSnackBar(
          "Successfully updated the password."
        );
      }
    });
  };

  mergeAccounts = () => {
    const {
      openLoadingSpinner,
      closeLoadingSpinner,
      fetchProfile
    } = this.props;
    const component = this;
    openLoadingSpinner("Merging Accounts...");
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN +
          "/api/portal/customers/merge_accounts",
        {},
        { headers: getAuthToken() }
      )
      .then(response => {
        closeLoadingSpinner();
        component.toggleMergeAccounts();
        fetchProfile();
      })
      .catch(error => {
        const errors = error.response.data;
        const { setErrors } = this.props;
        closeLoadingSpinner();
        setErrors(errors);
      });
  };

  render() {
    const { customer, loading } = this.props;
    const { resetPasswordOpen, mergeAccountsOpen } = this.state;

    if (loading) {
      return <LoadingSpinner />;
    } else {
      return (
        <section className="details">
          {customer.hasPossibleConnections && (
            <button className="btn" onClick={this.toggleMergeAccounts}>
              Merge Your Accounts
            </button>
          )}
          <Link className="btnLink" to="/profile/edit">
            <Edit />
          </Link>
          <h4>Contact Info</h4>
          {customer.picture ? (
            <figure>
              <Imgix
                src={customer.picture.imgixUrl}
                alt={customer.firstName + " " + customer.lastName}
              />
            </figure>
          ) : (
            <ProfileInfo />
          )}
          <div className="detailView">
            <div>
              <label>Name</label>
              <h3>
                {customer.firstName} {customer.lastName}
              </h3>
            </div>
            <div>
              <label>Email</label>
              {customer.email}
            </div>
            {customer.cell && (
              <div>
                <label>Phone</label>
                {formatPhoneNumber(customer.cell)}
              </div>
            )}
            {customer.driverLicenseNumber && (
              <div>
                <label>Driver License Number</label>
                ***{customer.driverLicenseNumber.slice(-4)}
              </div>
            )}
            {customer.streetAddress1 && (
              <div>
                <label>Address</label>
                {customer.streetAddress1}{" "}
                {customer.streetAddress2 && `#${customer.streetAddress2}`}
                <br />
                {customer.city}
                {", "}
                {customer.locale} {customer.postalCode}
                {", "}
                {customer.country}
              </div>
            )}
            <button className="btn" onClick={this.toggleResetPassword}>
              Reset Password
            </button>
          </div>

          <Modal
            title="Reset Password"
            open={resetPasswordOpen}
            toggle={this.toggleResetPassword}
          >
            <p>Track rentals, make payments, request changes. You made it!</p>
            <PasswordResetForm onSubmit={this.handleReset} />
          </Modal>
          <Modal
            title="Merge Accounts"
            open={mergeAccountsOpen}
            toggle={this.toggleMergeAccounts}
            actions={[
              <button className="btn" onClick={this.mergeAccounts}>
                Yes, Merge My Accounts
              </button>,
              <button className="btn cancel" onClick={this.toggleMergeAccounts}>
                Cancel
              </button>
            ]}
          >
            <p>
              Looks like you’ve rented with businesses powered by TapGoods using
              this email on your account. Would you like to merge those
              accounts?
            </p>
          </Modal>
        </section>
      );
    }
  }
}

const mapStateToProps = state => {
  const { customer, errors, loading } = state.customer;
  return { customer, errors, loading };
};

export default connect(mapStateToProps, actions)(Profile);
