import React, { Component } from "react";
import deliveryChange from "./DeliveryChange";
import itemChange from "./ItemChange";
import bundleChange from "./BundleChange";
import accessoryChange from "./AccessoryChange";
import addOnChange from "./AddOnChange";
import scheduleChange from "./ScheduleChange";
import textChange from "./TextChange";
import { tempItemChange } from "./ItemChange";
import { Close } from "Utils/SvgIcons";
import axios from "axios";
import { getAuthToken } from "Api";


export function getChangedElements(changeRequest) {
  if (!changeRequest || !changeRequest.subChangeRequests) {
    return [];
  }

  const elems = [
    textChange(changeRequest, "name", "Name"),
    scheduleChange(changeRequest),
    itemChange(changeRequest),
    tempItemChange(changeRequest),
    accessoryChange(changeRequest),
    addOnChange(changeRequest),
    bundleChange(changeRequest),
    deliveryChange(changeRequest),
    textChange(changeRequest, "customerContactPhone", "Phone Number")
  ];
  return elems.filter(elem => elem !== null);
}

class ChangeRequestData extends Component {
  state = {
    showModal: false
  }

  componentDidMount(){
    const { changeRequest } = this.props;
    this.setState({
      showModal: !changeRequest.hideRequestData
    });
  }

  hideModal = () => {
    const { rentalId } = this.props;
    axios.patch(process.env.REACT_APP_API_DOMAIN +
      `/api/portal/rentals/${rentalId}/hide_change_request`, {},
        { headers: getAuthToken() }).then();
    this.setState({
      showModal: false
    })
  }

  render(){
    const { showModal } = this.state;
    if(!showModal) return(null);
    const { changeRequest } = this.props;
    const changedElements = getChangedElements(changeRequest);
    if(changedElements.length === 0) return(null);
    return(
      <section className="changeRequestModal">
        {changeRequest.status !== 'pending' &&
          <div className="closeBtn">
            <a onClick={this.hideModal}><Close/></a>
          </div>
        }
        <h2 className="itemChanges">
          <span>Change Requests</span>
          <label>Old Request</label>
          <label>New Request</label>
          {changeRequest.status !== 'pending' && <label>Approved/Denied</label>}
        </h2>
        {changedElements}
      </section>
    )
  }

}

export default ChangeRequestData;
