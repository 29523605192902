import React from "react";
import MediaQuery from "react-responsive";

// Pass in the code as 2 props (desktop and mobile) that fits the media query

function MediaBreakpoints(props) {
  return (
    <MediaQuery query="(min-width: 769px)">
      {matches => {
        if (matches) {
          return props.desktop;
        } else {
          return props.mobile;
        }
      }}
    </MediaQuery>
  );
}

export default MediaBreakpoints;
