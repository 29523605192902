export const calculateBillingFields = rental => {
  const total =
    parseFloat(rental.rentalItemTotal) +
    parseFloat(rental.rentalAddOnTotal) +
    parseFloat(rental.rentalBundleTotal);
  const tapGoodsFeeTotal =
    parseFloat(rental.tapgoodsFeeTotal) + parseFloat(rental.tapgoodsTaxTotal);
  const transactionFeeTotal =
    Number(tapGoodsFeeTotal) + Number(rental.processingFeeTotal);
  const transactionFeeRefundTotal =
    Number(rental.tapgoodsFeeRefunded) +
    Number(rental.tapgoodsTaxRefunded) +
    Number(rental.processingFeeRefunded);
  const netEarnings = parseFloat(rental.overallTotal - transactionFeeTotal);
  const overallTotal =
    total + parseFloat(rental.feeTotal) + parseFloat(rental.deliveryCost);
  return {
    overallTotal,
    total,
    tapGoodsFeeTotal,
    transactionFeeTotal,
    transactionFeeRefundTotal,
    netEarnings
  };
};
