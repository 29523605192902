import React from "react";
import * as actions from "Actions";
import { connect } from "react-redux";

class ConnectAccount extends React.Component {
  render() {
    const { connectAccount } = this.props;
    return (
      <div className="reclaim">
        <header>
          <h1>Good news!</h1>
        </header>
        <section>
          <p>Looks like you've already used TapGoods before.</p>
          <p>Would you like to link your accounts to this one?</p>
          <div className="actions">
            <a className="btn full" onClick={() => connectAccount(true)}>
              Yes
            </a>
            <a
              className="btn cancel full"
              onClick={() => connectAccount(false)}
            >
              No
            </a>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(null, actions)(ConnectAccount);
