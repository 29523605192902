import React from "react";
import { Link } from "react-router-dom";
import SignUpForm from "Components/home/SignUpForm";
import * as actions from "Actions";
import { connect } from "react-redux";

class SignUp extends React.Component {
  render() {
    const { errors, createUser } = this.props;
    return (
      <section>
        <SignUpForm errors={errors} onSubmit={createUser} />
        <Link to="/login">or Sign In</Link>
      </section>
    );
  }
}
const mapStateToProps = state => {
  const { errors, loading } = state.auth;
  return { errors, loading };
};

export default connect(mapStateToProps, actions)(SignUp);
