import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="errorLanding">
      <section>
        <h1>404: Page Not Found</h1>
        <p>Hmmm... We couldn't find the page you were looking for.</p>
        <Link to="/marketplace" className="btn">
          Go to Homepage
        </Link>
      </section>
    </div>
  );
};
export default NotFound;
