import React from "react";
import { Alert } from "Utils/SvgIcons";
import CurrencyLabel from "Utils/CurrencyLabel";
import { FormattedNumber } from "react-intl";
import { standardService } from "Api/service";

const displayDeliveryType = {
  customer_pick_up: "pick-up",
  default_delivery: "delivery"
};

class RentalBilling extends React.Component {
  state = {
    salesTaxes: [],
    taxBreakdown: false
  };
  componentDidMount() {
    this.getSalesTaxes();
  }

  getSalesTaxes = () => {
    const { addOns, items, rentalBundles } = this.props;
    if (addOns && addOns.length > 0) {
      let locationID = addOns[0].addOn.businessInfo.locationId;
      let businessID = addOns[0].addOn.businessInfo.id;
      this.getSalesTaxesFromLocation(locationID, businessID);
    } else if (items && items.length > 0) {
      let locationID = items[0].product.businessInfo.locationId;
      let businessID = items[0].product.businessInfo.id;
      this.getSalesTaxesFromLocation(locationID, businessID);
    } else if (rentalBundles && rentalBundles.length > 0) {
      let locationID = rentalBundles[0].bundle.businessInfo.locationId;
      let businessID = rentalBundles[0].bundle.businessInfo.id;
      this.getSalesTaxesFromLocation(locationID, businessID);
    }
  };

  getSalesTaxesFromLocation = (locationID, businessID) => {
    const component = this;
    const headers = {
      "location-id": locationID,
      "business-id": businessID
    };
    standardService
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/api/marketplace/taxes/find_tax_rate`,
        {},
        headers
      )
      .then((response) => {
        if (response.data && response.data.tax_info) {
          const {
            geoState,
            geoCity,
            geoCounty,
            geoPostalCode,
            stateSalesTax,
            citySalesTax,
            countySalesTax,
            districtSalesTax
          } = response.data.tax_info;
          const salesTaxes = [
            {
              taxType: "state",
              name: geoState,
              taxPercent: stateSalesTax
            },
            {
              taxType: "city",
              name: geoCity,
              taxPercent: citySalesTax
            },
            {
              taxType: "county",
              name: geoCounty,
              taxPercent: countySalesTax
            },
            {
              taxType: "district",
              name: geoPostalCode,
              taxPercent: districtSalesTax
            }
          ];
          component.setState({
            salesTaxes: salesTaxes,
            taxBreakdown: response.data.tax_breakdown
          });
        }
      })
      .catch((error) => {
        const { setErrors } = component.props;
        setErrors && setErrors(error.response.data);
      });
  };

  renderTotalLabel(step) {
    switch (step) {
      case 1:
        return "Subtotal";
      case 2:
        return "Running Subtotal";
      case 3:
        return "Total";
      default:
        return "Subtotal";
    }
  }
  render() {
    const { event, items, rentalBundles, addOns, step } = this.props;
    const { salesTaxes, taxBreakdown } = this.state;

    const rentalInventory = items.concat(rentalBundles, addOns);
    const rentalInventoryTotal = rentalInventory.reduce(
      (total, item_container) =>
        total + parseFloat(item_container.selectedPrice),
      0
    );
    let hasPriceAvailableOnRequest = rentalInventory.some(
      (item_container) => +item_container.selectedPrice === 0
    );

    const business = items[0]
      ? items[0].product.businessInfo
      : rentalBundles[0]
      ? rentalBundles[0].bundle.businessInfo
      : addOns[0].addOn.businessInfo;
    if (!business.shopShowItemPricing) hasPriceAvailableOnRequest = true;

    const minimumItemTotal =
      event.deliveryType === "customer_pick_up"
        ? business.minimumItemTotalForPickup
        : business.minimumItemTotalForDelivery;
    const hasMinimumItemTotalAlert =
      !hasPriceAvailableOnRequest && rentalInventoryTotal < minimumItemTotal;

    const deliveryCost = parseFloat(event.deliveryCost[business.name] || 0);
    let damageWaiverFee = parseFloat(
      event.damageWaiverFeeTotal[business.name] || 0
    );

    let subTotal = rentalInventoryTotal + damageWaiverFee;
    if (step > 2) {
      subTotal += deliveryCost;
    }
    const taxTotal = Number(event.taxTotal[business.name]) || 0;
    const eventTotal = subTotal + taxTotal;
    return (
      <div>
        <strong>{business.name}</strong>
        <div className="billingDetails">
          <div>
            <label>Items Subtotal</label>
            {hasPriceAvailableOnRequest ? (
              <span>Price On Request</span>
            ) : (
              <CurrencyLabel value={rentalInventoryTotal} />
            )}
          </div>
          <div>
            <label>Damage Waiver Fee</label>
            {hasPriceAvailableOnRequest ? (
              <span>Price On Request</span>
            ) : (
              <CurrencyLabel value={damageWaiverFee} />
            )}
          </div>
          {taxBreakdown ? (
            salesTaxes.length > 0 &&
            salesTaxes.map((tax) => {
              if(tax.taxPercent > 0){
                return (
                  <div>
                    <label>{tax.name}</label>
                    {step < 3 ? (
                      <span>TBD</span>
                    ) : hasPriceAvailableOnRequest ? (
                      <span>Price On Request</span>
                    ) : (
                      <FormattedNumber
                        value={tax.taxPercent * rentalInventoryTotal}
                        style="currency"
                        currency="USD"
                      />
                    )}
                  </div>
                );
              } else {
                return null;
              }
              
            })
          ) : (
            <div>
              <label>Tax</label>
              {step < 3 ? (
                <span>TBD</span>
              ) : hasPriceAvailableOnRequest ? (
                <span>Price On Request</span>
              ) : (
                <CurrencyLabel value={taxTotal} />
              )}
            </div>
          )}
          <div>
            <label>Estimated Delivery</label>
            {step < 3 ? (
              <span>TBD</span>
            ) : (
              <CurrencyLabel value={deliveryCost} />
            )}
          </div>
          <div className="subtotal">
            <label>{this.renderTotalLabel(step)}</label>
            {step < 3 ? (
              hasPriceAvailableOnRequest ? (
                <span>Price On Request</span>
              ) : (
                <CurrencyLabel value={subTotal} />
              )
            ) : hasPriceAvailableOnRequest ? (
              <span>Price On Request</span>
            ) : (
              <CurrencyLabel value={eventTotal} />
            )}
          </div>
          {hasMinimumItemTotalAlert && (
            <div className="alert">
              <Alert />
              <p>
                Your order does not meet the minimum purchase amount for{" "}
                {displayDeliveryType[event.deliveryType]}. (
                <CurrencyLabel value={minimumItemTotal} />{" "}
                min. )
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default RentalBilling;
