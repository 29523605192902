import React from "react";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import classnames from "classnames";
import { renderItemQty } from "HelperFunctions/rentals";
import { TapGoods } from "Utils/SvgIcons";
import { displayPeriodUnit } from "Constants/displayConstants";
import {
  hasSelectedFlatPrice,
  getSelectedFlatPrice,
} from "HelperFunctions/rental_items";
import converter from "json-style-converter/es5";
import RentalBundleItem from "./rental_bundle/RentalBundleItem";
import Imgix from "react-imgix";
import CurrencyLabel from "Utils/CurrencyLabel";
import { countryToLocale } from "Constants/currencyConstants";

const renderPeriodPrice = (item, locale) => {
  const { period, product, bundle, selectedPrice, quantity } = item;
  if (
    !period ||
    period === "flat_price" ||
    period === "edited_flat_unit_price"
  ) {
    return (
      <span>
        <CurrencyLabel
          value={selectedPrice / quantity}
          locale={locale}
        />
        {" / Each"}
      </span>
    );
  } else if (hasSelectedFlatPrice(item, item.productType)) {
    const flatPrice = getSelectedFlatPrice(item);
    return (
      <span>
        <CurrencyLabel
          value={flatPrice.amount}
          locale={locale}
        />
        {" / Flat: " + flatPrice.name}
      </span>
    );
  }

  const inventory = product || bundle;
  if (inventory) {
    if (bundle) {
      const flatPricesSum = item.flatPrices.reduce((sum, fp) => { return sum + Number(fp.amount) }, 0) + Number(inventory.purchasePrice);
      return (
        <span>
          <CurrencyLabel
            value={inventory[converter.snakeToCamelCase(period)]}
            locale={locale}
          />
          {" / "}
          {displayPeriodUnit[period]}
          <div>
            {" + "}
            <CurrencyLabel
              value={flatPricesSum}
              locale={locale}
            />
            {" / Each"}
          </div>
        </span>
      );
    } else {
      return (
        <span>
          <CurrencyLabel
            value={inventory[converter.snakeToCamelCase(period)]}
            locale={locale}
          />
          /{displayPeriodUnit[period]}
        </span>
      );
    }
  }

  return null;
};

const RentalBundle = (props) => {
  const {
    rentalBundle,
    showItemImages,
    showItemNotes,
    showUnitPricingToCustomers,
    tileView,
    locale
  } = props;
  const {
    name,
    bundle,
    hasShortage,
    selectedPrice,
    rentalItems,
    rentalAddOns
  } = rentalBundle;
  const bundleItems = [...rentalItems, ...rentalAddOns];

  return (
    <MediaBreakpoints
      desktop={
        <tbody className="itemContent">
          <tr
            className={classnames({
              bottom: showItemNotes && bundle.notes
            })}
          >
            {tileView && showItemImages && (
              <td
                rowSpan={showItemNotes && bundle.notes ? "2" : "1"}
                className="image"
              >
                <figure>
                  {bundle && bundle.pictures[0] ? (
                    <Imgix
                      src={bundle.pictures[0].imgixUrl}
                      alt={name}
                      width={510}
                      height={288}
                    />
                  ) : (
                    <TapGoods />
                  )}
                </figure>
              </td>
            )}
            <td>{name}</td>
            <td
              className={classnames({
                right: true,
                hasShortage: hasShortage
              })}
            >
              {renderItemQty(rentalBundle)}
            </td>
            {showUnitPricingToCustomers && (
              <td className="right">{renderPeriodPrice(rentalBundle, locale)}</td>
            )}
            <td></td>
            <td className="right">
              {selectedPrice && (
                <CurrencyLabel
                  value={selectedPrice || 0}
                  locale={locale}
                />
              )}
            </td>
          </tr>
          {showItemNotes && bundle.notes && (
            <tr className="top noBorder">
              <td className="caption">{bundle.notes}</td>
            </tr>
          )}
          {bundle.showItemsToCustomer && (
            <tr>
              <td
                className="indent"
                colSpan={
                  showUnitPricingToCustomers
                    ? tileView
                      ? "5"
                      : "4"
                    : tileView
                    ? "4"
                    : "3"
                }
              >
                <table className="grid">
                  {bundleItems.map((bundleItem, index) => (
                    <RentalBundleItem
                      key={index}
                      item={bundleItem}
                      bundle={bundle}
                      bundlePeriod={rentalBundle.period}
                      tileView={tileView}
                      showUnitPricingToCustomers={showUnitPricingToCustomers}
                      locale={locale}
                    />
                  ))}
                </table>
              </td>
            </tr>
          )}
        </tbody>
      }
      mobile={
        <tbody className="mobileItemContent">
          {tileView && showItemImages && (
            <tr>
              <td colSpan="3">
                <figure>
                  {bundle &&
                  bundle.pictures[0] &&
                  bundle.pictures[0].imgixUrl ? (
                    <Imgix
                      src={bundle.pictures[0].imgixUrl}
                      alt={rentalBundle.name}
                      height={288}
                      width={510}
                    />
                  ) : (
                    <TapGoods />
                  )}
                </figure>
              </td>
            </tr>
          )}
          <tr className="noBorder">
            <td colSpan={showUnitPricingToCustomers ? "3" : "2"}>
              {rentalBundle.name}
            </td>
          </tr>
          <tr className="noBorder">
            <td
              className={classnames({
                alert: rentalBundle.hasShortage
              })}
            >
              {renderItemQty(rentalBundle)}
            </td>
            {showUnitPricingToCustomers && (
              <td className="right">{renderPeriodPrice(rentalBundle, locale)}</td>
            )}
            <td></td>
            <td className="right">
              {selectedPrice && (
                <CurrencyLabel
                  value={selectedPrice || 0}
                  locale={locale}
                />
              )}
            </td>
          </tr>
          {showItemNotes && bundle.notes && (
            <tr className="top noBorder">
              <td
                colSpan={showUnitPricingToCustomers ? "3" : "2"}
                className="caption"
              >
                {bundle.notes}
              </td>
            </tr>
          )}
          {bundle.showItemsToCustomer && (
            <tr className="top noBorder">
              <td colSpan={showUnitPricingToCustomers ? "3" : "2"}>
                <table className="grid">
                  {bundleItems.map((bundleItem, index) => (
                    <RentalBundleItem
                      key={index}
                      item={bundleItem}
                      bundle={bundle}
                      bundlePeriod={rentalBundle.period}
                      tileView={tileView}
                      showUnitPricingToCustomers={showUnitPricingToCustomers}
                      locale={locale}
                    />
                  ))}
                </table>
              </td>
            </tr>
          )}
        </tbody>
      }
    />
  );
};
export default RentalBundle;
