import axios from "axios";
import {
  saveAuthToken,
  getAuthToken,
  clearAuthToken,
  ROOT_URL,
  authToken,
  saveError
} from "Api";
import { history as browserHistory } from "Components/Routes";
import { setErrors, openSuccessSnackBar } from "./SnackbarActions";
import { fetchProfile } from "./CustomerActions";
import {
  FIELD_CHANGED,
  ADMIN_LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  CREATE_USER_FAIL,
  SIGN_OUT_USER_SUCCESS,
  SIGN_OUT_USER_FAIL,
  SIGN_OUT_USER,
  VALIDATE_USER,
  VALIDATE_USER_SUCCESS,
  VALIDATE_USER_FAIL,
  SET_ERROR_MESSAGE
} from "Constants/redux";
import { reset } from "redux-form";

const REDIRECT_ROOT_URL = process.env.REACT_APP_FRONT_END_DOMAIN;

export const fieldChanged = ({ field, text }) => {
  return {
    type: FIELD_CHANGED,
    payload: { field, text }
  };
};

export const loginUserSuccess = (dispatch, user) => {
  dispatch({
    type: LOGIN_USER_SUCCESS,
    payload: user
  });
  dispatch(fetchProfile());
};
const validateUserSuccess = (dispatch, user) => {
  dispatch({
    type: VALIDATE_USER_SUCCESS,
    payload: user
  });
  dispatch(fetchProfile());
};

const signOutUserSuccess = dispatch => {
  dispatch({
    type: SIGN_OUT_USER_SUCCESS
  });
};

export const validateUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: VALIDATE_USER });
    const authTokenHead = authToken();

    if (authTokenHead) {
      axios
        .get(`${ROOT_URL}/customer_auth/validate_token`, {
          params: {
            uid: authTokenHead["uid"],
            client: authTokenHead["client"],
            "access-token": authTokenHead["access-token"]
          }
        })
        .then(response => {
          validateUserSuccess(dispatch, response.data.data);
          saveAuthToken(response.headers);
        })
        .catch(error => {
          saveError(error, getState());
          dispatch({ type: VALIDATE_USER_FAIL });
          browserHistory.push({
            pathname: "/login",
            state: { from: browserHistory.location.pathname }
          });
        });
    } else {
      dispatch({ type: VALIDATE_USER_FAIL });
      browserHistory.push({
        pathname: "/login",
        state: { from: browserHistory.location.pathname }
      });
    }
  };
};

export const refreshStoredUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: VALIDATE_USER });
    const authTokenHead = authToken();

    if (authTokenHead) {
      axios
        .get(`${ROOT_URL}/customer_auth/validate_token`, {
          params: {
            uid: authTokenHead["uid"],
            client: authTokenHead["client"],
            "access-token": authTokenHead["access-token"]
          }
        })
        .then(response => {
          validateUserSuccess(dispatch, response.data.data);
          saveAuthToken(response.headers);
        })
        .catch(error => {
          saveError(error, getState());
          dispatch({ type: VALIDATE_USER_FAIL });
        });
    } else {
      dispatch({ type: VALIDATE_USER_FAIL });
    }
  };
};

export const loginUser = ({ email, password, onSuccess }) => {
  return (dispatch, getState) => {
    axios
      .post(`${ROOT_URL}/customer_auth/sign_in`, { email, password })
      .then(response => {
        saveAuthToken(response.headers);
        loginUserSuccess(dispatch, response.data.data.customer);
        if (onSuccess) {
          onSuccess();
        } else {
          if (
            browserHistory.location.state &&
            browserHistory.location.state.from
          ) {
            browserHistory.replace(browserHistory.location.state.from);
          } else {
            browserHistory.push("/marketplace");
          }
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          dispatch(setErrors(error.response.data.errors));
          dispatch({
            type: LOGIN_USER_FAIL,
            payload: error.response.data.errors
          });
        }
      });
  };
};

export const adminUserLogin = id => {
  return (dispatch, getState) => {
    dispatch({ type: ADMIN_LOGIN_USER });

    axios
      .post(
        `${ROOT_URL}/admin/become_customer`,
        { id },
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        saveAuthToken(response.headers);
        loginUserSuccess(dispatch, response.data.data);
        browserHistory.push("/marketplace");
        dispatch(
          openSuccessSnackBar(
            "You are now successfully logged in as this customer."
          )
        );
      })
      .catch(error => {
        saveError(error, getState());
        dispatch(setErrors(error.response.data.errors));
        dispatch({
          type: LOGIN_USER_FAIL,
          payload: error.response.data.errors
        });
      });
  };
};

export const signOutUser = () => {
  return (dispatch, getState) => {
    const headers = getAuthToken();
    dispatch({ type: SIGN_OUT_USER });

    axios
      .delete(`${ROOT_URL}/customer_auth/sign_out`, {
        headers: headers
      })
      .then(response => {
        browserHistory.push("/marketplace");
        signOutUserSuccess(dispatch, response.data.data);
        clearAuthToken();
      })
      .catch(error => {
        saveError(error, getState());
        dispatch(setErrors(error.response.data.errors));
        dispatch({
          type: SIGN_OUT_USER_FAIL,
          payload: error.response.data.errors
        });
      });
  };
};

export const createUser = ({
  email,
  password,
  passwordConfirmation,
  firstName,
  lastName,
  phone,
  streetAddress1,
  city,
  state,
  zip,
  country,
  onSuccess
}) => {
  return (dispatch, getState) => {
    const data = Object.assign(
      {
        source: "marketplace",
        active: true,
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        password_confirmation: passwordConfirmation
      },
      phone && {
        phone_numbers_attributes: {
          "0": {
            cell: phone,
            main_contact_number: true
          }
        }
      },
      (streetAddress1 || city || zip || state || country) && {
        physical_addresses_attributes: {
          "0": {
            street_address_1: streetAddress1,
            city,
            locale: state,
            postal_code: zip,
            country,
            main_contact_address: true
          }
        }
      }
    );

    axios
      .post(`${ROOT_URL}/customer_auth`, data)
      .then(response => {
        saveAuthToken(response.headers);
        if (response.data.has_possible_connections) {
          browserHistory.push("/connectAccount");
        } else {
          loginUserSuccess(dispatch, response.data.data.customer);
          if (onSuccess) {
            onSuccess();
          } else {
            browserHistory.push("/marketplace");
          }
        }
      })
      .catch(error => {
        console.log(error);
        const errors = error.response.data.errors;
        if (errors.email && errors.email.includes("has already been taken")) {
          browserHistory.push("/reclaimAccount?email=" + email);
        } else {
          saveError(error, getState());
          dispatch(setErrors(errors));
          dispatch({
            type: CREATE_USER_FAIL,
            payload: errors
          });
        }
      });
  };
};

export const setErrorMessage = ({ field, text }) => {
  return {
    type: SET_ERROR_MESSAGE,
    payload: {
      field,
      text
    }
  };
};

export const sendPasswordReset = ({ email }) => {
  return (dispatch, getState) => {
    axios
      .post(`${ROOT_URL}/customer_auth/password`, {
        email,
        redirect_url: `${REDIRECT_ROOT_URL}/passwordReset`
      })
      .then(response => {
        dispatch(openSuccessSnackBar("Password reminder email has been sent."));
        dispatch(reset("ForgotPassword"));
      })
      .catch(err => {
        saveError(err, getState());
        dispatch(setErrors(err.response.data.errors));
      });
  };
};

export const resetPassword = ({
  password,
  password_confirm,
  query,
  onComplete
}) => {
  return (dispatch, getState) => {
    const { uid, token, client_id } = query;
    axios
      .put(`${ROOT_URL}/customer_auth/password`, null, {
        headers: {
          uid,
          client: client_id,
          "access-token": token
        },
        params: {
          password,
          password_confirmation: password_confirm
        }
      })
      .then(response => {
        saveAuthToken(response.headers);
        if (onComplete) {
          onComplete();
        } else {
          loginUserSuccess(dispatch, response.data.data);
          browserHistory.push("/marketplace");
        }
      })
      .catch(err => {
        saveError(err, getState());
        dispatch(setErrors(err.response.data.errors.full_messages));
      });
  };
};
