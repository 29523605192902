import React from "react";
import { FormattedNumber, FormattedDate } from "react-intl";
import { connect } from "react-redux";
import * as actions from "Actions";
import service from "Api/service";
import { saveAuthToken, getAuthToken, saveError } from "Api";
import { Receipts } from "Utils/SvgIcons";
import LoadingSpinner from "Utils/LoadingSpinner";
import PaginationBoxView from "Utils/PaginationBoxView";
import { setFilterParams, filterFromPropsAndState } from "HelperFunctions/urls";
import CurrencyLabel from "Utils/CurrencyLabel";

class ProfilePaymentHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pagination: {},
      transactions: []
    };
  }
  componentWillMount() {
    this.fetchTransactions(this.props.filter);
  }
  componentWillReceiveProps(nextProps) {
    const oldFilter = this.props.filter;
    let newFilter = nextProps.filter;
    let changed = false;
    for (var property in newFilter) {
      if (newFilter.hasOwnProperty(property)) {
        const newTest =
          newFilter[property] instanceof Date
            ? newFilter[property].getTime()
            : newFilter[property];
        const oldTest =
          oldFilter[property] instanceof Date
            ? oldFilter[property].getTime()
            : oldFilter[property];
        if (newTest !== oldTest) {
          changed = true;
        }
      }
    }
    if (changed) {
      const filterToSubmit = Object.assign(newFilter);
      this.fetchTransactions(filterToSubmit);
    }
  }

  fetchTransactions(filter) {
    this.setState({
      loading: true
    });
    service
      .get(
        process.env.REACT_APP_API_DOMAIN + `/api/portal/transactions/paginated`,
        {
          page: filter.page,
          per: filter.numberPer || 5
        }
      )
      .then(response => {
        const newState = Object.assign(
          {},
          {
            loading: false,
            transactions: response.data.transactions
          },
          response.data.meta && { pagination: response.data.meta.pagination }
        );
        this.setState(newState);
      })
      .catch(error => {
        console.error(error);
        this.setState({
          loading: false
        });
      });
  }

  handlePageChange = data => {
    const { location, history } = this.props;
    const newPage = data.selected + 1;
    setFilterParams(
      {
        page: newPage
      },
      location,
      history
    );
  };

  render() {
    const { loading, pagination, transactions } = this.state;
    const { filter } = this.props;
    if (loading) {
      return <LoadingSpinner />;
    }
    return (
      <section className="history">
        <h4>Payment History</h4>
        <Receipts />
        <table className="grid">
          <thead>
            <tr>
              <th>Payment Date</th>
              <th>Amount</th>
              <th>Type</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map(payment => (
              <tr key={payment.id}>
                <td>
                  <FormattedDate
                    day="numeric"
                    month="numeric"
                    year="numeric"
                    value={payment.paymentDate}
                  />
                </td>
                <td>
                  <CurrencyLabel value={payment.amount} />
                </td>
                <td>{payment.paymentData.type}</td>
                <td>{payment.paymentData.number}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <PaginationBoxView
          className="pagerWrapper"
          containerClassName="pager"
          forcePage={+filter.page - 1}
          pageCount={pagination.totalPages}
          onPageChange={this.handlePageChange}
        />
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { errors, loading } = state.customer;
  let filter = filterFromPropsAndState(ownProps);
  return { errors, loading, filter };
};

export default connect(mapStateToProps, actions)(ProfilePaymentHistory);
