import axios from "axios";
import { history as browserHistory } from "Components/Routes";
import {
  FETCH_GUEST_DATA_REQUEST,
  FETCH_GUEST_DATA_SUCCESS,
  FETCH_GUEST_DATA_FAILURE,
  RECEIVE_GUEST_RENTAL,
  CHANGE_STEP,
  CHANGE_SIGNING
} from "Constants/redux";

import { saveError, saveAuthToken } from "Api";
import { setErrors } from "./SnackbarActions";

import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";

import { loginUserSuccess } from "./AuthActions";

// fetchRental is called to grab the full rental from backend,
// and call the appropriate redux action to save it correctly or error out.

export const fetchGuestData = token => {
  return (dispatch, getState) => {
    if (token) {
      dispatch(openLoadingSpinner("Loading..."));
      dispatch({ type: FETCH_GUEST_DATA_REQUEST });
      return axios
        .get(process.env.REACT_APP_API_DOMAIN + "/api/external_login", {
          params: {
            token: token
          }
        })
        .then(response => {
          dispatch(closeLoadingSpinner());
          dispatch({
            type: FETCH_GUEST_DATA_SUCCESS,
            payload: response.data
          });
        })
        .catch(error => {
          dispatch(closeLoadingSpinner());
          dispatch({
            type: FETCH_GUEST_DATA_FAILURE
          });
          saveError(error, getState());
        });
    } else {
      dispatch({
        type: FETCH_GUEST_DATA_FAILURE
      });
    }
  };
};

export const receiveGuestRental = rental => {
  return (dispatch, getState) => {
    dispatch({
      type: RECEIVE_GUEST_RENTAL,
      payload: {
        rental: rental
      }
    });
  };
};
export const changeStep = step => {
  return {
    type: CHANGE_STEP,
    payload: { step }
  };
};

export const changeSigning = signing => {
  return {
    type: CHANGE_SIGNING,
    payload: { signing }
  };
};

export const submitGuestSignature = (rentalId, data, onSuccess) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Submitting Signature..."));
    return axios
      .put(
        process.env.REACT_APP_API_DOMAIN + `/api/portal/rentals/${rentalId}/`,
        data
      )
      .then(response => {
        dispatch(closeLoadingSpinner());
        onSuccess && onSuccess(response);
      })
      .catch(err => {
        console.log(err);
        dispatch(setErrors(err.response.data));
        dispatch(closeLoadingSpinner());
      });
  };
};

export const signUpGuestSubmit = values => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Signing Up..."));
    const customer = Object.assign(
      {
        first_name: values.firstName,
        last_name: values.lastName,
        password: values.password,
        password_confirmation: values.passwordConfirmation
      },
      values.phone && {
        phone_numbers_attributes: {
          "0": {
            cell: values.phone,
            main_contact_number: true
          }
        }
      }
    );

    const data = {
      customer: customer,
      token: values.token
    };

    return axios
      .put(
        process.env.REACT_APP_API_DOMAIN +
          "/api/portal/customers/guest_sign_up/",
        data
      )
      .then(response => {
        saveAuthToken(response.headers);
        loginUserSuccess(dispatch, response.data.customer);
        dispatch(closeLoadingSpinner());
        browserHistory.push("/dashboard");
      })
      .catch(err => {
        console.log(err);
        dispatch(setErrors(err.response.data));
        dispatch(closeLoadingSpinner());
      });
  };
};
