import React from "react";
import classnames from "classnames";

export default class RenderField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholder: props.placeholder
    };
  }

  handleFocus = () => {
    this.setState({ placeholder: "" });
  };

  handleBlur = () => {
    const { placeholder } = this.props;

    this.setState({ placeholder });
  };

  render() {
    const {
      input,
      className,
      type,
      meta: { touched, error }
    } = this.props;

    const { placeholder } = this.state;

    return (
      <input
        {...input}
        placeholder={placeholder}
        className={classnames({
          [className]: className,
          error: touched && error
        })}
        type={type}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      />
    );
  }
}
