import React from "react";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import { ArrowRight } from "Utils/SvgIcons";

const StandardUploadField = props => {
  const {
    className,
    label,
    meta: { touched, error },
    input,
    placeholder,
    ...other
  } = props;
  const files = input.value;

  return (
    <div
      className={classnames({
        upload: true,
        [className]: className,
        error: touched && error
      })}
    >
      <Dropzone
        className="dropZone"
        onDrop={(filesToUpload, e) => input.onChange(filesToUpload)}
        {...other}
      >
        {files && Array.isArray(files) ? (
          files.map((file, i) => <label key={i}>{file.name}</label>)
        ) : (
          <label className="placeholder">{placeholder}</label>
        )}
      </Dropzone>
      <ArrowRight />
    </div>
  );
};

export default StandardUploadField;
