import {
  SET_IN_SERVICE_AREA,
  SET_ZIP_CODE,
  SET_LOCALE
} from "Constants/redux/ServiceAreaConstants";

const INITIAL_STATE = {
  inServiceArea: false,
  zipCode: "",
  locale: "en-US",
  categories: [
    { id: 1, name: "Chairs" },
    { id: 2, name: "Tables" },
    { id: 3, name: "Special Seating" },
    { id: 4, name: "Lighting" }
  ]
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_IN_SERVICE_AREA:
      return { ...state, inServiceArea: action.value };
    case SET_ZIP_CODE:
      return { ...state, zipCode: action.value };
    case SET_LOCALE:
      return { ...state, locale: action.value };
    default:
      return state;
  }
};
