import classnames from "classnames";
import React from "react";

const yearSelectField = props => {
  const {
    input,
    className,
    startYear,
    endYear,
    meta: { touched, error }
  } = props;

  let years = [];
  for (let i = startYear; i <= endYear; i++) {
    years.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <select
      {...input}
      className={classnames({
        [className]: className,
        error: touched && error
      })}
    >
      <option value="">Select Year</option>
      {years}
    </select>
  );
};

yearSelectField.defaultProps = {
  startYear: new Date().getFullYear(),
  endYear: new Date().getFullYear() + 20
};

export default yearSelectField;
