import React from "react";
import * as actions from "Actions";
import { connect } from "react-redux";
import EventsTable from "./EventsTable";
import { withRouter } from "react-router-dom";

class MobileEvents extends React.Component {
  componentDidMount() {
    const { type, fetchEventList } = this.props;
    fetchEventList({ type });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.type !== nextProps.type) {
      this.props.fetchEventList({
        type: nextProps.type
      });
    }
  }
  render() {
    const { type } = this.props;

    return (
      <div className="events">
        <header>
          <h1>{type} Events</h1>
        </header>
        <EventsTable type={type} />
      </div>
    );
  }
}
export default withRouter(connect(null, actions)(MobileEvents));
