import React from "react";
import { connect } from "react-redux";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import classnames from "classnames";
import { TapGoods } from "Utils/SvgIcons";
import {
  renderItemQty,
  renderItemSpecificFee,
  isItem,
  extractFeeFromPrice
} from "HelperFunctions/rentals";
import Imgix from "react-imgix";
import { RemoveCircle } from "Utils/SvgIcons";
import CurrencyLabel from "Utils/CurrencyLabel";
import { localeToCurrencySymbol } from "Constants/currencyConstants";

class RentalItem extends React.Component {
  renderItemPeriodPrice = () => {
    const {
      rentalItem,
      index,
      sectionIndex,
      removeDuplicatedItem,
      editing,
      showUnitPricingToCustomers,
      parentConnectionType,
      showDiscountsOnPricing,
      locale
    } = this.props;
    const currencySymbol = localeToCurrencySymbol[locale];

    if (!showUnitPricingToCustomers) {
      return null;
    }
    const item = rentalItem;
    let periodPriceAfterDiscount = item.selectedPriceBeforeDiscount;
    if (isItem(item)) {
      periodPriceAfterDiscount = extractFeeFromPrice(
        item,
        item.selectedPriceBeforeDiscount,
        item.selectedPrice,
        item.quantity
      );
      periodPriceAfterDiscount = `${currencySymbol}${(
        periodPriceAfterDiscount /
        +item.quantity /
        (item.duration || 1)
      ).toFixed(2)}`;

      periodPriceAfterDiscount += ` /${item.periodPrice.split("/")[1]}`;
    }
    if (parentConnectionType === "duplicate") {
      return (
        <td className=" right">
          Duplicate
          {editing && (
            <a
              className="btnLink delete"
              onClick={() => removeDuplicatedItem(sectionIndex, index)}
              style={{ paddingLeft: "5px" }}
            >
              <RemoveCircle />
            </a>
          )}
        </td>
      );
    } else if (showDiscountsOnPricing) {
      return (
        <td className=" right normalText">
          {item.rentalItemDiscountRelationships &&
            item.rentalItemDiscountRelationships.length > 0 &&
            (isItem(item) ? (
              <div
                className={classnames({
                  strikeThrough: item.periodPrice !== periodPriceAfterDiscount
                })}
              >
                {item.periodPrice}
              </div>
            ) : (
              <div>
                <CurrencyLabel
                  value={
                    item.selectedPrice ? item.selectedPrice / item.quantity : 0
                  }
                  locale={locale}
                />
              </div>
            ))}
          {periodPriceAfterDiscount}
        </td>
      );
    } else {
      return (
        <td className=" right normalText">
          {isItem(item) ? (
            <div>{periodPriceAfterDiscount}</div>
          ) : (
            <div>
              <CurrencyLabel
                value={item.selectedPrice ? item.selectedPrice : 0}
                locale={locale}
              />
            </div>
          )}
        </td>
      );
    }
  };

  renderItemSelectedPrice = () => {
    const { rentalItem, locale } = this.props;
    const item = rentalItem;
    let selectedPrice = item.selectedPrice;
    if (isItem(item)) {
      selectedPrice = extractFeeFromPrice(
        item,
        item.selectedPriceBeforeDiscount,
        item.selectedPrice,
        item.quantity
      );
    }
    return (
      <td className="medSm right">
        {parseFloat(item.selectedPriceBeforeDiscount) !==
          parseFloat(selectedPrice) &&
          item.rentalItemDiscountRelationships &&
          item.rentalItemDiscountRelationships.length > 0 && (
            <div className="strikeThrough">
              <CurrencyLabel
                value={item.selectedPriceBeforeDiscount}
                locale={locale}
              />
            </div>
          )}

        {selectedPrice && (
          <CurrencyLabel
            value={selectedPrice ? selectedPrice : 0}
            locale={locale}
          />
        )}
      </td>
    );
  };

  render() {
    const {
      rentalItem,
      showItemImages,
      showItemNotes,
      showUnitPricingToCustomers,
      tileView,
      sectionMembership,
    } = this.props;
    const product = rentalItem.product || rentalItem.addOn;

    return (
      <MediaBreakpoints
        desktop={
          <tbody className="itemContent">
            <tr
              className={classnames({
                bottom: showItemNotes && rentalItem.notes
              })}
            >
              {tileView && showItemImages && (
                <td rowSpan="2" className="image">
                  <figure>
                    {product &&
                    product.pictures[0] &&
                    product.pictures[0].imgixUrl ? (
                      <Imgix
                        src={product.pictures[0].imgixUrl}
                        alt={rentalItem.name}
                        height={288}
                        width={510}
                      />
                    ) : (
                      <TapGoods />
                    )}
                  </figure>
                </td>
              )}
              <td className="normalText">{rentalItem.name}</td>
              <td
                className={classnames({
                  right: true,
                  alert: rentalItem.hasShortage
                })}
              >
                {renderItemQty(rentalItem, sectionMembership.quantity)}
              </td>
              {showUnitPricingToCustomers && this.renderItemPeriodPrice()}
              <td className="right">{renderItemSpecificFee(rentalItem)}</td>
              {this.renderItemSelectedPrice()}
            </tr>
            {showItemNotes && rentalItem.notes && (
              <tr className="top noBorder">
                <td className="caption">{rentalItem.notes}</td>
              </tr>
            )}
          </tbody>
        }
        mobile={
          <tbody className="mobileItemContent">
            {tileView && showItemImages && (
              <tr>
                <td colSpan={showUnitPricingToCustomers ? "3" : "2"}>
                  <figure>
                    {product &&
                    product.pictures[0] &&
                    product.pictures[0].imgixUrl ? (
                      <Imgix
                        src={product.pictures[0].imgixUrl}
                        alt={rentalItem.name}
                        height={288}
                        width={510}
                      />
                    ) : (
                      <TapGoods />
                    )}
                  </figure>
                </td>
              </tr>
            )}
            <tr
              className={classnames({
                noBorder: tileView && showItemImages
              })}
            >
              <td colSpan={showUnitPricingToCustomers ? "3" : "2"}>
                {rentalItem.name}
              </td>
            </tr>
            <tr className="noBorder">
              <td
                className={classnames({
                  alert: rentalItem.hasShortage
                })}
              >
                {renderItemQty(rentalItem, sectionMembership.quantity)}
              </td>
              {showUnitPricingToCustomers && this.renderItemPeriodPrice()}
              <td className="right">{renderItemSpecificFee(rentalItem)}</td>
              {this.renderItemSelectedPrice()}
            </tr>
            {showItemNotes && rentalItem.notes && (
              <tr className="top noBorder">
                <td
                  colSpan={showUnitPricingToCustomers ? "3" : "2"}
                  className="caption"
                >
                  {rentalItem.notes}
                </td>
              </tr>
            )}
          </tbody>
        }
      />
    );
  }
}

export default RentalItem;
