export const FIELD_CHANGED = "field_changed";
export const LOGIN_USER_SUCCESS = "login_user_success";
export const LOGIN_USER_FAIL = "login_user_fail";
export const CREATE_USER_FAIL = "create_user_fail";
export const LOGIN_USER = "login_user";
export const ADMIN_FIELD_CHANGED = "admin_field_changed";
export const LOGIN_ADMIN_SUCCESS = "login_admin_success";
export const LOGIN_ADMIN_FAIL = "login_admin_fail";
export const CREATE_ADMIN_FAIL = "create_admin_fail";
export const LOGIN_ADMIN = "login_admin";
export const SIGN_OUT_USER_SUCCESS = "sign_out_user_success";
export const SIGN_OUT_USER_FAIL = "sign_out_user_fail";
export const SIGN_OUT_USER = "sign_out_user";
export const SIGN_OUT_ADMIN_SUCCESS = "sign_out_admin_success";
export const SIGN_OUT_ADMIN_FAIL = "sign_out_admin_fail";
export const SIGN_OUT_ADMIN = "sign_out_admin";
export const ADMIN_LOGIN_USER = "ADMIN_LOGIN_USER";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const VALIDATE_USER = "VALIDATE_USER";
export const VALIDATE_USER_SUCCESS = "VALIDATE_USER_SUCCESS";
export const VALIDATE_USER_FAIL = "VALIDATE_USER_FAIL";
