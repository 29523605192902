import testValidations from "./validations";

const Validator = modelValidations => {
  const validate = values => {
    const errors = {};
    for (var property in modelValidations) {
      if (modelValidations.hasOwnProperty(property)) {
        for (let validation in modelValidations[property]) {
          if (modelValidations[property].hasOwnProperty(validation)) {
            const definition = modelValidations[property][validation];
            if (
              testValidations[validation](
                values[property],
                definition.value,
                values
              )
            ) {
              errors[property] = definition.message;
            }
          }
        }
      }
    }
    return errors;
  };
  return validate;
};

export default Validator;
