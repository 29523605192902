import React from "react";
import ReactSignature from "./ReactSignature";
import Modal from "Utils/Modal";
import MediaBreakpoints from "Utils/MediaBreakpoints";

class SignaturePad extends React.Component {
  state = {
    open: false
  };

  clearSignaturePad = () => {
    this.refs.signaturePad.handleClear();
  };

  toggleSignaturePad = () => {
    this.setState({
      open: !this.state.open
    });
  };

  saveSignaturePad = () => {
    this.setState({
      open: false
    });
    this.props.saveSignature(this.refs.signaturePad.toDataURL());
  };

  render() {
    const { signature } = this.props;
    const { open } = this.state;

    return (
      <div className="signaturePad">
        <a onClick={this.toggleSignaturePad}>
          {signature && (
            <img src={signature ? signature : null} alt="Signature" />
          )}
        </a>
        <Modal
          title="Rental Signature"
          open={open}
          large={true}
          className="signatureModal"
          toggle={this.toggleSignaturePad}
          actions={[
            <button className="btn" onClick={this.saveSignaturePad}>
              Save
            </button>,
            <button className="btn cancel" onClick={this.toggleSignaturePad}>
              Cancel
            </button>,
            <button className="btn cancel" onClick={this.clearSignaturePad}>
              Clear
            </button>
          ]}
        >
          <MediaBreakpoints
            desktop={<ReactSignature ref="signaturePad" />}
            mobile={
              <ReactSignature ref="signaturePad" width={340} height={70} />
            }
          />
        </Modal>
      </div>
    );
  }
}

export default SignaturePad;
