import React from "react";
import axios from "axios";
import { getAuthToken } from "Api";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { FormattedDate } from "react-intl";
import moment from "moment";
import * as actions from "Actions";
import LoadingSpinner from "Utils/LoadingSpinner";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import { TapGoods, MessageBubble } from "Utils/SvgIcons";
import { filterFromPropsAndState } from "HelperFunctions/urls";
import { humanizeTimeLeftToExpire } from "HelperFunctions/rentals";
import Imgix from "react-imgix";
import CurrencyLabel from "Utils/CurrencyLabel";
import { countryToLocale } from "Constants/currencyConstants";

class EventsTable extends React.Component {
  extendExpiration = event => {
    const {
      events,
      openLoadingSpinner,
      closeLoadingSpinner,
      setEventList
    } = this.props;
    openLoadingSpinner("Extending expiration...");
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN +
          "/api/marketplace/events/" +
          event.id +
          "/extend_expiration",
        {},
        { headers: getAuthToken() }
      )
      .then(response => {
        const newEvent = response.data.event;
        closeLoadingSpinner();
        let newEvents = events.slice();
        const newEventIndex = events.findIndex(e => e.id === event.id);
        newEvents[newEventIndex] = newEvent;
        setEventList(newEvents);
      })
      .catch(error => {
        const errors = error.response.data;
        this.props.setErrors(errors);
        closeLoadingSpinner();
      });
  };
  getActionsCount = event => {
    let count = 0;

    if (event.approvalStatus === "pending") {
      return 0;
    }

    if (
      event.amountRemaining > 0 ||
      (event.signatureRequired && event.hasSignature)
    ) {
      count += 1;
    }

    return count;
  };

  render() {
    const { events, type, loading } = this.props;
    const eventsByMonth = events.reduce((holder, event) => {
      const key = moment(event.eventStartDateTime).format("MMM YYYY");
      if (!holder.hasOwnProperty(key)) {
        holder[key] = [];
      }
      holder[key].push(event);
      return holder;
    }, {});

    return (
      <section className="items">
        {loading ? (
          <LoadingSpinner />
        ) : events && events.length > 0 ? (
          <MediaBreakpoints
            desktop={
              <div>
                {Object.keys(eventsByMonth).map((monthKey, monthIndex) => (
                  <div key={monthIndex} className="month">
                    <h4>{monthKey}</h4>
                    <table className="grid thumbnail" key={monthIndex}>
                      <tbody>
                        {eventsByMonth[monthKey].map((event, index) => {
                          let actionCount = this.getActionsCount(event);
                          let locale = "en-US";
                          if (event.rentals[0] && event.rentals[0].businessAddress) {
                            locale = countryToLocale[event.rentals[0].businessAddress.country];
                          }
                          const pictures = event.rentals
                            .map(r => r.pictures)
                            .flat()
                            .filter(p => p !== null);

                          return (
                            <tr key={index}>
                              <td className="image">
                                {pictures.length > 0 ? (
                                  <Carousel
                                    showArrows={false}
                                    showThumbs={false}
                                    showStatus={false}
                                    infiniteLoop={true}
                                    autoPlay={true}
                                    interval={8000}
                                  >
                                    {pictures.map((url, index) => {
                                      return (
                                        <figure key={index}>
                                          <img src={url} alt={url} />
                                        </figure>
                                      );
                                    })}
                                  </Carousel>
                                ) : (
                                  <figure>
                                    <TapGoods />
                                  </figure>
                                )}
                              </td>
                              <td>
                                <Link
                                  className="rTableRow"
                                  to={`/events/${event.token}`}
                                >
                                  <h3>{event.name}</h3>
                                  <strong>
                                    <FormattedDate
                                      day="numeric"
                                      month="numeric"
                                      year="numeric"
                                      value={event.eventStartDateTime}
                                    />
                                  </strong>
                                </Link>
                              </td>
                              {type !== "pending" && type !== "expired" ? (
                                <td className="summary">
                                  <div>
                                    <label>Event Total</label>
                                    <CurrencyLabel
                                      value={event.eventTotal}
                                      locale={locale}
                                    />
                                  </div>
                                  <div>
                                    <label>Balance Due</label>
                                    <CurrencyLabel
                                      value={event.balanceDue}
                                      locale={locale}
                                    />
                                  </div>
                                  <div>
                                    <label>Action Required</label>
                                    {actionCount > 0 ? (
                                      <span>{actionCount} Items</span>
                                    ) : (
                                      <span>None</span>
                                    )}
                                  </div>
                                </td>
                              ) : (
                                <td className="summary">
                                  {event.timeLeftToExpire <= 0 ? (
                                    <div>
                                      <button
                                        onClick={() =>
                                          this.extendExpiration(event)
                                        }
                                      >
                                        Extend Expiration by 24 Hours
                                      </button>
                                    </div>
                                  ) : (
                                    <div>
                                      <label>Expires in</label>
                                      {humanizeTimeLeftToExpire(
                                        event.timeLeftToExpire
                                      )}
                                    </div>
                                  )}
                                </td>
                              )}
                              <td className="action">
                                {event.unreadMessagesCount > 0 && (
                                  <div className="unreadCount">
                                    <MessageBubble />
                                    <label>{event.unreadMessagesCount}</label>
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            }
            mobile={
              <div className="month mobileList">
                {Object.keys(eventsByMonth).map((monthKey, monthIndex) => (
                  <div key={monthIndex}>
                    <h4>{monthKey}</h4>
                    {eventsByMonth[monthKey].map((event, index) => {
                      let actionCount = this.getActionsCount(event);
                      let locale = "en-US";
                      if (event.rentals[0] && event.rentals[0].businessAddress) {
                        locale = countryToLocale[event.rentals[0].businessAddress.country];
                      }
                      return (
                        <Link key={index} to={`/events/${event.token}`}>
                          <figure>
                            {event.rentals[0].pictures[0] ? (
                              <Imgix
                                src={event.rentals[0].pictures[0]}
                                alt="event_image"
                                width={510}
                                height={288}
                              />
                            ) : (
                              <TapGoods />
                            )}
                          </figure>
                          <h3>
                            {event.name}
                            {event.unreadMessagesCount > 0 && (
                              <div className="unreadCount">
                                <MessageBubble />
                                <label>{event.unreadMessagesCount}</label>
                              </div>
                            )}
                          </h3>
                          <strong>
                            <FormattedDate
                              day="numeric"
                              month="numeric"
                              year="numeric"
                              value={event.eventStartDateTime}
                            />
                          </strong>
                          {type !== "pending" && type !== "expired" ? (
                            <div className="summary">
                              <div>
                                <label>Event Total</label>
                                <CurrencyLabel
                                  value={event.eventTotal}
                                  locale={locale}
                                />
                                <label>Balance Due</label>
                                <CurrencyLabel
                                  value={event.balanceDue}
                                  locale={locale}
                                />
                                <label>Action Required</label>
                                <span>
                                  {actionCount > 0 ? (
                                    <span>{actionCount} Items</span>
                                  ) : (
                                    <span>None</span>
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="summary">
                              {event.timeLeftToExpire <= 0 ? (
                                <div>
                                  <button
                                    onClick={() => this.extendExpiration(event)}
                                  >
                                    Extend Expiration by 24 Hours
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <label>Expires in</label>
                                  {humanizeTimeLeftToExpire(
                                    event.timeLeftToExpire
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </Link>
                      );
                    })}
                  </div>
                ))}
              </div>
            }
          />
        ) : (
          <p className="noItems">There are currently no events.</p>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  const { events, loading } = state.eventList;

  return {
    events: events,
    filter: newFilter,
    loading
  };
};

export default withRouter(connect(mapStateToProps, actions)(EventsTable));
