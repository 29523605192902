import axios from "axios";
import {
  FETCH_EVENT_LIST_REQUEST,
  FETCH_EVENT_LIST_SUCCESS,
  FETCH_EVENT_LIST_FAILURE,
  SET_EVENT_LIST
} from "Constants/redux";

import { saveAuthToken, getAuthToken, saveError } from "Api";

import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";

export const fetchEventList = params => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner("Loading events..."));
    let url = process.env.REACT_APP_API_DOMAIN + "/api/marketplace/events/list";

    dispatch({
      type: FETCH_EVENT_LIST_REQUEST
    });
    return axios
      .get(url, {
        params: params,
        headers: getAuthToken()
      })
      .then(response => {
        saveAuthToken(response.headers);
        dispatch(closeLoadingSpinner());
        const { events } = response.data;
        dispatch({
          type: FETCH_EVENT_LIST_SUCCESS,
          payload: { events }
        });
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: FETCH_EVENT_LIST_FAILURE
        });
        saveError(error, getState());
      });
  };
};

export const setEventList = events => {
  return {
    type: SET_EVENT_LIST,
    payload: { events }
  };
};
