import React from "react";
import PasswordResetForm from "Components/home/PasswordResetForm";
import * as actions from "Actions";
import { connect } from "react-redux";
import { parseQuery } from "HelperFunctions/QueryString";

class PasswordReset extends React.Component {
  handleSubmit = data => {
    console.log(data);
    this.props.resetPassword({
      ...data,
      query: parseQuery(this.props.location.search)
    });
  };
  render() {
    return (
      <div className="passwordReset">
        <header>
          <h1>Password Reset</h1>
        </header>
        <section>
          <PasswordResetForm onSubmit={this.handleSubmit} />
        </section>
      </div>
    );
  }
}

export default connect(null, actions)(PasswordReset);
