import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { uniq } from "lodash";
import { FormattedDate, FormattedTime } from "react-intl";
import converter from "json-style-converter/es5";
import classnames from "classnames";
import * as actions from "Actions";
import { customerDeliveryTypes } from "Constants/displayConstants";
import CartItem from "Components/cart/CartItem";
import { CartOutline, ListView, ImageView } from "Utils/SvgIcons";
import { combineDateAndTime } from "HelperFunctions/general";
import { showSelectedPrice } from "HelperFunctions/rental_items";
import { formatPhoneNumber, toNumber } from "HelperFunctions/general";
import moment from "moment";
import CurrencyLabel from "Utils/CurrencyLabel";

class CartItems extends React.Component {
  handleItemChange = (e, businessKey, itemIndex) => {
    const { event, setCartProps } = this.props;
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
    const { name, value } = e.target;

    let newItems = event.items[businessKey].slice();
    let beforeAttributes = event;

    newItems[itemIndex] = {
      ...newItems[itemIndex],
      [name]: toNumber(value),
      changeRequest: true
    };

    const selected = showSelectedPrice(
      newItems[itemIndex].product,
      newItems[itemIndex].quantity,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime),
      "items"
    );

    newItems[itemIndex] = {
      ...newItems[itemIndex],
      selectedPrice: selected.total,
      selectedRate: selected.rate,
      duration: selected.duration,
      period: selected.period
    };

    setCartProps({
      items: {
        ...event.items,
        [businessKey]: newItems
      }
    });
  };

  handleItemRemove = (businessKey, itemIndex) => {
    const { event, setCartProps } = this.props;
    let newItems = { ...event.items };

    if (newItems[businessKey].length === 1) {
      delete newItems[businessKey];
    } else {
      newItems[businessKey].splice(itemIndex, 1);
      newItems[businessKey][0] = {
        ...newItems[businessKey][0],
        changeRequest: true
      };
    }

    setCartProps({
      items: newItems
    });
  };

  handleRentalBundleChange = (e, businessKey, rentalBundleIndex) => {
    const { updateBundleInCart } = this.props;
    const { name, value } = e.target;
    updateBundleInCart(name, value, rentalBundleIndex, businessKey);
  };

  handleRentalBundleRemove = (businessKey, rentalBundleIndex) => {
    const { event, setCartProps } = this.props;
    let newRentalBundles = { ...event.rentalBundles };
    if (newRentalBundles[businessKey].length === 1) {
      delete newRentalBundles[businessKey];
    } else {
      newRentalBundles[businessKey].splice(rentalBundleIndex, 1);
    }
    setCartProps({
      rentalBundles: newRentalBundles
    });
  };

  render() {
    const {
      event,
      customer,
      tileView,
      showTiles,
      hideTiles,
      stopAddingItemsToEvent
    } = this.props;

    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      items,
      rentalBundles,
      deliveryType,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry
    } = event;
    const timeZone = moment.tz.guess(eventEndTime);
    const businessKeys = uniq([
      ...Object.keys(items),
      ...Object.keys(rentalBundles)
    ]);

    return (
      <section
        className={classnames({
          itemForm: true,
          noItems: Object.values(items).length === 0
        })}
      >
        {businessKeys.length === 0 ? (
          <div className="prompt">
            <CartOutline />
            <p>You haven’t added anything to your cart.</p>
            <Link className="btn" to="/shop/search">
              Shop Now
            </Link>
          </div>
        ) : (
          <div>
            <div className="eventDetails">
              <div className="title">
                <h2>{event.name}</h2>
                <h3>
                  <FormattedDate
                    value={eventStart}
                    month="numeric"
                    day="numeric"
                  />{" "}
                  <FormattedTime
                    value={eventStartTime}
                    timeZone={timeZone}
                    timeZoneName="short"
                  />
                  {" - "}
                  <FormattedDate
                    value={eventEnd}
                    month="numeric"
                    year="numeric"
                    day="numeric"
                  />{" "}
                  <FormattedTime
                    value={eventEndTime}
                    timeZone={timeZone}
                    timeZoneName="short"
                  />
                </h3>
              </div>
              {customer.id && (
                <div className="contact">
                  <label>Contact:</label>
                  <strong>
                    {customer.firstName} {customer.lastName}
                  </strong>
                  <div>{customer.email}</div>
                  {customer.cell && (
                    <div>{formatPhoneNumber(customer.cell)}</div>
                  )}
                  {customer.streetAddress1 && (
                    <div>
                      {customer.streetAddress1}{" "}
                      {customer.streetAddress2 && (
                        <div>{customer.streetAddress2}</div>
                      )}
                      <div>
                        {customer.city}
                        {", "}
                        {customer.locale} {customer.postalCode}
                        {", "}
                        {customer.country}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="delivery">
                <label>{customerDeliveryTypes[deliveryType]}</label>
                {["default_delivery", "ship"].includes(deliveryType) && (
                  <div>
                    <div>
                      {deliveryAddressStreetAddress1}{" "}
                      {deliveryAddressStreetAddress2}
                    </div>
                    <div>
                      {deliveryAddressCity}, {deliveryAddressLocale}{" "}
                      {deliveryAddressPostalCode}, {deliveryAddressCountry}
                    </div>
                  </div>
                )}
              </div>
              <button className="btn" onClick={stopAddingItemsToEvent}>
                Make This Item Quote a New Event
              </button>
            </div>
            <div className="items">
              <h2>Items</h2>
              <div className="tileToggle">
                <a
                  onClick={hideTiles}
                  className={classnames({
                    active: !tileView
                  })}
                >
                  <ListView />
                </a>
                <a
                  onClick={showTiles}
                  className={classnames({
                    active: tileView
                  })}
                >
                  <ImageView />
                </a>
              </div>
              {businessKeys.length > 0 &&
                businessKeys.map((key, index) => {
                  const businessRentalItems = items[key] || [];
                  const businessRentalBundles = rentalBundles[key] || [];
                  const itemTotal = businessRentalItems.reduce(
                    (sum, item) => (sum += toNumber(item.selectedPrice)),
                    0
                  );
                  const rentalBundleTotal = businessRentalBundles.reduce(
                    (sum, item) => (sum += toNumber(item.selectedPrice)),
                    0
                  );
                  const total = itemTotal + rentalBundleTotal;
                  return (
                    <div key={index} className="companyItems">
                      <strong>
                        Items from: <span>{key}</span>
                      </strong>
                      <table
                        className={classnames({
                          grid: true,
                          thumbnail: tileView
                        })}
                      >
                        <tbody>
                          {businessRentalItems.map((item, itemIndex) => {
                            if (item._destroy === "1") {
                              return null;
                            } else {
                              return (
                                <CartItem
                                  businessKey={key}
                                  key={itemIndex}
                                  item={item}
                                  itemIndex={itemIndex}
                                  tileView={tileView}
                                  onItemChange={this.handleItemChange}
                                  onItemRemove={this.handleItemRemove}
                                />
                              );
                            }
                          })}
                          {businessRentalBundles.map((item, itemIndex) => {
                            if (item._destroy === "1") {
                              return null;
                            } else {
                              return (
                                <CartItem
                                  businessKey={key}
                                  key={itemIndex}
                                  item={item}
                                  itemIndex={itemIndex}
                                  tileView={tileView}
                                  onItemChange={this.handleRentalBundleChange}
                                  onItemRemove={this.handleRentalBundleRemove}
                                />
                              );
                            }
                          })}
                        </tbody>
                      </table>
                      <div className="billingDetails">
                        <div>
                          <label>Estimated Delivery</label>
                          <span>TBD</span>
                        </div>
                        <div className="subtotal">
                          <label>Item Total</label>
                          <CurrencyLabel value={total} />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { event } = state.cart;
  const { errors } = state.dashboard;
  const { tileView } = state.products;
  const { customer } = state.customer;
  return { event, customer, tileView, errors };
};

export default connect(mapStateToProps, actions)(CartItems);
