import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import classnames from "classnames";
import {
  Accents,
  Balloons,
  Banners,
  Bars,
  Chairs,
  Drinks,
  Lighting,
  Linens,
  MirroredBall,
  Photobooth,
  Seating,
  Tabletop,
  Tents
} from "Utils/SvgIcons";

class Categories extends React.Component {
  componentDidMount() {
    //this.props.fetchFeaturedCategories();
    //this.props.fetchAllCategories();
  }
  render() {
    //const { featuredCategories, allCategories } = this.props;
    //const largeCategories = featuredCategories.filter(
    //  fc => fc.size === "large"
    //);
    //const smallCategories = featuredCategories.filter(
    //  fc => fc.size === "small"
    //);
    return (
      <div
        className={classnames({
          categories: true,
          shopLanding: window.location.pathname === "/shop"
        })}
      >
        <ul className="large">
          <li>
            <Link to="/shop/search?selectedCategories=4">
              <Tabletop />
              <label>Tabletop</label>
            </Link>
          </li>
          <li>
            <Link to="/shop/search?selectedCategories=8">
              <Seating />
              <label>Specialty Seating</label>
            </Link>
          </li>
          <li>
            <Link to="/shop/search?selectedCategories=10">
              <Bars />
              <label>Bars</label>
            </Link>
          </li>
        </ul>
        <ul className="small">
          <li>
            <Link to="/shop/search?selectedCategories=21">
              <Tents />
              <label>Tents</label>
            </Link>
          </li>
          <li>
            <Link to="/shop/search?selectedCategories=3">
              <Linens />
              <label>Linens</label>
            </Link>
          </li>
          <li>
            <Link to="/shop/search?selectedCategories=2">
              <Chairs />
              <label>Chairs</label>
            </Link>
          </li>
          <li>
            <Link to="/shop/search?selectedCategories=6">
              <Accents />
              <label>Accents</label>
            </Link>
          </li>
        </ul>
        <section className="fullListing">
          <h1>
            All Categories
            <Link to="/shop/search">Shop All Categories</Link>
          </h1>
          <ul>
            <li>
              <Balloons />
              <h2>Decorate Like a Pro</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=23">
                    Confetti & Balloons
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=6">Accents</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=19">
                    Accents - Holiday
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=25">
                    Accents - Themed
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Banners />
              <h2>Spruce It Up</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=30">Plants</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=18">
                    Floral & Containers
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=24">
                    Accents - Hanging
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Drinks />
              <h2>Eat, Drink, Be Happy</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=10">Bars</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=15">
                    Catering & Utility
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Tabletop />
              <h2>Set the Table</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=2">Chairs</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=1">Tables</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=3">Linens</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=4">Tabletop</Link>
                </li>
              </ul>
            </li>
            <li>
              <Tents />
              <h2>Step Outside</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=14">
                    Venues & Outdoors
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=26">
                    Accents - Ceremony
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=21">Tents</Link>
                </li>
              </ul>
            </li>
            <li>
              <Seating />
              <h2>Lounge Around</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=8">
                    Specialty Seating
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=29">
                    Children's Furniture
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Photobooth />
              <h2>Game On</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=17">Services</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=11">
                    Party Activities & Entertainment
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=28">
                    Printing & Laser
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <MirroredBall />
              <h2>Bust a Move</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=20">
                    Audio & Video
                  </Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=22">DJs</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=12">Flooring</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=9">Stages</Link>
                </li>
              </ul>
            </li>
            <li>
              <Lighting />
              <h2>Create Ambience</h2>
              <ul>
                <li>
                  <Link to="/shop/search?selectedCategories=16">Lighting</Link>
                </li>
                <li>
                  <Link to="/shop/search?selectedCategories=13">
                    Pipe & Drape
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { allCategories, featuredCategories } = state.categoryList;
  return { featuredCategories, allCategories };
};
export default connect(mapStateToProps, actions)(Categories);
