import React from "react";
import Rental from "./Rental";
import moment from "moment-timezone";
import {
  nestedStateForRailsWithConversion,
  prepopulateArray
} from "HelperFunctions/formHelpers";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import LoadingSpinner from "Utils/LoadingSpinner";
import Modal from "Utils/Modal";
import { Edit } from "Utils/SvgIcons";
import axios from "axios";
import { getAuthToken } from "Api";
import { objectToFormData } from "HelperFunctions/formHelpers";
import { FormattedDate } from "react-intl";
import { history } from "Components/Routes";
import ChangeRequestData from "./change_request/ChangeRequestData";

class RentalContainer extends React.Component {
  constructor(props) {
    super(props);
    const initialState = Object.assign({
      cancelModalOpen: false,
      tab: 0,
      rental: {},
      loaded: false,
      hours: 0,
      name: "",
      status: "",
      schedule_id: "",
      checkout: null,
      checkoutWindowEnd: null,
      checkout_time: null,
      checkin: null,
      checkinWindowStart: null,
      checkin_time: null,
      event_start: null,
      event_start_time: null,
      event_end: null,
      event_end_time: null,
      taxExempt: false,
      depositRequired: false,
      signatureRequired: false,
      npsRequired: false,
      hasAgreement: true,
      manualDamageWaiver: false,
      depositType: "optional",
      expireType: "no_expire",
      customExpireDate: null,
      delivery_cost: "",
      delivery_type: "select_a_delivery_method",
      delivery_address_location_name: "",
      delivery_address_street_address_1: "",
      delivery_address_street_address_2: "",
      delivery_address_city: "",
      delivery_address_locale: "",
      delivery_address_postal_code: "",
      delivery_address_country: "",
      shouldRecalcDelivery: true,
      fees: [],
      last_fee_id: 0,
      expenses: [],
      last_expense_id: 0,
      notes: [],
      last_note_id: 0,

      customers: [],
      last_customer_id: 0,

      discounts: [],
      last_discount_id: 0,

      items: [],
      last_item_id: 0,

      rentalAccessories: [],
      last_rental_accessory_id: 0,

      rentalAddOns: [],
      last_rental_add_on_id: 0,

      rentalBundles: [],
      last_rental_bundle_id: 0,

      documents: [],
      last_document_id: 0,
      employee_rental_relationship: null,
      damage_waiver_fee_total: 0,
      templateOpen: false,
      customersOpen: false,
      discountsOpen: false,
      salespersonOpen: false,
      venueOpen: false,
      companyOpen: false,
      accessoriesOpen: false,
      addOnsOpen: false,
      itemsOpen: false,
      bundlesOpen: false,
      taxExemptDialogOpen: false,
      width: 400,
      canLeave: true,
      sameAsEventDates: false,
      paymentOpen: false,
      documentModalOpen: false,
      signModalOpen: false
    });
    this.state = initialState;
  }

  componentDidMount() {
    const {
      match: {
        params: { rentalId }
      }
    } = this.props;
    this.prepopulateRental(rentalId);
  }

  refreshRental = () => {
    const {
      match: {
        params: { rentalId }
      }
    } = this.props;
    this.prepopulateRental(rentalId);
  };

  prepopulateRental = id => {
    const { openLoadingSpinner, closeLoadingSpinner } = this.props;
    openLoadingSpinner("Loading rental...");
    this.setState({ loading: true });
    this.fetchRental(id)
      .then(response => {
        let rental = response.data.rental;
        closeLoadingSpinner();
        this.fillNewRentalData(rental);
        this.setState({
          loading: false
        });
      })
      .catch(() => {
        history.push("/marketplace");
      });
  };

  fetchRental = id => {
    return axios.get(
      process.env.REACT_APP_API_DOMAIN + "/api/portal/rentals/" + id,
      { headers: getAuthToken() }
    );
  };

  updateRental = (data, onSuccess) => {
    const { openLoadingSpinner, closeLoadingSpinner } = this.props;
    openLoadingSpinner("Updating rental...");
    const params = objectToFormData({ rental: data });

    axios
      .patch(
        process.env.REACT_APP_API_DOMAIN +
          "/api/portal/rentals/" +
          this.props.match.params.rentalId,
        params
      )
      .then(response => {
        let rental = response.data.rental;
        closeLoadingSpinner();
        this.fillNewRentalData(rental);
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch(error => {
        const errors = error.response.data;
        this.props.setErrors(errors);
      });
  };
  recalcDamageWaiverFee = () => {
    this.beforeAttributes = this.build_rails_object();
  };

  fillNewRentalData = (rental, overwriteStatus = true) => {
    const component = this;

    let nextItemId = component.state.last_item_id + 1;
    let ItemResult = prepopulateArray(
      nextItemId,
      rental.rentalItems,
      "rental_item"
    );
    nextItemId = ItemResult[1];
    let newItems = ItemResult[0];
    newItems = newItems.map(product => {
      const rentalItem = product.rental_item;
      const rate = rentalItem.selectedPrice / rentalItem.quantity;
      const duration = 1;

      const newRentalItem = Object.assign(rentalItem, {
        duration: duration,
        selectedRate: rate
      });
      product.rental_item = newRentalItem;
      return product;
    });

    let timeDiffhours;
    if (
      rental.schedule.eventStartDateTime &&
      rental.schedule.eventEndDateTime
    ) {
      const timeDiffms =
        new Date(rental.schedule.eventEndDateTime) -
        new Date(rental.schedule.eventStartDateTime);
      timeDiffhours = timeDiffms / 1000 / 60 / 60;
    } else {
      timeDiffhours = 0;
    }

    let newState = Object.assign(
      component.state,
      {
        hours: timeDiffhours,
        rental: rental,
        taxExempt: rental.taxExempt,
        depositRequired: rental.depositRequired,
        depositType: rental.depositType,
        expireType: rental.expireType,
        customExpireDate: rental.customExpireDate
          ? new Date(rental.customExpireDate)
          : null,
        signatureRequired: rental.signatureRequired,
        npsRequired: rental.npsRequired,
        hasAgreement: rental.hasAgreement,
        manualDamageWaiver: rental.manualDamageWaiver,
        damage_waiver_fee_total: rental.damageWaiverFeeTotal,
        items: newItems,
        last_item_id: nextItemId,
        sameAsEventDates: rental.schedule.sameAsEventDate,
        shouldRecalcDelivery: false,

        delivery_address_location_name:
          rental.deliveryAddressLocationName || "",
        delivery_address_street_address_1:
          rental.deliveryAddressStreetAddress1 || "",
        delivery_address_street_address_2:
          rental.deliveryAddressStreetAddress2 || "",
        delivery_address_city: rental.deliveryAddressCity || "",
        delivery_address_locale: rental.deliveryAddressLocale || "",
        delivery_address_postal_code: rental.deliveryAddressPostalCode || "",
        delivery_address_country: rental.deliveryAddressCountry || ""
      },
      overwriteStatus && { status: rental.status },
      rental.name && { name: rental.name },
      //Schedule

      rental.schedule.id && { schedule_id: rental.schedule.id },
      rental.schedule.startDate && {
        checkout: new Date(rental.schedule.startDate),
        start_date_time: new Date(rental.schedule.startDate)
      },
      rental.schedule.startTime && {
        checkout_time: new Date(rental.schedule.startTime)
      },
      rental.schedule.startWindowFinish && {
        checkoutWindowEnd: new Date(rental.schedule.startWindowFinish)
      },
      rental.schedule.endDate && {
        checkin: new Date(rental.schedule.endDate),
        end_date_time: new Date(rental.schedule.endDate)
      },
      rental.schedule.endTime && {
        checkin_time: new Date(rental.schedule.endTime)
      },
      rental.schedule.endWindowBeginning && {
        checkinWindowStart: new Date(rental.schedule.endWindowBeginning)
      },
      rental.schedule.eventStartDateTime && {
        event_start: new Date(rental.schedule.eventStartDateTime),
        event_start_time: new Date(rental.schedule.eventStartDateTime),
        event_start_date_time: new Date(rental.schedule.eventStartDateTime)
      },
      rental.schedule.eventEndDateTime && {
        event_end: new Date(rental.schedule.eventEndDateTime),
        event_end_time: new Date(rental.schedule.eventEndDateTime),
        event_end_date_time: new Date(rental.schedule.eventEndDateTime)
      },
      rental.deliveryCost && { delivery_cost: rental.deliveryCost },
      rental.deliveryType && { delivery_type: rental.deliveryType }
    );
    component.setState(newState, this.recalcDamageWaiverFee);
  };
  addDocument = document => {
    const data = {
      document_rental_relationships_attributes: {
        "0": document
      }
    };
    this.updateRental(data, response => {
      this.toggleDocumentModal();
      this.props.openSuccessSnackBar("Successfully uploaded the document.");
    });
  };
  handleSubmitSignature = ({ signature, signerName }) => {
    const { openLoadingSpinner, closeLoadingSpinner } = this.props;
    openLoadingSpinner("Submitting signature...");
    const data = {
      signature: signature,
      signer_name: signerName
    };
    const component = this;
    const params = objectToFormData({ rental: data });
    axios
      .patch(
        `${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${this.props.match.params.rentalId}/submit_signature`,
        params
      )
      .then(response => {
        let rental = response.data.rental;
        closeLoadingSpinner();
        this.fillNewRentalData(rental);
        component.toggleSignModal();
      })
      .catch(error => {
        console.log(error);
        const errors = error.response.data;
        this.props.setErrors(errors);
      });
  };

  build_rails_object = () => {
    const {
      items,
      schedule_id,
      start_date_time,
      end_date_time,
      checkoutWindowEnd,
      checkinWindowStart,
      event_start_date_time,
      event_end_date_time,
      sameAsEventDates,
      delivery_cost,
      delivery_type,

      delivery_address_location_name,
      delivery_address_street_address_1,
      delivery_address_street_address_2,
      delivery_address_city,
      delivery_address_locale,
      delivery_address_postal_code,
      delivery_address_country,
      damage_waiver_fee_total
    } = this.state;
    const timeZone = moment.tz.guess();

    let rental = {
      active: true,
      delivery_cost: delivery_cost,
      delivery_type: delivery_type,
      delivery_address_location_name: delivery_address_location_name,
      delivery_address_street_address_1: delivery_address_street_address_1,
      delivery_address_street_address_2: delivery_address_street_address_2,
      delivery_address_city: delivery_address_city,
      delivery_address_locale: delivery_address_locale,
      delivery_address_postal_code: delivery_address_postal_code,
      delivery_address_country: delivery_address_country
    };
    let schedule = Object.assign(
      {
        start_date_time: start_date_time,
        end_date_time: end_date_time,
        start_window_finish: checkoutWindowEnd,
        end_window_beginning: checkinWindowStart,
        event_start_date_time: event_start_date_time,
        event_end_date_time: event_end_date_time,
        same_as_event_date: sameAsEventDates,
        time_zone: timeZone
      },
      schedule_id && { id: schedule_id }
    );

    const rental_item_standards = items.filter(
      item => item.rental_item.type === "RentalItemStandard"
    );
    let formatted_rental_item_standards = nestedStateForRailsWithConversion(
      rental_item_standards.slice(),
      "rental_item"
    );

    /*eslint-disable */
    rental = Object.assign(rental, {
      damage_waiver_fee_total: damage_waiver_fee_total,
      schedule_attributes: schedule,
      rental_item_standards_attributes: formatted_rental_item_standards
    });
    return rental;
  };

  requestCancel = () => {
    const component = this;
    const { history, openLoadingSpinner, closeLoadingSpinner } = this.props;
    openLoadingSpinner("Cancelling the event...");
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN +
          "/api/portal/rentals/" +
          this.props.match.params.rentalId +
          "/request_cancel",
        {},
        { headers: getAuthToken() }
      )
      .then(response => {
        let rental = response.data.rental;
        closeLoadingSpinner();
        component.toggleCancelModal();
        component.fillNewRentalData(rental);
      })
      .catch(error => {
        const errors = error.response.data;
        this.props.setErrors(errors);
      });
  };

  handleSubmitQuote = rental => {
    const { event } = this.state;
    const { history, submitQuote } = this.props;
    submitQuote(event.id, rental.id);
    history.push(`/events/${event.id}/rentals`);
  };

  handleDeliveryChangeSubmit = state => {
    const {
      deliveryType,
      deliveryAddressLocationName,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry,
      checkout,
      checkoutWindowEnd,
      checkoutTime,
      checkin,
      checkinWindowStart,
      checkinTime,
      sameAsEventDates
    } = state;

    const newState = Object.assign(
      {
        start_date_time: checkout,
        end_date_time: checkin,
        checkout,
        checkoutWindowEnd,
        checkout_time: checkout,
        checkin,
        checkinWindowStart,
        checkin_time: checkin,
        sameAsEventDates,
        delivery_type: deliveryType,
        delivery_address_location_name: deliveryAddressLocationName,
        delivery_address_street_address_1: deliveryAddressStreetAddress1,
        delivery_address_street_address_2: deliveryAddressStreetAddress2,
        delivery_address_city: deliveryAddressCity,
        delivery_address_locale: deliveryAddressLocale,
        delivery_address_postal_code: deliveryAddressPostalCode,
        delivery_address_country: deliveryAddressCountry,
        canLeave: false
      },
      deliveryType === "customer_pick_up" && { deliveryCost: 0 }
    );
    this.setState(newState);
  };

  adjustRentalItems = (rentalItem, index) => {
    let items = this.state.items.slice();
    if (rentalItem.quantity === "") {
      rentalItem.quantity = 0;
    }
    const newRentalItem = Object.assign(items[index].rental_item, {
      ...rentalItem
    });
    items[index].rental_item = newRentalItem;
    this.setState({
      items: items,
      canLeave: false
    });
  };

  deleteRentalItem = index => {
    let items = this.state.items.slice();
    items[index].rental_item = Object.assign(items[index].rental_item, {
      _destroy: "1"
    });
    this.setState({
      items: items,
      canLeave: false
    });
  };

  renderSelectItem = item => {
    return <div>{item.businessName}</div>;
  };

  toggleCancelModal = () => {
    this.setState({
      cancelModalOpen: !this.state.cancelModalOpen
    });
  };

  togglePayment = () => {
    this.setState({
      paymentOpen: !this.state.paymentOpen
    });
  };

  toggleDocumentModal = () => {
    this.setState({
      documentModalOpen: !this.state.documentModalOpen
    });
  };

  toggleSignModal = () => {
    this.setState({
      signModalOpen: !this.state.signModalOpen
    });
  };

  render() {
    const { rental, loading, canLeave, cancelModalOpen } = this.state;
    const {
      match: {
        params: { id, rentalId }
      },
      history
    } = this.props;

    if (loading || !rental.id) {
      return <LoadingSpinner />;
    } else {
      return (
        <div className="rental">
          <header>
            <div className="details">
              <h1>{rental.name}</h1>
              <h3>
                <FormattedDate
                  value={rental.startDate}
                  month="short"
                  day="numeric"
                />
                {" - "}
                <FormattedDate
                  value={rental.endDate}
                  month="short"
                  year="numeric"
                  day="numeric"
                />
              </h3>
            </div>
            {rental.changesAllowed &&
              rental.status !== "cancelled" &&
              !rental.cancelRequested && (
                <div className="right">
                  <a className="btn outline" onClick={this.toggleCancelModal}>
                    Cancel Rental
                  </a>
                  <Link
                    className="btn secondary"
                    to={`/events/${id}/rentals/${rentalId}/edit`}
                  >
                    <Edit />
                    Request Change To Order
                  </Link>
                </div>
              )}
          </header>
          <ChangeRequestData  rentalId={rental.id} changeRequest={rental.changeRequest} />
          <Rental
            rental={rental}
            fields={this.state}
            onDeliveryChangeSubmit={this.handleDeliveryChangeSubmit}
            adjustRentalItems={this.adjustRentalItems}
            deleteRentalItem={this.deleteRentalItem}
            addDocument={this.addDocument}
            onSubmitSignature={this.handleSubmitSignature}
            refreshRental={this.refreshRental}
            togglePayment={this.togglePayment}
            toggleDocumentModal={this.toggleDocumentModal}
            toggleSignModal={this.toggleSignModal}
          />

          <Modal
            title="Cancel Event"
            actions={[
              <button
                key="cancel"
                className="btn warn"
                onClick={this.requestCancel}
              >
                Yes, Send Cancellation Request
              </button>,
              <button
                key="no"
                className="btn cancel"
                onClick={this.toggleCancelModal}
              >
                No
              </button>
            ]}
            modal={false}
            open={cancelModalOpen}
            className="dialog"
            toggle={this.toggleCancelModal}
          >
            <p>
              Are you sure you want to request cancellation for this rental?
            </p>
          </Modal>
        </div>
      );
    }
  }
}

export default connect(null, actions)(RentalContainer);
