import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { get } from "lodash";
import { standardService } from "Api/service";
import * as actions from "Actions";
import { arraysEqual, debounce } from "HelperFunctions/general";
import { setFilterParams, filterFromPropsAndState } from "HelperFunctions/urls";
import PaginationBoxView from "Utils/PaginationBoxView";
import AvailableItemCard from "Components/shop/AvailableItemCard";
import { ArrowLeft } from "Utils/SvgIcons";

class CustomPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: props.filter,
      pagination: {},
      showcaseProducts: [],
      customPage: {
        picturings: [],
        photoCredits: []
      }
    };

    this.handleQueryChange = debounce(this.handleQueryChange, 500);
  }

  componentDidMount() {
    this.fetchCustomPage();
  }

  fetchShowcaseProducts = filter => {
    const { page } = filter;
    const component = this;
    standardService
      .get(
        process.env.REACT_APP_API_DOMAIN +
          "/api/marketplace/marketplace_custom_pages/" +
          component.state.customPage.id +
          "/showcase_products",
        {
          page: page,
          number_per: 9
        }
      )
      .then(response => {
        component.setState({
          showcaseProducts: response.data.showcaseProducts,
          pagination: response.data.meta.pagination
        });
      })
      .catch(error => {
        const errors = error.response.data;
        console.log(errors);
      });
  };

  fetchCustomPage = () => {
    const component = this;
    const navigationLink = this.props.match.params.id;
    standardService
      .get(
        process.env.REACT_APP_API_DOMAIN +
          "/api/marketplace/marketplace_custom_pages/page_from_navigation_link",
        { navigation_link: navigationLink }
      )
      .then(response => {
        const customPage = get(response, "data.marketplaceCustomPage");
        if (customPage) {
          component.setState(
            {
              customPage: response.data.marketplaceCustomPage
            },
            () => this.fetchShowcaseProducts(this.state.filter)
          );
        } else {
          component.props.history.push("/shop");
        }
      })
      .catch(error => {
        const errors = error.response.data;
        console.log(errors);
      });
  };

  componentWillReceiveProps(nextProps) {
    const oldFilter = this.props.filter;
    let newFilter = nextProps.filter;
    let changed = false;

    for (var property in newFilter) {
      if (newFilter.hasOwnProperty(property)) {
        if (newFilter[property] instanceof Array) {
          if (arraysEqual(newFilter[property], oldFilter[property]) === false) {
            changed = true;
            break;
          }
        } else {
          const newTest =
            newFilter[property] instanceof Date
              ? newFilter[property].getTime()
              : newFilter[property];
          const oldTest =
            oldFilter[property] instanceof Date
              ? oldFilter[property].getTime()
              : oldFilter[property];
          if (newTest !== oldTest) {
            changed = true;
            break;
          }
        }
      }
    }

    if (changed) {
      const filterToSubmit = Object.assign(newFilter);
      this.fetchShowcaseProducts(filterToSubmit);
    }
  }

  handleSearch = event => {
    const { value } = event.target;
    this.setState({
      filter: {
        ...this.state.filter,
        query: value
      }
    });
    this.handleQueryChange(value);
  };

  handleQueryChange(value) {
    const { location, history } = this.props;
    setFilterParams(
      {
        query: value
      },
      location,
      history
    );
  }

  handlePageChange = data => {
    const { location, history } = this.props;
    setFilterParams(
      {
        page: data.selected + 1
      },
      location,
      history
    );
  };

  renderTable = () => {
    const { showcaseProducts } = this.state;
    const apiClassToProductType = {
      Product: "items",
      AddOn: "addons",
      Bundle: "bundles"
    };
    let table = showcaseProducts.map(showcaseProduct => {
      const product = {
        ...showcaseProduct.product,
        productType: apiClassToProductType[showcaseProduct.productType]
      };
      return <AvailableItemCard key={showcaseProduct.id} product={product} />;
    });
    return table;
  };

  render() {
    const { filter, customPage, showcaseProducts, pagination } = this.state;

    return (
      <div className="getTheLook">
        <header>
          <a className="btn" href="/GettheLook">
            <ArrowLeft />
            Back
          </a>
          <h3>
            {customPage.navigationName}
            <a href={`/shop/search?locationId=${customPage.locationId}`}>
              Idea by {customPage.locationName}
            </a>
          </h3>
          <a
            className="btn outline"
            href={`/shop/search?locationId=${customPage.locationId}`}
          >
            Shop our Entire Collection
          </a>
        </header>
        {customPage.picturings.length > 0 && (
          <Carousel
            className="lookCarousel"
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={4000}
          >
            {customPage.picturings.map(picturing => (
              <figure>
                <img key={picturing.id} src={picturing.url} alt="" />
              </figure>
            ))}
          </Carousel>
        )}
        <div className="photoCredits">
          {customPage.photoCredits.map(photoCredit => (
            <div key={photoCredit.id}>
              <label>{photoCredit.role}:</label> {photoCredit.name}
            </div>
          ))}
        </div>
        <p>{customPage.heroDescription}</p>
        <section className="storeItems">
          {showcaseProducts.length > 0 && this.renderTable()}
          <PaginationBoxView
            className="pagerWrapper"
            containerClassName="pager"
            forcePage={filter.page ? +filter.page - 1 : 0}
            pageCount={pagination.totalPages}
            onPageChange={this.handlePageChange}
          />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  return {
    filter: newFilter
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, actions)
)(CustomPage);
