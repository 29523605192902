import React from "react";
import DefaultCurrencyInput from "react-number-format";
import { toNumber } from "HelperFunctions/general";

class CurrencyInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleValueChange = this.handleValueChange.bind(this);
  }
  handleValueChange(values) {
    const { name } = this.props;
    const FakeEvent = {
      target: {
        value: toNumber(values.value),
        name
      }
    };
    this.props.onChange(FakeEvent);
  }
  render() {
    const { name, placeholder, className, value, disabled } = this.props;
    return (
      <DefaultCurrencyInput
        prefix="$"
        name={name}
        thousandSeparator=""
        placeholder={placeholder}
        className={className}
        value={value}
        disabled={disabled}
        onValueChange={this.handleValueChange}
        decimalScale={2}
        fixedDecimalScale
      />
    );
  }
}

export default CurrencyInput;
