import React from "react";
import { render } from "react-dom";
import Root from "./components/Root";
import "./stylesheets/main.scss";
// import registerServiceWorker from "./registerServiceWorker";
import { unregister } from "./registerServiceWorker";

render(<Root />, document.getElementById("root"));
// registerServiceWorker();
unregister();

if (module.hot) {
  module.hot.accept("./components/Root", () => {
    const NextApp = require("./components/Root").default;
    render(<NextApp />, document.getElementById("root"));
  });
}
