import React from "react";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import { Carousel } from "react-responsive-carousel";
import DesktopImage1 from "../../images/marketing/Hero-Lounge.jpg";
import DesktopImage2 from "../../images/marketing/Hero-Tabletop.jpg";
import DesktopImage3 from "../../images/marketing/Hero-Tents.jpg";
import DesktopImage4 from "../../images/marketing/Hero-DJ_AV.jpg";
import DesktopImage5 from "../../images/marketing/Hero-Inflatables.jpg";
import MobileImage1 from "../../images/marketing/Mobile-Lounge.jpg";
import MobileImage2 from "../../images/marketing/Mobile-Tabletop.jpg";
import MobileImage3 from "../../images/marketing/Mobile-Tents.jpg";
import MobileImage4 from "../../images/marketing/Mobile-DJ_AV.jpg";
import MobileImage5 from "../../images/marketing/Mobile-Inflatables.jpg";
import DesktopInflatableImage1 from "../../images/marketing/Hero-Inflatables.jpg";
import DesktopInflatableImage2 from "../../images/marketing/Hero-Inflatables-2.jpg";
import DesktopInflatableImage3 from "../../images/marketing/Hero-Inflatables-3.jpg";
import DesktopInflatableImage4 from "../../images/marketing/Hero-Inflatables-4.jpg";
import DesktopInflatableImage5 from "../../images/marketing/Hero-Inflatables-5.jpg";
import MobileInflatableImage1 from "../../images/marketing/Mobile-Inflatables.jpg";
import MobileInflatableImage2 from "../../images/marketing/Mobile-Inflatables-2.jpg";
import MobileInflatableImage3 from "../../images/marketing/Mobile-Inflatables-3.jpg";
import MobileInflatableImage4 from "../../images/marketing/Mobile-Inflatables-4.jpg";
import MobileInflatableImage5 from "../../images/marketing/Mobile-Inflatables-5.jpg";

class HomeCarousel extends React.Component {
  pageImagesDesktop = () => {
    const { pathname } = this.props.location;
    switch (pathname) {
      case "/inflatables":
        return [
          DesktopInflatableImage1,
          DesktopInflatableImage2,
          DesktopInflatableImage3,
          DesktopInflatableImage4,
          DesktopInflatableImage5
        ];
      default:
        return [
          DesktopImage1,
          DesktopImage2,
          DesktopImage3,
          DesktopImage4,
          DesktopImage5
        ];
    }
  };

  pageImagesMobile = () => {
    const { pathname } = this.props.location;
    switch (pathname) {
      case "/inflatables":
        return [
          MobileInflatableImage1,
          MobileInflatableImage2,
          MobileInflatableImage3,
          MobileInflatableImage4,
          MobileInflatableImage5
        ];
      default:
        return [
          MobileImage1,
          MobileImage2,
          MobileImage3,
          MobileImage4,
          MobileImage5
        ];
    }
  };

  render() {
    return (
      <MediaBreakpoints
        desktop={
          <Carousel
            className="homeCarousel"
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={4000}
          >
            {this.pageImagesDesktop().map(element => {
              return <img src={element} alt="" />;
            })}
          </Carousel>
        }
        mobile={
          <Carousel
            className="homeCarousel"
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={4000}
          >
            {this.pageImagesMobile().map(image => {
              return <img src={image} alt="" />;
            })}
          </Carousel>
        }
      />
    );
  }
}

export default HomeCarousel;
