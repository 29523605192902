import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import PaginationBoxView from "Utils/PaginationBoxView";
import AvailableItemCard from "./AvailableItemCard";
import LoadingSpinner from "Utils/LoadingSpinner";
import { setFilterParams, filterFromPropsAndState } from "HelperFunctions/urls";
import LowItemCard from "./LowItemCard";
import NoItemsPage from "./NoItemsPage";

class AvailableItemList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      conversionTarget: "",
      conversionAlertOpen: false
    };
  }

  handlePageChange = data => {
    const { location, history } = this.props;
    setFilterParams(
      {
        page: data.selected + 1
      },
      location,
      history
    );
  };

  renderTable = () => {
    const { inventory, filter } = this.props;

    let table = inventory.map(product => (
      <AvailableItemCard key={product.id} product={product} />
    ));
    const itemCount = inventory.length;
    if (itemCount < Number(filter.numberPer) - 1) {
      table.push(<LowItemCard key="lowItemCard" />);
    }
    return table;
  };

  render() {
    const { inventory, pagination, filter, loading } = this.props;
    if (loading) {
      return <LoadingSpinner />;
    }

    return (
      <section className="storeItems">
        {inventory.length > 0 ? this.renderTable() : <NoItemsPage />}
        <PaginationBoxView
          className="pagerWrapper"
          containerClassName="pager"
          forcePage={filter.page ? +filter.page - 1 : 0}
          pageCount={pagination.totalPages}
          onPageChange={this.handlePageChange}
        />
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { inventory, pagination, loading, pagesRemaining } = state.products;
  const filter = filterFromPropsAndState(ownProps, state.products, true);
  return {
    inventory,
    pagination,
    loading,
    pagesRemaining,
    filter
  };
};

export default withRouter(connect(mapStateToProps, actions)(AvailableItemList));
