import axios from "axios";
import { saveAuthToken, getAuthToken } from "Api";
import { history as browserHistory } from "Components/Routes";
import { reset } from "redux-form";

import {
  FETCH_CONVERSATION_LIST_REQUEST,
  FETCH_CONVERSATION_LIST_SUCCESS,
  FETCH_CONVERSATION_LIST_FAILURE,
  CREATE_CONVERSATION_REQUEST,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_FAILURE,
  READ_CONVERSATION_REQUEST,
  READ_CONVERSATION_FAILURE,
  FETCH_CONVERSATION_REQUEST,
  FETCH_CONVERSATION_SUCCESS,
  FETCH_CONVERSATION_FAILURE,
  CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAILURE,
  ADD_MESSAGE,
  ADD_UNREAD_MESSAGE
} from "Constants/redux";

export const fetchConversationList = () => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_CONVERSATION_LIST_REQUEST
    });
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/api/portal/conversations/`, {
        headers: getAuthToken()
      })
      .then(response => {
        saveAuthToken(response.headers);
        dispatch({
          type: FETCH_CONVERSATION_LIST_SUCCESS,
          payload: {
            conversations: response.data.conversations,
            unreadMessages: response.data.conversations.reduce(
              (sum, conversation) =>
                (sum += parseInt(conversation.unreadMessages, 10)),
              0
            )
          }
        });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: FETCH_CONVERSATION_LIST_FAILURE });
      });
  };
};

export const createConversation = (
  title,
  body,
  sender_id,
  location_id,
  rental_id,
  forRental = false
) => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_CONVERSATION_REQUEST
    });
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/api/portal/conversations`,
        {
          conversation: {
            title: title,
            location_conversation_relationships_attributes: {
              "0": {
                location_id: location_id,
                role: "supplier"
              }
            },
            rental_conversation_relationships_attributes: {
              "0": {
                rental_id: rental_id,
                role: "supplier"
              }
            },
            messages_attributes: {
              "0": {
                body: body,
                sender_type: "Customer",
                sender_id: sender_id
              }
            }
          }
        },
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        saveAuthToken(response.headers);
        dispatch({
          type: CREATE_CONVERSATION_SUCCESS,
          payload: {
            conversation: response.data.conversation
          }
        });

        dispatch(reset("ConversationForm"));
        browserHistory.push(`/conversations/${response.data.conversation.id}`);
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: CREATE_CONVERSATION_FAILURE });
      });
  };
};

export const readConversation = id => {
  return (dispatch, getState) => {
    dispatch({
      type: READ_CONVERSATION_REQUEST
    });
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/api/portal/conversations/${id}/read`,
        {},
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        dispatch({
          type: FETCH_CONVERSATION_LIST_SUCCESS,
          payload: {
            conversations: response.data.conversations,
            unreadMessages: response.data.conversations.reduce(
              (sum, conversation) =>
                (sum += parseInt(conversation.unreadMessages, 10)),
              0
            )
          }
        });
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: READ_CONVERSATION_FAILURE });
      });
  };
};

// Called from the Conversation container
export const fetchConversation = id => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_CONVERSATION_REQUEST
    });
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/api/portal/conversations/${id}`,
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        saveAuthToken(response.headers);
        dispatch({
          type: FETCH_CONVERSATION_SUCCESS,
          payload: {
            conversation: response.data.conversation
          }
        });
        dispatch(readConversation(id));
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: FETCH_CONVERSATION_FAILURE });
      });
  };
};

export const createMessage = (conversationId, body) => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_MESSAGE_REQUEST
    });

    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/api/portal/conversations/${conversationId}/messages`,
        {
          message: {
            body: body,
            conversation_id: conversationId
          }
        },
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        saveAuthToken(response.headers);
        dispatch({
          type: CREATE_MESSAGE_SUCCESS,
          payload: {
            conversation: response.data.conversation
          }
        });
        dispatch(reset("NewMessage"));
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: CREATE_MESSAGE_FAILURE });
      });
  };
};

// export function setSelectedConversation(selectedConversation) {
//   return (dispatch, getState) => {
//     dispatch(fetchConversation(selectedConversation.id))
//     return {
//       type: SET_SELECTED_CONVERSATION,
//       payload: { selectedConversation }
//     };
//   }
// }

export const addMessage = data => {
  return {
    type: ADD_MESSAGE,
    payload: data.message
  };
};
export const addUnreadMessage = () => {
  return {
    type: ADD_UNREAD_MESSAGE
  };
};
