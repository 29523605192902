import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LoadingSpinner from "Utils/LoadingSpinner";
import * as actions from "Actions";
import { Documents } from "Utils/SvgIcons";
import Document from "./documents/Document";

// Fetch profile info from Redux customer, then display it.

class ProfileDocuments extends React.Component {
  componentDidMount() {
    const { fetchProfile } = this.props; // Redux
    fetchProfile();
  }

  render() {
    const { customer, deleteDocument, loading } = this.props;
    if (loading) {
      return <LoadingSpinner />;
    }
    return (
      <section className="documents">
        <h4>Documents</h4>
        <Documents />
        <div>
          <Link className="btn" to="/profile/documents/new">
            Upload A New Document
          </Link>
          {customer.documents && (
            <ul className="itemList">
              {customer.documents.map(doc => (
                <Document key={doc.id} doc={doc} onDelete={deleteDocument} />
              ))}
            </ul>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  const { customer, errors, loading } = state.customer;
  return { customer, errors, loading };
};

export default connect(mapStateToProps, actions)(ProfileDocuments);
