import {
  FETCH_INVENTORY_COLORS_REQUEST,
  FETCH_INVENTORY_COLORS_SUCCESS,
  FETCH_INVENTORY_COLORS_FAILURE
} from "Constants/redux/ColorListConstants";

const INITIAL_STATE = {
  colors: [],
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_INVENTORY_COLORS_REQUEST:
      return { ...state, loading: true };
    case FETCH_INVENTORY_COLORS_FAILURE:
      return { ...state, loading: false };
    case FETCH_INVENTORY_COLORS_SUCCESS:
      return {
        ...state,
        colors: action.response.data.colors,
        loading: false
      };
    default:
      return state;
  }
};
