import {
  FETCH_ALL_CATEGORIES_SUCCESS,
  FETCH_FEATURED_CATEGORIES_SUCCESS,
  FETCH_INVENTORY_CATEGORIES_REQUEST,
  FETCH_INVENTORY_CATEGORIES_SUCCESS,
  FETCH_INVENTORY_CATEGORIES_FAILURE
} from "Constants/redux/CategoryListConstants";

const INITIAL_STATE = {
  allCategories: [
    { id: 1, name: "Chairs" },
    { id: 2, name: "Tables" },
    { id: 3, name: "Special Seating" },
    { id: 4, name: "Lighting" }
  ],
  featuredCategories: [],
  itemCategories: [],
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_CATEGORIES_SUCCESS:
      return { ...state, allCategories: action.response.data.categories };
    case FETCH_FEATURED_CATEGORIES_SUCCESS:
      return {
        ...state,
        featuredCategories: action.response.data.featuredCategories
      };
    case FETCH_INVENTORY_CATEGORIES_REQUEST:
      return { ...state, loading: true };
    case FETCH_INVENTORY_CATEGORIES_FAILURE:
      return { ...state, loading: false };
    case FETCH_INVENTORY_CATEGORIES_SUCCESS:
      return {
        ...state,
        [`${action.productType}Categories`]: action.response.data,
        loading: false
      };
    default:
      return state;
  }
};
