import React from "react";
import { Route, Switch } from "react-router-dom";
import CustomPages from "./CustomPages";
import CustomPage from "./CustomPage";
import WithAuth from "Components/../hocs/WithAuth";

const GetTheLook = props => {
  return (
    <Switch>
      <Route exact path="/GettheLook" component={CustomPages} />
      <Route path="/GettheLook/:id" component={CustomPage} />
    </Switch>
  );
};

export default GetTheLook;
