import {
  SET_IN_SERVICE_AREA,
  SET_ZIP_CODE,
  SET_LOCALE
} from "Constants/redux";
import { geocodeFromLatLng } from "HelperFunctions/geocoder";
import { setErrors } from "./SnackbarActions";
import { setCartProps } from "./CartActions";
import { countryToLocale } from "Constants/currencyConstants";

export const setInServiceArea = (value = false) => {
  if (value) {
    localStorage.setItem("inServiceArea", true);
  } else {
    localStorage.removeItem("inServiceArea");
  }
  return {
    type: SET_IN_SERVICE_AREA,
    value: value
  };
};

export const restoreZipCodeIfNeeded = () => {
  return (dispatch, getState) => {
    const storedZip = getState().serviceArea.zipCode;
    if (storedZip) {
      return null;
    }

    let zipCode = localStorage.getItem("zipCode");
    if (zipCode === null) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(location) {
          geocodeFromLatLng(location.coords).then(
            response => {
              const address = response.results[0];
              const pc = address.address_components.filter(element => {
                return element.types[0] === "postal_code";
              });
              const zip = pc[0].long_name;
              dispatch(setZipCode(zip));
            },
            error => {
              return dispatch({
                type: SET_ZIP_CODE,
                value: ""
              });
            }
          );
        });
      } else {
        return dispatch({
          type: SET_ZIP_CODE,
          value: ""
        });
      }
    } else {
      return dispatch({
        type: SET_ZIP_CODE,
        value: zipCode
      });
    }
  };
};

export const restoreCountryIfNeeded = () => {
  return (dispatch, getState) => {
    const storedCountry = getState().cart.deliveryAddressCountry;
    if (storedCountry) {
      return null;
    }
    let country = localStorage.getItem("country");
    if (country === null) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(location) {
          console.log(location);
          geocodeFromLatLng(location.coords).then(
            response => {
              const address = response.results[0];
              console.log(address);
              const result = address.address_components.find(
                ac => ac.types[0] === "country"
              ).short_name;
              const mapper = {
                CA: "CAN",
                US: "USA"
              };
              let country = mapper[result] || "USA";
              localStorage.setItem("country", country);
              dispatch(setCartProps({ deliveryAddressCountry: country }));
              const locale = countryToLocale[country];
              dispatch(setLocale(locale));
            },
            error => {
              console.log(error);
              dispatch(setLocale("en-US"));
            }
          );
        });
      } else {
        dispatch(setLocale("en-US"));
      }
    } else {
      dispatch(setCartProps({ deliveryAddressCountry: country }));
      const locale = countryToLocale[country];
      dispatch(setLocale(locale));
    }
  };
};
export const setZipCode = value => {
  return (dispatch, getState) => {
    localStorage.setItem("zipCode", value);
    const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    if (isValidZip) {
      return dispatch({
        type: SET_ZIP_CODE,
        value: value
      });
    } else {
      return dispatch(setErrors("Invalid zip code."));
    }
  };
};

const setLocale = value => {
  return (dispatch, getState) => {
    return dispatch({
      type: SET_LOCALE,
      value: value
    });
  };
};
