import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import { Burst } from "Utils/SvgIcons";
import CurrencyLabel from "Utils/CurrencyLabel";

class EventBilling extends React.Component {
  renderTotalLabel(step) {
    switch (step) {
      case 1:
        return "Subtotal";
      case 2:
        return "Running Subtotal";
      case 3:
        return "Total";
      default:
        return "Subtotal";
    }
  }

  render() {
    const { step, event, onSelectStep, locale } = this.props;
    if (event.items.length === 0 && event.rentalBundles.length === 0 && event.addOns.length === 0) {
      return null;
    } else {
      const rentalInventory = event.items
        .concat(event.rentalBundles)
        .concat(event.addOns)
        .filter(item => item._destroy !== "1");
      const rentalItemTotal = rentalInventory.reduce(
          (total, item_container) =>
            total + parseFloat(item_container.selectedPrice),
          0
        );
      const deliveryCost = parseFloat(event.deliveryCost || 0);
      let damageWaiverFee = parseFloat(event.damageWaiverFeeTotal || 0);

      let subTotal = rentalItemTotal + damageWaiverFee + deliveryCost;
      // if (step < 3) {
      //   subTotal += deliveryCost;
      // }
      const business = event.items[0]
        ? event.items[0].product.businessInfo
        : event.rentalBundles[0].bundle.businessInfo;
      const businessTaxRate = business.physicalAddress
        ? parseFloat(
            business.physicalAddress.taxRate
              ? business.physicalAddress.taxRate
              : 0
          )
        : 0;
      const eventTaxRate =
        event.id && event.taxRate ? parseFloat(event.taxRate) : 0;
      const taxRate = event.id ? eventTaxRate : businessTaxRate;
      const taxTotal = event.taxExempt ? 0 : parseFloat(event.taxTotal);
      const eventTotal = subTotal + taxTotal;
      return (
        <section className="billing">
          {onSelectStep && (
            <div>
              {step === 4 && (
                <div className="complete">
                  <Burst />
                  <p>We updated your quote! (#{event.token})</p>
                </div>
              )}
            </div>
          )}
          {step < 4 && (
            <div className="billingSummary">
              <h2>Quote Summary</h2>
              <div className="billingDetails">
                <div>
                  <label>Items Subtotal</label>
                  <CurrencyLabel
                    value={rentalItemTotal}
                    locale={locale}
                  />
                </div>
                <div>
                  <label>Damage Waiver Fee</label>
                  <CurrencyLabel
                    value={damageWaiverFee}
                    locale={locale}
                  />
                </div>
                <div>
                  <label>Tax</label>
                  <CurrencyLabel
                    value={taxTotal}
                    locale={locale}
                  />
                </div>
                <div>
                  <label>Estimated Delivery</label>
                  <CurrencyLabel
                    value={deliveryCost}
                    locale={locale}
                  />
                </div>
                <div className="subtotal">
                  <label>{this.renderTotalLabel(step)}</label>
                  {step < 3 ? (
                    <CurrencyLabel
                      value={subTotal}
                      locale={locale}
                    />
                  ) : (
                    <CurrencyLabel
                      value={eventTotal}
                      locale={locale}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </section>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { rental } = state.rental;
  return { event: rental };
};

export default connect(mapStateToProps, actions)(EventBilling);
