import React from "react";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";
import * as actions from "Actions";

class SuccessPopUp extends React.Component {
  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.successOpen) {
      this._notificationSystem.addNotification({
        message: nextProps.successMessage,
        level: "success",
        position: "bc",
        autoDismiss: 3
      });
    }
  }
  render() {
    return <NotificationSystem ref="notificationSystem" style={false} />;
  }
}
const mapStateToProps = state => {
  const { successOpen, successMessage } = state.dashboard;

  return { successOpen, successMessage };
};

export default connect(mapStateToProps, actions)(SuccessPopUp);
