import React from "react";
import { withRouter, Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "Actions";
import { ArrowUp, ArrowDown, Cart } from "Utils/SvgIcons";
import { filterFromPropsAndState } from "HelperFunctions/urls";
import gps from "../../../images/ara/GPS.png";

class SignedOutNavAra extends React.Component {
  state = {
    featuredCustomPages: [],
    zipCode: {
      open: false,
      value: ""
    }
  };
  componentWillMount() {
    this.setState({
      zipCode: {
        ...this.state.zipCode,
        value: this.props.zipCode
      },
      featuredCustomPages: this.props.customPages.customPages
    });
  }
  componentDidMount() {
    this.props.fetchCustomPages(this.state.zipCode.value);
    this.setState({
      featuredCustomPages: this.props.customPages.customPages
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      zipCode: {
        ...this.state.zipCode,
        value: nextProps.zipCode
      },
      featuredCustomPages: this.props.customPages.customPages
    });
  }

  toggleZipCode = event => {
    this.setState({
      zipCode: {
        ...this.state.zipCode,
        open: !this.state.zipCode.open,
        anchorEl: event.currentTarget
      }
    });
  };
  handleZipCodeChange = event => {
    this.setState({
      zipCode: {
        ...this.state.zipCode,
        value: event.target.value
      }
    });
  };
  handleZipCodeSubmit = event => {
    event.preventDefault();
    this.props.setZipCode(this.state.zipCode.value);
    this.props.reloadInventory(this.props.filter);
    this.props.fetchCustomPages(this.state.zipCode.value);
  };

  render() {
    const { event } = this.props;
    const { featuredCustomPages, zipCode } = this.state;
    const itemCount = Object.values(event.items)
      .flat()
      .reduce(sum => sum + 1, 0);
    const bundleCount = Object.values(event.rentalBundles)
      .flat()
      .reduce(sum => sum + 1, 0);
    const addOnCount = Object.values(event.addOns)
      .flat()
      .reduce(sum => sum + 1, 0);
    const cartCount = itemCount + bundleCount + addOnCount;
    return (
      <nav className="mainMenu">
        <Link className="araLogo" to="/ara" />
        <div className="links">
          <NavLink exact to="/ara">
            Home
          </NavLink>
          <NavLink to="/shop">Shop</NavLink>
          <NavLink to="#">Discover</NavLink>
        </div>
        <div className="callouts">
          <div
            className="location"
            onMouseEnter={this.toggleZipCode}
            onMouseLeave={this.toggleZipCode}
          >
            <img src={gps} alt="" />
            {zipCode.value === "" ? "No Zip" : zipCode.value}
            {this.state.zipCode.open ? <ArrowUp /> : <ArrowDown />}
            {this.state.zipCode.open && (
              <div>
                <form onSubmit={this.handleZipCodeSubmit}>
                  <input
                    type="text"
                    placeholder="Zip"
                    value={this.state.zipCode.value}
                    onChange={this.handleZipCodeChange}
                  />
                  <input type="submit" value="Update Zip" className="btn" />
                </form>
              </div>
            )}
          </div>
          <NavLink className="icon cart" to="/cart">
            <Cart />
            <label>{cartCount > 0 && cartCount}</label>
          </NavLink>
        </div>
        <div className="buttons">
          <NavLink className="btn" to="/login">
            Sign In
          </NavLink>
          <NavLink className="btn outline" to="/signUp">
            Sign Up Free
          </NavLink>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { event } = state.cart;
  const { customPages } = state;
  const { zipCode } = state.serviceArea;
  const newFilter = filterFromPropsAndState(ownProps);
  const selectedCategories = newFilter.selectedCategories
    ? newFilter.selectedCategories.split(",")
    : [];
  const selectedSubCategories = newFilter.selectedSubCategories
    ? newFilter.selectedSubCategories.split(",")
    : [];
  return {
    filter: {
      ...newFilter,
      selectedCategories,
      selectedSubCategories
    },
    event,
    zipCode,
    customPages
  };
};

export default withRouter(connect(mapStateToProps, actions)(SignedOutNavAra));
