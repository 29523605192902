import React from "react";
import { connect } from "react-redux";
import Cart from "./Cart";
import CartAddItems from "./CartAddItems";

class CartMiddle extends React.Component {
  render() {
    const { addingItems } = this.props;
    if (addingItems) {
      return <CartAddItems />;
    } else {
      return <Cart />;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { addingItems } = state.cart;
  return { addingItems };
};

export default connect(mapStateToProps)(CartMiddle);
