import { LOCATION_CHANGE } from "react-router-redux";
import * as actionTypes from "Constants/redux";

function dashboard(
  state = {
    errors: "",
    errorsOpen: false,
    successOpen: false
  },
  action
) {
  switch (action.type) {
    case actionTypes.OPEN_SUCCESS_SNACKBAR:
      return Object.assign({}, state, {
        successOpen: action.payload.successOpen,
        successMessage: action.payload.successMessage
      });
    case actionTypes.CLOSE_SUCCESS_SNACKBAR:
      return Object.assign({}, state, {
        successOpen: action.payload
      });
    case actionTypes.SET_OPEN_VALIDATION_SNACKBAR:
      return Object.assign({}, state, {
        errorsOpen: action.payload
      });
    case actionTypes.SET_CLOSED_VALIDATION_SNACKBAR:
      return Object.assign({}, state, {
        errorsOpen: action.payload
      });
    case actionTypes.SET_ERRORS:
      return Object.assign({}, state, {
        errorsOpen: action.payload.errorsOpen,
        errors: action.payload.errors
      });
    case LOCATION_CHANGE:
      return {
        ...state,
        errors: "",
        errorsOpen: false
      };
    default:
      return state;
  }
}

export default dashboard;
