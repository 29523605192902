import React from "react";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import Checkbox from "Utils/Checkbox";
import Validator from "HelperFunctions/validator";
import PhoneInput from "Utils/redux_form_inputs/PhoneInputField";

const validations = {
  firstName: {
    required: {
      value: true,
      message: "First Name is required"
    }
  },
  email: {
    required: {
      value: true,
      message: "Email is required"
    }
  },
  password: {
    required: {
      value: true,
      message: "Password is required"
    }
  }
};

class SignUpForm extends React.Component {
  state = {
    showPassword: false
  };

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    const { showPassword } = this.state;
    return (
      <form onSubmit={handleSubmit} className="form">
        <div className="fields">
          <Field
            name="firstName"
            component={StandardTextField}
            type="text"
            placeholder="First Name *"
          />
          <Field
            name="lastName"
            component={StandardTextField}
            type="text"
            placeholder="Last Name"
          />
          <Field
            name="email"
            component={StandardTextField}
            type="text"
            placeholder="Email *"
          />
          <Field
            name="password"
            component={StandardTextField}
            type={showPassword ? "text" : "password"}
            placeholder="Password *"
          />
          <Field
            name="phone"
            component={PhoneInput}
            type="text"
            placeholder="Phone"
          />
          <Checkbox
            checked={showPassword}
            onCheck={this.toggleShowPassword}
            label="Show Password"
          />
        </div>
        <div className="actions">
          <button
            className="full"
            type="submit"
            disabled={pristine || submitting}
          >
            Sign Up
          </button>
        </div>
      </form>
    );
  }
}
export default reduxForm({
  form: "SignUp", // a unique identifier for this form
  validate: Validator(validations)
})(SignUpForm);
