import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import ConversationsDesktopSidePane from "./ConversationsDesktopSidePane";
import { Route, Switch } from "react-router-dom";
import IndexConversations from "Components/conversations/IndexConversations";
import Conversation from "Components/conversations/Conversation";
import NewConversation from "Components/conversations/NewConversation";

class Conversations extends React.Component {
  componentDidMount() {
    const { fetchConversationList } = this.props;
    fetchConversationList();
  }

  renderRoutes = () => {
    const { match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.path}`} component={IndexConversations} />
        <Route exact path={`${match.path}/new`} component={NewConversation} />
        <Route
          path={`${match.path}/:conversationId`}
          component={Conversation}
        />
      </Switch>
    );
  };

  render() {
    const { conversations } = this.props;
    return (
      <MediaBreakpoints
        desktop={
          <div className="conversations">
            <header>
              <h1>Messaging</h1>
            </header>
            <div>
              <ConversationsDesktopSidePane
                conversations={conversations}
                newConversationLink={`/conversations/new`}
              />
              {this.renderRoutes()}
            </div>
          </div>
        }
        mobile={
          <div className="conversations">
            <header>
              <h1>Messaging</h1>
            </header>
            {this.renderRoutes()}
          </div>
        }
      />
    );
  }
}

const mapStateToProps = state => {
  const { conversations } = state.conversationList;
  return { conversations };
};
export default connect(mapStateToProps, actions)(Conversations);
