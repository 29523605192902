import React from "react";
import HeroImage from "../../../images/kazzam/hero.jpg";

class HomeBanner extends React.Component {
  render() {
    return (
      <figure className="kazzamHero">
        <img src={HeroImage} alt="" />
      </figure>
    );
  }
}

export default HomeBanner;
