import axios from "axios";
import { ROOT_URL } from "Api";
import {
  RELOAD_INVENTORY_REQUEST,
  SAVE_INVENTORY_FILTER,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  HIDE_TILES,
  SHOW_TILES
} from "Constants/redux";
import { apiConnections } from "Constants/displayConstants";

export const reloadInventory = inventoryFilter => {
  return (dispatch, getState) => {
    const {
      locationId,
      query,
      numberPer,
      page,
      selectedCategories,
      selectedSubCategories,
      selectedColors,
      minQuantity,
      maxQuantity,
      eventStart,
      eventEnd,
      type
    } = inventoryFilter;
    const { zipCode, locale } = getState().serviceArea;
    const options = {
      query,
      type,
      location_id: locationId,
      per: numberPer || 12,
      page: page,
      categories: selectedCategories ? selectedCategories.join(",") : "",
      sub_categories: selectedSubCategories
        ? selectedSubCategories.join(",")
        : "",
      colors: selectedColors ? selectedColors.join(",") : "",
      min_quantity: +minQuantity === 0 ? null : minQuantity,
      max_quantity: +maxQuantity === 500 ? null : maxQuantity,
      zip_code: zipCode,
      start_date: eventStart,
      end_date: eventEnd,
      locale
    };
    dispatch({
      type: SAVE_INVENTORY_FILTER,
      inventoryFilter
    });
    dispatch({
      type: RELOAD_INVENTORY_REQUEST,
      options,
      inventoryType: "items"
    });
  };
};

export function fetchProduct(productType, id, locationId = "") {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_PRODUCT_REQUEST });
    axios
      .get(
        `${ROOT_URL}/marketplace/${apiConnections[productType]}/${id}?location_id=${locationId}`
      )
      .then(response => {
        return dispatch({
          productType,
          id,
          response,
          type: FETCH_PRODUCT_SUCCESS
        });
      })
      .catch(error => {
        dispatch({
          id,
          error,
          type: FETCH_PRODUCT_FAILURE
        });
      });
  };
}

export function sendSearchId(searchId, objClass, id) {
  axios
    .post(
      process.env.REACT_APP_API_DOMAIN +
        "/api/marketplace/inventories/add_to_cart",
      {
        search_id: searchId,
        obj_class: objClass,
        obj_id: id
      }
    )
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    });
}

export function showTiles() {
  return {
    type: SHOW_TILES
  };
}

export function hideTiles() {
  return {
    type: HIDE_TILES
  };
}
