import {
  FETCH_EVENT_LIST_REQUEST,
  FETCH_EVENT_LIST_SUCCESS,
  FETCH_EVENT_LIST_FAILURE,
  SET_EVENT_LIST
} from "Constants/redux";

const INITIAL_STATE = {
  events: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_EVENT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_EVENT_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };
    case SET_EVENT_LIST:
    case FETCH_EVENT_LIST_SUCCESS:
      return {
        ...state,
        events: action.payload.events,
        loading: false
      };
    default:
      return state;
  }
};
