import * as actionTypes from "Constants/redux";

export function openSuccessSnackBar(message) {
  return {
    type: actionTypes.OPEN_SUCCESS_SNACKBAR,
    payload: {
      successOpen: true,
      successMessage: message
    }
  };
}
export function closeSuccessSnackBar() {
  return {
    type: actionTypes.CLOSE_SUCCESS_SNACKBAR,
    payload: false
  };
}

export function setOpenValidationSnackBar() {
  return {
    type: actionTypes.SET_OPEN_VALIDATION_SNACKBAR,
    payload: true
  };
}
export function setClosedValidationSnackBar() {
  return {
    type: actionTypes.SET_CLOSED_VALIDATION_SNACKBAR,
    payload: false
  };
}

export function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    payload: {
      errors: errors,
      errorsOpen: true
    }
  };
}
