import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import { Helmet } from "react-helmet";
import { CreditCards } from "Utils/SvgIcons";
import CreditCardForm from "./creditCards/CreditCardForm";

// Fetch the profile from Redux customer prop,
// then call ProfileForm to render the form

class NewCreditCard extends React.Component {
  render() {
    const { submitCreditCard } = this.props; // From Redux

    return (
      <section className="payments new">
        <h4>Add New Card</h4>
        <CreditCards />
        <CreditCardForm onSubmit={submitCreditCard} />
        <Helmet>
          <title>TapGoods - Add a new card</title>
        </Helmet>
      </section>
    );
  }
}

export default connect(null, actions)(NewCreditCard);
