import React from "react";
import MaterialRadio from "@material-ui/core/Radio";

const Radio = props => {
  const { ...other } = props;
  return (
    <MaterialRadio
      classes={{
        root: "radio",
        checked: "checked"
      }}
      disableRipple
      {...other}
    />
  );
};

export default Radio;
