import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import { Helmet } from "react-helmet";
import ProfileForm from "./ProfileForm";

// Fetch the profile from Redux customer prop,
// then call ProfileForm to render the form

class EditProfile extends React.Component {
  componentDidMount() {
    const { fetchProfile } = this.props; // From Redux
    fetchProfile();
  }

  render() {
    const { submitProfile } = this.props; // From Redux

    return (
      <section className="details edit">
        <h4>Edit Profile</h4>
        <ProfileForm onSubmit={submitProfile} />
        <Helmet>
          <title>TapGoods - Edit Profile</title>
        </Helmet>
      </section>
    );
  }
}

export default connect(null, actions)(EditProfile);
