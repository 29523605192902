import {
  SET_CART_PROPS,
  INIT_CART_PROPS,
  START_ADDING_ITEMS_TO_EVENT,
  STOP_ADDING_ITEMS_TO_EVENT,
  ADD_BUNDLE_IN_EVENT,
  UPDATE_BUNDLE_IN_EVENT,
  SET_RENTAL_PROPS
} from "Constants/redux";
import axios from "axios";
import { uniq } from "lodash";
import { getAuthToken } from "Api";
import converter from "json-style-converter/es5";
import { combineDateAndTime, toNumber } from "HelperFunctions/general";
import { getRentalBundleWithPrice } from "HelperFunctions/rental_items";
import {
  getItemsWithNewPricesForEvent,
  getDamageWaiverFeeForEvent
} from "HelperFunctions/events";
import { setErrors } from "./SnackbarActions";
import { ROOT_URL } from "Api";
import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";
import moment from "moment-timezone";

export const addBundleInCart = (bundle, bundleQuantity, locationName) => {
  return (dispatch, getState) => {
    const { event } = getState().cart;
    const { eventStart, eventStartTime, eventEnd, eventEndTime, rentalBundles } = event;

    dispatch({
      type: ADD_BUNDLE_IN_EVENT,
      payload: {
        ...bundle,
        quantity: bundleQuantity
      }
    });

    let newBundle = {
      name: bundle.name,
      quantity: bundleQuantity,
      bundleId: bundle.id,
      defaultPricing: bundle.defaultPricing,
      hourlyPrice: bundle.hourlyPrice,
      halfDayPrice: bundle.halfDayPrice,
      dailyPrice: bundle.dailyPrice,
      weeklyPrice: bundle.weeklyPrice,
      monthlyPrice: bundle.monthlyPrice,
      flatPrices: bundle.flatPrices.map(
        flatPrice => ({
          id: "",
          name: flatPrice.name,
          amount: flatPrice.amount,
          pricingLabelId: flatPrice.pricing_label_id
        })
      ),
      purchasePrice: bundle.purchasePrice,
      description: bundle.description,
      discountPercent: bundle.discountPercent,
      priceLocked: bundle.priceLocked,
      rentalItems: [],
      rentalAccessories: [],
      rentalAddOns: []
    };

    axios
      .get(`${ROOT_URL}/portal/bundles/${bundle.token}`)
      .then(response => {
        const detailedBundle = response.data.bundle;
        detailedBundle.productBundleRelationships.forEach(
          (container, index) => {
            const quant = bundleQuantity * container.quantity;
            const item = container.product;
            newBundle.rentalItems.push({
              name: item.name,
              type: "RentalItemStandard",
              quantity: quant,
              productId: item.id,
              description: item.description,
              defaultPricing: item.defaultPricing,
              hourlyPrice: item.hourlyPrice,
              halfDayPrice: item.halfDayPrice,
              dailyPrice: item.dailyPrice,
              weeklyPrice: item.weeklyPrice,
              monthlyPrice: item.monthlyPrice,
              flatPrices: item.flatPrices.map(
                flatPrice => ({
                  id: "",
                  name: flatPrice.name,
                  amount: flatPrice.amount,
                  pricingLabelId: flatPrice.pricing_label_id
                })
              ),
              _destroy: "0"
            });
          }
        );

        detailedBundle.accessoryBundleRelationships.forEach(
          (container, index) => {
            const quant = bundleQuantity * container.quantity;
            const accessory = container.accessory;
            newBundle.rentalAccessories.push({
              name: accessory.name,
              quantity: quant,
              accessoryId: accessory.id,
              description: accessory.description,
              _destroy: "0"
            });
          }
        );

        detailedBundle.addOnBundleRelationships.forEach(
          (container, index) => {
            const quant = bundleQuantity * container.quantity;
            const addOn = container.addOn;
            newBundle.rentalAddOns.push({
              name: addOn.name,
              quantity: quant,
              addOnId: addOn.id,
              pricing: addOn.pricing,
              description: addOn.description,
              _destroy: "0"
            });
          }
        );

        newBundle = {
          ...newBundle,
          bundle: detailedBundle,
        };

        let rentalBundleWithPrice = getRentalBundleWithPrice(
          newBundle,
          combineDateAndTime(eventStart, eventStartTime),
          combineDateAndTime(eventEnd, eventEndTime)
        );

        let newRentalBundles = { ...rentalBundles };
        if (!newRentalBundles.hasOwnProperty(locationName)) {
          newRentalBundles[locationName] = [];
        }
        newRentalBundles[locationName].push(rentalBundleWithPrice);

        const newEvent = {
          ...event,
          rentalBundles: newRentalBundles
        };

        const newDamageWaiverFee = getDamageWaiverFeeForEvent(newEvent);
        dispatch({
          type: SET_CART_PROPS,
          payload: {
            rentalBundles: newRentalBundles,
            damageWaiverFeeTotal: newDamageWaiverFee
          }
        });
      });
  }
}

export const updateBundleInCart = (name, value, rentalBundleIndex, locationName) => {
  return (dispatch, getState) => {
    const { event } = getState().cart;
    const { eventStart, eventStartTime, eventEnd, eventEndTime, rentalBundles } = event;

    let newRentalBundles = { ...rentalBundles };
    let rentalBundle = newRentalBundles[locationName][rentalBundleIndex];

    let newRentalBundle = {
      ...rentalBundle,
      [name]: toNumber(value)
    };

    dispatch({
      type: UPDATE_BUNDLE_IN_EVENT,
      payload: {
        newRentalBundle
      }
    });

    /** Update quantities of underlying bundle inventories **/
    const newBundleQuantity = Number(newRentalBundle.quantity);
    newRentalBundle.rentalItems = newRentalBundle.rentalItems.map(
      rentalItem => {
        if (!rentalItem.setManually) {
          const container = newRentalBundle.bundle.productBundleRelationships.find(
            pbr => pbr.productId === rentalItem.productId
          );
          rentalItem.quantity = newBundleQuantity * container.quantity;
        }
        return rentalItem;
      }
    );

    newRentalBundle.rentalAccessories = newRentalBundle.rentalAccessories.map(
      rentalAccessory => {
        if (!rentalAccessory.setManually) {
          const container = newRentalBundle.bundle.accessoryBundleRelationships.find(
            pbr => pbr.accessoryId === rentalAccessory.accessoryId
          );
          rentalAccessory.quantity = newBundleQuantity * container.quantity;
        }
        return rentalAccessory;
      }
    );

    newRentalBundle.rentalAddOns = newRentalBundle.rentalAddOns.map(
      rentalAddOn => {
        if (!rentalAddOn.setManually) {
          const container = newRentalBundle.bundle.addOnBundleRelationships.find(
            pbr => pbr.addOnId === rentalAddOn.addOnId
          );
          rentalAddOn.quantity = newBundleQuantity * container.quantity;
        }
        return rentalAddOn;
      }
    );
    /** Update quantities of underlying bundle inventories **/

    const rentalBundleWithPrice = getRentalBundleWithPrice(
      newRentalBundle,
      combineDateAndTime(eventStart, eventStartTime),
      combineDateAndTime(eventEnd, eventEndTime)
    );
    newRentalBundles[locationName][rentalBundleIndex] = rentalBundleWithPrice;

    const newEvent = {
      ...event,
      rentalBundles: newRentalBundles
    };

    const newDamageWaiverFee = getDamageWaiverFeeForEvent(newEvent);
    dispatch({
      type: SET_CART_PROPS,
      payload: {
        rentalBundles: newRentalBundles,
        damageWaiverFeeTotal: newDamageWaiverFee
      }
    });
  }
}

export const setCartProps = newProps => {
  return (dispatch, getState) => {
    const { event } = getState().cart;
    const newEvent = { ...event, ...newProps };
    const newDamageWaiverFee = getDamageWaiverFeeForEvent(newEvent);
    dispatch({
      type: SET_CART_PROPS,
      payload: { ...newProps, damageWaiverFeeTotal: newDamageWaiverFee }
    });
  };
};

export const initCartProps = () => {
  return {
    type: INIT_CART_PROPS
  };
};

const build_rental_rails_object = (state, items, rentalBundles, addOns, schedule) => {
  const { event } = state.cart;
  const {
    eventStart,
    eventStartTime,
    eventEnd,
    eventEndTime,
    deliveryAddressStreetAddress1,
    deliveryAddressStreetAddress2,
    pickupAddressStreetAddress1,
    pickupAddressStreetAddress2,
    damageWaiverFeeTotal,
    customerContactPhone,
    id,
    ...other
  } = event;
  const business = items[0]
    ? items[0].product.businessInfo
    : rentalBundles[0]
    ? rentalBundles[0].bundle.businessInfo
    : addOns[0].addOn.businessInfo;
  const rentalId = items[0]
    ? items[0].rentalId
    : rentalBundles[0]
    ? rentalBundles[0].rentalId
    : addOns[0].rentalId;
  const itemsRailsObject = items.reduce((holder, item, currentIndex) => {
    item.selectedPriceBeforeDiscount = item.selectedPrice;
    item.flatPricesAttributes = item.flatPrices;
    item.rentalItemCustomTaxRelationshipAttributes = item.rentalItemCustomTaxRelationship;
    const formattedItem = converter.camelToSnakeCase(item);
    holder[currentIndex] = formattedItem;
    return holder;
  }, {});
  const addOnsRailsObject = addOns.reduce((holder, item, currentIndex) => {
    item.selectedPriceBeforeDiscount = item.selectedPrice;
    const formattedItem = converter.camelToSnakeCase(item);
    holder[currentIndex] = formattedItem;
    return holder;
  }, {});
  const autoBookNeeded = items.every(
    item => item.product.numberAvailable > item.quantity
  );
  const rentalBundlesRailsObject = rentalBundles.reduce(
    (holder, rentalBundle, currentIndex) => {
      rentalBundle.flatPricesAttributes = rentalBundle.flatPrices;
      for(let rentalItem of rentalBundle.rentalItems) {
        if (rentalItem.hasOwnProperty("flatPrices")) {
          rentalItem["flatPricesAttributes"] = rentalItem.flatPrices;
        }
      }
      let formattedItem = converter.camelToSnakeCase(rentalBundle);
      formattedItem["rental_item_standards_attributes"] =
        formattedItem.rental_items;
      formattedItem["rental_accessories_attributes"] =
        formattedItem.rental_accessories;
      formattedItem["rental_add_ons_attributes"] = formattedItem.rental_add_ons;
      holder[currentIndex] = formattedItem;
      return holder;
    },
    {}
  );

  const timeZone = moment.tz.guess();
  const eventStartDateTime = combineDateAndTime(eventStart, eventStartTime);
  const eventEndDateTime = combineDateAndTime(eventEnd, eventEndTime);
  const scheduleRailsObject = {
    id: schedule ? schedule.id : "",
    event_start_date_time: eventStartDateTime,
    event_end_date_time: eventEndDateTime,
    start_date_time: eventStartDateTime,
    end_date_time: eventEndDateTime,
    start_window_finish: eventStartDateTime,
    end_window_beginning: eventEndDateTime,
    off_shelf_at: eventStartDateTime,
    on_shelf_at: eventEndDateTime,
    time_zone: timeZone,
    same_as_event_date: true
  };

  const otherRailsObject = converter.camelToSnakeCase(other);

  const returnable = {
    ...otherRailsObject,
    id: rentalId,
    location_id: business.locationId,
    active: true,
    source: "marketplace",
    rental_item_standards_attributes: itemsRailsObject,
    rental_bundles_attributes: rentalBundlesRailsObject,
    rental_add_ons_attributes: addOnsRailsObject,
    schedule_attributes: scheduleRailsObject,
    delivery_address_street_address_1: deliveryAddressStreetAddress1,
    delivery_address_street_address_2: deliveryAddressStreetAddress2,
    pickup_address_street_address_1: pickupAddressStreetAddress1,
    pickup_address_street_address_2: pickupAddressStreetAddress2,
    customer_contact_phone: customerContactPhone,
    auto_booked: autoBookNeeded
  };

  let changeRequestedItem = items.find(el => !!el.changeRequest);
  let changeRequestAttrs = {};

  if (!!changeRequestedItem && !!state.event.beforeAttributes) {
    let change_request_before_attributes = JSON.stringify(
      Object(
        state.event.beforeAttributes.rentals.find(
          rental => rental.id === changeRequestedItem.rentalId
        )
      )
    );

    let change_request_after_attributes = JSON.stringify(returnable);
    changeRequestAttrs = {
      change_request_attributes: {},
      change_request_before_attributes,
      change_request_after_attributes
    };
  }

  return {
    ...returnable,
    ...changeRequestAttrs
  };
};

const build_rails_object = state => {
  const { event } = state.cart;
  const { user } = state.auth;
  const {
    items,
    rentalBundles,
    addOns,
    eventStart,
    eventStartTime,
    eventEnd,
    eventEndTime,
    name,
    schedules
  } = event;

  let rentalsRailsObject = {};
  const businessKeys = uniq([
    ...Object.keys(items),
    ...Object.keys(rentalBundles),
    ...Object.keys(addOns)
  ]);
  businessKeys.forEach((key, index) => {
    rentalsRailsObject[index] = build_rental_rails_object(
      state,
      items[key] || [],
      rentalBundles[key] || [],
      addOns[key] || [],
      schedules && schedules[key]
    );
    if(rentalsRailsObject[index] && rentalsRailsObject[index]['delivery_cost']){
      rentalsRailsObject[index]['delivery_cost'] = rentalsRailsObject[index]['delivery_cost'][key]
    }
  });
  const eventStartDateTime = combineDateAndTime(eventStart, eventStartTime);
  const eventEndDateTime = combineDateAndTime(eventEnd, eventEndTime);
  return {
    name: name,
    event_start_date_time: eventStartDateTime,
    event_end_date_time: eventEndDateTime,
    customer_event_relationships_attributes: {
      "1": {
        customer_id: user ? user.id : ""
      }
    },
    rentals_attributes: rentalsRailsObject
  };
};

export const newReview = onSuccess => {
  return (dispatch, getState) => {
    const data = build_rails_object(getState());
    dispatch(openLoadingSpinner("review in progress..."));
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN + "/api/marketplace/events/new_review",
        {
          event: data
        }
      )
      .then(response => {
        dispatch(closeLoadingSpinner());
        dispatch(updateRentalsAfterReview(response.data.event.rentals, response.data.event.rentalTotals, response.data.event.shouldAllowAutoBook));
        onSuccess();
      })
      .catch(error => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
      });
  };
};

export const editReview = onSuccess => {
  return (dispatch, getState) => {
    const data = build_rails_object(getState());
    const { event } = getState().cart;
    dispatch(openLoadingSpinner("review in progress..."));
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN +
          "/api/marketplace/events/" +
          event.id +
          "/edit_review",
        {
          event: data
        }
      )
      .then(response => {
        dispatch(closeLoadingSpinner());
        dispatch(updateRentalsAfterReview(response.data.event.rentals, response.data.event.rentalTotals, response.data.event.shouldAllowAutoBook));
        onSuccess();
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
      });
  };
};

const updateRentalsAfterReview = (rentals, rentalTotals, shouldAllowAutoBook) => {
  return (dispatch, getState) => {
    let deliveryCost = {};
    let taxTotal = {};
    let canBeAutoBooked = {}
    let businessName = '';
    rentals.forEach((r, index) => {
      businessName = rentalTotals[index].businessName
      deliveryCost[businessName] = rentalTotals[index].deliveryCost;
      taxTotal[businessName] = rentalTotals[index].taxTotal;
      canBeAutoBooked[businessName] = shouldAllowAutoBook[index].shouldAllowAutoBook;
    });
    dispatch(
      setCartProps({
        deliveryCost: deliveryCost,
        taxTotal: taxTotal,
        canBeAutoBooked: canBeAutoBooked
      })
    );
  };
};

export const newSubmit = onSuccess => {
  return (dispatch, getState) => {
    const data = build_rails_object(getState());

    dispatch(openLoadingSpinner("Submitting rental..."));
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN + "/api/marketplace/events",
        {
          event: data
        },
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        dispatch(closeLoadingSpinner());
        dispatch(initCartProps());
        onSuccess(response.data.event);
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
      });
  };
};

export const editSubmit = onSuccess => {
  return (dispatch, getState) => {
    const data = build_rails_object(getState());
    const { event } = getState().cart;

    dispatch(openLoadingSpinner("Submitting rental..."));
    axios
      .patch(
        process.env.REACT_APP_API_DOMAIN +
          "/api/marketplace/events/" +
          event.id,
        {
          event: data
        },
        {
          headers: getAuthToken()
        }
      )
      .then(response => {
        dispatch(closeLoadingSpinner());
        dispatch(initCartProps());
        onSuccess(response.data.event);
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
      });
  };
};

export const startAddingItemsToEvent = event => {
  return (dispatch, getState) => {
    const rental = event.rentals[0];
    let eventItems = {};
    let eventRentalBundles = {};
    let addOns = {};
    let schedules = {}
    event.rentals.forEach(r => {
      if (r.rentalItems && r.rentalItems.length > 0) {
        eventItems[r.businessName] = r.rentalItems;
      }
      if (r.rentalBundles && r.rentalBundles.length > 0) {
        eventRentalBundles[r.businessName] = r.rentalBundles;
      }
      if (r.addOns && r.addOns.length > 0) {
        addOns[r.businessName] = r.addOns;
      }
      if (r.schedule){
        schedules[r.businessName] = r.schedule;
      }
    });
    let newEvent = {
      id: event.id,
      eventStart: new Date(event.eventStartDateTime),
      eventStartTime: new Date(event.eventStartDateTime),
      eventEnd: new Date(event.eventEndDateTime),
      eventEndTime: new Date(event.eventEndDateTime),
      name: event.name,
      items: eventItems,
      rentalBundles: eventRentalBundles,
      addOns,
      deliveryType: rental.deliveryType,
      deliveryAddressLocationName: rental.deliveryAddressLocationName,
      deliveryAddressStreetAddress1: rental.deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2: rental.deliveryAddressStreetAddress2,
      deliveryAddressCity: rental.deliveryAddressCity,
      deliveryAddressLocale: rental.deliveryAddressLocale,
      deliveryAddressPostalCode: rental.deliveryAddressPostalCode,
      deliveryAddressCountry: rental.deliveryAddressCountry,
      pickupSameAsDelivery: rental.pickupSameAsDelivery,
      pickupAddressLocationName: rental.pickupAddressLocationName,
      pickupAddressStreetAddress1: rental.pickupAddressStreetAddress1,
      pickupAddressStreetAddress2: rental.pickupAddressStreetAddress2,
      pickupAddressCity: rental.pickupAddressCity,
      pickupAddressLocale: rental.pickupAddressLocale,
      pickupAddressPostalCode: rental.pickupAddressPostalCode,
      pickupAddressCountry: rental.pickupAddressCountry,
      schedules: schedules
    };

    const { items, rentalBundles } = getItemsWithNewPricesForEvent(newEvent);
    newEvent = {
      ...newEvent,
      items: items,
      rentalBundles: rentalBundles
    };

    const newDamageWaiverFee = getDamageWaiverFeeForEvent(newEvent);
    newEvent = {
      ...newEvent,
      damageWaiverFeeTotal: newDamageWaiverFee
    };

    dispatch({
      type: START_ADDING_ITEMS_TO_EVENT,
      payload: newEvent
    });
  };
};

export const stopAddingItemsToEvent = newProps => {
  return {
    type: STOP_ADDING_ITEMS_TO_EVENT
  };
};
