import React from "react";
import { Link } from "react-router-dom";
import {
  UpcomingEvents,
  PastEvents,
  CancelledEvents,
  ArrowRight
} from "Utils/SvgIcons";

class EventsIndex extends React.Component {
  render() {
    return (
      <div className="eventLanding">
        <header>
          <h1>Events</h1>
        </header>
        <div className="events">
          <nav className="sideNav">
            <Link to="/events/upcoming">
              <UpcomingEvents />
              Upcoming Events
              <ArrowRight />
            </Link>
            <Link to="/events/past">
              <PastEvents />
              Past Events
              <ArrowRight />
            </Link>
            <Link to="/events/cancelled">
              <CancelledEvents />
              Cancelled Events
              <ArrowRight />
            </Link>
          </nav>
        </div>
      </div>
    );
  }
}

export default EventsIndex;
