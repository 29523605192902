import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import { NavLink } from "react-router-dom";
import ActionCable from "actioncable";
import { getAuthToken } from "Api";
import { MessageBubble } from "Utils/SvgIcons";

class MessagesTab extends React.Component {
  componentDidMount() {
    const { fetchConversationList, addUnreadMessage } = this.props;
    fetchConversationList();
    const authToken = getAuthToken();
    const url = `${process.env.REACT_APP_ACTION_CABLE}/cable?access-token=${authToken["access-token"]}&client=${authToken["client"]}&uid=${authToken["uid"]}&resource_type=customer`;
    this.cable = ActionCable.createConsumer(url);
    this.channel = this.cable.subscriptions.create(
      { channel: "CustomerUnreadMessagesChannel" },
      {
        connected: () => {},
        received: addUnreadMessage
      }
    );
  }

  componentWillUnmount() {
    this.channel.unsubscribe();
  }

  render() {
    const { unreadMessages } = this.props;
    return (
      <NavLink className="icon message" to="/conversations">
        <MessageBubble />
        <label>{unreadMessages > 0 && unreadMessages}</label>
      </NavLink>
    );
  }
}

const mapStateToProps = state => {
  const { unreadMessages } = state.conversationList;

  return {
    unreadMessages
  };
};

export default connect(mapStateToProps, actions, null, {
  pure: false
})(MessagesTab);
