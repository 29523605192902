import React from "react";
import SearchFilter from "Utils/SearchFilter";

const ShopIndex = props => (
  <header>
    <h1>Shop</h1>
    <SearchFilter />
  </header>
);
export default ShopIndex;
