import createReducer from "../lib/CreateReducer";
import update from "react/lib/update";
import {
  FETCH_CUSTOM_PAGES_SUCCESS,
  RECEIVE_CUSTOM_PAGE
} from "Constants/redux";

const INITIAL_STATE = {
  customPages: [],
  customFrontPages: []
};

export default createReducer(INITIAL_STATE, {
  [FETCH_CUSTOM_PAGES_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  },
  [RECEIVE_CUSTOM_PAGE]: (state, action) => {
    const newCustomPage = action.payload.customPage;
    const pageIndex = state.customPages.findIndex(
      cp => cp.id === newCustomPage.id
    );
    const newState = update(state, {
      customPages: {
        [pageIndex]: { $set: newCustomPage }
      }
    });
    return newState;
  }
});
