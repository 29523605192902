import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import NewMessage from "Components/conversations/NewMessage";

class NewMessageContainer extends React.Component {
  handleSubmit(values) {
    const { createMessage, conversation } = this.props;
    createMessage(conversation.id, values.message);
  }
  render() {
    return <NewMessage onSubmit={this.handleSubmit.bind(this)} />;
  }
}

const mapStateToProps = state => {
  const { conversation } = state.conversation;
  return { conversation };
};

export default connect(mapStateToProps, actions)(NewMessageContainer);
