import React from "react";
import { Carousel } from "react-responsive-carousel";
import CarouselImage from "../../../images/kazzam/carousel.png";

const TestimonialBox = props => {
  const testimonials = [
    {
      id: 1,
      description:
        "Totally worth every penny!! My daughter and her friends had the best time and the cotton candy maker was a hugh hit!!",
      name: "Ashleigh B.",
      title: "Totally worth every penny!!"
    },
    {
      id: 2,
      description:
        "Great Service & great product! Thank you! I've already referred a couple of my friends!",
      name: "Marisa R.",
      title: "Great Service"
    },
    {
      id: 3,
      description:
        "Our ballon artist was terrific! He created the most amazing ballon animals that fit our unicorn and paw patrol theme. he was wonderful!!",
      name: "Cheryl M.",
      title: "Our ballon artist was terrific!"
    },
    {
      id: 4,
      description:
        "Totally worth every penny!! My daughter and her friends had the best time and the cotton candy maker was a hugh hit!!",
      name: "Ashleigh B.",
      title: "Totally worth every penny!!"
    },
    {
      id: 5,
      description:
        "Great Service & great product! Thank you! I've already referred a couple of my friends!",
      name: "Marisa R.",
      title: "Great Service"
    },
    {
      id: 6,
      description:
        "Our ballon artist was terrific! He created the most amazing ballon animals that fit our unicorn and paw patrol theme. he was wonderful!!",
      name: "Cheryl M.",
      title: "Our ballon artist was terrific!"
    }
  ];
  let params = {
    slidesToShow: 3
  };
  if (testimonials.length > 1) {
    params["navigation"] = {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    };
  }
  return (
    <div className="kazzamTestimonials">
      <figure>
        <img src={CarouselImage} alt="" />
      </figure>
    </div>
  );
};

export default TestimonialBox;
