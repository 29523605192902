import React from "react";
import {
  TapGoods
  // Facebook,
  // Instagram,
  // Pinterest,
  // Twitter
} from "Utils/SvgIcons";

const Footer = props => (
  <footer>
    <div className="nav">
      <TapGoods />
      <nav>
        <a
          href="//www.tapgoods.com/pro/terms-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>
        <a
          href="//www.tapgoods.com/pro/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </nav>
    </div>
    <div className="marketing">
      <h3>
        Are you a rental company
        <br />
        wanting to rent on
        <br />
        TapGoods Marketplace?
      </h3>
      <nav>
        <a
          href="//www.tapgoods.com/pro"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn How To Rent On TapGoods Marketplace
        </a>
        <a
          href="https://www.tapgoods.com/pro/schedule-a-demo/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Schedule a Free Demo
        </a>
        <a
          href="https://business.tapgoods.com/signup"
          target="_blank"
          rel="noopener noreferrer"
        >
          Start a Free Trial
        </a>
      </nav>
    </div>
    <div className="socialLinks">
      {/* <a href=""><Facebook /></a>
	  <a href=""><Instagram /></a>
	  <a href=""><Pinterest /></a>
	  <a href=""><Twitter /></a> */}
    </div>
  </footer>
);

export default Footer;
