import {
  RELOAD_INVENTORY_REQUEST,
  RELOAD_INVENTORY_SUCCESS,
  SAVE_INVENTORY_FILTER,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  SHOW_TILES,
  HIDE_TILES
} from "Constants/redux";
import { apiReceiving } from "Constants/displayConstants";
const INITIAL_STATE = {
  inventory: [],
  pagination: {},
  searchId: null,
  loading: false,
  pagesRemaining: true,
  page: null,
  numberPer: 12,
  tileView: false,
  categories: [],
  inventoryFilter: {},
  product: {
    id: "",
    name: "",
    material: "",
    description: "",
    turnoverTime: "",
    turnoverUnit: "hours",
    quantity: "",
    productType: "",
    buffer: "",
    weight: "",
    depth: "",
    width: "",
    height: "",
    category: {
      id: "",
      name: ""
    },
    colors: [],
    suppliers: [],
    categories: [],
    subCategories: [],
    businessInfo: {}
  },
  bundle: {
    id: "",
    name: "",
    material: "",
    description: "",
    turnoverTime: "",
    turnoverUnit: "hours",
    productType: "",
    quantity: "",
    buffer: "",
    weight: "",
    depth: "",
    width: "",
    height: "",
    category: {
      id: "",
      name: ""
    },
    categories: [],
    subCategories: [],
    businessInfo: {}
  },
  addOn: {
    id: "",
    name: "",
    material: "",
    description: "",
    turnoverTime: "",
    turnoverUnit: "hours",
    quantity: "",
    buffer: "",
    weight: "",
    depth: "",
    width: "",
    height: "",
    productType: "add_ons",
    category: {
      id: "",
      name: ""
    },
    colors: [],
    suppliers: [],
    categories: [],
    subCategories: [],
    businessInfo: {}
  },
  colors: [],
  suppliers: [],
  subCategories: [],
  businessInfo: {},
  productBundleRelationships: [],
  accessoryBundleRelationships: [],
  addOnBundleRelationships: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RELOAD_INVENTORY_REQUEST:
    case FETCH_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case SAVE_INVENTORY_FILTER:
      return { ...state, inventoryFilter: action.inventoryFilter };
    case RELOAD_INVENTORY_SUCCESS:
      const newState = Object.assign(
        {
          inventory: action.response.data[apiReceiving[action.inventoryType]],
          inventoryType: action.inventoryType,
          loading: false,
          page: 1
        },
        action.response.data.meta && {
          searchId: action.response.data.meta.searchId,
          pagination: action.response.data.meta.pagination,
          categories: action.response.data.meta.categories,
          subCategories: action.response.data.meta.subCategories
        }
      );
      return {
        ...state,
        ...newState
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        [action.productType]: action.response.data[action.productType],
        loading: false
      };

    case SHOW_TILES:
      return {
        ...state,
        tileView: true
      };
    case HIDE_TILES:
      return {
        ...state,
        tileView: false
      };
    default:
      return state;
  }
};
