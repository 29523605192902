export default {
  required: function(value, prop) {
    return prop ? value == null || value === "" : false;
  },

  minLength: function(value, prop) {
    return prop && value ? value.length < prop : false;
  },

  maxLength: function(value, prop) {
    return prop && value ? value.length > prop : false;
  },

  email: function(value, prop) {
    return prop && value
      ? !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
          value
        )
      : false;
  },

  min: function(value, prop) {
    return prop && value ? !isFinite(value) || parseFloat(value) < prop : false;
  },

  greaterThanOrEqual: function(value, prop) {
    return prop && value ? !isFinite(value) || parseFloat(value) < prop : false;
  },
  greaterThanOrEqualToDate: function(value, prop) {
    return prop && value ? !(value instanceof Date) || value < prop : false;
  },

  lessThanOrEqual: function(value, prop) {
    return prop && value ? !isFinite(value) || parseFloat(value) > prop : false;
  },
  lessThanOrEqualToDate: function(value, prop) {
    return prop && value ? !(value instanceof Date) || value > prop : false;
  },

  max: function(value, prop) {
    return prop && value ? !isFinite(value) || parseFloat(value) > prop : false;
  },

  pattern: function(value, prop) {
    return !value ? false : !prop.test(value);
  },

  equalTo: function(value, prop) {
    return !value ? false : prop !== value;
  },

  oneOf: function(value, prop) {
    return !value ? false : prop.indexOf(value) === -1;
  },

  promise: function(value, prop, dispatch) {
    if (typeof prop === "function") {
      return prop(value, dispatch);
    }
    throw new Error("FormValidation: type promise must be a function!");
  },
  digits: function(value, prop) {
    return prop ? isNaN(Number(value)) : false;
  },
  creditcard: function(value, prop) {
    if (!value) {
      return true;
    }
    // accept only spaces, digits and dashes
    if (/[^0-9 -]+/.test(value)) {
      return false;
    }
    var nCheck = 0,
      nDigit = 0,
      bEven = true,
      n,
      cDigit;

    value = value.replace(/\D/g, "");

    // Basing min and max length on
    // http://developer.ean.com/general-info/valid-card-types/
    if (value.length < 13 || value.length > 19) {
      return true;
    }

    for (n = value.length - 1; n >= 0; n--) {
      cDigit = value.charAt(n);
      nDigit = parseInt(cDigit, 10);
      if (bEven) {
        nDigit *= 2;
        if (nDigit > 9) {
          nDigit -= 9;
        }
      }
      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 === 0;
  },
  cvv: function(value, prop) {
    if (!value) {
      return true;
    }
    return value.length >= 3 && value.length <= 4 && Number(value)
      ? false
      : true;
  },
  routingNumber: function(value, prop) {
    if (!value) {
      return true;
    }
    if (value.length !== 9) {
      return true;
    }

    // http://en.wikipedia.org/wiki/value_transit_number#MICR_value_number_format
    var checksumTotal =
      7 *
        (parseInt(value.charAt(0), 10) +
          parseInt(value.charAt(3), 10) +
          parseInt(value.charAt(6), 10)) +
      3 *
        (parseInt(value.charAt(1), 10) +
          parseInt(value.charAt(4), 10) +
          parseInt(value.charAt(7), 10)) +
      9 *
        (parseInt(value.charAt(2), 10) +
          parseInt(value.charAt(5), 10) +
          parseInt(value.charAt(8), 10));

    var checksumMod = checksumTotal % 10;
    if (checksumMod !== 0) {
      return true;
    } else {
      return false;
    }
  },
  bankAccountNumber: function(value, prop) {
    if (!value) {
      return true;
    }
    return !/^[0-9]{7,14}$/.test(value);
  },
  matchField: function(value, prop, dispatch, allValues) {
    return !value ? false : value !== allValues[prop];
  }
};
