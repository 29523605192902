import {
  SET_RENTAL_PROPS,
  SET_BEFORE_ATTRIBUTES,
  FETCH_RENTAL_REQUEST,
  FETCH_RENTAL_SUCCESS,
  FETCH_RENTAL_FAILURE
} from "Constants/redux";

let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const INITIAL_STATE = {
  rental: {
    eventStart: new Date(),
    eventStartTime: new Date(),
    eventEnd: tomorrow,
    eventEndTime: tomorrow,
    name: "",
    items: [],
    rentalBundles: [],
    addOns: [],
    deliveryType: "customer_pick_up",
    deliveryCost: 0,
    deliveryAddressLocationName: "",
    deliveryAddressStreetAddress1: "",
    deliveryAddressStreetAddress2: "",
    deliveryAddressCity: "",
    deliveryAddressLocale: "",
    deliveryAddressPostalCode: "",
    deliveryAddressCountry: "",
    pickupSameAsDelivery: false,
    damageWaiverFeeTotal: 0,
    taxExempt: "",
    taxTotal: 0,
    venue: {
      name: "",
      cell: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      locale: "",
      postalCode: "",
      country: ""
    },
    isRecurringPayment: false,
    recurringTransactionInfo: {},
    recurringPaymentData: {}
  },
  beforeAttributes: null,
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RENTAL_REQUEST:
      return { ...state, loading: true };
    case FETCH_RENTAL_FAILURE:
      return { ...state, loading: true };
    case FETCH_RENTAL_SUCCESS:
      const rental = action.payload;
      const {
        id,
        token,
        name,
        deliveryType,
        deliveryCost,
        deliveryAddressLocationName,
        deliveryAddressStreetAddress1,
        deliveryAddressStreetAddress2,
        deliveryAddressCity,
        deliveryAddressLocale,
        deliveryAddressPostalCode,
        deliveryAddressCountry,
        pickupSameAsDelivery,
        pickupAddressLocationName,
        pickupAddressStreetAddress1,
        pickupAddressStreetAddress2,
        pickupAddressCity,
        pickupAddressLocale,
        pickupAddressPostalCode,
        pickupAddressCountry,
        preferredDeliveryWindow,
        preferredPickupWindow,
        damageWaiverFeeTotal,
        taxExempt,
        taxTotal,
        location,
        isRecurringPayment,
        recurringTransactionInfo,
        recurringPaymentData
      } = rental;
      const newObject = Object.assign(
        state.rental,
        {
          id,
          token,
          name,
          deliveryType,
          deliveryCost,
          deliveryAddressLocationName,
          deliveryAddressStreetAddress1,
          deliveryAddressStreetAddress2,
          deliveryAddressCity,
          deliveryAddressLocale,
          deliveryAddressPostalCode,
          deliveryAddressCountry,
          pickupSameAsDelivery,
          pickupAddressLocationName,
          pickupAddressStreetAddress1,
          pickupAddressStreetAddress2,
          pickupAddressCity,
          pickupAddressLocale,
          pickupAddressPostalCode,
          pickupAddressCountry,
          preferredDeliveryWindow,
          preferredPickupWindow,
          damageWaiverFeeTotal,
          taxExempt,
          taxTotal,
          location,
          isRecurringPayment,
          recurringTransactionInfo,
          recurringPaymentData
        },
        {
          items: rental.rentalItems,
          rentalBundles: rental.rentalBundles,
          addOns: rental.rentalAddOns,
          eventStart: new Date(rental.schedule.eventStartDateTime),
          eventStartTime: new Date(rental.schedule.eventStartDateTime),
          eventEnd: new Date(rental.schedule.eventEndDateTime),
          eventEndTime: new Date(rental.schedule.eventEndDateTime),
          schedule: { id: rental.schedule.id }
        },
        rental.venueRentalRelationship && {
          venue: rental.venueRentalRelationship.venue
        }
      );
      return { ...state, rental: newObject, loading: false };
    case SET_RENTAL_PROPS:
      const newRental = {
        ...state.rental,
        ...action.payload
      };
      return { ...state, rental: newRental };
    case SET_BEFORE_ATTRIBUTES:
      return { ...state, beforeAttributes: action.payload };
    default:
      return state;
  }
};
