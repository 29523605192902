// Is this component still being used??
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import * as actions from "Actions";
import { ROOT_URL } from "Api";
import { apiToSingularRoot } from "Constants/displayConstants";
import SearchFilter from "Utils/SearchFilter";
import DatePicker from "Utils/DatePicker";
import TimePicker from "Utils/TimePicker";
import Modal from "Utils/Modal";
import { TapGoods, Info } from "Utils/SvgIcons";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import {
  combineDateAndTime,
  formatPhoneNumber,
  toNumber
} from "HelperFunctions/general";
import {
  showSelectedPrice,
  hasSelectedFlatPrice,
  getSelectedFlatPrice,
} from "HelperFunctions/rental_items";
import { getFirstAvailablePricingType } from "HelperFunctions/rentals";
import { imgixUrl } from "HelperFunctions/imgix";
import SuggestionTable from "./SuggestionTable";
import Imgix from "react-imgix";
import InnerImageZoom from "Utils/images/InnerImageZoom";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import CurrencyLabel from "Utils/CurrencyLabel";

const priceTypeToBreakpoint = {
  hourlyPrice: "hours",
  dailyPrice: "days",
  halfDayPrice: "halfDay",
  weeklyPrice: "weeks",
  monthlyPrice: "months",
  flatPrice: "each"
};
// Third shopping page
const AvailableItem = productType => {
  class WrappedComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        quantity: "",
        breakpoint: "",
        submitted: false,
        open: false,
        suggestionModalOpen: false
      };
    }

    componentDidMount() {
      this.props.fetchProduct(
        apiToSingularRoot[productType],
        this.props.match.params.id
      );
    }

    componentWillReceiveProps(nextProps) {
      const { product } = nextProps;
      const firstPriceType = Object.keys(priceTypeToBreakpoint).find(
        objectKey => product[objectKey] !== undefined
      );
      if (firstPriceType !== undefined) {
        this.setState({
          breakpoint: priceTypeToBreakpoint[firstPriceType]
        });
      }
    }

    handleChange = event => {
      const { name, value } = event.target;
      const newValue = value.replace(/[^0-9]/g, "");
      this.setState({
        [name]: toNumber(newValue),
        submitted: false
      });
    };

    handleEventChange = e => {
      const { name, value } = e.target;
      this.props.setCartProps({ [name]: value });
    };

    handleDateChange = e => {
      const { name, value } = e.target;
      const {
        event,
        inventoryFilter,
        setCartProps,
        reloadInventory
      } = this.props;

      const newEvent = {
        ...event,
        [name]: value
      };
      setCartProps(newEvent);

      // Reload inventory with new event dates
      const { eventStart, eventStartTime, eventEnd, eventEndTime } = newEvent;
      const eventStartDateTime = eventStart
        ? combineDateAndTime(eventStart, eventStartTime)
        : null;
      const eventEndDateTime = eventEnd
        ? combineDateAndTime(eventEnd, eventEndTime)
        : null;
      reloadInventory({
        ...inventoryFilter,
        eventStart: eventStartDateTime,
        eventEnd: eventEndDateTime
      });
    };

    handleAddToQuote = () => {
      if (productType === "items") {
        this.handleAddItemToQuote();
      } else if (productType === "add_ons") {
        this.handleAddPurchaseToQuote();
      } else {
        this.handleAddBundleToQuote();
      }
    };

    handleAddPurchaseToQuote = () => {
      const {
        event: { eventStart, eventStartTime, eventEnd, eventEndTime, addOns },
        product,
        setCartProps
      } = this.props;

      const locationName = product.businessInfo.name;
      const { quantity } = this.state;
      let newItems = { ...addOns };
      if (!newItems.hasOwnProperty(locationName)) {
        newItems[locationName] = [];
      }
      const oldItemIndex = newItems[locationName].findIndex(
        item => item.addOnId === product.id
      );
      let itemQuantity =
        oldItemIndex !== -1
          ? newItems[locationName][oldItemIndex].quantity + Number(quantity)
          : Number(quantity);
      const selected = showSelectedPrice(
        product,
        itemQuantity,
        combineDateAndTime(eventStart, eventStartTime),
        combineDateAndTime(eventEnd, eventEndTime),
        "add_ons"
      );
      const newItem = {
        name: product.name,
        type: "RentalPurchaseStandard",
        quantity: itemQuantity,
        addOnId: product.id,
        addOn: product,
        pricing: product.pricing,
        description: product.description,
        holidayHourlyPrice: product.holidayHourlyPrice,
        selectedPrice: selected.total,
        selectedRate: selected.rate,
        duration: selected.duration,
        period: selected.period
      };

      if (oldItemIndex !== -1) {
        newItems[locationName][oldItemIndex] = newItem;
      } else {
        newItems[locationName].push(newItem);
      }

      setCartProps({
        addOns: newItems
      });

      this.setState({
        submitted: true
      });
    };

    handleAddItemToQuote = () => {
      const {
        event: { eventStart, eventStartTime, eventEnd, eventEndTime, items },
        product,
        setCartProps
      } = this.props;

      const { locationName } = product;
      const { quantity } = this.state;
      let newItems = { ...items };
      if (!newItems.hasOwnProperty(locationName)) {
        newItems[locationName] = [];
      }
      const oldItemIndex = newItems[locationName].findIndex(
        item => item.productId === product.id
      );
      let itemQuantity =
        oldItemIndex !== -1
          ? newItems[locationName][oldItemIndex].quantity + Number(quantity)
          : Number(quantity);
      const selected = showSelectedPrice(
        product,
        itemQuantity,
        combineDateAndTime(eventStart, eventStartTime),
        combineDateAndTime(eventEnd, eventEndTime),
        "items"
      );

      const newItem = {
        name: product.name,
        type: "RentalItemStandard",
        quantity: itemQuantity,
        productId: product.id,
        product: product,
        defaultPricing: product.defaultPricing,
        pricing: product.pricing,
        hourlyPrice: product.hourlyPrice,
        halfDayPrice: product.halfDayPrice,
        dailyPrice: product.dailyPrice,
        weeklyPrice: product.weeklyPrice,
        monthlyPrice: product.monthlyPrice,
        // flatPrice: product.flatPrice,
        flatPrices: product.flatPrices.map(
          flatPrice => ({
            id: "",
            name: flatPrice.name,
            amount: flatPrice.amount,
            pricingLabelId: flatPrice.pricing_label_id
          })
        ),
        description: product.description,
        holidayHourlyPrice: product.holidayHourlyPrice,
        selectedPrice: selected.total,
        selectedRate: selected.rate,
        duration: selected.duration,
        period: selected.period
      };

      if (oldItemIndex !== -1) {
        newItems[locationName][oldItemIndex] = newItem;
      } else {
        newItems[locationName].push(newItem);
      }

      setCartProps({
        items: newItems
      });

      this.setState({
        submitted: true
      });
    };

    handleAddBundleToQuote = () => {
      const {
        event: {
          rentalBundles
        },
        product: bundle,
        addBundleInCart,
        updateBundleInCart,
        setErrors
      } = this.props;

      const { locationName } = bundle;
      const { quantity } = this.state;

      if (quantity > 0) {
        let newRentalBundles = { ...rentalBundles };
        if (!newRentalBundles.hasOwnProperty(locationName)) {
          newRentalBundles[locationName] = [];
        }
        const rentalBundleIndex = newRentalBundles[locationName].findIndex(
          item => item.bundleId === bundle.id
        );
        if (rentalBundleIndex !== -1) {
          let bundleQuantity = newRentalBundles[locationName][rentalBundleIndex].quantity + Number(quantity);
          updateBundleInCart('quantity', bundleQuantity, rentalBundleIndex, locationName)
        } else {
          addBundleInCart(bundle, Number(quantity), locationName);
        }
        this.setState({
          submitted: true
        });
      } else {
        setErrors("Amount must be greater than zero.");
      }
    };

    toggleOpen = () => {
      this.setState({
        open: !this.state.open
      });
    };

    handleOpenSuggestionModal = () => {
      this.setState({ suggestionModalOpen: true });
    };

    handleCloseSuggestionModal = () => {
      this.setState({ suggestionModalOpen: false });
    };

    renderPricing = product => {
      if (hasSelectedFlatPrice(product, productType)) {
        const selectedFlatPrice = getSelectedFlatPrice(product);
        if (selectedFlatPrice) {
          return (
            <div className="pricing">
              <div>
                <CurrencyLabel value={selectedFlatPrice.amount} />
                <label>/ Flat: {selectedFlatPrice.name}</label>
              </div>
            </div>
          );
        }
      } else {
        const firstAvailablePricingType = getFirstAvailablePricingType(product, productType);

        if (firstAvailablePricingType) {
          return (
            <div className="pricing">
              {product.hourlyPrice && (
                <div>
                  <CurrencyLabel value={product.hourlyPrice} />
                  <label>/ Hour</label>
                </div>
              )}
              {product.pricing && (
                <div>
                  <CurrencyLabel value={product.pricing} />
                  <label>/ Each</label>
                </div>
              )}
              {product.halfDayPrice && (
                <div>
                  <CurrencyLabel value={product.halfDayPrice} />
                  <label>/ Half-Day</label>
                </div>
              )}
              {product.dailyPrice && (
                <div>
                  <CurrencyLabel value={product.dailyPrice} />
                  <label>/ Day</label>
                </div>
              )}
              {product.weeklyPrice && (
                <div>
                  <CurrencyLabel value={product.weeklyPrice} />
                  <label>/ Week</label>
                </div>
              )}
              {product.monthlyPrice && (
                <div>
                  <CurrencyLabel value={product.monthlyPrice} />
                  <label>/ Month</label>
                </div>
              )}

              {productType === "bundles" && !product.priceLocked && product.flatPrices &&
                product.flatPrices.map((flatPrice, index) =>
                  <div key={index}>
                    <CurrencyLabel value={flatPrice.amount} />
                    <label>/ Flat: {flatPrice.name}</label>
                  </div>
              )}

              {productType === "bundles" && product.purchasePrice && (
                <div className="fee">
                  <span>
                    +
                    <CurrencyLabel value={product.purchasePrice} />
                  </span>
                  <label>/ fee</label>
                </div>
              )}
            </div>
          );
        }
      }

      return <div className="pricing">Price Available Upon Request</div>;
    };

    renderImgixPicture = (picture, index) => {
      const { src, srcSet } = imgixUrl({
        src: picture.imgixUrl,
        width: 1020,
        height: 576
      });
      return (
        <div>
          <img src={src} style={{ display: "none" }} />
          <MediaBreakpoints
            desktop={<InnerImageZoom src={src} alt={src} srcSet={srcSet} />}
            mobile={
              <img
                height={288}
                width={"100%"}
                style={{ objectFit: "cover" }}
                alt={src}
                src={src}
                srcSet={srcSet}
              />
            }
          />
        </div>
      );
    };

    render() {
      const { product, event } = this.props;
      const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
      const { quantity, submitted, suggestionModalOpen } = this.state;

      return (
        <div className="product">
          <header>
            <SearchFilter />
          </header>
          <section className="content">
            <section className="images">
              {product.pictures !== undefined && product.pictures.length > 0 ? (
                product.pictures.length > 1 ? (
                  <Carousel
                    showArrows={true}
                    showThumbs={true}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={8000}
                  >
                    {product.pictures.map((picture, index) => {
                      return this.renderImgixPicture(picture, index);
                    })}
                  </Carousel>
                ) : (
                  <div>{this.renderImgixPicture(product.pictures[0], 0)}</div>
                )
              ) : (
                <figure>
                  <TapGoods />
                </figure>
              )}
            </section>
            <section className="summary">
              <h4>{product.name}</h4>
              {product.businessInfo.shopShowItemPricing &&
                this.renderPricing(product)}
              <div className="quantitySelect">
                <StandardTextField
                  type="text"
                  meta={{ error: {} }}
                  placeholder="Qty"
                  input={{
                    name: "quantity",
                    value: quantity,
                    onChange: this.handleChange
                  }}
                />
                {quantity > product.numberAvailable && (
                  <div
                    className="info"
                    data-tip
                    data-for="info"
                    data-event="click"
                    data-event-off="mouseleave"
                  >
                    <Info />
                  </div>
                )}
                {quantity > product.numberAvailable && (
                  <ReactTooltip
                    id="info"
                    class="tooltip bottom"
                    place="bottom"
                    type="light"
                    effect="solid"
                  >
                    <h3>Item not available for autobook</h3>
                    <p>
                      You're still available to add this to a quote, just note
                      this item needs an approval from the rental company before
                      you can rent it for your event.
                    </p>
                  </ReactTooltip>
                )}

                {submitted ? (
                  <Link className="btn" to="/cart">
                    View Cart
                  </Link>
                ) : (
                  <button className="btn" onClick={this.handleAddToQuote}>
                    Add To Quote
                  </button>
                )}
              </div>
            </section>

            <section className="details">
              <p>{product.description}</p>
              {productType === "bundles" &&
                product.showItemsToCustomer &&
                product.productBundleRelationships
                  .concat(product.addOnBundleRelationships)
                  .map((pbr, index) => (
                    <div key={index}>
                      <figure>
                        {pbr.primaryPhotoUrl !== "" ? (
                          <img
                            src={pbr.primaryPhotoUrl}
                            alt={pbr.primaryPhotoUrl}
                          />
                        ) : (
                          <TapGoods />
                        )}
                      </figure>
                      <strong>
                        x{pbr.quantity} {pbr.name}
                      </strong>
                      <p>{pbr.description}</p>
                    </div>
                  ))}
            </section>

            <section className="misc">
              <div className="dateRange">
                <p>Know your event date/time? Set it now.</p>
                <div>
                  <label>Event Start</label>
                  <DatePicker
                    name="eventStart"
                    value={eventStart}
                    onChange={this.handleDateChange}
                  />
                  <TimePicker
                    name="eventStartTime"
                    value={eventStartTime}
                    onChange={this.handleDateChange}
                  />
                </div>
                <div>
                  <label>Event End</label>
                  <DatePicker
                    name="eventEnd"
                    value={eventEnd}
                    onChange={this.handleDateChange}
                    startDate={eventStart ? eventStart : new Date()}
                  />
                  <TimePicker
                    name="eventEndTime"
                    value={eventEndTime}
                    onChange={this.handleDateChange}
                  />
                </div>
              </div>
              <div className="dimensions">
                {(product.category === "Linens" ||
                  product.category === "Tabletop") && (
                  <a onClick={this.handleOpenSuggestionModal}>
                    Need Help With Sizing?
                  </a>
                )}
                {(product.heightFt || product.height) && (
                  <div>
                    <label>H:</label>
                    <span>
                      {product.heightFt ? `${product.heightFt}'` : ""}{" "}
                      {product.height ? `${product.height}"` : ""}
                    </span>
                  </div>
                )}
                {(product.lengthFt || product.length) && (
                  <div>
                    <label>L:</label>
                    <span>
                      {product.lengthFt ? `${product.lengthFt}'` : ""}{" "}
                      {product.length ? `${product.length}"` : ""}
                    </span>
                  </div>
                )}
                {(product.widthFt || product.width) && (
                  <div>
                    <label>W:</label>
                    <span>
                      {product.widthFt ? `${product.widthFt}'` : ""}{" "}
                      {product.width ? `${product.width}"` : ""}
                    </span>
                  </div>
                )}
                {product.weight && (
                  <div>
                    <label>Weight:</label>
                    <span>{product.weight} lbs</span>
                  </div>
                )}
              </div>
              <div className="company">
                {product.businessInfo.picture && (
                  <figure>
                    <Imgix
                      src={product.businessInfo.picture.picture.imgixUrl}
                      alt={product.locationName}
                      width={510}
                      height={288}
                    />
                  </figure>
                )}
                <div className="information">
                  <Link
                    to={`/shop/search?locationId=${product.businessInfo.locationId}`}
                  >
                    {product.locationName}
                  </Link>
                  <div>
                    {product.businessInfo.phoneNumber &&
                      formatPhoneNumber(
                        product.businessInfo.phoneNumber.office
                      )}
                  </div>
                  <div>{product.businessInfo.email}</div>
                </div>
              </div>
            </section>
          </section>

          <Modal
            className="large"
            title="Table/Linen Suggestions"
            open={suggestionModalOpen}
            toggle={this.handleCloseSuggestionModal}
          >
            <SuggestionTable />
          </Modal>
        </div>
      );
    }
  }

  const mapStateToProps = state => {
    const product = state.products[apiToSingularRoot[productType]];
    const { event } = state.cart;
    const { inventoryFilter } = state.products;
    return { product, event, inventoryFilter };
  };

  return connect(mapStateToProps, actions, null, { pure: false })(
    WrappedComponent
  );
};

export default AvailableItem;
