import React from "react";
import ForgotPasswordForm from "Components/home/ForgotPasswordForm";
import * as actions from "Actions";
import { connect } from "react-redux";

class ForgotPassword extends React.Component {
  handleSubmit = data => {
    this.props.sendPasswordReset(data);
  };
  render() {
    return (
      <div className="forgotPassword">
        <header>
          <h1>Forgot Password</h1>
        </header>
        <section>
          <ForgotPasswordForm onSubmit={this.handleSubmit} />
        </section>
      </div>
    );
  }
}

export default connect(null, actions)(ForgotPassword);
