import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "../../utils/Radio";
import classNames from "classnames";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import StandardSelectField from "Utils/redux_form_inputs/StandardSelectField";
import Checkbox from "Utils/Checkbox";
import RegionDropdown from "Utils/RegionDropdown";

class EventFormFields extends React.Component {

  render() {
    let { event, errors, handleChange, handlePickupSameCheck } = this.props;
    errors = Object(errors);
    const {
      deliveryAddressLocationName,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry,
      pickupSameAsDelivery,
      pickupAddressLocationName,
      pickupAddressStreetAddress1,
      pickupAddressStreetAddress2,
      pickupAddressCity,
      pickupAddressLocale,
      pickupAddressPostalCode,
      pickupAddressCountry
    } = event;

    return (
      <div className="form">
        <label className="radio-group">Delivery/Pick Up</label>
        <RadioGroup
          name="deliveryType"
          className="radio-group"
          value={event.deliveryType ? event.deliveryType : ""}
          onChange={handleChange}
        >
          <FormControlLabel
            label="Delivery"
            value="default_delivery"
            control={<Radio />}
          />
          <FormControlLabel label="Ship" value="ship" control={<Radio />} />
          <FormControlLabel
            label="Pick Up"
            value="customer_pick_up"
            control={<Radio />}
          />
        </RadioGroup>
        {["default_delivery", "ship"].includes(event.deliveryType) && (
          <div className="fields">
            <StandardTextField
              type="text"
              meta={{}}
              placeholder="Venue Name (optional)"
              input={{
                name: "deliveryAddressLocationName",
                value: deliveryAddressLocationName,
                onChange: handleChange
              }}
            />
            <StandardSelectField
              meta={{
                touched: true,
                error: errors["rentals.delivery_address_country"]
              }}
              input={{
                name: "deliveryAddressCountry",
                value: deliveryAddressCountry,
                onChange: handleChange
              }}
            >
              <option value="">Select country</option>
              <option value="USA">United States</option>
              <option value="CAN">Canada</option>
            </StandardSelectField>
            <StandardTextField
              type="text"
              meta={{
                touched: true,
                error: errors["rentals.delivery_address_street_address_1"]
              }}
              className="lg"
              placeholder="Street Address *"
              input={{
                name: "deliveryAddressStreetAddress1",
                value: deliveryAddressStreetAddress1,
                onChange: handleChange
              }}
            />
            <StandardTextField
              type="text"
              meta={{}}
              className="sm"
              placeholder="Apt No."
              input={{
                name: "deliveryAddressStreetAddress2",
                value: deliveryAddressStreetAddress2,
                onChange: handleChange
              }}
            />
            <StandardTextField
              type="text"
              meta={{
                touched: true,
                error: errors["rentals.delivery_address_city"]
              }}
              className="med"
              placeholder="City *"
              input={{
                name: "deliveryAddressCity",
                value: deliveryAddressCity,
                onChange: handleChange
              }}
            />
            <RegionDropdown
              name="deliveryAddressLocale"
              defaultOptionLabel={
                deliveryAddressCountry === "USA" ? "State" : "Province"
              }
              className={classNames({
                sm: true,
                error: errors["rentals.delivery_address_locale"]
              })}
              disableWhenEmpty={true}
              countryValueType="short"
              labelType="short"
              valueType="short"
              country={deliveryAddressCountry}
              value={deliveryAddressLocale}
              onChange={handleChange}
            />
            <StandardTextField
              type="text"
              meta={{
                touched: true,
                error: errors["rentals.delivery_address_postal_code"]
              }}
              className="sm"
              placeholder="Zip *"
              input={{
                name: "deliveryAddressPostalCode",
                value: deliveryAddressPostalCode,
                onChange: handleChange
              }}
            />
          </div>
        )}

        {event.deliveryType === "default_delivery" && (
          <div className="fields">
            <Checkbox
              name="pickupSameAsDelivery"
              checked={pickupSameAsDelivery}
              onCheck={handlePickupSameCheck}
              label="Pick Up Address Is Same as Drop Off Address"
            />
          </div>
        )}

        {event.deliveryType === "default_delivery" && !pickupSameAsDelivery && (
          <div className="fields">
            <StandardTextField
              type="text"
              meta={{}}
              placeholder="Venue Name (optional)"
              input={{
                name: "pickupAddressLocationName",
                value: pickupAddressLocationName,
                onChange: handleChange
              }}
            />
            <StandardSelectField
              meta={{
                touched: true,
                error: errors["rentals.pickup_address_country"]
              }}
              input={{
                name: "pickupAddressCountry",
                value: pickupAddressCountry,
                onChange: handleChange
              }}
            >
              <option value="">Select country</option>
              <option value="USA">United States</option>
              <option value="CAN">Canada</option>
            </StandardSelectField>
            <StandardTextField
              type="text"
              meta={{
                touched: true,
                error: errors["rentals.pickup_address_street_address_1"]
              }}
              className="lg"
              placeholder="Street Address *"
              input={{
                name: "pickupAddressStreetAddress1",
                value: pickupAddressStreetAddress1,
                onChange: handleChange
              }}
            />
            <StandardTextField
              type="text"
              meta={{}}
              className="sm"
              placeholder="Apt No."
              input={{
                name: "pickupAddressStreetAddress2",
                value: pickupAddressStreetAddress2,
                onChange: handleChange
              }}
            />
            <StandardTextField
              type="text"
              meta={{
                touched: true,
                error: errors["rentals.pickup_address_city"]
              }}
              className="med"
              placeholder="City *"
              input={{
                name: "pickupAddressCity",
                value: pickupAddressCity,
                onChange: handleChange
              }}
            />
            <RegionDropdown
              name="pickupAddressLocale"
              defaultOptionLabel={
                pickupAddressCountry === "USA" ? "State" : "Province"
              }
              className={classNames({
                sm: true,
                error: errors["rentals.pickup_address_locale"]
              })}
              disableWhenEmpty={true}
              countryValueType="short"
              labelType="short"
              valueType="short"
              country={pickupAddressCountry}
              value={pickupAddressLocale}
              onChange={handleChange}
            />
            <StandardTextField
              type="text"
              meta={{
                touched: true,
                error: errors["rentals.pickup_address_postal_code"]
              }}
              className="sm"
              placeholder="Zip *"
              input={{
                name: "pickupAddressPostalCode",
                value: pickupAddressPostalCode,
                onChange: handleChange
              }}
            />
          </div>
        )}

        {["default_delivery"].includes(event.deliveryType) && (
          <div>
            <div className="fields">
              <label>{event.deliveryType === "ship" ? "Preferred Shipping Window" : "Preferred Delivery Window"}</label>
              <p>
                Setting this preference doesn't guarantee {event.deliveryType === "ship" ? "shipping" : "delivery" } in this
                window, it just gives us a heads up to schedule our trucks.
              </p>
              <StandardSelectField
                meta={{}}
                input={{
                  name: "preferredDeliveryWindow",
                  value: event.preferredDeliveryWindow,
                  onChange: handleChange
                }}
              >
                <option value="early">Early (7a-10a)</option>
                <option value="midday">Midday (10a-2p)</option>
                <option value="late">Late (2p-6p)</option>
              </StandardSelectField>
            </div>
            {event.deliveryType !== "ship" &&
            <div className="fields">
              <label>Preferred Pick Up Window</label>
              <p>
                Setting this preference doesn't guarantee delivery in this
                window, it just gives us a heads up to schedule our trucks.
              </p>
              <StandardSelectField
                meta={{}}
                input={{
                  name: "preferredPickupWindow",
                  value: event.preferredPickupWindow,
                  onChange: handleChange
                }}
              >
                <option value="early">Early (7a-10a)</option>
                <option value="midday">Midday (10a-2p)</option>
                <option value="late">Late (2p-6p)</option>
              </StandardSelectField>
            </div>
            }
          </div>
        )}
      </div>
    );
  }
}
export default EventFormFields;
