import { LOCATION_CHANGE } from "react-router-redux";
import {
  FIELD_CHANGED,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  CREATE_USER_FAIL,
  LOGIN_USER,
  SIGN_OUT_USER_SUCCESS,
  SIGN_OUT_USER_FAIL,
  SIGN_OUT_USER,
  ADMIN_LOGIN_USER,
  VALIDATE_USER,
  VALIDATE_USER_SUCCESS,
  VALIDATE_USER_FAIL
} from "Constants/redux/AuthConstants";

import converter from "json-style-converter/es5";

const INITIAL_STATE = {
  authenticated: false,
  email: "",
  password: "",
  passwordConfirmation: "",
  user: {
    firstName: "",
    lastName: "",
    createdAt: ""
  },
  business: {
    name: ""
  },
  errors: {
    email: "",
    password: "",
    login: "",
    signOut: ""
  },
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  let newPayload;
  switch (action.type) {
    case FIELD_CHANGED:
      const { field, text } = action.payload;
      return { ...state, [field]: text };
    case LOGIN_USER:
      return { ...state, loading: true, errors: INITIAL_STATE.errors };
    case ADMIN_LOGIN_USER:
      return { ...state, loading: true, errors: INITIAL_STATE.errors };
    case LOGIN_USER_SUCCESS:
      newPayload = converter.snakeToCamelCase(action.payload);
      return {
        ...state,
        ...INITIAL_STATE,
        user: newPayload,
        authenticated: true
      };
    case LOGIN_USER_FAIL:
      return {
        ...state,
        errors: { login: action.payload[0] },
        password: "",
        loading: false
      };
    case CREATE_USER_FAIL:
      const { email, password } = action.payload;
      return {
        ...state,
        errors: {
          email: email ? email[0] : "",
          password: password ? password[0] : ""
        }
      };
    case VALIDATE_USER:
      return { ...state, errors: INITIAL_STATE.errors, loading: true };
    case VALIDATE_USER_SUCCESS:
      newPayload = converter.snakeToCamelCase(action.payload);
      return {
        ...state,
        ...INITIAL_STATE,
        loading: false,
        user: newPayload,
        business: newPayload.business,
        authenticated: true
      };
    case VALIDATE_USER_FAIL:
      return { ...state, errors: INITIAL_STATE.errors, loading: false };
    case SIGN_OUT_USER:
      return { ...state, loading: true, errors: INITIAL_STATE.errors };
    case SIGN_OUT_USER_FAIL:
      return {
        ...state,
        errors: { signOut: action.payload[0] },
        password: "",
        loading: false
      };
    case SIGN_OUT_USER_SUCCESS:
      return { ...state, ...INITIAL_STATE, user: null, authenticated: false };
    case LOCATION_CHANGE:
      return { ...state, errors: INITIAL_STATE.errors };
    default:
      return state;
  }
};
